<div class="fs-buildings-wrapper" *ngIf="skeletonContentLoaded">
  <div>
    <fs-named-schedule-table [isInternal]="false" [isFacSchedulePage]="true" [siteRef]="siteRef"></fs-named-schedule-table>
  </div>

  <div [ngClass]="{'pointerDisable': isVofm}">
    <div class="p-t-24" *ngIf="siteName">
      <puc-special-schedules [specialSchedulesServerData]="buildingSettings?.buildingSpecialSchedule" [siteRef]="siteRef"
        [isZoneVac]="false" [siteTimeZone]="siteTz" [sliderDisplayFlag]="showSlider" [sliderInput]="sliderInput"
        [buildingLimits]="buildingLimit" [defaultLimits]="defaultLimits" [zoneName]="siteRef"
        [buildingOrZonename]="siteName" [refId]="siteRef" [isVofm]="isVofm"
        (specialSchedulerEmitEvent)="loadSchedulerData()"
        [unoccupiedSetback]="buildingSettings?.buildingLevelunoccupiedZoneSetback"
        [buildingToZoneDifferential]="buildingSettings?.buildingToZoneDifferential"
        [showRevampedSpecialScheduler]="showSchedulerOccupancy"></puc-special-schedules>
    </div>
  
    <div class="fs-buildings-wrapper__vacation">
      <puc-vacations [vacationsServerData]="vacationsData.buildingVacations" [roomRef]="vacationsData.roomRef"
        [siteRef]="vacationsData.siteRef" [siteTimeZone]="vacationsData.siteTimeZone"
        [isZoneVac]="vacationsData.isZoneVac" [isVofm]="isVofm">
      </puc-vacations>
    </div>
  
    <div class="p-t-24 building-schedule">
      <h3 class="fs-buildings-wrapper__schedule--heading" *ngIf="!showSchedulerOccupancy">Building Schedule</h3>
      <h3 class="fs-buildings-wrapper__schedule--heading" *ngIf="showSchedulerOccupancy">Building Occupancy</h3>
  
      <div *ngIf="!scheduleId  && !showSchedulerOccupancy" class="no-buildingSchedule"> Building Schedule entity does not
        exist</div>
      <div class="building-schedule-nonmigrated" *ngIf='isScheduleInfo && !showSchedulerOccupancy'>
        <puc-scheduler refType="siteRef" [scheduleInfo]="scheduleInfo" [scheduleId]="scheduleId" [scheduleType]="0"
          [defaultLimits]="defaultLimits" [sliderDisplayFlag]="showSlider" [siteTz]="siteTz"
          [buildingLimits]="buildingLimit" [sliderInput]="sliderInput" [zoneName]="siteRef" [refId]="siteRef"
          [siteId]="siteRef" (schedulerEmitEvent)="setSliderData()" [allZoneSchData]="allZonesData" [isVofm]="isVofm">
        </puc-scheduler>
      </div>
      <div *ngIf="showSchedulerOccupancy" class="building_Occupancy_Container">
        <puc-scheduler-occupany [allZoneSchData]="allZonesData" [siteId]="siteRef"
          [scheduleId]="buildingSettings?.buildingOccupancy?.id" [siteTz]="siteTz"
          [scheduleInfo]="buildingSettings?.buildingOccupancy?.val"></puc-scheduler-occupany>
      </div>
  
    </div>
  </div>
</div>

<!--Skeleton Mock Code-->
<div class="fs-buildings-wrapper" *ngIf="!skeletonContentLoaded">
  <div class="fs-buildings-wrapper__schedule">
    <div> <ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader></div>
    <div class="fs-buildings-wrapper__schedule__occupancy-status">
      <ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '500px'}"></ngx-skeleton-loader>
    </div><br>
    <div>
      <ngx-skeleton-loader [theme]="{ 'border-radius': '0', height: '250px'}"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="fs-buildings-wrapper__vacation">
    <ngx-skeleton-loader [theme]="{ 'border-radius': '0', height: '40px'}"></ngx-skeleton-loader>
    <div id="skeleton-vacation">
      <div class="skeleton-vacation-value" *ngFor="let repeat of [0,1,2,3]">
        <div class="skeleton-vacation-width" *ngFor="let repeat of [0,1,2,3]">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>