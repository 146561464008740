import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedModule } from '@/shared/shared.module'
import { Routes, RouterModule } from '@angular/router';
import { AlertsLayoutComponent } from './components/alerts-layout/alerts-layout.component';
import { AlertModule } from '@75f/portal-ui-components';

const routes: Routes = [
    { path: '', component: AlertsLayoutComponent }
];


@NgModule({
    declarations: [
        AlertsLayoutComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        AlertModule,
        FormsModule,
        NgxSkeletonLoaderModule,
        SharedModule,
        NgxDaterangepickerMd.forRoot(),
    ]
})
export class AlertsListModule { }
