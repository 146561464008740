import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  ChillerPlantMonitoringComponent
} from './components/chiller-plant-monitoring/chiller-plant-monitoring.component';

const routes: Routes = [
  {
    path: '',
    component: ChillerPlantMonitoringComponent
  }
];


@NgModule({
  declarations: [ChillerPlantMonitoringComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NgxSkeletonLoaderModule
  ]
})
export class ChillerPlantMonitoringModule { }
