<div class="w-100">
    <mat-radio-group class="row" [(ngModel)]="certificationLevel" >
        <mat-radio-button [disabled]="true" class="col-md-3 first-level-option" [id]="'non-certified'" [value]="1" [checked]="true">
            <span>
                <label class="certification-label">Non-Certified Installer</label>
                <i (mouseover)="updateAccessInfo('1')" class="fa fa-info-circle access-details m-r-3 m-l-8"
                    [tippy]="accessInfoToolTip" placement="bottom">
                </i>
            </span>
        </mat-radio-button>
        
        <mat-radio-button [disabled]="true" class="col-md-3 level-options" [id]="'certified-L1'" [value]="2">
            <span>
                <label class="certification-label">Certified Installer L1</label>
                <i (mouseover)="updateAccessInfo('2')" class="fa fa-info-circle access-details m-r-3 m-l-8"
                    [tippy]="accessInfoToolTip" placement="bottom">
                </i>
            </span>
        </mat-radio-button>
    
        <mat-radio-button [disabled]="true" class="col-md-3 level-options" [id]="'certified-L2'" [value]="3">
            <span>
                <label class="certification-label">Certified Installer L2</label>
                <i class="fa fa-info-circle access-details m-r-3 m-l-8" (mouseover)="updateAccessInfo('3')"
                    [tippy]="accessInfoToolTip" placement="bottom">
                </i>
            </span>
        </mat-radio-button>
    
        <mat-radio-button [disabled]="true" class="col-md-3 level-options" [id]="'certified-integrator'" [value]="4">
            <span>
                <label class="certification-label">Certified Integrator</label>
                <i class="fa fa-info-circle access-details m-r-3 m-l-8" (mouseover)="updateAccessInfo('4')"
                    [tippy]="accessInfoToolTip" placement="bottom">
                </i>
            </span>
        </mat-radio-button>
    </mat-radio-group>
</div>

<ng-template #accessInfoToolTip>
    <div class="tooltip">
        <div class="tooltip-header entity-tooltip entity-tooltip">
            <h5 class="tooltip-header">Access to</h5>
            <div class="tool-text">
                <span class="sub-text" *ngIf="isNonCertifiedUser">View Only {{appName}}</span>
                <span class="sub-text" *ngIf="!isNonCertifiedUser">{{appName}}</span><br>
                <span class="feature-options" *ngFor="let item of filteredFeatureItems">
                    - {{item}} <br>
                </span>
            </div>
        </div>
    </div>
</ng-template>