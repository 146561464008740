import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedModule } from '@/shared/shared.module'
import { Routes, RouterModule } from '@angular/router';
import { BuildingScheduleComponent } from './components/building-schedule/building-schedule.component';
import { PucComponentsModule } from '@75f/portal-ui-components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const routes: Routes = [
    { path: '', component: BuildingScheduleComponent }
];

@NgModule({
    declarations: [
        BuildingScheduleComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        NgxSkeletonLoaderModule,
        SharedModule,
        NgxDaterangepickerMd.forRoot(),
        PucComponentsModule
    ],
    providers: []
})
export class BuildingScheduleModule { }
