import { ConfigurationService } from '@75f/portal-ui-components';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fs-installer-levels',
  templateUrl: './installer-levels.component.html',
  styleUrls: ['./installer-levels.component.scss']
})
export class InstallerLevelsComponent {

  featureItems = [
    'Audit Trail',
    'Custom Alerts',
    'Custom Analytics',
    'Custom Parameters',
    'Custom Summary Table',
    'Energy Configuration',
    'Notes',
    'Site Manager',
    'Site Sequencer',
    'Remote Access',
    'Building Options'
  ];

  filteredFeatureItems: any;
  isNonCertifiedUser: boolean = false;


  appName: string = '';

  constructor(public config: ConfigurationService) {
    this.appName = this.config.getConfig('appDisplayName');
  }

  @Input() control: FormControl; // Input property to accept the form control

  // Getter and setter to bind the control's value
  get certificationLevel() {
    return this.control?.value;
  }

  set certificationLevel(value: string) {
    this.control.setValue(value);
  }

  updateAccessInfo(certificationLevel) {
    if (certificationLevel == "1") {
        // Update featureItems to only empty array
        this.filteredFeatureItems = [];
        this.isNonCertifiedUser = true;
    } else if (certificationLevel === "2") {
        // Update featureItems to only empty array
        this.filteredFeatureItems = [];
        this.isNonCertifiedUser = false;
    } else if (certificationLevel === "3") {
        // Filter out 'Site Manager' from featureItems
        this.filteredFeatureItems = this.featureItems.filter(item => item !== 'Site Manager' && item !== 'Site Sequencer' );
        this.isNonCertifiedUser = false;
    } else if (certificationLevel === "4") {
        // Restore the original list of featureItems
        this.filteredFeatureItems = this.featureItems;
        this.isNonCertifiedUser = false;
    }
}

}
