import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/shared/shared.module';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { AccountManagementComponent } from './pages/account-management/account-management.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { TransferOwnershipComponent } from './pages/transfer-ownership/transfer-ownership.component';
import { AccountManagementRoutingModule } from './account-management-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RoleDetailsComponent } from './pages/role-details/role-details.component';
import { PucDirectivesModule, PucComponentsModule } from '@75f/portal-ui-components';
import { NotificationManagementComponent } from './pages/notification-management/notification-management.component';

@NgModule({
  declarations: [AccountDetailsComponent, AccountManagementComponent, ChangePasswordComponent, TransferOwnershipComponent, RoleDetailsComponent, NotificationManagementComponent],
  imports: [
    CommonModule,
    AccountManagementRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    PucComponentsModule,
    PucDirectivesModule
  ],
  exports: []
})
export class AccountManagementModule { }
