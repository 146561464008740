import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@/shared/shared.module';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { Routes, RouterModule } from '@angular/router';
import { ZonesLayoutComponent } from './components/zones-layout/zones-layout.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
const routes: Routes = [
    { path: '', component: ZonesLayoutComponent }
];

@NgModule({
    declarations: [
        ZonesLayoutComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        NgxSkeletonLoaderModule,
        NgxDaterangepickerMd.forRoot(),
        CommonModule,
        FormsModule,
        SharedModule,
    ],
    providers: [

    ]
})
export class ZonesListModule { }
