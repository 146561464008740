export const settingsMenu: any[] = [
    {
        label: 'Floor Planner',
        path: ['/settings/floorplan'],
        key: 'floor'
    },
    {
        label: 'Tuners',
        path: ['/settings/tuners'],
        key: 'tuner'
    },
    {
        label: 'Building Options',
        path: ['/settings/buildingOptions'],
        key: 'building-options'
    },
    {
        label: 'Remote Access',
        path: ['/settings/remote-access'],
        key: 'remote-ccu-access'
    }
];
