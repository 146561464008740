import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '@75f/portal-ui-components';


@Injectable({
	providedIn: 'root'
})
export class PamWidgetService {
	pasUrl = this.configService.getConfig('pasUrl');

	constructor(private httpClient: HttpClient,
		private configService: ConfigurationService) {
	}
	saveWidget(widgetSelections, widgetSelectionsTransformed): Observable<any> {
		return new Observable(observer => {
			this.saveWidgetAfterTransformation(widgetSelections, widgetSelectionsTransformed).subscribe(saveResponse => {
				observer.next(saveResponse);
				observer.complete();
			}, err => observer.error(err));
		});
	}


	saveWidgetAfterTransformation(widgetSelections, widgetSelectionsTransformed) {
		let url;
		if (widgetSelectionsTransformed.widgetType == 'customTrendChart') {
			url = `${this.pasUrl}/trendchart-custom`;
		} else {
			url = `${this.pasUrl}/dashboard/widget`;
		}

		if (widgetSelections['widgetId']) {
			// PUT request doesn't accept any of these fields
			delete widgetSelectionsTransformed['dashboardId'];
			if (widgetSelections.chartType != 'trend' && widgetSelections.chartType != 'embedExternalWebPage') {
				delete widgetSelectionsTransformed['widgetType'];
			}

			// delete widgetSelectionsTransformed['customWidgetType'];

			return this.httpClient.put(`${url}/${widgetSelections['widgetId']}`, widgetSelectionsTransformed)
				.pipe(
					catchError(this.handleError)
				);
		} else {
			// return of({widgetId: uuidv4()});
			return this.httpClient.post(`${url}`, widgetSelectionsTransformed)
				.pipe(
					catchError(this.handleError)
				);
		}
	}

	private handleError(err: any) {
		// May send the server to some remote logging infrastructure instead of just logging it to the console
		let errorMessage: string;
		if (err.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			errorMessage = `An error occurred: ${err.error.message}`;
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			errorMessage = `Backend returned code ${err.status}: ${err.body?.error}`;
		}
		console.error(err);
		return throwError(err);
	}

	deleteWidget(widgetId): Observable<any> {
		return this.httpClient.delete(`${this.pasUrl}/dashboard/widget/${widgetId}`)
			.pipe(
				catchError(this.handleError)
			);
	}

}