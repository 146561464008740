import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CCUALERTService {
    public _selectedSites: any[] = [];
    public _totalAlertCount: any;
    public _alertOf: any;
    public alertCountEvent: EventEmitter<any>;
    public refreshAlertCountEvent: EventEmitter<any>;
    propertyNameAndValue: any;

    constructor() {
        this.alertCountEvent = new EventEmitter<any>();
        this.refreshAlertCountEvent = new EventEmitter<any>();
    }

    raiseEvent(propertyNameAndValue: any): void {
        this.propertyNameAndValue = propertyNameAndValue;
        let url = localStorage.getItem('url');
        this.alertCountEvent.emit({ 'list': propertyNameAndValue });
    }

    refreshAlertCountEventTrigger() {
        this.refreshAlertCountEvent.emit("refresh");
    }

    getSelectedSites() {
        return this._selectedSites;
    }

    setSelectedSites(selectedSites: any[]) {
        this._selectedSites = selectedSites;
    }

    getTotalAlertCount() {
        return this._totalAlertCount;
    }

    setTotalAlertCount(totalAlertCount: any) {
        this._totalAlertCount = totalAlertCount;
    }

    getAlertOf() {
        return this._alertOf;
    }

    setAlertOf(alertOf: any) {
        this._alertOf = alertOf;
        let sendValues = { 'selectedSites': this._selectedSites, 'totalAlertCount': this._totalAlertCount, 'alertOf': this._alertOf };
        this.raiseEvent(sendValues);
    }

}

