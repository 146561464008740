import {
	AlertService,
	HelperService,
	SiteService,
	RemoteInstallerOptionsSliderComponent,
	RemoteInstallerOptionsService, FeatureToggleService, AuthenticationService
} from '@75f/portal-ui-components';
import {
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import { CCUALERTService } from '@shared/_services';
import { MatDialog } from '@angular/material/dialog';
import sortBy from 'lodash-es/sortBy';
import { Subject, takeUntil } from 'rxjs';
import { settingsMenu } from '@/modules/settings/menu-constant';
import { HelperServiceFacilisight } from '@/shared/_services/helper.service';
@Component({
    selector: 'fs-building-options',
    templateUrl: './building-options.component.html',
    styleUrls: ['./building-options.component.scss'],
})
export class BuildingOptionsComponent implements OnInit {

	@ViewChild('remoteInstaller', { static: false }) remoteInstallerOptionsSliderComponent: RemoteInstallerOptionsSliderComponent;

	showFloorPlan: boolean = true;
	showRevampedMasterController: boolean = false;
	siteRef: string;
	selectedFloor = '';
	submenus: any[] = settingsMenu;
	isSiteActive: Boolean = false;
	rooms: any[] = [];
	originalRooms: any[] = [];
	floors: any = [];
	displayOrientation: Boolean = false;
	showRemoteAccess: Boolean = false;
	private unsubscribe: Subject<void> = new Subject();
	constructor(
		public authService: AuthenticationService,
		public siteService: SiteService,
		public helperService: HelperService,
		public alertService: AlertService,
		public ccuAlertService: CCUALERTService,
		public remoteInstallerService: RemoteInstallerOptionsService,
		public dialog: MatDialog,
		public featureToggleService: FeatureToggleService, 
		public facHelperService:  HelperServiceFacilisight
	) {
	}

	ngOnInit(): void {
		this.helperService.clearData();
		let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
		this.getBuildingFloors(buildingDetails.siteId);
		this.useFeatureToggles();
	}

	getBuildingFloors(siteRef: string) {
		this.isSiteActive = true;
		this.rooms = [];
		this.siteRef = this.stripHayStackTags(siteRef).split(' ')[0];
		this.checkForMigration(this.siteRef);
		this.selectedFloor = '';
		this.siteService.getSiteFloors(this.siteRef).subscribe(({ rows }) => {
				this.floors = sortBy(rows, [floor => floor.dis.toLowerCase()], ['asc']);
				//get rooms associated with floor
				this.floors.forEach(floor => {
						let floorId = this.stripHayStackTags(floor.id).split(' ')[0];
						this.siteService.getFloorRooms(floorId).subscribe(({ rows }) => {
								let index = this.floors.findIndex(f => this.stripHayStackTags(f.id).split(' ')[0] == floorId);
								if (index > -1) {
										this.floors[index]['rooms'] = rows;
										this.floors[index]['showDelete'] = rows.length;
								}

						}, err => {
						});
				});
		});
		this.displayOrientation = false;
		this.getPointByQuery();
	}

	stripHayStackTags(dataInput) {
		return this.helperService.stripHaystackTypeMapping(dataInput);
	}

	checkForMigration(selectedBuilding) {
		this.showRevampedMasterController = false;
		this.remoteInstallerService.getMigrationPoints(selectedBuilding).subscribe(res => {
			if (res?.rows?.length) {
				const pointIds = [];
				res.rows.forEach(r => pointIds.push(this.stripHayStackTags(r.id)))
				this.siteService.getBulkWritablePointData(pointIds).subscribe(res => {
					if (res?.rows?.length) {
						for (let data of res.rows) {
							if (data.data.length > 0) {
								this.showRevampedMasterController = true;
							}
						}
					}
				}, () => { }, () => { 
					this.remoteInstallerOptionsSliderComponent.showRemoteInstallerOptionsNew = this.showRevampedMasterController;
					this.remoteInstallerOptionsSliderComponent.setOptions();
					this.remoteInstallerService.setBuildingId(selectedBuilding, this.showRevampedMasterController); 
				});
			} else {
				this.showRevampedMasterController = false;
				this.remoteInstallerOptionsSliderComponent.showRemoteInstallerOptionsNew = this.showRevampedMasterController;
				this.remoteInstallerOptionsSliderComponent.setOptions();
				this.remoteInstallerService.setBuildingId(selectedBuilding, this.showRevampedMasterController)
			}
		}, (err) => { this.showRevampedMasterController = false },
			() => { })
	}

	checkUserCertificationAccess(view) {
      return this.facHelperService.checkCertification(view,this.showRemoteAccess);
    }

	getPointByQuery() {
        let displayUnitQuery;
        const siteRefPart = `siteRef==@${this.siteRef}`;
        displayUnitQuery = `${siteRefPart} and ((writable and point and displayUnit))`;//use the or to add the point to the query EX: ((writable and point and displayUnit) or (writable and point and user and cooling and min and limit))
        this.siteService.findByQuery(displayUnitQuery).subscribe(res => {
            if (res && res.rows) {
                this.getPointData(res.rows);
            }
        })
    }

    getPointData(pointRows) {
        let resPoints = [];
        pointRows.forEach(pointData => {
            if (pointData.building && pointData.displayUnit && pointData.point && pointData.writable) {
                resPoints.push({
                    name: 'displayUnit',
                    tags: ['building', 'displayUnit', 'writable', 'point'],
                    id: pointData.id
                })
            }
        });
        if (resPoints.length) {
            this.getDataFromPointIds(resPoints);
        }
    }

    getDataFromPointIds(pointsData) {
        pointsData.forEach(element => {
            this.helperService.assemblePointIdData(this.helperService.stripHaystackTypeMapping(element.id), 'write', element.name, '', 'update');
        });
        this.getUserSettingData();
    }

    getUserSettingData() {
        let pointCurrentData: any = {};
        this.helperService.getPointData().subscribe(res => {
            if (res) {
                pointCurrentData.displayUnit = { val: res.displayUnit.val, level: '16' }
            }
        });
    }

	useFeatureToggles() {
        let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags) => {
            this.setFlags(flags);
            sub.unsubscribe();
        });
        this.featureToggleService.getFlags();
    }

    setFlags(flags) {
        this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value : false;
    }



}
