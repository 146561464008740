import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fs-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  version = '';
  contactInfo: any;
  copyright: string;
  versionText: string;
  constructor() { }

  ngOnInit(): void {
    this.versionText = environment.portalVersionText;
    this.version = environment.version;
    this.contactInfo = environment.contact;
    this.copyright = `Copyright &copy; ${new Date().getFullYear()} ${environment.copyright}`
  }

}
