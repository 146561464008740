<div class="sidenav-wrapper">
    <nav class="fs-global-sidebar">
        <ul>
            <li id="site-overview" class="fs-global-sidebar__item p-l-15" (mouseenter)="open($event)" (mouseleave)="close($event)"
                [routerLink]="['/heatmap']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-building"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">SITE OVERVIEW</span>
                </a>
            </li>
            <li id="scheduling" class="fs-global-sidebar__item p-l-15" (mouseenter)="open($event)" (mouseleave)="close($event)"
                [routerLink]="['/scheduling']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-calendar-check"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">SCHEDULING</span>
                </a>
            </li>
            <li id="system-operations" class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
                [routerLink]="['/systemoperations']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-laptop p-l-0"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">SYSTEM OPERATIONS</span>
                </a>
            </li>
            <li id="zones" class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
                [routerLink]="['/zones']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-th-large p-l-2"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">ZONES</span>
                </a>
            </li>
            <li id="cpm" *ngIf="cpm && isSiteHasPublishedPlants" class="fs-global-sidebar__item" (mouseenter)="open($event)"
                (mouseleave)="close($event)" [routerLink]="['/cpm']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-chart-area p-l-2"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">CPM</span>
                </a>
            </li>
            <!-- commented temporarily -->
            <!-- <li class="fs-global-sidebar__item " (mouseenter)="open($event)" (mouseleave)="close($event)"
				[routerLink]="['/energymanagement']" [routerLinkActive]="['active']">
				<a>
					<i class="fas fa-bolt"></i>
					<span class="fs-global-sidebar__item__open-close-container"
						[@openClose]="isExpanded ? 'open' : 'closed'">ENERGY MANAGEMENT</span>
				</a>
			</li> 
            <li class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
                [routerLink]="['/occupancyanalysis']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-users"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">OCCUPANCY ANALYSIS</span>
                </a>
            </li>
            
             <li class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
				[routerLink]="['/graphs']" [routerLinkActive]="['active']">
				<a>
					<i class="fas fa-chart-area"></i>
					<span class="fs-global-sidebar__item__open-close-container"
						[@openClose]="isExpanded ? 'open' : 'closed'">GRAPHS</span>
				</a>
            </li> -->
            <li id="site-analytics" class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
                [routerLink]="['/energy-dashboard',siteRef]" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-chart-pie" aria-hidden="true"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">SITE ANALYTICS</span>
                </a>
            </li>
            <li id="alerts" class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
                (click)="navigateToAlerts()" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-bell p-l-2"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">ALERTS</span>
                </a>
            </li>
            <li id="site-explorer" class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
                [routerLink]="['/site-explorer']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-sitemap"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">SITE EXPLORER</span>
                </a>
            </li>
            <li id="settings" *ngIf="!isSettingsAccessable" class="fs-global-sidebar__item" (mouseenter)="open($event)"
                (mouseleave)="close($event)" [routerLink]="['/settings/floorplan']" [routerLinkActive]="['active']">
                <a>
                    <i class="fas fa-cogs"></i>
                    <span class="fs-global-sidebar__item__open-close-container"
                        [@openClose]="isExpanded ? 'open' : 'closed'">SETTINGS</span>
                </a>
            </li>
            <!-- commented temporarily -->
            <!-- <li class="fs-global-sidebar__item" (mouseenter)="open($event)" (mouseleave)="close($event)"
				[routerLink]="['/iftt']" [routerLinkActive]="['active']">
				<a>
					<i class="fas fa-sign-out-alt"></i>
					<span class="fs-global-sidebar__item__open-close-container"
						[@openClose]="isExpanded ? 'open' : 'closed'">IFTT</span>
				</a>
			</li> -->
            <!-- <li 
				class="fs-global-sidebar__item"
				(mouseenter)="open($event)" 
				(mouseleave)="close($event)" 
				[routerLink]="['/user-management']"  
				[queryParams]="{type: 'allUser'}"
				[routerLinkActive]="['active']">
				<a> 
					<i class="fas fa-users"></i>
					<span
						class="fs-global-sidebar__item__open-close-container"
						[@openClose]="isExpanded ? 'open' : 'closed'"
					>User Management</span>
				</a>	
			</li> -->
            <li>
                <i></i>
                <span class="fs-global-sidebar__item__open-close-container-last-block"
                    [@openClose]="isExpanded ? 'open' : 'closed'"></span>
            </li>
        </ul>
    </nav>
</div>