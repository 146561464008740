import { ErrorHandler, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {AnalyticsService} from 'src/app/shared/_services/analytics.service'

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  public errorEvent: Subject<Error> = new Subject<any>();
  constructor(private analyticsService:AnalyticsService) { }

  handleError(error: any): void {
    console.error('ErrorService.handleError', error);
    if (error instanceof EvalError) {
      this.analyticsService.logException( error.stack, false);

    } else if (error instanceof RangeError) {
      this.analyticsService.logException( error.stack, false);

    } else if (error instanceof ReferenceError) {
      this.analyticsService.logException( error.stack, false);

    } else if (error instanceof SyntaxError) {
      this.analyticsService.logException( error.stack, false);

    } else if (error instanceof TypeError) {
      this.analyticsService.logException( error.stack, false);

    } else if (error instanceof URIError) {
      this.analyticsService.logException( error.stack, false);

    } else if (error instanceof ErrorEvent) {
      this.analyticsService.logException(error, false);

    } else {
      if (error.error) {
        this.analyticsService.logException(error.error, false);
      }
    }
  }
}
