import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MaterialModule } from '@/shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@/shared/shared.module';
import { EnergyConfigurationLayoutComponent } from '@75f/portal-ui-components';
import { EnergyConfigDeactivateGuard } from '@75f/portal-ui-components';
import { energyconfigurationModule } from '@75f/portal-ui-components';
import { PucPipesModule } from '@75f/portal-ui-components';
import { EnergyCategoryResolver } from '@75f/portal-ui-components';


const routes: Routes = [
    {
        path: '', component: EnergyConfigurationLayoutComponent
    },
    {
        path: ':siteId', component: EnergyConfigurationLayoutComponent,
        canDeactivate: [EnergyConfigDeactivateGuard],
        resolve: { energyCategories: EnergyCategoryResolver }
    }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        energyconfigurationModule,
        PucPipesModule,
        RouterModule.forChild(routes)
    ],
    entryComponents:[]
})
export class EnergyConfigurationMangementModule { }
