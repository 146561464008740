import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { SiteExplorerLayoutComponent } from './components/site-explorer-layout/site-explorer-layout.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', component: SiteExplorerLayoutComponent }
];
@NgModule({
    declarations: [
        SiteExplorerLayoutComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    providers: []
})
export class SiteExplorerModule { }
