import { RouterModule, Routes } from '@angular/router';

import { CommonModule } from '@angular/common';
import { EnergyDashboardLayoutComponent } from './components/energy-dashboard-layout/energy-dashboard-layout.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const routes: Routes = [
    {
        path: '',
        component: EnergyDashboardLayoutComponent
    },
    {
        path: ':id',
        component: EnergyDashboardLayoutComponent
    },
];
@NgModule({
    declarations: [EnergyDashboardLayoutComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        NgxSkeletonLoaderModule
    ]
})
export class EnergyDashboardModule { }
