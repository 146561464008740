import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/_services/error-handler.service';
import { AppComponent } from '@/app.component';
import { routing } from '@/router';
import {
    SidebarComponent
} from '@/shared/_components';
import { SharedModule } from '@/shared/shared.module';
import { ProtectedComponent } from './components/protected/protected.component';
import { ConfigurationService, HttpCancelInterceptor, JwtInterceptor } from '@75f/portal-ui-components';
import { TopbarComponent } from './shared/_components/fs-topbar';
import { AboutComponent } from './shared/_components/about/about.component';
import { environment } from 'src/environments/environment';

const appInitializerFn = (appConfig: ConfigurationService) => {
    return () => {
        return appConfig.setConfig(environment.config);
    };
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        routing
    ],
    declarations: [
        AppComponent,
        TopbarComponent,
        SidebarComponent,
        AboutComponent,
        ProtectedComponent  ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            deps: [ConfigurationService],
            multi: true
        },
        { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
        { provide: 'WINDOW', useFactory: getWindow },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: 'environment', useValue: environment.config }
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        SharedModule
    ],
    entryComponents:[AboutComponent]
})

export class AppModule { }

export function getLocalStorage() {
    return (typeof window !== "undefined") ? window.localStorage : null;
}

export function getWindow(): any {
    return (typeof window !== "undefined") ? window : {};
}
