import { AuthenticationService } from '@75f/portal-ui-components';
import { Component } from '@angular/core';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss']
})
export class AccountManagementComponent {
/**
 *  Initially showPasswordLink is assigned as 'false'.
 */
  showPasswordLink: boolean = false;

  constructor(public authService: AuthenticationService) {
    this.isShowPasswordOption();
  }
/**
 *  In this method  if strategy == 'true' then showPasswordLink is assigned as 'true'.
 */
  isShowPasswordOption() {
    this.showPasswordLink = this.authService.getUser() ? this.authService.getUser().strategy == 'pass' : true;
  }

}
