

import {
    Component,
    OnInit,
    SimpleChange,
    SimpleChanges,
    HostListener,
    OnChanges,
    ViewChild,
    OnDestroy,
    ElementRef,
    AfterViewInit
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil, map, debounceTime, distinctUntilChanged ,first} from 'rxjs/operators';
import { Subject, Observable, Subscription, forkJoin, interval, lastValueFrom } from 'rxjs';
import { SecurityContext } from '@angular/core';
import { HeatmapHeaderComponent } from '../heatmap-header/heatmap-header.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    PucSystemVavComfortIndexGraphWidget, PucSystemDabComfortIndexGraphWidget, ObjectUtil,
    DateUtil, PucUserIntentTempSliderValueChangedIds, PucSliderInputOutputData,
    PucUserLimitDataSources, ZoneSettingsService,SliderUserIntentService, MessageService,
    LoaderService,ExportService,GraphService, ConfirmModalComponent, SiteService,
    VacationsService, HelperService, PubNubService,RuntimeGraphService, HeatMapToolTipService, 
    DeviceHelperService, CustomHeatmapService, HeatmapConnectService, SchedulerService, 
    ArrayUtil, UserService, HeatmapService,UnitService,ConfigurationService, AuthenticationService, NamedScheduleService, SchedulerEditModalComponent, IntrinsicSchedulesComponent, FeatureToggleService, SummaryTableHelperService,
    systemProfileTagsArray, systemProfileOaoTagsArray, systemProfileFilterTags, systemProfileFilterOAOTags, AlertService, AnalyticsVisBuilderModalComponent, HeatmapFilterService
    ,zoneProfile_vav, zoneProfile_dab, zoneProfile_cpu_hyperstat, zoneProfile_cpu_non_hyperstat,systemprofileWidgets,
    zoneProfile_hpu_non_hyperstat, ZoneProfile_2pipe_non_hyperstat, zoneProfile_pipe4, zoneProfile_emr, zoneProfile_pid, zoneProfile_sse, zoneProfile_sense, zoneProfile_monitoring, zoneProfile_vrv, zoneReconfig_ti, zoneProfile_bpos,
    zoneProfile_otn, zoneProfile_dualDuct, zoneProfile_series, zoneProfile_parallel, zoneProfile_hyperstathpu, zoneProfile_hyperstatPipe2, NotesService, zoneProfile_hyperstatCpu_specific, zoneProfile_hyperstatSplit_specific,zoneProfile_chilledBeam, PointSummaryListComponent,
    systemprofileEquipGraphics, AnalyticsListComponent, vavAdvancedAhuV2EquipGraphics, AnalyticsLayoutService
} from '@75f/portal-ui-components';
import { MatSelect } from '@angular/material/select';
import { CCUALERTService } from '@/shared/_services';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { ProfileWidgets, UserIntent } from '@/shared/_models';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import { environment } from 'src/environments/environment';
import uniq from 'lodash-es/uniq';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ActionType, ChatCommand, ChatEventsService, WidgetResponse } from '@75f/chat-ui';
interface CustomGlobalHeatmapDashboard {
    id: string,
    ownerUserId: string,
    siteRef: string,
    isPublished: boolean,
    updatedDateTime: string,
    globalDashboardId: string,
    entityDashboards: {}
}
@Component({
    selector: 'fs-heatmap-layout',
    templateUrl: './heatmap-layout.component.html',
    styleUrls: ['./heatmap-layout.component.scss'],
})
export class HeatmapLayoutComponent implements OnInit,AfterViewInit , OnChanges, OnDestroy {
    showlastUpdatedToggleEnabled: boolean = false;
    openedSchedulerEditModal = false;
    newSchedulerOnNamedSchedule: any;
    zonehasDomainName:any;
    ranges;
    checkForEventType:any;
    equipGraphicSystemProfile:any
    equipGraphicsProfiles:any;
    cmVersion;
    occStatusValues: string[] = ['Occupied', 'Unoccupied', 'Tenant Override'];
    OccStatus:any;
    userTempPreference:any;
    systemLevelEquipGraphics: boolean = false;
    humidifier:boolean;
    hsCpuWidgetsData: Array<any> = [];
    deHumidifier:boolean;
    customWidgetDataForExport = {};
    isDomainIntegrated: boolean = false;
    highlightCCU = '';
    userPrefData = {
        ccuOrder:[]
    };
    widgetCheck:any;
    checkForHyperSense:any;
    OaoShow: boolean;
    screenfloors:any=[];
    vacationSkeletonLoader : boolean;
    hideDisplayUIBtuText:boolean =false;
    hideDisplayUIModbusText:boolean = false;
    showModbus:boolean;
    modbusType:string;
    hideDisplayUIText:boolean =false;
    overlayHeight;
    skeletonContentLoaded: boolean;
    isTIZone: boolean = false;
    showModbusEmr: boolean;
    showBtu: boolean;
    showTiProfile: any;
    showEmr = false;
    btuMeterType: string;
    showEquipGraphics:any;
    emrMeterType: string
    btuTagsProfile = {
        profileType: 'modbus',
        profile: {
            name: 'BTU METER'
        }
    }
    heatingDesriedTempChangeHolder = [];
    coolingDesriedTempChangeHolder = [];
    desiredCoolingTempHolder;
    desiredHeatingTempHolder;
    settingType: any;
    desiredTemp: any;
    schedulerOccupancyLegend = [
		{ name: 'Occupied', color: 'rgba(250, 135, 51, 0.5)', button: false },
		{ name: 'Unoccupied', color: 'rgba(208, 227, 157, 0.5)', button: false },
		{ name: 'edit schedule', color: '', button: true },
	];
    onChangeDesiredTempSubject = new Subject();

    public subscriptionCollection: Array<any> = new Array<any>();
    public highlightRoomsSubscriptionCollection: Array<any> = new Array<any>();
    public getUserSettingDataSubscriptionCollection: any = {};
    public getCCUSettingDataSubscriptionCollection: Array<any> = new Array<any>();
    public getCCUSettingDataChannelSubscriptionCollection: Array<any> = new Array<any>();

    zonePriorityId = "zone_priority";
    scheduleTypeId = "schedule_type";

    public zoneVacations: Array<any>;
    private unsubscribe: Subject<void> = new Subject();
    public checkZoneDeadSubscription: Subscription = new Subscription();
    public checkZoneDeadHisPointSubscriptions: Subscription[] = [];
    public checkIfOAOIsPairedSubscription: Subscription = new Subscription();
    private getEntitySubscription: Subscription = new Subscription();
    version: any;
    dataForExport = {};
    checkWidgestLoaded = {};
    systemReconfigurationSettings: any;
    equipGraphicData
    noteUploadOpen: boolean = false;
    @ViewChild(HeatmapHeaderComponent, { static: false }) header: HeatmapHeaderComponent;
    @ViewChild('intrinsicRef', { static: false }) IntrinsicSchedulesComponent: IntrinsicSchedulesComponent;
    intrinsicScheduleData = {};
    allZonesVacationArr = {};
    conflictMsg: string;
    refs: any = {
        siteRef: undefined,
        floorRef: undefined,
        ccuName: undefined,
        zoneName: undefined,
        equipRef: undefined,
        roomRef: undefined,
        ccuRef: undefined,
        gateway: undefined,
        ccuEquipRef: undefined
    };


    buildingLimit: any = {
        min: undefined,
        max: undefined
    }
    siteTz: string;
    currentTemperatureRef: any = [];
    profiles: any;
    profileCheckFlag: Boolean = false;
    profileObj: any = {
        profileType: undefined,
        profile: undefined,
    };
    headerUserIntent: boolean = false;
    currentRateInput: any;
    energyReadingInput: any;
    energyMeterStatus: any;
    piLoopstatus: any;
    buildings: any = {
        buildings: null,
        buildingDetails: [],
        floors: [],
        floorsMaster: [],
        ccus: [],
        floorCcu: [],
        rooms: {},
        equips: {},
        zones: [],
        schedule: []
    };
    zone: any = {
        equipId: undefined,
        name: undefined,
        priority: undefined,
    };
    modal: any = {
        room: {
            active: false,
            fixed: false,
            width: 60,
            left: 0,
        },
        system: {
            active: false,
            fixed: false,
            width: 60,
            left: 0,
        }
    };
    singleFloor = false;
    ccuColors: any = this.heatmapService.ccuColors;
    temperatureColors = [
        {
            color: '#304369',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#fcaf17',
            coolingDesiredColor: '#d9e7f4',
            temp: -6
        },
        {
            color: '#92bed4',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: -4
        },
        {
            color: '#d9e7f4',
            zoneNameColor: '#4f4f4f',
            currentTempColor: '#4f4f4f',
            partitionColor: '#4f4f4f',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: -2
        },
        {
            color: '#d0e39d',
            zoneNameColor: '#4f4f4f',
            currentTempColor: '#4f4f4f',
            partitionColor: '#4f4f4f',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: 0
        },
        {
            color: '#fcaf17',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: 2
        },
        {
            color: '#f6892e',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#d9e7f4',
            temp: 4
        },
        {
            color: '#b02c25',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#fcaf17',
            coolingDesiredColor: '#d9e7f4',
            temp: 6
        }
    ];
    heatingDeadBandRoomRefList: any;
    coolingDeadBandRoomRefList: any;
    arrZoneList = [];
    zoneSettings: any = UserIntent.zoneSettings;
    priorityArray: any = [];
    options: any = UserIntent.options;
    accordionHeaderStyle: any = {
        firstLevel: {
            'font-size': '1rem',
            'font-weight': '700',
            //'color': '#E24301',
            'text-transform': 'capitalize',
        },
        secondLevel: {
            'font-size': '0.8rem',
            'margin': '2px 0',
            'font-weight': '500',
            'color': '#231f20',
            'text-transform': 'capitalize',
        },
    };
    sliderInput: PucSliderInputOutputData = {
        currentTemp: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        coolingDeadband: undefined,
        heatingDeadband: undefined,
    };
    multipleModuleInput: any = {
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingDeadband: 2,
        coolingDeadband: 2,
    };
    activeTile: string;
    mousePos: any;
    xCords: any = [];
    ccuEquips: any = [];
    isFloorPlanLoaded: Boolean = false;
    isHeaderFixed: Boolean = false;
    displayRangeSlider: Boolean = undefined;
    pointIdSet: any = new Set();
    selected: any;
    buildingMaster: any;
    widgets: any;
    systemWidgets: any;
    defaultMinHumidity: number;
    defaultMaxHumidity: number;
    defaultCompensateHumidity: number;
    defaultDemandResponse: number;
    desiredCIValue;
    desiredCISliderValue: any;
    humdityValues: any;

    ccuSettingObj: any = [];
    modeList = ['OFF', 'AUTO', 'COOL ONLY', 'HEAT ONLY'];
    rangeSliderHack = {
        2: 5,
        3: 4,
        4: 3,
        5: 2,
    };
    ccuSetting: any = {
        master: null,
        systemMode: null,
        status: null,
        desiredComfortIndex: 5,
        compensateHumidity: null,
        demandResponse: null,
        minHumidity: null,
        maxHumidity: null,
        equipStatus: null,
        occStatus: null,
        occupancyStatus: null,
        oao: null,
        smartPostpurge: null,
        smartPrepurge: null,
        demandResponseActivation:null,
        enhancedVentilation: null,

    };
    roomObj: any;
    schedulerUnitCheck:any
    onSystemMode: boolean = false;
    avgCurrentTemp: any = 0;
    coolingDeadbandArray: any = [];
    heatingDeadbandArray: any = [];
    heatUserLimitArray: any = [];
    coolUserLimitArray: any = [];
    multiModuleProfile: boolean = false;
    multiModuleProfileHasHPU: boolean = false;
    multiModuleProfileHasCPU: boolean = false;
    multiModuleZoneStatus: any = [];
    zonePriorityVal: any;
    oldZonePriorityVal: any;
    scheduleTypeVal: any;
    oldscheduleTypeVal: any;
    multiModuleZonePriorityList: Map<string, any> = new Map();
    multiModuleScheduleTypeList: Map<string, any> = new Map();
    zonePriorityCount: number = 0;
    zoneScheduleCount: number = 0;
    hoverEnterTimer:any;
    average = (array) => array.reduce((a, b) => a + b) / array.length;

    modelStyle: any = {
        display: 'none'
    };
    siteNamemodalShow;
    modalStyleClickedFlag;
    modelStyleOnClicked: any = {
        display: 'none'
    };

    siteNameModalStyle: any = {
        display: 'none'
    }
    siteNameModalStyleClickedFlag;
    siteNameModalStyleOnClicked: any = {
        display: 'none'
    }
    active = null;
    ccuOrZone = { type: '', actionUsed: '' }
    oaoArcOptions: any = UserIntent.oaoArcOptions;
    oaoArcValue: number = 0;
    zoneChange = false;
    ahumode = [];
    ccusWithPairedZones;
    sideMenu;
    subscriptions: any = {};
    cancelChanges: any[] = [];

    //In case graph-wrapper widht isn't caught , we'll use this one
    graphWidth: any;

    zoneId = "";
    defaultLimits: any = {
        desiredTempHeating: undefined,
        desiredTempCooling: undefined
    };
    schSliderInputData;

    private heatCoolLimitsHisData = new Map<string, any>();
    public ccuListByFloors = [];

    @ViewChild('CompensateHumidity', { static: false }) CompensateHumidity: any;
    @ViewChild('DemandResponse', { static: false }) DemandResponse: any;
    deadbandScale: any = [];

    showHyperStatCpu:any;
    selectedCCu : any;
    selectedZone : any;
    searchText = '';
    searchMatchingZones = [];
    tempBuildingScheduleInfo: any;
    //trim sch array holder
    forceTrimArray = [];
    systemReconfigProfileName: any;

    delayChanges = new Subject();
    autoRefreshSelection: any;
    autoRefreshSub: any;
    allEquips: Array<any> = [];
    allDevices: Array<any> = [];
    allNodes: Array<any> = [];
    editAnalytics: boolean = false;
    showGlobalSettings = false;

    globalDashboardObject: CustomGlobalHeatmapDashboard;
    dashboardWidgetsMap: any = {};

    customWidgets = {
        local: [],
        global: []
    };
    lazyLoadFloors=[];
    showLazyLoader: boolean;
    hiddenAccordions = 0;
    singleFloorFlagStyle: boolean;
    selectedFilter
    defaultFilter;
    floorheight: number = 2;
    hoverZoneData;
    selectedFloorRef: any;
    shapeId = '';
    systemPoints = [];
    concatQuery = '';
    allFLoor: any = [];
    allDetails: boolean = false;
    ccuForLayout: any = [];
    allCcus: any = [];

    @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
    zonePreDefinedWidgetsOpenState: boolean = true;
    zoneAlertsAccordionOpenState: boolean = true;
    zoneCustomWidgetsOpenState: boolean = true;
    zoneCustomGlobalWidgetsOpenState: boolean = true;
    zoneTunersOpenState: boolean = false;
    zoneSettingsOpenState: boolean = true;
    zoneReconfigurationOpenState: boolean = false;
    CCUPreDefinedWidgetsOpenState: boolean = true;
    CCUAlertsAccordionOpenState:boolean = true;
    CCUcustomWidgetsOpenState: boolean = true;
    CCUcustomGlobalWidgetsOpenState: boolean = true;
    systemTunersOpenState: boolean = false;
    CCUSettingsOpenState: boolean = true;
    ccuPointSummaryOpenState: boolean = true;
    zonePointSummaryOpenState: boolean = true;
    CCUreconfigurationOpenState: boolean = false;
    remoteAccessOpenState: boolean = false;
    CCULogsOpenState: boolean = false;
    initialFilter: boolean = true;
    hoverdOrClickEventFalg:any;
  
    userPreferenceData: any = {
      userId: "",
      heatmapPreferences: {
        lastSelectedFilter: {},
        defaultFilter: {},
  
        CCUHeatmapPreferences: {
          preDefinedWidgets: true,
          customWidgets: true,
          customGlobalWidgets: true,
          CCUSettings: true,
          systemEquipGraphics:true,
          pointSummary:true,
          analytics:true
        },
  
        zoneHeatmapPreferences: {
          preDefinedWidgets: true,
          customWidgets: true,
          customGlobalWidgets: true,
          zoneSettings: true,
          accordionSectionSelction: true,
          pointSummary:true,
          analytics:true
        },
      },
    };
    showSchedulerOccupancy: boolean = true;
    private schedulerEditDialogRef: MatDialogRef<SchedulerEditModalComponent>;
    checkHyperStatProfile: any;
    messagingSubscribe: any;
    userUpdatedValue: boolean = false;
    tempZoneSettingsHolder: any;
    overrideCancelled: boolean;
    summaryTableOpen = false;
    autoRefreshSubject = new Subject();
    siteName = '';
    hideCCUHeader: boolean = false;
    isVofm = false;
    timeSeriesinteval;
    buildingDataTempHolder: any;
    isModbus: boolean;
    operationModePoint: any = [];
    hideSummaryTableSection: boolean = false;
    isPartner: boolean = false;
    multiFloorSelected: boolean = false;
    compareFloorChanges: any = [];
    monitoringProfile: boolean = false;
    enableDownLoad: boolean = false;
    zoneTuners: Array<any> = [];
    tunerDataChanged = false;
    tuners: Array<any> = [];
    allowTunersAccess: boolean = false;
    certificationLevel: any;
    showEditGlobalViz: boolean = true;
    showAddButton:boolean = true;
    prevState_editGloablViz: boolean;
    prevState_showAddButton: boolean;
    tagList = [];
    isFilter: boolean = false;
    publishingDraft : boolean = false;
    showInlineVisBuilder = false;
    selectedScopedEntityId: any;
    scopedData: any = {};
    selectedScopedEntityIndex = null;
    serverDeleteInProgress: boolean = false;
    selectedWidgetForDeletion: any;
    notesList = [];
    filteredNotesList = [];

    // Feature toggles
    showSummaryTables: boolean = false;
    showAIDashboard; boolean = false;
    showSystemEquipGraphics:any;
    showOtaCommands:any;
    show75FChatButton: boolean;
    iframeSrc: any;
    dispProgress:any;
    ccuId: any;
    showRemoteAccess: boolean = false;
    modbusEquipId: any;
    systemReconfigTitle: string = '';

    // Chat UI
    autoAddedSummaryTables: string[] = [];
    aiDashboardData: any;
    showTerminalEquipGraphics: boolean = false;
    terminalEquipGraphics:any;

    zoneHovercheckFlag: boolean;
    ccuHoverCheckFlag: boolean;
    monitoringEquipParameteres: { };
    @ViewChild('heatmapView', { static: false }) heatmapView: ElementRef;
    dispTerminalGraphic: boolean = false;
    openMenu: boolean = false;
    loadSummaryView: boolean = false;
    showPointSummary: boolean;
    isdomainName: boolean = false;
    profileReconfigCheck: boolean;
    ccuAnalyticsOpenState: boolean = true;
    zoneAnalyticsOpenState: boolean = true;
    entitiesList: any;
    showNotesAccordion: boolean = true;
    modalPopupOpened:boolean = false;

     // scroll to notes section
     expandNotesSection : Boolean = false;

    enableViewAssetsSystemProfile: boolean = false;
    taggedFilesListSystemProfile: any;
    fileUpload: any;
    showQRCode: any;
    versionText: string;
    homeAppVersion: string;
    remoteAccessAppVersion: string;
    backnetAppVersion: string;
    isDownloadExcelPending: boolean = false;
    clearExcelInterval: any;

    constructor(
        public sanitizer: DomSanitizer,
        public siteService: SiteService,
        public helperService: HelperService,
        public loaderService: LoaderService,
        public graphService: GraphService,
        public pubNubService: PubNubService,
        public deviceHelper: DeviceHelperService,
        public sliderUserIntentService: SliderUserIntentService,
        public heatMapToolTipService: HeatMapToolTipService,
        private vacationsService: VacationsService,
        public zoneSettingsService: ZoneSettingsService,
        public runTimeGraphsService: RuntimeGraphService,
        public el: ElementRef,
        public messageService: MessageService,
        public exportData: ExportService,
        public dialog: MatDialog,
        public dom: DomSanitizer,
        public customHeatmapService: CustomHeatmapService,
        public heatmapConnect: HeatmapConnectService,
        public schedulerService: SchedulerService,
        public userPref: UserService,
        public heatmapService: HeatmapService,
        private ccuAlertService: CCUALERTService,
        public configService: ConfigurationService,
        public unitService : UnitService,
        private authService: AuthenticationService,
        public namedScheduleService: NamedScheduleService,
        private analyticsService: AnalyticsService,
        private featureToggleService: FeatureToggleService,
        private summaryTableHelperService: SummaryTableHelperService,
        public alertService: AlertService,
        private filterSrvc: HeatmapFilterService,
        public notesService: NotesService,
        private chatEventsService: ChatEventsService,
        private analyticsLayoutService: AnalyticsLayoutService
    ) {

        this.subscriptions['popUpCancelClick'] = this.helperService.popUpCancelClick.subscribe(() => {
            this.overrideCancelled = true;
            this.getUserSettingData('');
        });
        
        this.subscriptions['updateEntitySubscriptions'] = this.pubNubService.updateEntityForNamedSch.pipe(
            debounceTime(1000),
          ).subscribe({
            next: (addedVacId) => {
                this.UpdateBuildingsDataWhenNamedSchApply();
            }
        });

        this.subscriptions['chatEvents'] = this.chatEventsService.chatEvent$.subscribe((command: ChatCommand) => {
            const widgetCommand = command as WidgetResponse;
            if (command.actionType == ActionType.ADD_SUMMARY_TABLE) {
                this.autoAddedSummaryTables = [widgetCommand.visualizationId];
            } else if (command.actionType == ActionType.GENERATE_DASHBOARD) {
                this.aiDashboardData = widgetCommand.data;
            } else if (command.actionType == ActionType.APPLY_HEATMAP_OVERLAY) {
                if (widgetCommand.default) {
                    this.selectedFilter = { filterId: widgetCommand.visualizationId, defaultInd: true }
                }
                else {
                    let filter = this.filterSrvc.filtersList.find(f => f.filterId == widgetCommand.visualizationId);
                    if (filter) {
                        this.setFilterPoints(ObjectUtil.deepClone(filter)).then(() => {})
                        .catch((error) => console.error("Failed to set filter via chat event", error));
                    }
                }
                this.heatmapView?.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }
          })

        this.onChangeDesiredTempSubject.pipe(
            debounceTime(0), distinctUntilChanged()).subscribe((val: any) => {
                this.handleSliderChanges()
            })

        this.delayChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe((res: any) => {
            if (res.key == 'reConfig') {
                this.getSystemReconfigurationData(res.val);
            } else {
           

                if (this.multiModuleProfile) {
                    const response = ObjectUtil.deepClone(res.val);
                    const zoneSettings = ObjectUtil.deepClone(this.tempZoneSettingsHolder);
                    for(const keys of this.profiles) {
                        delete (response[keys['profile']['name']]?.lastUpdated?.val);
                        if (zoneSettings != null) {
                            delete (zoneSettings[keys['profile']['name']]?.lastUpdated?.val);
                            //In modbus profile the will be no schedule points so it iss breaking so kept profile check
                            if(this.profileCheckFlag) {
                                delete (zoneSettings["zoneSchedule"]["floorName"])
                                delete (zoneSettings["zoneSchedule"]["scheduleType"])
                                delete (zoneSettings["zoneSchedule"]["zoneName"])
                            }                       
                        }
                       
                    }

                    if ((!ObjectUtil.isEqual(response, zoneSettings) && !this.userUpdatedValue) || this.overrideCancelled) {
                        this.zoneSettings = ObjectUtil.deepClone(res.val);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                        this.zoneSettingsFunction();
                    } else {
                        this.updateLastedValues(res);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                    }
                    
                } else {
                    const response = ObjectUtil.deepClone(res.val);
                    delete (response?.lastUpdated?.val);
                    const zoneSettings = ObjectUtil.deepClone(this.tempZoneSettingsHolder);
                    if (zoneSettings != null) {
                        delete (zoneSettings?.lastUpdated?.val);
                        //In modbus profile the will be no schedule points so it iss breaking so kept profile check
                        if(this.profileCheckFlag) {
                            delete (zoneSettings["zoneSchedule"]["floorName"])
                            delete (zoneSettings["zoneSchedule"]["scheduleType"])
                            delete (zoneSettings["zoneSchedule"]["zoneName"])
                        }
                    }
                    
                    if ((!ObjectUtil.isEqual(response, zoneSettings) && !this.userUpdatedValue) || this.overrideCancelled) {
                        this.zoneSettings = ObjectUtil.deepClone(res.val);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                        this.zoneSettingsFunction();
                    } else {
                        this.updateLastedValues(res);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                    }
                }
            }
        });

        this.summaryTableHelperService.openPopup.subscribe((res: any) => {
            if (res.type == 'zone') {
                let ccu = this.ccusWithPairedZones.find(c=>c.pairedZoneList.indexOf(res.zone.id)>-1);
                let ccuName = {'ccuName' : ccu?.CCUName || ''};
                res['zoneData'] = ccuName;
                ccu ? res['ccuId'] = ccu.id : '';
                this.zoneClicked(res, true);
            } else if (res.type == 'system') {
                this.ccuClickFromSummaryTable(res);
            }
        })
        this.isPartner = environment.partner;
    }

    async ccuClickFromSummaryTable(ccuObj) {
        let ccuData = {
            "ccuId": ccuObj.ccuId,
            "$event": {
                "isTrusted": true
            }
        }
        await this.getSystemSettingsOnClick(ccuData, true);
    }


    setUserUpdatedValue() {
        this.userUpdatedValue = true;
        setTimeout(()=>{
            this.userUpdatedValue = false;
        },5000)
    }

    zoneSettingsFunction() {
        this.handleHumidityDehumidity();
        this.handleZonePriority();
        this.handleZoneSchedule();
        this.heartBeathandle();
        this.setTerminalGraphicValues(this.zoneSettings);
        this.overrideCancelled = false;
        this.zoneSettingsService.subject.next(this.zoneSettings);
        if (this.zoneSettings?.zoneSpecialSchedule) {
            this.zoneSettings.zoneSpecialSchedule = [].concat(this.zoneSettings.zoneSpecialSchedule);
        }
        if (this.zoneSettings?.zoneVacation) {
            this.zoneVacations = [].concat(this.zoneSettings.zoneVacation);
            this.vacationSkeletonLoader = false;
        } else {
            this.vacationSkeletonLoader = false;
        }
        this.zoneUserIntentSettings();
    }

    ngAfterViewInit(){
        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }

    getBuildingRef(buildingId) {
        let selectedBuildingId = this.stripHayStackTags(buildingId);
        return selectedBuildingId.split(' ')[0];
    }

    stripHayStackTags(dataInput) {
        return this.helperService.stripHaystackTypeMapping(dataInput);
    }

    commaSeparatedPointIds = (a, b) => { return a + ',' + b };

    pointIDOrPointIDsWithComma = (buildingObj, roomRef, tagsList: any) => {
        let returnPointIds = this.helperService.getPointIdbyTags(buildingObj, tagsList, roomRef);
        if (returnPointIds) {
            if (returnPointIds.length > 0) {
                return returnPointIds.join();
            }
            else if (returnPointIds.length == 0) {
                return undefined;
            }
            else {
                return returnPointIds;
            }
        }
    };

    findMaxValuefromCommaSeparated = (input) => {
        let output = input.split(',');
        output.pop();
        if (output.length > 0) {
            output = output.reduce((prev, curr) => {
                return Math.max(Number(prev), Number(curr))
            });
        }
        return output.toString();
    };

    intializeDate() {
        let tz: string = this.siteService.getIANATimeZone(this.siteTz);
        this.selected = {
            startDate: dayjs().tz(tz).startOf('day'),
            endDate: dayjs(Date.now()).tz(tz),
            timeZone: tz
        }
        this.heatMapToolTipService.selectedDateRange = this.selected;
    }

    ngOnChanges(changes: SimpleChanges) {
        const floors: SimpleChange = changes.floors;
        this.buildings.floors = floors.currentValue;
    }

    async ngOnInit() {
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        if (buildingDetails) {
            this.siteName = buildingDetails.siteName;
            this.refs.siteRef = buildingDetails.siteId;
            this.getBuildingDetails(this.getBuildingRef(buildingDetails.siteId));
            this.floorheight = Math.round((window.screen.height - 74)/500);
            this.getBuildingFloors(this.getBuildingRef(buildingDetails.siteId));
        }
        
        try {
            await this.getUserPreferencesData(true);
        } catch(e) {
            this.filterChangeHandle(null, false);
            console.log(e);
        }
        this.userPreferenceData.userId = this.authService.getUser() ? this.authService.getUser().userId : null;
        let userPreference = JSON.parse(localStorage.getItem('user_preference'));
        this.isVofm = this.authService.isUserViewOnly();
        this.userTempPreference = userPreference.temperatureUnit;
        this.schedulerUnitCheck = this.userTempPreference
        this.ranges=this.helperService.fetchRanges();
        this.vacationSkeletonLoader = true
        this.loaderService.active(true);
        this.helperService.clearData();
        this.heatMapToolTipService.clearTooltipData();
        this.defaultMinHumidity = 0;
        this.defaultMaxHumidity = 0;
        this.defaultCompensateHumidity = 0;
        this.defaultDemandResponse = 0;
        this.humdityValues = new Array(101).fill(0).map((x, i) => i);
        this.subscriptions['cancelledChangeSubscriptions'] = this.helperService.cancelledChange.subscribe((param: any) => {
            this.cancelChanges = ArrayUtil.clone(param.list || []);
            this.revertChangedValues(param.list);
        });

        this.subscriptions['vacationAddedSubscriptions'] = this.pubNubService.vacationAdded.pipe(debounceTime(1000)).subscribe({
            next: (addedVacId) => {
                this.UpdateBuildingsDataForVacationEdit(addedVacId);
            }
        });

        this.subscriptions['dashboardLevelChanges'] = this.heatmapConnect.getDashboardLevelChanges().subscribe(data => {
            this.updateGlobalDashboard(data.ref, data.dashboardId, data.type, data.widget, data.op);
            // This defines what to do with changesTrackerArray.
        });

        this.ccuAlertService.refreshAlertCountEventTrigger();
        this.filterSrvc.closepopups.subscribe((val) => {
            this.isFilter = val;
        });
        this.subscriptions['flags'] = this.featureToggleService.flagChange.subscribe((flags)=>{
            this.setFlags(flags);
        });
        this.useFeatureToggles();

        this.subscriptions['draftActionTaken'] = this.heatmapConnect.getDraftActionTaken().subscribe(action => {
            this.workOnDraftAction(action);
        });
    }

    useFeatureToggles() {
        
        let sub = this.featureToggleService.featureFlagsSubject.subscribe((flags)=>{
            this.setFlags(flags);
            sub.unsubscribe();
            
        });
        this.featureToggleService.getFlags();
    }

    setFlags(flags) {
        this.showTiProfile  = flags.hasOwnProperty('ti-external-sensor') ? flags['ti-external-sensor'].value: false;
        this.showSummaryTables = flags.hasOwnProperty('summary-table') ? flags['summary-table'].value: false;
        this.showSystemEquipGraphics  = flags.hasOwnProperty('system-equip-graphics') ? flags['system-equip-graphics'].value: false;
        this.showOtaCommands = flags.hasOwnProperty('ota-commands-certificates') ? flags['ota-commands-certificates'].value: false;
        this.show75FChatButton = flags.hasOwnProperty('show-75fchat-button') ? flags['show-75fchat-button'].value: false;
        this.showAIDashboard = flags.hasOwnProperty('show-ai-dashboard') ? flags['show-ai-dashboard'].value: false;
        this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value: false;
        this.dispTerminalGraphic = flags.hasOwnProperty('terminal-equip-graphics') ? flags ['terminal-equip-graphics'].value: false;
        this.showPointSummary = flags.hasOwnProperty('point-summary') ? flags['point-summary'].value : false;
        this.fileUpload = flags.hasOwnProperty('file-upload') ? flags['file-upload'].value : false;
        this.showQRCode = flags.hasOwnProperty('qr-share') ? flags['qr-share'].value : false;
    }

    clickMenu() {
        this.openMenu = !this.openMenu;
    }

    openPointSummaryList() {
        const dialogRef = this.dialog.open(PointSummaryListComponent, {
            panelClass: 'pointsummary-padding-dialog',
        });

        dialogRef.componentInstance.siteId = this.refs.siteRef;
        dialogRef.componentInstance.siteNameRef = this.siteName;
        dialogRef.afterClosed().subscribe(() => {
            this.clickMenu();
        });
    }

    handleSliderChanges() {
        if (this.coolingDesriedTempChangeHolder.length > 0 && this.heatingDesriedTempChangeHolder.length > 0) {
            let coolingChanged = false;
            let heatingChanged = false;
            let coolingVal = this.coolingDesriedTempChangeHolder[this.coolingDesriedTempChangeHolder.length - 1];
            this.coolingDesriedTempChangeHolder = [];
            if (coolingVal.temp != this.desiredCoolingTempHolder) coolingChanged = true;

            let heatingVal = this.heatingDesriedTempChangeHolder[this.heatingDesriedTempChangeHolder.length - 1];
            this.heatingDesriedTempChangeHolder = [];
            if (heatingVal.temp != this.desiredHeatingTempHolder) heatingChanged = true;

            if (heatingChanged && coolingChanged) {
                //bothchanged
                this.onChangeDesiredTemp([{
                    name: heatingVal.type,
                    value: heatingVal.temp
                }, {
                    name: coolingVal.type,
                    value: coolingVal.temp
                }])
            } else if (heatingChanged) {
                this.onChangeDesiredTemp([{
                    name: heatingVal.type,
                    value: heatingVal.temp
                }]);
            } else {
                this.onChangeDesiredTemp([{
                    name: coolingVal.type,
                    value: coolingVal.temp
                }]);
            }
        }
        else if (this.coolingDesriedTempChangeHolder.length > 0) {
            let val = this.coolingDesriedTempChangeHolder[this.coolingDesriedTempChangeHolder.length - 1];
            this.coolingDesriedTempChangeHolder = [];
            if (val.temp == this.desiredCoolingTempHolder) return;
            this.onChangeDesiredTemp([{
                name: val.type,
                value: val.temp
            }])
        }
        else if (this.heatingDesriedTempChangeHolder.length > 0) {
            let val = this.heatingDesriedTempChangeHolder[this.heatingDesriedTempChangeHolder.length - 1];
            this.heatingDesriedTempChangeHolder = [];
            if (val.temp == this.desiredHeatingTempHolder) return;
            this.onChangeDesiredTemp([{
                name: val.type,
                value: val.temp
            }])
        }
    }

    displayLabel(value) {
        switch (value) {
            case 5:
                return 'Full comfort'
            case 4:
                return 'Partial comfort '
            case 3:
                return 'Partial economy'
            case 2:
                return 'Full economy'
        }
    }

    multiModuleZonePriority() {
        this.profiles.forEach(profile => {
            if (this.zoneSettings
                && this.zoneSettings[profile.profile.name]
                && this.zoneSettings[profile.profile.name].zonePriority
                && this.zoneSettings[profile.profile.name].zonePriority.hasOwnProperty('val')
                && ['vav', 'dab', 'ti', 'series', 'parallel','otn', 'bpos','chilledBeam'].includes(profile['profileType'])
                && ['vav', 'dab', 'ti', 'series', 'parallel', 'vrv','chilledBeam'].includes(profile['profileType'])
            ) {
                const name = profile.profile.name;
                const data = { 'profile': name, 'profileType': profile.profileType, 'zonePriorityValue': this.zoneSettings[profile.profile.name].zonePriority.val }
                this.multiModuleZonePriorityList.set(name, data);
            }
        })
    }

    multiModuleZoneSchedule() {
        this.profiles.forEach(profile => {
            if (this.zoneSettings
                && this.zoneSettings[profile.profile.name]
                && this.zoneSettings[profile.profile.name].scheduleType
                && this.zoneSettings[profile.profile.name].scheduleType.hasOwnProperty('val')
                && ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti','otn', 'bpos', 'dualDuct', 'series', 'parallel','vrv','chilledBeam'].includes(profile['profileType'])
            ) {
                const name = profile.profile.name;
                const data = { 'profile': name, 'profileType': profile.profileType, 'scheduleTypeValue': this.zoneSettings[profile.profile.name].scheduleType.val }
                this.multiModuleScheduleTypeList.set(name, data);
            }
        })
    }

    getMultiScheduleTypeVal() {
        if (this.profiles.length && this.zoneScheduleCount) {
            if ((this.zoneScheduleCount == this.multiModuleScheduleTypeList.size)) {
                const scheduleTypeValues = Array.from(this.multiModuleScheduleTypeList).map(([key, value]) => value);
                this.scheduleTypeVal = scheduleTypeValues
                    .reduce((max, p) => Number(p.scheduleTypeValue) > max ? Number(p.scheduleTypeValue) : max, Number(scheduleTypeValues[0].scheduleTypeValue)).toString();
            }
        }
    }

    getMultiZonePriorityVal() {
        if (this.profiles.length && this.zonePriorityCount) {
            if ((this.zonePriorityCount == this.multiModuleZonePriorityList.size)) {
                const zonePriorityValues = Array.from(this.multiModuleZonePriorityList).map(([key, value]) => value);
                this.zonePriorityVal = zonePriorityValues
                    .reduce((max, p) => Number(p.zonePriorityValue) > max ? Number(p.zonePriorityValue) : max, Number(zonePriorityValues[0].zonePriorityValue)).toString();
            }
        }
    }

    zoneUserIntentSettings() {
        if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click') {
            if (this.zoneSettings && this.profileObj && this.profileObj.profile && this.profileObj.profile.name) {
                this.headerUserIntent = true;
                if (this.profileObj.profileType == 'emr') {
                    this.currentRateInput = this.zoneSettings[this.profileObj.profile.name] ? this.zoneSettings[this.profileObj.profile.name].currentRate : '';
                    this.energyReadingInput = this.zoneSettings[this.profileObj.profile.name] ? this.zoneSettings[this.profileObj.profile.name].energyReading : '';
                }
                if (this.profileObj.profileType == 'emr' || this.profileObj.profileType == 'pid') {
                    this.energyMeterStatus = this.zoneSettings[this.profileObj.profile.name] ? this.zoneSettings[this.profileObj.profile.name].equipStatusMessage : '';
                }

            }
            if (this.profileObj.profileType == 'ti') {
                this.isTIZone = true;
            }
            else {
                this.isTIZone = false;
            }
        }

        this.validateValuesAndUpdateSlider();
    }

 

    validateValuesAndUpdateSlider() {
        if (this.multiModuleProfile) {
            let dataReceived = false;
            let dataperprofile = 1;
            if (this.zoneSettings?.buildingLimitMin && this.zoneSettings?.buildingLimitMin.val
                && this.zoneSettings?.buildingLimitMax && this.zoneSettings?.buildingLimitMax.val
                && this.zoneSettings?.desiredTempHeating && this.zoneSettings?.desiredTempHeating.val
                && this.zoneSettings?.desiredTempCooling && this.zoneSettings?.desiredTempCooling.val
                && this.zoneSettings?.zoneHeatingUserLimitMin && this.zoneSettings?.zoneHeatingUserLimitMin?.val
                && this.zoneSettings?.zoneHeatingUserLimitMax && this.zoneSettings?.zoneHeatingUserLimitMax?.val
                && this.zoneSettings?.zoneCoolingUserLimitMin && this.zoneSettings?.zoneCoolingUserLimitMin?.val
                && this.zoneSettings?.zoneCoolingUserLimitMax && this.zoneSettings?.zoneCoolingUserLimitMax?.val
            ) {
                if (this.multiModuleZoneStatus.length == this.profiles.length) {
                    this.profiles.forEach(profile => {
                        if (!(this.zoneSettings && this.zoneSettings[profile.profile.name]
                            && this.zoneSettings[profile.profile.name].currentTemp
                            && this.zoneSettings[profile.profile.name].currentTemp.hasOwnProperty('val')
                            && this.zoneSettings[profile.profile.name].heatingDeadband
                            && this.zoneSettings[profile.profile.name].heatingDeadband.hasOwnProperty('val')
                            && this.zoneSettings[profile.profile.name].coolingDeadband
                            && this.zoneSettings[profile.profile.name].coolingDeadband.hasOwnProperty('val')
                        )) {
                            dataperprofile = 0;
                        }
                        dataReceived = (dataperprofile == 0) ? false : true;

                        if (
                            (
                                this.zoneSettings?.[profile.profile.name]
                                && this.zoneSettings?.[profile.profile.name]?.currentTemp
                                && this.zoneSettings?.[profile.profile.name]?.heatingDeadband
                                && this.zoneSettings?.[profile.profile.name]?.coolingDeadband
                            )
                            &&
                            (
                                this.zoneSettings?.[profile.profile.name]?.currentTemp.error
                                || this.zoneSettings?.[profile.profile.name]?.heatingDeadband.error
                                || this.zoneSettings?.[profile.profile.name]?.coolingDeadband.error
                            )
                        ) {
                            this.displayRangeSlider = false;
                        }
                    });

                    if (dataReceived) {
                        this.calculateAvgCurrentTemp();
                        if (this.zoneSettings['coolingDeadband'] && this.zoneSettings['heatingDeadband']) {
                            this.findHighestDeadbands();
                        }

                        this.updateSliderInput();
                        this.displayRangeSlider = true;
                    }
                }

            }
            else {
                if (
                    (
                        this.zoneSettings?.buildingLimitMin
                        && this.zoneSettings?.buildingLimitMax
                        && this.zoneSettings?.desiredTempHeating
                        && this.zoneSettings?.desiredTempCooling
                        && this.zoneSettings?.zoneHeatingUserLimitMin
                        && this.zoneSettings?.zoneHeatingUserLimitMax
                        && this.zoneSettings?.zoneCoolingUserLimitMin
                        && this.zoneSettings?.zoneCoolingUserLimitMax
                    )
                    &&
                    (
                        this.zoneSettings?.buildingLimitMin?.error
                        || this.zoneSettings?.buildingLimitMax?.error
                        || this.zoneSettings?.desiredTempHeating?.error
                        || this.zoneSettings?.desiredTempCooling?.error
                        || this.zoneSettings?.zoneHeatingUserLimitMin?.error
                        || this.zoneSettings?.zoneHeatingUserLimitMax?.error
                        || this.zoneSettings?.zoneCoolingUserLimitMin?.error
                        || this.zoneSettings?.zoneCoolingUserLimitMax?.error)
                ) {
                    this.displayRangeSlider = false;
                }
            }

        }
        else {
            if (this.zoneSettings?.buildingLimitMin && this.zoneSettings?.buildingLimitMin?.val
                && this.zoneSettings?.buildingLimitMax && this.zoneSettings?.buildingLimitMax?.val
                && this.zoneSettings?.desiredTempHeating && this.zoneSettings?.desiredTempHeating?.val
                && this.zoneSettings?.desiredTempCooling && this.zoneSettings?.desiredTempCooling?.val
                && this.zoneSettings?.currentTemp && this.zoneSettings?.currentTemp.hasOwnProperty('val')
                && this.zoneSettings?.zoneHeatingUserLimitMin && this.zoneSettings?.zoneHeatingUserLimitMin?.val
                && this.zoneSettings?.zoneHeatingUserLimitMax && this.zoneSettings?.zoneHeatingUserLimitMax?.val
                && this.zoneSettings?.zoneCoolingUserLimitMin && this.zoneSettings?.zoneCoolingUserLimitMin?.val
                && this.zoneSettings?.zoneCoolingUserLimitMax && this.zoneSettings?.zoneCoolingUserLimitMax?.val
                && this.zoneSettings?.heatingDeadband && this.zoneSettings?.heatingDeadband.hasOwnProperty('val')
                && this.zoneSettings?.coolingDeadband && this.zoneSettings?.coolingDeadband.hasOwnProperty('val')
            ) {

                this.updateSliderInput();
                this.displayRangeSlider = true;
            }
            else if (
                (   this.zoneSettings?.buildingLimitMin
                    && this.zoneSettings?.buildingLimitMax
                    && this.zoneSettings?.currentTemp
                    && this.zoneSettings?.desiredTempHeating
                    && this.zoneSettings?.desiredTempCooling
                    && this.zoneSettings?.zoneHeatingUserLimitMin
                    && this.zoneSettings?.zoneHeatingUserLimitMax
                    && this.zoneSettings?.zoneCoolingUserLimitMin
                    && this.zoneSettings?.zoneCoolingUserLimitMax
                    && this.zoneSettings?.heatingDeadband
                    && this.zoneSettings?.coolingDeadband
                )
                &&
                (
                    this.zoneSettings?.buildingLimitMin?.error
                    || this.zoneSettings?.buildingLimitMax?.error
                    || this.zoneSettings?.currentTemp?.error
                    || this.zoneSettings?.desiredTempHeating?.error
                    || this.zoneSettings?.desiredTempCooling?.error
                    || this.zoneSettings?.zoneHeatingUserLimitMin?.error
                    || this.zoneSettings?.zoneHeatingUserLimitMax?.error
                    || this.zoneSettings?.zoneCoolingUserLimitMin?.error
                    || this.zoneSettings?.zoneCoolingUserLimitMax?.error
                    || this.zoneSettings?.heatingDeadband?.error
                    || this.zoneSettings?.coolingDeadband?.error
                )
            ) {
                this.displayRangeSlider = false;
            }
        }
    }

    updateSliderInput() {
        /**Temperature Unit prefernce conversions**/
        let sliderValueFromZoneSettings: PucSliderInputOutputData = {
            currentTemp: undefined,
            desiredTempHeating: undefined,
            desiredTempCooling: undefined,
            heatingUserLimitMin: undefined,
            heatingUserLimitMax: undefined,
            coolingUserLimitMin: undefined,
            coolingUserLimitMax: undefined,
            coolingDeadband: undefined,
            heatingDeadband: undefined,
        };
        if(this.profiles[0]?.profileType == "sense" || this.profiles[0]?.profileType == "monitoring"){
            this.checkForHyperSense = true;
            sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings.currentTemp.val;
            sliderValueFromZoneSettings.desiredTempHeating = '0';
            sliderValueFromZoneSettings.desiredTempCooling = '0';
            sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings.zoneHeatingUserLimitMin.val;
            sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings.zoneHeatingUserLimitMax.val;
            sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings.zoneCoolingUserLimitMin.val;
            sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings.zoneCoolingUserLimitMax.val;
            sliderValueFromZoneSettings.heatingDeadband = '2'
            sliderValueFromZoneSettings.coolingDeadband = '2'
            this.tempConversion()
        } else {
            /**Temperature Unit prefernce conversions**/
            this.celciusConversion(sliderValueFromZoneSettings)
        }

        if ( this.sliderInput ) {
            this.updateSlider(sliderValueFromZoneSettings);
        }
        else {
            this.sliderInput = sliderValueFromZoneSettings;
            this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
        }
        this.schSliderInputData = { ...this.sliderInput };
        this.schSliderInputData.desiredTempCooling = this.schSliderInputData.coolingUserLimitMin;
        this.defaultLimits.desiredTempCooling = this.schSliderInputData.coolingUserLimitMin;
        if(this.schSliderInputData?.heatingUserLimitMax > this.schSliderInputData?.heatingUserLimitMin ) {
            this.schSliderInputData.desiredTempHeating  = this.schSliderInputData?.heatingUserLimitMax;
            this.defaultLimits.desiredTempHeating   = this.sliderInput?.heatingUserLimitMax;
        } else {
            this.schSliderInputData.desiredTempHeating  = this.schSliderInputData?.heatingUserLimitMin;
            this.defaultLimits.desiredTempHeating   = this.sliderInput?.heatingUserLimitMin;
        }
    }

    schedulerEventEmit() {
        this.helperService.getPointData()
        .subscribe(res => {
            this.zoneSettings.heatingDeadband.val = res.heatingDeadband.val
            this.zoneSettings.coolingDeadband.val = res.coolingDeadband.val
        }) 
        this.schedulerUnitCheck = this.unitService.unitTemperaturePreferenceData()
        this.tempConversion() 
    }

    scheduleUnitCheck() {
        if(this.schedulerUnitCheck  == this.unitService.unitTemperaturePreferenceData()){
            this.userTempPreference = this.schedulerUnitCheck 
            return this.userTempPreference;
        } else if(this.schedulerUnitCheck !== this.unitService.unitTemperaturePreferenceData()) {
            this.userTempPreference = this.schedulerUnitCheck 
            return this.userTempPreference
        }
    }

    tempConversion() {
        this.scheduleUnitCheck()
        if(this.profiles[0]?.profileType == "sense" || this.profiles[0]?.profileType == "monitoring") {
            this.userTempPreference = this.unitService.unitTemperaturePreferenceData()
        }
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) { 
            this.buildingLimit.min =  this.unitService.fahrenheitToCelecius(this.zoneSettings.buildingLimitMin.val,'buildingLimitMin')
            this.buildingLimit.max =  this.unitService.fahrenheitToCelecius(this.zoneSettings.buildingLimitMax.val,'buildingLimitMin')
        } else {
            this.buildingLimit.min = this.zoneSettings.buildingLimitMin.val;
            this.buildingLimit.max = this.zoneSettings.buildingLimitMax.val;
        }
    }
    updateSlider(sliderValueFromZoneSettings) {
        if (this.sliderUserIntentService.isEquivalent(this.sliderInput, sliderValueFromZoneSettings)) {
            let dataforuserIntentslider;

            let datalastupdatedby = this.sliderUserIntentService.getLatestUpdatedBy(this.refs.ccuName);
            if (datalastupdatedby)
                dataforuserIntentslider = this.sliderUserIntentService.getData(datalastupdatedby, this.refs.ccuName);
            if (dataforuserIntentslider) {
                if (!this.sliderUserIntentService.isEquivalent(this.sliderInput, dataforuserIntentslider)) {
                    this.sliderUserIntentService.setData(dataforuserIntentslider, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
                    this.sliderInput = dataforuserIntentslider;
                }
            }
            else {
                this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
                this.sliderInput = sliderValueFromZoneSettings;
            }
        }
        else {
            this.sliderInput = sliderValueFromZoneSettings;
            this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
        }
    }

    /**Temperature Unit prefernce conversions**/
    celciusConversion(sliderValueFromZoneSettings :any) {
        this.tempConversion()
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
                sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings?.currentTemp?.val;
                sliderValueFromZoneSettings.desiredTempHeating = this.zoneSettings?.desiredTempHeating?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString(): this.zoneSettings?.desiredTempHeating?.val;
                sliderValueFromZoneSettings.desiredTempCooling = this.zoneSettings?.desiredTempCooling?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString(): this.zoneSettings?.desiredTempCooling?.val;
                sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings?.zoneHeatingUserLimitMin?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneHeatingUserLimitMin?.val,'heatingUserLimitMin')).toString(): this.zoneSettings?.zoneHeatingUserLimitMin?.val;
                sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings?.zoneHeatingUserLimitMax?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneHeatingUserLimitMax?.val,'heatingUserLimitMax')).toString(): this.zoneSettings?.zoneHeatingUserLimitMax?.val;
                sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings?.zoneCoolingUserLimitMin?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneCoolingUserLimitMin?.val,'coolingUserLimitMin')).toString(): this.zoneSettings?.zoneCoolingUserLimitMin?.val;
                sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings?.zoneCoolingUserLimitMax?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneCoolingUserLimitMax?.val,'coolingUserLimitMax')).toString(): this.zoneSettings?.zoneCoolingUserLimitMax?.val;
                sliderValueFromZoneSettings.heatingDeadband =  this.unitService.convertingDeadBandValue(this.multiModuleProfile ? this.multipleModuleInput.heatingDeadband : this.zoneSettings?.heatingDeadband?.val) 
                sliderValueFromZoneSettings.coolingDeadband =  this.unitService.convertingDeadBandValue(this.multiModuleProfile ? this.multipleModuleInput.coolingDeadband : this.zoneSettings?.coolingDeadband?.val);
                this.zoneSettings.heatingDeadband.val = Number(sliderValueFromZoneSettings.heatingDeadband);
                this.zoneSettings.coolingDeadband.val = Number(sliderValueFromZoneSettings.coolingDeadband);   
        } else {
                sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings?.currentTemp?.val;
                this.setSliderBasedOnSingleDual(sliderValueFromZoneSettings, false)
                sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings?.zoneHeatingUserLimitMin?.val;
                sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings?.zoneHeatingUserLimitMax?.val;
                sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings?.zoneCoolingUserLimitMin?.val;
                sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings?.zoneCoolingUserLimitMax?.val; 
                sliderValueFromZoneSettings.heatingDeadband = this.multiModuleProfile ? this.multipleModuleInput.heatingDeadband : this.zoneSettings.heatingDeadband.val;
                sliderValueFromZoneSettings.coolingDeadband = this.multiModuleProfile ? this.multipleModuleInput.coolingDeadband : this.zoneSettings.coolingDeadband.val;
                this.zoneSettings.heatingDeadband.val = Number(sliderValueFromZoneSettings.heatingDeadband);
                this.zoneSettings.coolingDeadband.val = Number(sliderValueFromZoneSettings.coolingDeadband);   
        }   
    }

    setSliderBasedOnSingleDual(sliderValueFromZoneSettings, prefUnit) {

        sliderValueFromZoneSettings.desiredTempHeating = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString() : this.zoneSettings?.desiredTempHeating?.val;
        sliderValueFromZoneSettings.desiredTempCooling = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString() : this.zoneSettings?.desiredTempCooling?.val;

        if (this.zoneSettings['singleDual']?.val == 0) {
            sliderValueFromZoneSettings.desiredTempHeating = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString() : this.zoneSettings?.desiredTempHeating?.val;
            sliderValueFromZoneSettings.desiredTempCooling = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString() : this.zoneSettings?.desiredTempCooling?.val;
        } else if (this.zoneSettings['singleDual']?.val == 2) {
            sliderValueFromZoneSettings.desiredTempHeating = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString() : this.zoneSettings?.desiredTempHeating?.val;
            sliderValueFromZoneSettings.desiredTempCooling = '0';
        } else if (this.zoneSettings['singleDual']?.val == 1) {
            sliderValueFromZoneSettings.desiredTempHeating = '0';
            sliderValueFromZoneSettings.desiredTempCooling = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString() : this.zoneSettings?.desiredTempCooling?.val;
        }

        return sliderValueFromZoneSettings;
    }

    isDataLoaded(data: any, equipStatus: boolean = false) {
        return this.helperService.isDataLoaded(data, equipStatus);
    }

    callZoneEvent(event, floorName) {

        let parentZone: HTMLElement = event.target.parentElement as HTMLElement;

        while (!parentZone.className.includes('box')) {
            parentZone = parentZone.parentElement as HTMLElement;
        }

        if (event.type == "click") {
            parentZone.click();
        }

    }

    handleHumidityDehumidity() {
        let hyperStatProfileName = this.profileObj?.profile?.markers?.find(e => e === 'hyperstatsplit' || e === 'hyperstat');
        if (hyperStatProfileName?.length || this.multiModuleProfile) {
            let keys = ['relay1Association', 'relay2Association', 'relay3Association', 'relay4Association', 'relay5Association', 'relay6Association']
            let relayKeysStatus = ['relay1', 'relay2', 'relay3', 'relay4', 'relay5', 'relay6']
            //Note As of now for only hyperstat split has relay 7 and relay 8 for the other hyperstat profiles
            // with the old builds we have up to relay6 in futture aprt form hyperstat  split also we will get relay8 
            if (hyperStatProfileName == 'hyperstatsplit') {
                keys = [...keys, 'relay7Association', 'relay8Association']
                relayKeysStatus = [...relayKeysStatus, 'relay7', 'relay8']
            }
            if (this.profiles?.length) {
                this.profiles.forEach((_item, _index) => {
                    if (_item['profileType'] == 'cpu' || _item['profileType'] == 'cpuecon' || _item['profileType'] == 'hpu' || _item['profileType'] == 'pipe2') {
                        let profileName = _item['profile']?.['name'] ? _item['profile']['name'] : '';
                        let relayVal;
                        let relayEnabled
                        for (let i = 0; i < keys.length; i++) {
                            let profileTypeCheck = _item['profileType']
                            const relayKey = profileTypeCheck == _item['profileType'] ? keys[i] : '';
                            const relayKeyStatus = profileTypeCheck == _item['profileType'] ? relayKeysStatus[i] : '';
                            if (this.profiles.length > 1) {
                                relayVal = this.setRelayproperties(profileName, relayKey);
                                relayEnabled = this.setRelayproperties(profileName, relayKeyStatus);
                            } else {
                                relayVal = this.relaypropertForEmptyProfiles(relayKey);
                                relayEnabled = this.relaypropertForEmptyProfiles(relayKeyStatus);
                            }
                            if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
                                _item[relayKey] = relayVal;

                                if (_item['profileType'] == 'pipe2' && relayVal == 8 && relayEnabled == 1 || _item['profileType'] == 'cpu' && relayEnabled == 1 && relayVal == 11 || _item['profileType'] == 'cpuecon' && relayEnabled == 1 && relayVal == 11 || _item['profileType'] == 'hpu' && relayEnabled == 1 && relayVal == 10) {
                                    _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidifier'], _item['tags'], 1);
                                    this.profiles[_index] = Object.assign({}, _item);
                                }


                                if (_item['profileType'] == 'pipe2' && relayEnabled == 1 && relayVal == 9 || _item['profileType'] == 'cpu' && relayEnabled == 1 && relayVal == 12 || _item['profileType'] == 'cpuecon' && relayEnabled == 1 && relayVal == 12 || _item['profileType'] == 'hpu' && relayEnabled == 1 && relayVal == 11) {
                                    _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                                    this.profiles[_index] = Object.assign({}, _item);
                                }
                            }

                        }
                    }
                });

            }
        } else {

            if (this.profiles?.length) {
                this.profiles.forEach((_item, _index) => {
                    if (_item['profileType'] == 'cpu' || _item['profileType'] == 'hpu') {
                        let relayKey = _item['profileType'] == 'cpu' ? 'relay6Type' : 'relay5Type';
                        let profileName =  _item['profile']?.['name'] ? _item['profile']['name'] : '';
                        let relayVal;
                        if (this.profiles.length > 1) {
                            relayVal = (this.zoneSettings && profileName && this.zoneSettings[profileName]) ?
                                ((this.zoneSettings[profileName]?.[relayKey]?.['val']) ?
                                    (this.zoneSettings[profileName][relayKey]['val'] || '') : '') : '';
                        } else {
                            relayVal = (this.zoneSettings) ?
                                ((this.zoneSettings[relayKey]?.['val']) ?
                                    (this.zoneSettings[relayKey]['val'] || '') : '') : '';
                        }
                        if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
                            _item[relayKey] = relayVal;
                            if (relayVal == '2') { // Adding targetHumidity
                                _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidity'], _item['tags'], 1);
                                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            } else if (relayVal == '3') { // Adding targetDehumidity
                                _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            } else {
                                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            }
                        }
                    }
                });
            }
        }

    }

    setRelayproperties(profileName, relayKey) {
        let relay
        if (this.profiles.length > 1) {
            if (this.zoneSettings && profileName && this.zoneSettings[profileName]) {

                if (this.zoneSettings[profileName][relayKey] && this.zoneSettings[profileName][relayKey]['val']) {
                    relay = this.zoneSettings[profileName][relayKey]['val'] || '';
                }
            } else {
                relay = ''
            }
        }
        return relay;

    }

    relaypropertForEmptyProfiles(relayKey) {
        let relay;
        if (this.zoneSettings) {
            if (this.zoneSettings[relayKey] && this.zoneSettings[relayKey]['val']) {
                relay = this.zoneSettings[relayKey]['val'] || '';
            } else {
                relay = ''
            }
        } else {
            relay = ''
        }

        return relay;
    }

    addClickEvent(event) {
        var $this = this;
        this.checkForEventType = event.type
        this.refs.zoneName = event.zone.dis
        if (event.type === 'click') {
            this.zoneHovercheckFlag = true;
            this.zoneClicked(event);
        }
        else if (event.type === 'hover' && event.type !== 'click') {
            this.zoneHovercheckFlag = false;
            this.clearHoverTimer();
            $this.hoverEnterTimer = setTimeout(function() {
				$this.zoneMouseHover(event);
			}, 1000);
        }
        else if (event.type === 'leave') {
            this.clearHoverTimer();
            this.zoneMouseLeave(event);
        }
        this.isFloorPlanLoaded = true;
        this.loaderService.active(false);
     }

     modbusProfileSort() {
        //The below conditional checks is applicable for only modbus profiles.
       //Based on port number child and parent equips are classified.
       let portNumber: number;
       this.profiles.forEach(x => {
           this.isModbus = x.profileType == "modbus";
           if (!this.isModbus) {
               x['profileName'] = x.profile.name;
               return;
           }
            x['profileName'] = `${x.profile.name}_${x.profile._id}`;
           if ((!x.profile?.referenceIDs?.hasOwnProperty('equip')) && this.isModbus) {
               portNumber = x.portNum;;
               x['isChild'] = false;
           }

           if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum == portNumber)) {
               x['isChild'] = true;
           } else if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum != portNumber)) {
               x['isChild'] = false;
           }
       })
         this.profiles.sort((a, b)=>{
             if (!a.profile.referenceIDs.equip && !b.profile.referenceIDs.equip) {
                 return 0;
               }
               if (a.profile.referenceIDs.equip && !b.profile.referenceIDs.equip) {
                 return 1;
               }
               if (!a.profile.referenceIDs.equip && b.profile.referenceIDs.equip) {
                 return -1;
               }
               if (a.profile.referenceIDs.equip && b.profile.referenceIDs.equip) {
                 return 1;
               }
            return 0;
         })
     }


    zoneClicked(event, fromSummaryTable = false){
        this.notesList = [];
        this.analyticsLayoutService.exportAnalyticsExcelData = [];
        this.analyticsLayoutService.totalAnalyticsCount = 0;
        this.analyticsLayoutService.isAnalyticsListLoading = false;
        this.isDownloadExcelPending = false;
        this.getNotesList(this.helperService.stripHaystackTypeMapping(event.zone.id),'zone')
        let modalLeftPos: number = 120;
        this.showNotesAccordion = false;
        this.modalPopupOpened = true;
        //Clean up the hovered modal
        this.closeHoverModel();
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;
       // this.messageService.scrollIndex = window.scrollY;
        (<HTMLElement>document.getElementsByClassName('fs-heatmap-wrapper__content')[0]).style.position = 'fixed';      
        this.dataForExport = {};
        this.checkWidgestLoaded = {};
        this.zonePriorityVal = undefined;
        this.oldZonePriorityVal = undefined;
        this.scheduleTypeVal = undefined;
        this.oldscheduleTypeVal = undefined;
        this.checkHyperStatProfile = undefined;
        this.zoneScheduleCount = 0;
        this.refs.roomRef = "";
        this.refs.equipRef = '';
        this.zoneSettings = null;
        this.tempZoneSettingsHolder = null;
        this.ccuOrZone = { type: 'zone', actionUsed: 'click' };
        this.hoverZoneData = event['zoneData'];
        this.cancelChanges = [];
        this.zonePriorityCount = 0;
        this.headerUserIntent = false;
        this.intializeDate();
        this.activeTile = event.zone.dis;
        const ccu = this.buildings.ccus.find(c=>c._id == event.ccuId);
        this.refs.ccuName = event['zoneData']?.ccuName;
        if(ccu) {
            this.refs.ccuEquipRef = ccu.referenceIDs.equip
        }
        this.getEquipRef(this.helperService.stripHaystackTypeMapping(event.zone.id));

        this.profiles = this.deviceHelper.getZoneProfile(this.roomObj);
        if(this.fileUpload) {
            if(this.profiles?.length > 0 ) {
                const observables = this.profiles.map(profile => {
                    return this.notesService.getTaggedFiles(this.refs.siteRef, profile.profile._id);
                });
            
                forkJoin(observables).subscribe(
                    (responses: any[]) => {
                        responses.forEach((res, index) => {
                            const profile = this.profiles[index];
                            profile['taggedFiles'] = res;
                            profile['enableViewAssets'] = res && res.length > 0;
                        });
                    },
                    (error) => {
                        console.log('Error fetching tagged files for profiles', error);
                    }
                );
            }
        }
        if(this.profiles.length == 0 ) { 
            this.enableDownLoad = true;
        }
        this.loadSummaryView = true;
        this.modbusProfileSort();
        this.multiModuleProfile = (this.profiles.length > 1);
        if (this.multiModuleProfile) {
            this.fetchequipstatus();
            const profiles = Array.from(this.profiles)
            profiles.forEach((_item) => {
                if (_item && ['vav', 'dab', 'ti','otn', 'bpos', 'series', 'parallel','vrv','chilledBeam'].includes(_item['profileType'])) {
                    this.zonePriorityCount++;
                }
                if (_item && ['vav', 'dab', 'ti', 'series', 'parallel', 'vrv','chilledBeam'].includes(_item['profileType'])) {
                    this.zonePriorityCount++;
                }
                if (_item && ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti', 'dualDuct', 'series', 'parallel', 'vrv','chilledBeam'].includes(_item['profileType'])) {
                    this.zoneScheduleCount++;
                }
            })
        }
        this.showTerminalEquipGraphics = false;
        this.checkProfileFlag();
        this.getProfileData(this.profiles);
        this.getUserSettingData("zoneClick");
        this.loadZoneTuners(this.roomObj);

        this.modalStyleClickedFlag = true;
        this.modelStyleOnClicked = {
            top: 45 + window.scrollY + 'px',
            left: modalLeftPos + 'px',
            height: 'auto',
            width: '82%',
            display: 'block'
        };
        if (fromSummaryTable) {
            this.modelStyleOnClicked.top = '45px';
        }
        this.hideSummaryTableSection = true;

        this.el.nativeElement.querySelector(".arrow")?.classList.add("hide");
        this.assimilateEntityCustomWidgets();
        setTimeout(() => {
            window.scroll(0,0);
        },50);
         //start the setInterval call to call the api to update the data change if any every 5 sec
        this.pollingFunction();
        this.pollGraphData();
    }

    //Clean up the hovered modal while opening click model
    closeHoverModel(){
        if (this.ccuOrZone.actionUsed == "hover" && this.ccuOrZone.type == "zone") {
            this.modelStyle = {
                display: 'none'
            };
            if(this.checkForEventType == 'leave' ) {
                document.documentElement.scrollTop = this.messageService.scrollIndex;
            }
            this.closeModal();
        }
    }

    checkProfileFlag(){
        if (this.profiles[(this.profiles).length - 1]) {
            this.profileCheckFlag = ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti','otn', 'bpos', 'dualDuct', 'series', 'parallel','vrv','chilledBeam'].includes(this.profiles[(this.profiles).length - 1].profileType);
        }
    }

    zoneMouseHover(event){
        if (event.zone.dis === this.activeTile) {
            return;
        }
        this.messageService.getMessage().subscribe(message => {
            if (message.isExpanded !== undefined) {
                this.sideMenu = message.isExpanded
            }
        });
        this.messageService.scrollIndex = window.scrollY

        let isSideOpened = document.getElementsByClassName('fs-sidebar ng-star-inserted expand');

        if (isSideOpened.length > 0) {
            this.sideMenu = true;
        } else {
            this.sideMenu = false;
        }
        //clean up previous hovered zone
        this.closeModal();

      if(this.fileUpload) {
        if(this.profiles?.length > 0 ) {
            const observables = this.profiles.map(profile => {
                return this.notesService.getTaggedFiles(this.refs.siteRef, profile.profile._id);
            });
        
            forkJoin(observables).subscribe(
                (responses: any[]) => {
                    responses.forEach((res, index) => {
                        const profile = this.profiles[index];
                        profile['taggedFiles'] = res;
                        profile['enableViewAssets'] = res && res.length > 0;
                    });
                },
                (error) => {
                    console.log('Error fetching tagged files for profiles', error);
                }
            );
        }
    }
        if (!event?.ccuId) {
            // If event.ccuId is empty, use event.zone.ccuRef as we need to highlight the ccu if zone is not paired
            this.highlightCCU = this.helperService.stripHaystackTypeMapping(event?.zone?.ccuRef)
        } else {
            // highlight the ccu on ccu section if zone is paired
            this.highlightCCU = event?.ccuId;
        }
        const ccu = this.buildings.ccus.find(c=>c._id == event.ccuId);
        if(ccu) {
            this.refs.ccuEquipRef = ccu.referenceIDs.equip
        }
        this.intializeDate();
        this.getEquipRef(this.helperService.stripHaystackTypeMapping(event.zone.id));
        this.ccuOrZone = { type: 'zone', actionUsed: 'hover' };
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;

        this.modalStyleClickedFlag = false;
        this.siteNameModalStyleClickedFlag = false;
        this.styleMouseHoverModel(event);

        this.activeTile = event.zone.dis;
        this.zoneId = event.zone.zoneId;
        this.shapeId = event.id;
        this.hoverZoneData  = event['zoneData'];
        this.refs.ccuName = event['zoneData']?.ccuName;
        this.profiles = this.deviceHelper.getZoneProfile(this.roomObj);
        this.modbusProfileSort();
        this.multiModuleProfile = (this.profiles.length > 1);
        this.showTerminalEquipGraphics = false;
        this.getProfileData(this.profiles);
        this.getUserSettingData("zoneClick");
        setTimeout(() => {
            this.overlayHeight = this.getOverlayHeight();
        }, 20);
        this.assimilateEntityCustomWidgets();
    }
    
    getEquipGraphicPoints() {
        const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        const OAOObj = this.getOAOEquip(['oao', 'equip', 'profile']);
        const btuObj = this.getBtuObj(['btu', 'equip', 'profile']);
    
        /**EquipGraphics**/
        if(this.equipGraphicSystemProfile !== "vavAdvancedHybridAhuV2") {
            systemprofileEquipGraphics.forEach((_profileObj: any) => {
                if(_profileObj.key == 'dcwbEnabled') {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, _profileObj?.filterTags,_profileObj?.domainName), _profileObj.action);
                }else {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null,null, _profileObj?.domainName), _profileObj.action);
                }
            });
        }
        //Note: Here we are keeping the seperate method for VAV Advanced AHU there are lot of thing which may be included in future 
        // And also this profile is only deals with DM integatsion so it will be handled seperatley with out comnbining with other profiles 
        //Cooling stages and heating stages differ based on load and sat 
        if(this.equipGraphicSystemProfile === "vavAdvancedHybridAhuV2") {
            this.getAdvancedHybridAhuV2Points(equipObj);
        }

        if(btuObj) {
            this.getZoneSettings('Outlet Water Temperature', this.helperService.getPointIdbyTags(btuObj, ['outlet','temp','system','displayInUi']), 'read');
            this.getZoneSettings('Inlet Water Temperature', this.helperService.getPointIdbyTags(btuObj, ['inlet','system','temp','displayInUi']), 'read');
            }
            this.getZoneSettings('chilled Water Valve',this.helperService.getPointIdbyTags(equipObj,['chilled','water','valve']),'read')
    
        if(OAOObj) {
            this.getZoneSettings('epidemic mode', this.helperService.getPointIdbyTags(equipObj, ['epidemic','mode']), 'read');
        }
    }
    
    getAdvancedHybridAhuV2Points(equipObj) {
        //vavAdvancedAhuV2EquipGraphics object are defined in the puc component from there were fetching requipred points
        vavAdvancedAhuV2EquipGraphics.forEach((_profileObj: any) => {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, null, _profileObj?.domainName), _profileObj.action);
        });
    }
    
    styleMouseHoverModel(event){
        let x = document.getElementById(event.id + '-center')? document.getElementById(event.id + '-center').getBoundingClientRect() : document.getElementById(event.id).getBoundingClientRect();
        let topPos = document.getElementById('heatMapHeader').getBoundingClientRect().height + 51;
        let modalLeftPos: number = this.sideMenu ? x.right - 35 - 141 : x.right - 35;
        let modalRightPos: number = this.sideMenu ? ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 15 : ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 10;
        if (((window.innerWidth || document.documentElement.clientWidth) - x.right) > ((window.innerWidth || document.documentElement.clientWidth) * 0.42)) {
            this.modelStyle = {
                top:  topPos + window.scrollY + 'px',
                left: modalLeftPos - x.width / 1.6 + 10 + 'px',
                height: window.screen.availHeight,
                width: 42 + '%',
                display: 'block'
            };
        } else {
            this.modelStyle = {
                top:  topPos + window.scrollY + 'px',
                right: modalRightPos - x.width / 1.6 + (this.sideMenu ? 90 : 30) + 'px',
                height: window.screen.availHeight,
                width: 42 + '%',
                display: 'block'
            };
        }
    }

    zoneMouseLeave(event){
        if (this.active) {
            clearTimeout(this.active);
        }
        if (this.ccuOrZone.type == "zone" && this.zoneId == event.zone.zoneId && document.getElementById(event.zone.zoneId) && document.getElementById(event.zone.zoneId).matches(':hover')) {
            return;
        }
        
        this.active = setTimeout(() => {
            if (event.zone.dis === this.activeTile && this.ccuOrZone.actionUsed != 'click') {
                this.modelStyle = {
                    display: 'none'
                };
                this.closeModal();
            }
        }, 50);
    }

    onPopEvent(e) {
        if (e.type === 'mouseenter') {
            if(this.shapeId)
            d3?.select(`#${this.shapeId}`)?.select(`.shapeArea`)?.style('stroke-width', 5);
            if (this.active) {
                clearTimeout(this.active);
            }
        } else if (e.type === 'mouseleave') {
            if (this.ccuOrZone.actionUsed == "hover") {
                if (this.ccuOrZone.type == "zone" && this.zoneId == e.target.id && document.getElementById(e.target.id) && document.getElementById(e.target.id).matches(':hover')) {
                    return;
                }
                if (this.ccuOrZone.type == "ccu" && this.refs.ahuRef == e.target.id && document.getElementById(e.target.id) && document.getElementById(e.target.id).matches(':hover')) {
                    return;
                }
                this.modelStyle = {
                    display: 'none'
                };

                this.siteNameModalStyle = {
                    display: 'none'

                }
                this.closeModal();
                if(this.shapeId) {
                    d3?.select(`#${this.shapeId}`)?.select(`.shapeArea`)?.style('stroke-width', 2);
                }
            }
        }
    }

    ClickedOut(event): void {
         this.checkForEventType = event.type;
         this.showNotesAccordion = true;
         this.modalPopupOpened = false;
        if (!((<Element>event.target).className == 'fs-heatmap-wrapper__content__box ng-star-inserted')) {
            this.modelStyleOnClicked = {
                display: 'none'
            }
            this.siteNameModalStyle = {
                display: 'none'
            }
            if(this.shapeId) {
                d3?.select(`#${this.shapeId}`)?.select(`.shapeArea`)?.style('stroke-width', 2);
            }
            if(this.checkForEventType == "click") {
                (<HTMLElement>document.getElementsByClassName('fs-heatmap-wrapper__content')[0]).style.position = 'relative';
                document.documentElement.scrollTop = this.messageService.scrollIndex;
            }
            this.closeModal();
        }

        this.siteNameModalStyleOnClicked = {
            display: 'none'
        }

        this.siteNameModalStyleClickedFlag = false;
        this.modalStyleClickedFlag = false;
        this.checkZoneDeadHisPointSubscriptions.forEach(subscription => subscription.unsubscribe());
        if (this.checkZoneDeadSubscription != undefined) {
            this.checkZoneDeadSubscription.unsubscribe();
        }
        if (this.checkIfOAOIsPairedSubscription != undefined) {
            this.checkIfOAOIsPairedSubscription.unsubscribe();
        }
        this.hideSummaryTableSection = false;
        this.clearHeatmapWidgets();
    }

    getProfileData(profileObj: any) {
        profileObj.map(profile => {
            if (profile) {
                this.loadProfileTags(profile.profileType, profile.profile, profile.tags, profile.portNum, profile.modBusEquip);
            }
        });
        //Check if its multi module , and it doenst have any runtime ,fire data fetch from here
        if (this.multiModuleProfile && this.runTimeGraphsService.moduleWithRuntime == null && !this.multiModuleProfileHasHPU && !this.multiModuleProfileHasCPU) {
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
        }
    }

    getHPUConfigPoints(profile: any): Observable<any[]> {
        let airFlowEnable = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['th1', 'enable'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));
        let heatPumpchangeovertype = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['zone', 'hpu', 'changeover', 'type'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));
        let hpuRelay5Config = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['relay5', 'type', 'config'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));

        return forkJoin([airFlowEnable, heatPumpchangeovertype, hpuRelay5Config])
    }

    getCPUConfigPoints(profile: any): Observable<any[]> {
        let cpuRelay6Config = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['relay6', 'type', 'config'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));

        return forkJoin([cpuRelay6Config])
    }

    checkIfOAOIsPaired(ahuRef) {
		this.siteService.oaoPairedCheck(ahuRef, 'oao and equip').subscribe(res => {
			if (res?.rows?.length > 0) {
				this.OaoShow = true;
				let systemPoints;
				let oaoRef = this.helperService.stripHaystackTypeMapping(res.rows[0].id);
				if ((this.systemReconfigProfileName === "dabExternalAHUController") || (this.systemReconfigProfileName === "vavExternalAHUController") || (this.isdomainName) || (this.equipGraphicSystemProfile  === "vavAdvancedHybridAhuV2")) {
					systemPoints = [
					  {
						  pointName: 'oaoLastUpdated',
						  query: `(equipRef==@${oaoRef})`,
						  type: 'read',
						  tags: ['heartbeat','oao']
					  },
					  {
						  pointName: 'oao',
						  query: `(equipRef==@${oaoRef.split(' ')[0]})`,
						  type: 'read',
						  tags: ['return', 'air', 'co2', 'sensor']
					  },
                        {
                            pointName: 'smartPrepurge',
                            type: 'write',
                            domainName: 'systemPrePurgeEnable'
                        },
                        {
                            pointName: 'smartPostpurge',
                            type: 'write',
                            domainName: 'systemPostPurgeEnable'
                        },
                        {
                            pointName: 'enhancedVentilation',
                            type: 'write',
                            domainName: 'systemEnhancedVentilationEnable'
                        }
                    ];
                } else {
                    systemPoints = [
                        {
                            pointName: 'oaoLastUpdated',
                            query: `(equipRef==@${oaoRef})`,
                            type: 'read',
                            tags: ['heartbeat']
                        },
                        {
                            pointName: 'oao',
                            query: `(equipRef==@${oaoRef.split(' ')[0]})`,
                            type: 'read',
                            tags: ['return', 'air', 'co2', 'sensor']
                        },
                        {
                            pointName: 'smartPrepurge',
                            type: 'write',
                            tags: ['system', 'userIntent', 'prePurge', 'enabled'],

                        },
                        {
                            pointName: 'smartPostpurge',
                            type: 'write',
                            tags: ['system', 'userIntent', 'postPurge', 'enabled'],

                        },
                        {
                            pointName: 'enhancedVentilation',
                            type: 'write',
                            tags: ['system', 'userIntent', 'enhanced', 'ventilation'],

                        }
                    ];
                }

                this.systemPoints = this.systemPoints.concat(systemPoints);
            }
            else {
                this.OaoShow = false;
            }
            this.getCCUSettingData();
        }, err => {
            this.getCCUSettingData();
        });
    }
    checkIfConnectModuleIsPaired(ahuRef) {
        this.siteService.oaoPairedCheck(ahuRef, 'equip and connectModule').subscribe(res => {
            if (res && res?.rows && res?.rows?.length > 0) {
                let connectModuleId = this.helperService.stripHaystackTypeMapping(res?.rows[0]?.id);
                let systemPoints = [];
                if (this.equipGraphicSystemProfile === "vavAdvancedHybridAhuV2") {
                    systemPoints = [
                        {
                            pointName: 'connectmodulecoolingstage1',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage1"
                        }, {
                            pointName: 'connectmodulecoolingstage2',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage2"
                        }, {
                            pointName: 'connectmodulecoolingstage3',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage3"
                        }, {
                            pointName: 'connectmodulecoolingstage4',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage4"
                        }, {
                            pointName: 'connectmodulecoolingstage5',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage5"
                        },
                        {
                            pointName: 'connectmoduleheatingstage1',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage1"
                        }, {
                            pointName: 'connectmoduleheatingstage2',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage2"
                        }, {
                            pointName: 'connectmoduleheatingstage3',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage3"
                        }, {
                            pointName: 'connectmoduleheatingstage4',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage4"
                        }, {
                            pointName: 'connectmoduleheatingstage5',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage5"
                        }, {
                            pointName: 'connectmoduleloadbasedHeatingcontrol',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadBasedHeatingControl"
                        }, {
                            pointName: 'connectmoduleloadbasedcoolingcontrol',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadBasedCoolingControl"
                        },
                    ];
                }

                this.systemPoints = (this.systemPoints || []).concat(systemPoints);
            }
            this.getCCUSettingData();
        }, (err) => {
            this.getCCUSettingData();
        });
    }

    loadGraphWidget(profileType: string, profileObj: any, tags: any, airFlowEnabled: boolean, heatPumpChangeOverType: string = null, hpuRelay5Config: string = null, cpuRelay6Config: string = null, moduleIdentifier: string = null, istrueCfmControlEnabled: boolean = false) {
        this.profiles.map(async profile => {
            if (profile && (profileObj._id === profile.profile._id)) {
                let data = null;
                let isMasterControllerEnabled = false;
                let isAirflowDirectionSupportEnabled = false;
                this.zonehasDomainName = profileObj?.markers?.includes('domainName');
                if (profileType == 'pid') {
                    data = this.getPIData(profileObj);
                } else if (profileType == 'cpu' || profileType == 'pipe2' ||  profileType == 'hpu') {
                    this.checkHyperStatProfile = profileObj.markers.find(e => e === 'hyperstat' || e === 'hyperstatsplit');
                    if (this.checkHyperStatProfile == 'hyperstat' || this.checkHyperStatProfile == 'hyperstatsplit') {
                        data = this.getHsCpuCTData(profileObj);
                    }
                } else if (profileType == 'sense' || profileType == 'monitoring') {
                    this.monitoringProfile = profileType == 'monitoring';
                    data = this.getSenseData(profileObj);
                } else if(profileType == 'vrv') {
                    const masterControllerMode = this.helperService.getPointIdbyTags(profileObj, ['masterController', 'vrv']);
                    const airflowDirectionSupportCapability = this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv', 'support']);
                    if(masterControllerMode.length > 0) {
                        await this.siteService.getWritablePointData(masterControllerMode[0]).subscribe((response) => {
                            let masterControllerModeValue = response.rows[0];
                            if(this.helperService.stripHaystackTypeMapping(masterControllerModeValue.val).split(' ')[0] == 1){
                                isMasterControllerEnabled =  true;
                                profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, false, true,this.checkHyperStatProfile,this.zonehasDomainName);
                            }
                        });
                    
                    }
                    if(airflowDirectionSupportCapability.length>0) {
                        await this.siteService.getHisPointData(airflowDirectionSupportCapability[0], 'current').subscribe((response) => {
                            let airflowDirectionSupportCapabilityValue =  response.rows[0];
                            if(this.helperService.stripHaystackTypeMapping(airflowDirectionSupportCapabilityValue.val).split(' ')[0] == 1){
                                isAirflowDirectionSupportEnabled =  true;
                                profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, false, true,this.checkHyperStatProfile,this.zonehasDomainName);
                            }
                        });
                    }
                
                } 

                const hscpuOperationMode = (this.helperService.getPointIdbyTags(profileObj, ['operating', 'mode', 'zone'], null))
                if (hscpuOperationMode?.length) {
                    this.siteService.getHisPointData(hscpuOperationMode[0], "current").subscribe((writableData) => {
                        this.operationModePoint = this.helperService.stripHaystackTypeMapping(writableData?.rows[0]?.val).split(' ')[0];
                        if (this.operationModePoint.length) {
                            this.deviceHelper.setOperationModeData(this.operationModePoint);
                        }
                    })
                }
                profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, istrueCfmControlEnabled,true,this.checkHyperStatProfile,this.zonehasDomainName);

                //Only show runtime for first module in case of multimodule profile
                if (this.runTimeGraphsService.isRuntimeRendered && this.runTimeGraphsService.moduleWithRuntime == profile.portNum) {
                    let subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
                        //Modify the runtime graph object
                        if (systemProfile) {
                            if(systemProfile.ccuRef != this.refs.ahuRef) {
                                return
                            }
                            //Runtime graphs logic
                            if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                                profile.widgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                                this.loadRuntimeWidgetData(this.refs.ahuRef);
                            }
                            else {
                                let subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                                    if (runtimeProfile) {
                                        //We can directly used after fetching once
                                        this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef,runtimeProfile.widgetData);
                                        profile.widgets[0] = runtimeProfile.widgetData;
                                        this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                                        subs_runtimeSub.unsubscribe();
                                        subs_sysProfile.unsubscribe();
                                    }
                                });
                                this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, false, this.refs.gateway,this.refs.ccuRef,this.refs.ccuName,false);
                            }
                            if (systemProfile.systemProfileName.includes("DEFAULT")) {
                                //for defualt profile remove comfortindex,modes widget and runtime widget
                                this.systemWidgets = this.systemWidgets.filter(widget => widget.id != "comfortIndex");
                                this.systemWidgets = this.systemWidgets.filter(widget => widget.id != "modes");
                                this.systemWidgets = this.systemWidgets.filter(widget => widget.id != 'runtimesystemprofile');
                            }
                        }
                        else {
                            //means no runtime for this profile , fetch the data
                            //All points have been fetched , get bulk read
                            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
                        }
                    });
                }
            }
        });

        this.loadWidgetData(profileType, profileObj, moduleIdentifier);
        //For profiles without runtime , fetch the data
        if (!this.multiModuleProfile && this.runTimeGraphsService.moduleWithRuntime == null) {
            //All points have been fetched , get bulk read
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
        }
    }

    getSenseData(profileObj) {
        let senseData = {
            analog1: '',
            analog2: '',
            th1:'',
            th2: '',
        }
        if (profileObj) {
            const profileType = this.monitoringProfile ? 'monitoring' : 'sense';
            let th1      = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'th1'], null);
            let th2      = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'th2'], null);
            let analog1  = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'analog1'], null);
            let analog2  = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'analog2'], null);
            
            if (profileObj.entities) {
                profileObj.entities.map(entity => {
                    [{
                        pointId: analog1,
                        key: 'analog1'
                    }, {
                        pointId: analog2,
                        key: 'analog2'
                    }, {
                        pointId: th1,
                        key: 'th1'
                    }, {
                        pointId: th2,
                        key: 'th2'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            senseData[point.key] = entity.dispName;
                        }
                    });
                });
            }
        }
        return senseData;
    }


    getPIData(profileObj) {
        let piData = {
            target: '',
            dynamicTarget: '',
            loopOutput:'',
            input: '',
            inputUnit: '',
            outputUnit: ''
        }
        if (profileObj) {
            let target = this.helperService.getPointIdbyTags(profileObj, ['zone', 'pid', 'target', 'config', 'value'], null);
            let dynamicTarget = this.helperService.getPointIdbyTags(profileObj, ['pid', 'dynamic', 'target', 'zone', 'value'], null);
            let input = this.helperService.getPointIdbyTags(profileObj, ['process', 'logical', 'variable'], null);
            let loopOutput = this.helperService.getPointIdbyTags(profileObj, ['control', 'variable'], null);
            if (profileObj.entities) {
                profileObj.entities.map(entity => {
                    [ {
                        pointId: dynamicTarget,
                        key: 'dynamicTarget'
                    }, {
                        pointId: target,
                        key: 'target'
                    },{
                        pointId: input,
                        key: 'input'
                    },{
                        pointId: loopOutput,
                        key: 'loopOutput'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            piData[point.key] = entity.dispName
                            if (point.key == 'input') {
                                piData.inputUnit = entity.unit;
                            } else if(point.key == 'loopOutput') {
                                piData.loopOutput = entity.unit;
                            }else {
                                piData.outputUnit = entity.unit;
                            }
                        }
                    });
                });
            }
        }

        return piData;

    }

    getHsCpuCTData(profileObj) {
        const hsCpuCTData = {
            analog1Ct: '',
            analog2Ct: ''
        };
        if(profileObj) {
            const analog1Ct = this.helperService.getPointIdbyTags(profileObj, ['analog1', 'in', 'current', 'logical'], null);
            const analog2Ct = this.helperService.getPointIdbyTags(profileObj, ['analog2', 'in', 'current', 'logical'], null);
            if (profileObj.entities) {
                profileObj.entities.map(entity => {
                    [{
                        pointId: analog1Ct,
                        key: 'analog1Ct'
                    }, {
                        pointId: analog2Ct,
                        key: 'analog2Ct'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            hsCpuCTData[point.key] = entity.dispName;    
                        }
                    });
                });
            }
        }
        return hsCpuCTData;
    }

    triggerFetchTimeSeriesData(key,moduleIdentifier) {
        //Check if its multi module , fire data fetch from here
        if (this.multiModuleProfile) {
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange, key + moduleIdentifier);
        }
    }

    loadProfileTags(profileType: string, profileObj: any, tags: any, moduleIdentifier: string = null, modBusEquip: string = null) {
        this.profileObj.profileType = profileType;
        this.profileObj.profile = profileObj;
        let zoneProfileType = profileObj.markers.find(e => e === 'hyperstat' ||e === 'smartstat' || e === 'hyperstatsplit');
        switch (profileType) {
            case "hpu":
                if (zoneProfileType == 'smartstat') {
                    this.multiModuleProfileHasHPU = true;
                    this.getHPUConfigPoints(profileObj).pipe(takeUntil(this.unsubscribe)).subscribe(responseList => {
                        let airflowEnable = responseList[0];
                        let hpcType = responseList[1];
                        let hpuRelay5Config = responseList[2];
                        if (airflowEnable.rows.length > 0 && hpcType.rows.length > 0 && hpuRelay5Config.rows.length > 0) {
                            this.loadGraphWidget(profileType, profileObj, tags, airflowEnable.rows[0].val, hpcType.rows[0].val, hpuRelay5Config.rows[0].val, null, moduleIdentifier);
                        }
                        this.triggerFetchTimeSeriesData("hpu", moduleIdentifier);
                    });
                } else {
                    this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier);
                }
                break;

            case 'chilledBeam':
            case 'vav':
            case 'series':
            case 'parallel':
                let isVavTrueCfmControlEnabled: boolean;
                const vavTrueCfmAirFlow = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'air', 'flow'],null,null,'airFlowSensor');
                const vavTrueCfmMinHeating = this.helperService.getPointIdbyTags(profileObj, ['heating', 'min', 'trueCfm'],null,null,'minCFMReheating');
                const vavTrueCfmMaxHeating = this.helperService.getPointIdbyTags(profileObj, ['heating', 'max', 'trueCfm'],null,null,'maxCFMReheating');
                const vavTrueCfmMinCooling = this.helperService.getPointIdbyTags(profileObj, ['cooling', 'min', 'trueCfm'],null,null,'minCFMCooling');
                const vavTrueCfmMaxCooling = this.helperService.getPointIdbyTags(profileObj, ['cooling', 'max', 'trueCfm'],null,null,'maxCFMCooling');
                if (vavTrueCfmAirFlow.length > 0 && vavTrueCfmMinHeating.length > 0 && vavTrueCfmMaxHeating.length > 0 && vavTrueCfmMinCooling.length > 0 && vavTrueCfmMaxCooling.length > 0) {
                    isVavTrueCfmControlEnabled = true;
                } else {
                    isVavTrueCfmControlEnabled = false;
                }
                this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isVavTrueCfmControlEnabled);
                break;

            case 'dab':
            case 'dualDuct':
                let isDabTrueCfmControlEnabled: boolean;
                const dabTrueCfmAirFlow = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'air', 'flow']);
                const dabTrueCfmIAQ = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'iaq']);
                if (dabTrueCfmAirFlow.length > 0 && dabTrueCfmIAQ.length > 0) {
                    isDabTrueCfmControlEnabled = true;
                } else {
                    isDabTrueCfmControlEnabled = false;
                }
                this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isDabTrueCfmControlEnabled);
                break;
               
            case 'cpu':
                this.loadGraphsForCPU(zoneProfileType, profileType, profileObj, tags, moduleIdentifier);
                break;

            default:
                this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier);
                break;
        }

        if (this.ccuOrZone.actionUsed == "click" || this.ccuOrZone.actionUsed == 'hover') {
            this.loadUserIntent(profileType,profileObj,zoneProfileType);
           
        } else {
            this.loadHeartBeatOnHover(profileType,profileObj);
        }

    }

    loadGraphsForCPU(zoneProfileType,profileType,profileObj,tags,moduleIdentifier) {
        if(zoneProfileType== "hyperstat"  || zoneProfileType == "hyperstatsplit") {
            if(ObjectUtil.isNotUndefinedOrNull(profileObj.domainName)) {
                this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier);
                if (this.multiModuleProfile) {
                    this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange, profileType + moduleIdentifier);
                }
             } else {
            let hsCpuAnalogOut1 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog1'], this.refs.roomRef))
            let hsCpuAnalogOut2 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog2'], this.refs.roomRef))
            let hsCpuAnalogOut3 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog3'], this.refs.roomRef))
            let hsCpuAnalogIn1 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'input', 'analog1'], this.refs.roomRef))
            let hsCpuAnalogIn2 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'input', 'analog2'], this.refs.roomRef))
            
            this.hsCpuWidgetsData.push(hsCpuAnalogOut1[0],hsCpuAnalogOut2[0],hsCpuAnalogOut3[0],hsCpuAnalogIn1[0],hsCpuAnalogIn2[0])
            this.siteService.getBulkWritablePointData(this.hsCpuWidgetsData.filter(v => v)).subscribe((writableData) => {
                 
                /** Analog out 1,2,3  point are useful in future validating the keycard and other auto away sensor points in graphs **/
                
                const hsCpuAnalogIn1Value = this.helperService.stripHaystackTypeMapping( writableData?.rows[3]?.data[0]?.val).split(' ')[0];
                const hsCpuAnalogIn2Value = this.helperService.stripHaystackTypeMapping( writableData?.rows[4]?.data[0]?.val).split(' ')[0];

                this.loadGraphWidget(profileType, profileObj, tags, true, null,hsCpuAnalogIn1Value,hsCpuAnalogIn2Value,moduleIdentifier);
                this.triggerFetchTimeSeriesData(profileType,moduleIdentifier);
            });
        }   
        } else {
            this.multiModuleProfileHasCPU = true;
             this.getCPUConfigPoints(profileObj).pipe(takeUntil(this.unsubscribe)).subscribe(responseList => {
                const cpuRelay6Config = responseList[0];
                if (cpuRelay6Config.rows.length > 0) {
                    this.loadGraphWidget(profileType, profileObj, tags, true, null, null, cpuRelay6Config.rows[0].val,moduleIdentifier);
                }
                this.triggerFetchTimeSeriesData("cpu",moduleIdentifier);
            });        
        }
    }

    loadHeartBeatOnHover(profileType,profileObj) {
        let equipName = undefined;
        if (this.multiModuleProfile) {
            equipName = profileObj.name
        }
        switch (profileType) {
            case 'vav':
            case 'dab':
            case 'cpu':
            case 'hpu':
            case 'pipe2':
            case 'pipe4':
            case 'sse':
            case 'ti':
            case 'otn':
            case 'bpos':
            case 'dualDuct':
            case 'series':
            case 'parallel':
            case 'vrv':
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null,null,'heartBeat'), 'read', equipName);
                break;

            case 'emr':
                equipName = profileObj.name;
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);
                break;

            case 'chilledBeam':
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null,null,'heartBeat'), 'read', equipName);
                break;

            case 'pid':
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);
                break;

            case 'sense':
                equipName = null;
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);
            break;

            case 'modbus':
                let points = [];
                points = this.helperService.getPointIdbyTags(profileObj, ["heartbeat"], null);
                
                points = points.map(point => point);

                if(points.length > 0) {
                    this.getZoneSettings("lastUpdated", [points[0]], this.helperService.pointType(profileObj, points[0], this.refs.roomRef) ? 'read&hisWrite' : 'read&hisRead', equipName);  
                } 

                break;

            default:
                console.log('This profile is not handled');
                break;
        }
    }


    getRefFromData(_ref) {
        let ref = "";
        if (_ref == "roomRef") {
            ref = this.refs.equipRef
        } else if (_ref = "equipRef") {
            ref = this.refs.equipRef
        }
        return ref;
    }

    loadUserIntent(profileType,profileObj,zoneProfileType) {
        let equipName;
        if (this.multiModuleProfile) {
            equipName = profileObj.name
        }
        const certificationLevel = this.authService.certificationLevel;
        const domainName = profileObj?.domainName != undefined;
        switch (profileType) {
            case 'vav':
                zoneProfile_vav.forEach((configData: any) => {
                    this.isDomainIntegrated = !!profileObj.domainName;
                        
                    if (configData.certificationCheck.includes(certificationLevel)) {
                       this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null,configData?.filter, configData?.domainName), configData.action, equipName);
                    }
                });
                domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
                break;
                
            case 'series':
                zoneProfile_series.forEach((configData: any) => {
                    this.isDomainIntegrated = !!profileObj.domainName;
                        
                    if (configData.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null, configData?.filter, configData?.domainName), configData.action, equipName);
                    }
                });
                domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
                break;
            
            case 'parallel':
                zoneProfile_parallel.forEach((configData: any) => {
                    this.isDomainIntegrated = !!profileObj.domainName;
                    
                    if (configData.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null,configData?.filter, configData?.domainName), configData.action, equipName);
                    }
                });
                domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
                break;

            case 'dab':
                zoneProfile_dab['userIntent'].forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'cpu':
                if (zoneProfileType == "hyperstat" || zoneProfileType == "hyperstatsplit") {
                    zoneProfile_cpu_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });

                    if (zoneProfileType == "hyperstat") {
                        zoneProfile_hyperstatCpu_specific.forEach((_profileObj: any) => {
                            if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                            }
                        });
                    } else {
                        zoneProfile_hyperstatSplit_specific.forEach((_profileObj: any) => {
                            if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                            }
                        });
                    }

                } else {
                    zoneProfile_cpu_non_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });
                }
                break;

            case 'hpu':
                if (zoneProfileType == "hyperstat") {
                    zoneProfile_hyperstathpu.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });

                } else {
                    zoneProfile_hpu_non_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });
                }
                break;

            case 'pipe2':
                if (zoneProfileType == "hyperstat") {
                    zoneProfile_hyperstatPipe2.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });

                } else {
                    ZoneProfile_2pipe_non_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });
                }
                break;

            case 'pipe4':
                zoneProfile_pipe4.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'emr':
                equipName = profileObj.name;
                zoneProfile_emr.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'pid':
                zoneProfile_pid.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'sse':
                zoneProfile_sse.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'ti':
                zoneReconfig_ti.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                    }
                });

                if (this.showTiProfile) {
                    this.getZoneSettings('supplyAirTemperature', this.helperService.getPointIdbyTags(profileObj, ['supply', 'temp', 'ti', 'type', 'sp'], this.refs.roomRef), 'write', equipName);
                    this.getZoneSettings('roomTemperature', this.helperService.getPointIdbyTags(profileObj, ['space', 'temp', 'sp', 'ti', 'type'], this.refs.roomRef), 'write', equipName);
                }

                break;

            case 'otn':
                zoneProfile_otn.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                    }
                });
                break;

            case 'bpos':
                zoneProfile_bpos.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                    }
                });
                break;

            case 'dualDuct':
                zoneProfile_dualDuct.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'vrv':
                zoneProfile_vrv.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                    }
                });
                break;

            case 'modbus':
                profileObj = this.getModbusPoints(profileObj, equipName);
                break;

            case 'sense':
                zoneProfile_sense.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'monitoring':
                zoneProfile_monitoring.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'chilledBeam':
                zoneProfile_chilledBeam['userIntent'].forEach((_profileObj: any) => {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj,_profileObj.tags, _profileObj.roomRef, _profileObj?.filter, _profileObj?.domainName), _profileObj.action, equipName);
                });
                break;

            default:
                console.log('This profile is not handled');
                break;
        }
        if (profileType !== 'modbus') {
            this.getZoneSettings('desiredTempHeating', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'heating'], null,null,'desiredTempHeating'), 'write');
            this.getZoneSettings('desiredTempCooling', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'cooling'], null,null,'desiredTempCooling'), 'write');
            this.getZoneSettings('currentTemp', this.helperService.getPointIdbyTags(profileObj, ['current', 'temp'], null,null,'currentTemp'), 'read',equipName);
            const siteObj = this.getSiteObj(['equip','tuner']);
            this.getZoneSettings('zoneHeatingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneCoolingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneHeatingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneCoolingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('buildingLimitMin', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'min'],null,['tuner']), 'write');
            this.getZoneSettings('buildingLimitMax', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'max'],null,['tuner']), 'write');
            this.getZoneSettings('buildingToZoneDifferential', this.helperService.getPointIdbyTags(siteObj, ['building', 'default', 'differential', 'kind'],null,['tuner']), 'write');
            this.getZoneSettings('heatingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'schedulable'], this.refs.roomRef,), 'write');
            this.getZoneSettings('coolingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write');    
            this.getZoneSettings('zoneLevelunoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['zone', 'setback', 'unoccupied', 'kind','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('singleDual', this.helperService.getPointIdbyTags(this.buildings, ['hvacMode'], this.refs.roomRef), 'read');

            if (this.multiModuleProfile) {
                this.getZoneSettings('heatingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write', profileObj.name);
                this.getZoneSettings('coolingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write', profileObj.name);
                this.getZoneSettings('desiredTempCooling', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'cooling'], null), 'write', profileObj.name);
                this.getZoneSettings('desiredTempHeating', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'heating'], null), 'write', profileObj.name);
                this.getZoneSettings('zonePriority', this.helperService.getPointIdbyTags(profileObj, ['zone', 'priority', 'config'], null), 'write', profileObj.name);
                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', profileObj.name);
            }

            this.getZoneSettings('zoneVacation', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'vacation'], this.refs.roomRef), 'vacation');
            this.getZoneSettings('zoneSpecialSchedule', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'special'], this.refs.roomRef), 'vacation');
            this.getZoneSettings('equipScheduleStatus', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleStatus'], null), 'write');
            this.getZoneSettings('zoneSchedule', this.helperService.getPointIdbyTags(this.buildings, ['zone', 'schedule', 'days'], this.refs.roomRef), 'schedule');
            this.getZoneSettings('buildingSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'days']) , 'schedule');
            this.getZoneSettings('buildingOccupancy', this.helperService.getPointIdbyTags(this.buildings.buildingOccupancy, ['building', 'occupancy','days']) , 'schedule');
            this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write');
            //revamped scheduler building level user limit points
            this.getZoneSettings('buildingHeatingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max', 'default', 'schedulable'],null,null,'heatingUserLimitMax'), 'write');
            this.getZoneSettings('buildingCoolingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min', 'default', 'schedulable'],null,null,'coolingUserLimitMin'), 'write');
            this.getZoneSettings('buildingHeatingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min', 'default', 'schedulable'],null,null,'heatingUserLimitMin'), 'write');
            this.getZoneSettings('buildingCoolingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max', 'default', 'schedulable'],null,null,'coolingUserLimitMax'), 'write');
            this.getZoneSettings('buildingHeatingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'default', 'schedulable'],null,null,'heatingDeadband'), 'write');
            this.getZoneSettings('buildingCoolingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'default', 'schedulable'],null,null,'coolingDeadband'), 'write');
            this.getZoneSettings('buildingUnoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['unoccupied', 'setback', 'default', 'schedulable'],null,null,'unoccupiedZoneSetback'), 'write');
        }
    }

    getModbusPoints(profileObj,equipName) {
        let isHeartBeat = false;
        let points = [];
        profileObj['noPoints'] = true;
        let heartBeatPoints = this.helperService.getPointIdbyTags(profileObj, ["heartbeat"], null);
        if(heartBeatPoints.length) isHeartBeat = true;
        points = this.helperService.getPointIdbyTags(profileObj, ["modbus", "shortDis", "displayInUi"], null);
        points = heartBeatPoints.concat(points);
        points = points.map(point => point);
        if(points.length > 0) {
            if(isHeartBeat)
                profileObj['noPoints'] = points.length < 2;
            else 
                profileObj['noPoints'] = false;
            
            for (let i = 0; i < points.length; i++) {
                let label = i==0 && isHeartBeat? 'lastUpdated': "modbus" + (i + 1);
                let poinType = this.helperService.pointType(profileObj, points[i], null,'writable') ? 'read&hisWrite' : (this.helperService.pointType(profileObj, points[i], null,'his')? 'read&hisRead' : 'read&read'); 
                this.getZoneSettings(label, [points[i]], poinType, equipName);
            }
        }
        return profileObj;
    }

    getModBuslabels(points,isHeartBeat,profileObj,equipName) {
        for (let i = 0; i < points.length; i++) {
            let label = i == 0 && isHeartBeat ? 'lastUpdated' : "modbus" + (i + 1);
            let poinType = this.helperService.pointType(profileObj, points[i], this.refs.roomRef, 'writable') ? 'read&hisWrite' : (this.helperService.pointType(profileObj, points[i], this.refs.roomRef, 'his') ? 'read&hisRead' : 'read&read');
            this.getZoneSettings(label, [points[i]], poinType, equipName);
        }
    }

    loadRuntimeWidgetData(equipRef: string) {

        if (this.runTimeGraphsService.cmBoardPortsMappingsForDaikinCollection.get(equipRef)) {
            this.runTimeGraphsService.cmBoardPortsMappingsForDaikinCollection.get(equipRef).forEach((value, key) => {
                this.getPointId(key, value);
            });
        }
        if (this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef)) {
            this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef).forEach((value, key) => {
                if (value.isEnabled) {
                    this.getPointId(value.param, value.ref);
                }
            });
        }

        if (this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef)) {
            this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef).forEach((value, key) => {
                if (value.isEnabled) {
                    this.getPointId(value.param, value.ref);
                }
            })
        }

        //All points have been fetched , get bulk read
        this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);

        //Fetch the properties
        const btuObj = this.getBtuObj(['btu', 'equip', 'profile']);
        let equipObj = this.getEquip(['system', 'equip', 'profile']);
        this.getPointProperties(equipObj);
        this.getPointProperties(btuObj);
    }

    getCommonPoints(moduleIdentifier, siteObj) {
        this.getPointId('heatingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min','schedulable'], this.refs.roomRef)[0]);
        this.getPointId('coolingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max','schedulable'], this.refs.roomRef)[0]);
        this.getPointId('heatingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max','schedulable'], this.refs.roomRef)[0]);
        this.getPointId('coolingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min','schedulable'], this.refs.roomRef)[0]);
        
    }

    loadWidgetData(profileType: string, profileObj: any, moduleIdentifier: string) {
        let siteObj = this.getSiteObj(['tuner', 'equip']);
        const diagnosticObj = this.getDiagObj(['equip', 'diag'],true);
        const hyperStatProfileName = this.profileObj?.profile?.markers?.find(e => e === 'hyperstat' || e === 'hyperstatsplit');
        if (!moduleIdentifier) { 
          return;
        }
        switch (profileType) {
        
            case 'vav':
            case 'series':
            case 'parallel':
                this.getCommonPoints(moduleIdentifier,siteObj);
                ProfileWidgets['vav_terminal']['widgets'].forEach((p) => {
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,null,p?.domainName)[0]);
                })
                this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'vav', 'temp'], null,null,'currentTemp')[0]);
                break;

            case 'dab':
                this.getCommonPoints(moduleIdentifier, siteObj);
                zoneProfile_dab['widgets'].forEach((p) => {
                    this.getPointId(p.key + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
                })
                break;


            case 'cpu':
                this.getCommonPoints(moduleIdentifier,siteObj); 
                    
                /** Sensor values for smart stat and hyperstat**/
                this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], this.refs.roomRef)[0]);
                this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'logical', 'scheduleType'], this.refs.roomRef,null,'scheduleType')[0]);
                this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor', 'sense'], this.refs.roomRef)[0]);
                this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], this.refs.roomRef)[0]);
                this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], this.refs.roomRef)[0]);
                this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], this.refs.roomRef)[0]);
                this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], this.refs.roomRef)[0]);
                this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], this.refs.roomRef)[0]);
                this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], this.refs.roomRef)[0]);
                this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], this.refs.roomRef)[0]);
                if ((hyperStatProfileName == "hyperstat" && !this.showHyperStatCpu) || hyperStatProfileName == "hyperstatsplit") {
                    ProfileWidgets['hstat_split_cpu'].forEach((p) => {
                        this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, this.refs.roomRef,null,p?.domainName)[0]);
                    })

                    if (hyperStatProfileName == "hyperstat") {
                        this.getPointId('modulatingDamperAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'dcv', 'analog'], this.refs.roomRef)[0])
                        this.getPointId('modulatingFanSpeedAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['output', 'fan', 'logical', 'cmd', 'analog'], this.refs.roomRef)[0]);
                    } else {
                        this.getPointId('modulatingDamperAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'analog', 'economizer', 'output'], this.refs.roomRef,null,'oaoDamper')[0]);
                    }
            } else if(hyperStatProfileName == "hyperstat" && this.showHyperStatCpu) {
                /**Old hscpu tags */
                this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'cpu', 'temp'], null)[0]);
                this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'heating'], null)[0]);
                this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'scheduleType'], null)[0]);
                this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'cooling'], null)[0]);


                this.getPointId('condheatingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'hyperstat', 'stage1'], null)[0]);
                this.getPointId('condheatingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'hyperstat', 'stage2'], null)[0]);
                this.getPointId('condcoolingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'hyperstat', 'stage3'], null)[0]);
                this.getPointId('condheatingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'hyperstat', 'stage3'], null)[0]);
                this.getPointId('condcoolingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'hyperstat', 'stage1'], null)[0]);
                this.getPointId('condcoolingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'hyperstat', 'stage2'], null)[0]);


                this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hyperstat', 'cpu', 'auto', 'away', 'config'], null)[0]);
                this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hyperstat', 'cpu', 'auto', 'forced'], null)[0]);

                this.getPointId('modulatingCoolingAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog1', 'cooling', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingFanSpeedAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog1', 'fan', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingHeatingAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog1', 'heating', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingDamperAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog1', 'damper', 'out', 'logical'], null)[0]);

                this.getPointId('modulatingCoolingAnalog2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog2', 'cooling', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingFanSpeedAnalog2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog2', 'fan', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingHeatingAnalog2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog2', 'heating', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingDamperAnalog2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog2', 'damper', 'out', 'logical'], null)[0]);

                this.getPointId('modulatingCoolingAnalog3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog3', 'cooling', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingFanSpeedAnalog3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog3', 'fan', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingHeatingAnalog3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog3', 'heating', 'out', 'logical'], null)[0]);
                this.getPointId('modulatingDamperAnalog3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['analog3', 'damper', 'out', 'logical'], null)[0])
                /**Hscpu locial fan mode points for graphs**/
                this.getPointId('fanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'stage1', 'fan'], null)[0]);
                this.getPointId('fanMedium' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'stage2', 'fan'], null)[0]);
                this.getPointId('fanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'stage3', 'fan'], null)[0]);

                this.getPointId('doorSensorTh2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['th2', 'hisInterpolate', 'window'], null)[0]);

                this.getPointId('analog1InCurrent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'analog1', 'current', 'hyperstat'], null)[0]);
                this.getPointId('analog1InKeyCardSensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'analog1', 'keycard', 'hyperstat'], null)[0]);
                this.getPointId('analog1InDoorSensor1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'analog1', 'hyperstat', 'window'], null)[0]);

                this.getPointId('analog2InCurrent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'analog2', 'current', 'hyperstat'], null)[0]);
                this.getPointId('analog2InKeyCardSensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'analog2', 'keycard', 'hyperstat'], null)[0]);
                this.getPointId('analog2InDoorSensor2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['logical', 'analog2', 'hyperstat', 'window'], null)[0]);


                this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'air', 'temp', 'th1'], null)[0]);
                this.getPointId('FanModeHsCpuHpu' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'fan', 'mode'], null)[0]);
                this.getPointId('ZoneOperationalMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'operating', 'mode', 'temp'], null)[0]);
                this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'conditioning', 'mode'], null)[0]);
                this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
                this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);
            } else {

                this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'scheduleType'], null)[0]);
                this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'heating'], null)[0]);
                this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'cooling'], null)[0]);
                this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'cpu', 'temp'], null)[0]); 

                this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor', 'sense'], null)[0]);
                this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);


                this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'air', 'temp', 'th1'], null)[0]);
                this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'conditioning', 'mode'], null)[0]);
                this.getPointId('ZoneOperationalMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'operating', 'mode', 'temp'], null)[0]);
                this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
        
                this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);
                this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cpu', 'auto','forced'], null)[0]);
                this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cpu', 'auto', 'away','config'], null)[0]);
                        
                this.getPointId('condfanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'cpu', 'stage1', 'cmd'], null)[0]);
                // relay 6 config data
                this.getPointId('condfanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'cpu', 'stage2', 'cmd'], null)[0]);
                // hpuHumidifier
                this.getPointId('cpuHumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'cpu', 'humidifier'], null)[0]);
                // hpuDehumidifier
                this.getPointId('cpuDehumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'cpu', 'dehumidifier'], null)[0]);
    
                this.getPointId('condcoolingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage1'], null)[0]);
                this.getPointId('condcoolingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage2'], null)[0]);
                this.getPointId('condcoolingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage3'], null)[0]);
                this.getPointId('condheatingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage1'], null)[0]);
                this.getPointId('condheatingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage2'], null)[0]);
                this.getPointId('condheatingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage3'], null)[0]);
                        
                this.getPointId('FanModeCpuHpu' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'fan', 'mode'], null)[0]);
                        
                this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                        
            }
            break;
            case 'hpu':
                this.getCommonPoints(moduleIdentifier,siteObj); 
                this.getPointId('hpuOperatingMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'operating', 'mode'], null)[0]);
                if (hyperStatProfileName == "hyperstat") {
                    ProfileWidgets['hyperStatHpu'].forEach((p) => {
                        if(['voc',"co2","pm2p5"].indexOf(p.name) > -1) {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,['config'])[0]);
                        } else {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                        }
                    })
                } else {
                    this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'hpu', 'temp'], null)[0]);
                    this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'hpu', 'temp', 'heating'], null)[0]);
                    this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'hpu', 'temp', 'cooling'], null)[0]);
                    this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'scheduleType'], null)[0]);
                    this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);

                    this.getPointId('compressorStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'compressor', 'stage1'], null)[0]);
                    this.getPointId('compressorStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'compressor', 'stage2'], null)[0]);
                    this.getPointId('fanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'hpu', 'stage1'], null)[0]);
                    this.getPointId('auxHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'aux', 'heating'], null)[0]);
                    this.getPointId('hpuOperatingMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'operating', 'mode', 'temp'], null)[0]);
                    // hpc cooling
                    this.getPointId('heatpumpChangeoverCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'heatpump', 'changeover', 'cooling'], null)[0]);
                    // hpc heating
                    this.getPointId('heatpumpChangeoverHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'heatpump', 'changeover', 'heating'], null)[0]);

                    // relay5 data
                    // fanstage2
                    this.getPointId('fanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'fan', 'hpu', 'stage2'], null)[0]);
                    // hpuHumidifier
                    this.getPointId('hpuHumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'hpu', 'humidifier'], null)[0]);
                    // hpuDehumidifier
                    this.getPointId('hpuDehumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'hpu', 'dehumidifier'], null)[0]);


                    this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'air', 'temp', 'th1'], null)[0]);
                    this.getPointId('FanModeCpuHpu' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'fan', 'mode'], null)[0]);
                    this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'conditioning', 'mode'], null)[0]);
                    this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
                    this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hpu', 'auto', 'away', 'config'], null)[0]);
                    this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hpu', 'auto', 'forced'], null)[0]);
                    this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                    this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                    this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                    this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                    this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    /** Sensor values for smart stat and hyperstat**/
                    this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                    this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);

                
                }
                break;
            case 'pipe2':
                this.getCommonPoints(moduleIdentifier,siteObj)
                if (hyperStatProfileName == "hyperstat") {
                    ProfileWidgets['hyperStatPipe2'].forEach((p) => {
                        if(['voc',"co2","pm2p5"].indexOf(p.name) > -1) {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,['config'])[0]);
                        } else {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                        }
                    })
                } else {
                    this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'scheduleType'], null)[0]);
                    this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);

                    this.getPointId("airflowTempSensorTh1" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'air', 'temp', 'th1'], null)[0]);
                    this.getPointId("SupplyWaterTemperature" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'water', 'temp', 'th2'], null)[0]);
                    this.getPointId("FanModeFCU" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'fan', 'mode'], null)[0]);
                    this.getPointId("ZoneOperationalMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'operating', 'mode', 'temp'], null)[0]);
                    this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'pipe2', 'temp'], null)[0]);
                    this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe2', 'temp', 'heating'], null)[0]);
                    this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe2', 'temp', 'cooling'], null)[0]);
                    
                    this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe2', 'auto','forced'], null)[0]);
                    this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe2', 'auto', 'away','config'], null)[0]);
                    this.getPointId("ZoneConditionMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'conditioning', 'mode'], null)[0]);
                    this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);

                    this.getPointId("fanLow" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'low'], null)[0]);
                    this.getPointId("fanMedium" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'medium'], null)[0]);
                    this.getPointId("fanHigh" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'high'], null)[0]);
                    this.getPointId("auxHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fcu', 'pipe2', 'aux', 'heating'], null)[0]);
                    this.getPointId("waterValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'fcu', 'pipe2', 'valve'], null)[0]);

                    this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                    this.getPointId("VOC" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                    this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                    
                    this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
                    this.getPointId("CO2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                    this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                    
                    this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    /** Sensor values for smart stat and hyperstat**/
                    this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                    this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
                }
                break;

            case 'pipe4':
                this.getCommonPoints(moduleIdentifier,siteObj);
                this.getPointId("fanLow" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'low'], null)[0]);
                this.getPointId("fanMedium" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'medium'], null)[0]);
                this.getPointId("fanHigh" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'high'], null)[0]);
                this.getPointId("heatingValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'heating', 'pipe4', 'valve'], null)[0]);
                this.getPointId("coolingValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'cooling', 'pipe4', 'valve'], null)[0]);
                this.getPointId("auxHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fcu', 'pipe4', 'aux', 'heating'], null)[0]);
                this.getPointId("waterValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'fcu', 'pipe4', 'valve'], null)[0]);
                this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'pipe4', 'temp'], null)[0]);
                this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe4', 'temp', 'heating'], null)[0]);
                this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe4', 'temp', 'cooling'], null)[0]);
                
                this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'scheduleType'], null)[0]);
                this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);

                this.getPointId("Humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
                this.getPointId("CO2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                this.getPointId("VOC" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);

                this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe4', 'auto','forced'], null)[0]);
                    this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe4', 'auto', 'away','config'], null)[0]);
                this.getPointId("airflowTempSensorTh1" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'air', 'temp', 'th1'], null)[0]);
                this.getPointId("FanModeFCU" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'fan', 'mode'], null)[0]);
                this.getPointId("ZoneOperationalMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'operating', 'mode', 'temp'], null)[0]);
                this.getPointId("ZoneConditionMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'conditioning', 'mode'], null)[0]);
                this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
                this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
                this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
               
                this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                
                this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                /** Sensor values for smart stat and hyperstat**/
               
                break;

            case 'emr':
                this.getPointId("currentRate" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'current', 'rate'], null)[0]);
                this.getPointId("energyMeterReading" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'emr', 'cur', 'sp'], null)[0]);
                break;

            case 'pid':
                this.getPointId("Analog1AtMaxOutput" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pid', 'analog1', 'max'], null)[0]);
                this.getPointId("Analog1AtMinOutput" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pid', 'analog1', 'min'], null)[0]);
                this.getPointId("targetValue" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pid', 'target', 'config', 'value'], null)[0]);
                this.getPointId("inputValue" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['process', 'logical', 'variable'], null)[0]);
                this.getPointId("offsetValue" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['setpoint', 'sensor', 'offset'], null)[0]);
                this.getPointId("dynamicValue" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pid', 'dynamic', 'target', 'zone', 'value'], null)[0]);
                this.getPointId("controlSignal" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pid', 'control', 'variable'], null)[0]);
                break;

            case 'sse':
                this.getCommonPoints(moduleIdentifier,siteObj);
                ProfileWidgets['sse'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                break;

            case 'ti':
                this.getCommonPoints(moduleIdentifier,siteObj);
                ProfileWidgets['ti'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                if (this.showTiProfile){
                    this.getPointId('supplyAirTemperature' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['supply','temp','sensor','logical'], null)[0]);
                    this.getPointId('roomTemperature' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['space','sensor','temp'], null)[0]);

                }
                break;
            case 'otn':
                this.getCommonPoints(moduleIdentifier,siteObj)
                ProfileWidgets['otn'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                break;
            
            case 'bpos':
                this.getCommonPoints(moduleIdentifier,siteObj)
                ProfileWidgets['bpos'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                break;


            case 'dualDuct':
                this.getCommonPoints(moduleIdentifier,siteObj);
                this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'dualDuct'], null)[0]);
                this.getPointId("humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'dualDuct'], null)[0]);
                this.getPointId("targetMinInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'dualDuct', 'min', 'humidity', 'inside'], null)[0]);
                this.getPointId("targetMaxInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'dualDuct', 'max', 'humidity', 'inside'], null)[0]);
                
                this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'dualDuct', 'temp'], null)[0]);
                this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'dualDuct', 'temp', 'heating'], null)[0]);
                this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'dualDuct', 'temp', 'cooling'], null)[0]);
                
                this.getPointId("co2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'dualDuct', 'air'], null)[0]);
                this.getPointId("zoneCO2Target" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'dualDuct', 'target'], null)[0]);
                this.getPointId("voc" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'dualDuct', 'air'], null)[0]);
                this.getPointId("zoneVOCTarget" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'dualDuct', 'target'], null)[0]);
                
                this.getPointId("enteringAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'dualDuct', 'air', 'entering', 'sensor'], null)[0]);

                this.getPointId("dischargeAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'dualDuct', 'air', 'discharge', 'sensor'], null)[0]);
                this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dualDuct', 'scheduleType'], null)[0]);
                this.getPointId("maxHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'dualDuct', 'heating', 'damper', 'limit'], null)[0]);
                this.getPointId("maxCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'dualDuct', 'cooling', 'damper', 'limit'], null)[0]);
                this.getPointId("minHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'dualDuct', 'heating', 'damper', 'limit'], null)[0]);
                this.getPointId("minCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'dualDuct', 'cooling', 'damper', 'limit'], null)[0]);
                this.getPointId('coolingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId('heatingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId('coolingSupplyAirflow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId('heatingSupplyAirflow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId("reheatPosition" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['reheat', 'dualDuct', 'valve'], null)[0]);
                this.getPointId("zonePriority" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dynamic', 'priority'], null)[0]);
                this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);
                this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['dualDuct', 'auto','forced'], null)[0]);
                this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['dualDuct', 'auto', 'away','config'], null)[0]);
                break;

            case 'modbus':
                break;

            case 'sense':
                ProfileWidgets['sense'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                break;

            case 'monitoring':
                ProfileWidgets['monitoring'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                break;

            case 'chilledBeam':
                this.getCommonPoints(moduleIdentifier,siteObj);
                zoneProfile_chilledBeam['widgets'].forEach((p) => {
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null,p?.domainName)[0]);
                })
                break;

            case 'vrv':
                this.getCommonPoints(moduleIdentifier,siteObj);
                this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'vrv', 'temp'], null)[0]);
                this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vrv', 'temp', 'heating'], null)[0]);
                this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vrv', 'temp', 'cooling'], null)[0]);
                
                this.getPointId("vrvHumidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'vrv','sensor'], null)[0]);
                this.getPointId("fanSpeed" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['fanSpeed', 'vrv','enum'], null)[0]);
                this.getPointId("airflowDirection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv','enum'], null)[0]);
                this.getPointId("filterStatus" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['filterStatus', 'vrv'], null)[0]);
                this.getPointId("coolHeatRight" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['coolHeatRight', 'vrv'], null)[0]);
                this.getPointId("operationMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['operation', 'mode', 'enum', 'vrv'], null)[0]);
                this.getPointId("masterOperationMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['operation', 'mode', 'master', 'vrv'], null)[0]);
                this.getPointId("masterControllerMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['masterController', 'vrv'], null)[0]);
                this.getPointId("airflowDirectionSupportCapability" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv', 'support'], null)[0]);
                this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'vrv', 'scheduleType'], null)[0]);
                this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'vrv'], null)[0]);
                // Sensor Widget Graph Points
                this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                
                this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                this.getPointId('testOperation' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','testOperation'], null)[0]);
                this.getPointId('telcoCheck' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['telecoCheck','vrv'], null)[0]);
                this.getPointId('vrvTh1Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th1','sensor'], null)[0]);
                this.getPointId('vrvTh2Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th2','sensor'], null)[0]);
                this.getPointId('vrvTh3Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th3','sensor'], null)[0]);
                this.getPointId('vrvTh4Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th4','sensor'], null)[0]);
                this.getPointId('vrvTh5Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th5','sensor'], null)[0]);
                this.getPointId('vrvTh6Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th6','sensor'], null)[0]);
                
                this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
                break;
            default:
                console.log('This profile is not handled');
                break;
        }

        this.getPointId('zoneequipmentConnected'+ moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null)[0]);
        if(diagnosticObj)
            this.getPointId('cloudConnected'+ moduleIdentifier, this.helperService.getPointIdbyTags(diagnosticObj, ['cloud', 'diag', 'connected'])[0]);

        //Set the properties
        this.getPointProperties(profileObj);
        this.getPointProperties(siteObj);
    }

    getSeriesOrParallel(moduleIdentifier,profileObj,key) {
        this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'vav', 'temp', key], null)[0]);
        this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vav', 'temp', 'heating', key], null)[0]);
        this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vav', 'temp', 'cooling', key], null)[0]);
        this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'vav', key], null)[0]);
        this.getPointId("humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'vav', key], null)[0]);
        this.getPointId("targetMinInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'vav', 'min', 'humidity', 'inside', key], null)[0]);
        this.getPointId("targetMaxInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'vav', 'max', 'humidity', 'inside', key], null)[0]);
        this.getPointId("co2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'vav', 'air', key], null)[0]);
        this.getPointId("zoneCO2Target" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'target', 'vav'], null)[0]);
        this.getPointId("voc" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'vav', 'air', key], null)[0]);
        this.getPointId("zoneVOCTarget" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'vav', 'target'], null)[0]);

        this.getPointId('cfmMinHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj,['heating','min','trueCfm',key],null)[0])
        this.getPointId('cfmMaxHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating','trueCfm','max',key],null)[0])
        this.getPointId('cfmMinCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling','trueCfm','min',key],null)[0])
        this.getPointId('cfmMaxCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling','trueCfm','max',key],null)[0])
        this.getPointId('cfmAirFlowVolume' + moduleIdentifier,this.helperService.getPointIdbyTags(profileObj, [ 'air','flow','trueCfm'],   null)[0]);
        
        this.getPointId("enteringAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'vav', 'air', 'entering', 'sensor', key], null)[0]);
        this.getPointId("dischargeAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'vav', 'air', 'discharge', 'sensor', key], null)[0]);
        this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'vav', 'scheduleType'], null)[0]);
        this.getPointId("maxHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'vav', 'heating', 'damper', 'pos', key], null)[0]);
        this.getPointId("maxCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'vav', 'cooling', 'damper', 'pos', key], null)[0]);
        this.getPointId("minHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'vav', 'heating', 'damper', 'pos', key], null)[0]);
        this.getPointId("minCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'vav', 'cooling', 'damper', 'pos', key], null)[0]);
        this.getPointId("damperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'vav', key, 'normalized'], null)[0]);
        this.getPointId("reheatPosition" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['reheat', 'vav', 'valve', key], null)[0]);
        this.getPointId("zonePriority" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dynamic', 'priority'], null)[0]);
        this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);
        this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
        this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
        this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
        this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
        this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
        this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
        this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
        this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
        this.getPointId(key+"Fan" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, [key, 'fan'], null)[0]);
    }

    loadSystemWidgetsData() {
        let equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        let diagnosticObj = this.getDiagObj(['equip', 'diag']);
        let siteObj = this.getSiteObj(['tuner', 'equip']);
        let OAOObj = this.getOAOEquip(['oao', 'equip', 'profile']);
        const btuObj = this.getBtuObj(['btu', 'equip', 'profile']);
        const getEMRObj = this.getEMRObj(['emr', 'equip', 'profile']);
        const getModbusObj = this.getEquipObjBytags(['modbus']);
        const getBypassBamper = this.getEquipObjBytags(['bypassDamper']);
        this.getSystemWidgetsData(equipObj, diagnosticObj, siteObj, OAOObj, btuObj, getEMRObj, getModbusObj, getBypassBamper)
        //Set the properties
        this.getPointProperties(equipObj);
        this.getPointProperties(siteObj);
        this.getPointProperties(diagnosticObj);
        OAOObj ? this.getPointProperties(OAOObj) : '';
        getBypassBamper ? this.getPointProperties(getBypassBamper) : '';
        this.getCCUAppVersions(diagnosticObj);
    }

    getSystemWidgetsData(equipObj,diagnosticObj,siteObj,OAOObj,btuObj,getEMRObj,getModbusObj,getBypassBamper) {
        let sysObj:any;
        for (let i = 0; i < systemprofileWidgets.length; i++) {
            const systemProfileObj = systemprofileWidgets[i];
            
            if (systemProfileObj.objType == 'equipObj' || (systemProfileObj.objType == 'equipObj' && systemProfileObj.key == 'ConditionMode')) {
                sysObj = equipObj;
            } else if (systemProfileObj.objType == 'OAOObj' && OAOObj) {
                sysObj = OAOObj;
            } else if (systemProfileObj.objType == 'diagnosticObj') {
                sysObj = diagnosticObj;
            } else if (systemProfileObj.objType == 'modbusObj') {
                sysObj = getModbusObj;
            } else if (systemProfileObj.objType == 'btuObj') {
                sysObj = btuObj;
            } else if (systemProfileObj.objType == 'getEMRObj') {
                sysObj = getEMRObj;
            } else if (systemProfileObj.objType == 'modbusObj') {
                sysObj = getModbusObj;
            } else if(systemProfileObj.objType == 'bypassDamperObj') {
                sysObj = getBypassBamper;
            } else if (systemProfileObj.objType == "schedulable" && this.showSchedulerOccupancy) {
                sysObj = this.buildings.schedulable;
            } else if (systemProfileObj.objType == "notSchedulable" && !this.showSchedulerOccupancy) {
                sysObj = siteObj;
            }
            this.getPointId('ConditionMode', this.getConditioningStatusPoint_tempCode(equipObj));
            this.getPointId(systemProfileObj.key, this.helperService.getPointIdbyTags(sysObj,systemProfileObj.tags ? systemProfileObj.tags : null, null,null, systemProfileObj?.domainName)[0]);
        } 
    }

    getCCUAppVersions(diagnosticObj) {
        // get ccu , home app, remote access app, bacnet app, cm version ids
        const ccuAppId = this.helperService.getPointIdbyTags(diagnosticObj, ['app', 'diag', 'version'])[0] || '';
        const backneApptId = this.helperService.getPointIdbyTags(diagnosticObj, ['bacnet', 'diag', 'version'])[0] || '';
        const remoteAppId = this.helperService.getPointIdbyTags(diagnosticObj, ['remoteAccess', 'diag', 'version'])[0] || '';
        const homeAppId = this.helperService.getPointIdbyTags(diagnosticObj, ['home', 'diag', 'version'])[0] || '';
        let device = this.allDevices.find(device => device.referenceIDs.equip == this.refs.ahuRef);
        const cmVerId = this.helperService.getPointIdbyTags(device ? device['entities'] : [], ['firmware', 'version'])[0] || '';
        this.version = '';
        this.cmVersion = '';
        this.homeAppVersion = '';
        this.remoteAccessAppVersion = '';
        this.backnetAppVersion = '';
        this.versionText = "";
        let versionIds = [ccuAppId, backneApptId, remoteAppId, homeAppId, cmVerId].filter(id => id);
        if (versionIds.length) {
            let sub = this.siteService.getBulkWritablePointData(versionIds).pipe(
                map(this.helperService.stripHaystackTypeMapping)).subscribe(({ rows }) => {
                    sub?.unsubscribe();
                    if (rows?.length > 0) {
                        rows.forEach(row => {
                            if (row.hasOwnProperty('app')) {
                                this.version = row?.data[0]?.val || '';
                                this.refs['ccuVersion'] = this.version;
                            } else if (row.hasOwnProperty('bacnet') && row.hasOwnProperty('diag')) {
                                this.backnetAppVersion = row?.data[0]?.val || '';
                            } else if (row.hasOwnProperty('remoteAccess')) {
                                this.remoteAccessAppVersion = row?.data[0]?.val || '';
                            } else if (row.hasOwnProperty('home')) {
                                this.homeAppVersion = row?.data[0]?.val || '';
                            } else if (row.hasOwnProperty('firmware')) {
                                this.cmVersion = row?.data[0]?.val || '';
                            }
                        });
                    }
                    this.versionText = "CCU: " + (this.version ? "v" + this.version : "NA") + " | CM: " + (this.cmVersion ? "v" + this.cmVersion : "NA") + " | Home: " + (this.homeAppVersion ? "v" + this.homeAppVersion : "NA") + " | Remote Access: " + (this.remoteAccessAppVersion ? "v" + this.remoteAccessAppVersion : "NA") + " | BACnet: " + (this.backnetAppVersion ? "v" + this.backnetAppVersion : "NA");
                }, () => {
                    sub?.unsubscribe();
                });
        }
    }

    getConditioningStatusPoint_tempCode(equipObj) {
        let point = this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'rtu'])[0];

        if (point == undefined) {
            point = this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'conditioning'])[0]
        }

        return point;
    }

    fetchequipstatus() {
        this.multiModuleZoneStatus = [];
        let pointsList = [];

        pointsList = this.helperService.getPointIdbyTags(this.buildings.entites, ['zone', 'his', 'status', 'enum'], this.refs.roomRef,['diag']);

         this.siteService.getCurrenthisReadMany(
            pointsList
        ).pipe( takeUntil(this.unsubscribe)).subscribe(({ rows }) => {
            if (rows && rows.length > 0) {
                rows.forEach((row) => {

                    let desc = row.dis;
                    let substringpos = desc.lastIndexOf("-");
                    let profile = desc.slice(0, substringpos);
                    let zonedeadStatus = row.data[0] ? this.helperService.stripHaystackTypeMapping(row.data[0].val).split(' ')[0] : undefined;
                    this.multiModuleZoneStatus.push({ 'profile': profile, 'equipStatus': zonedeadStatus });
                });
            }
            else {
            }
           
        },
        (err) => {
        });
    }

    handleZonePriority() {
        if (this.multiModuleProfile) {
            this.multiModuleZonePriority();
            this.getMultiZonePriorityVal();
        } else if (this.zoneSettings?.zonePriority) {
            this.zonePriorityVal = String(this.zoneSettings.zonePriority?.val);
        }
    }
    handleZoneSchedule() {
        if (this.multiModuleProfile) {
            this.multiModuleZoneSchedule();
            this.getMultiScheduleTypeVal();
        } else if (this.zoneSettings?.scheduleType?.hasOwnProperty('val')) {
                this.scheduleTypeVal = String(this.zoneSettings.scheduleType.val)
        }
    }


    getUserSettingData(key: string) {
        this.getUserSettingDataSubscriptionCollection[key] = this.helperService.getPointData()
            .pipe(
                takeUntil(this.unsubscribe))
            .subscribe(res => {

                if (key == 'reConfig') {
                    this.delayChanges.next(ObjectUtil.deepClone({
                        val: res,
                        key: key
                    }))

            } else {
                this.delayChanges.next(ObjectUtil.deepClone({
                    val: res,
                    key: key
                }))

            }
        });
    }

    calculateAvgCurrentTemp() {
        this.avgCurrentTemp = 0;
        let avgTempList = [];
        let profileZoneStatus;
        this.profiles.forEach(profile => {
            if (new RegExp(/^(vav|dab|cpu|pipe2|pipe4|hpu|sse|ti|dualDuct|series|parallel|vrv|chilledBeam)$/).test(profile.profileType)) {

                let filteredProfile = this.multiModuleZoneStatus.filter((x) => { if (x.profile == profile.profile.name) { return x; } })[0];
                profileZoneStatus = (filteredProfile) ? parseInt(filteredProfile.equipStatus) : undefined;
                if (profileZoneStatus != 3)
                    avgTempList.push(parseFloat(this.zoneSettings[profile.profile.name].currentTemp.val));

            }
        });
        var filtered = avgTempList.filter(function (value, index, arr) {

            return (Math.trunc(Number(value)) !== 0);

        });

        this.avgCurrentTemp = (filtered && filtered.length > 0) ? parseFloat(this.average(filtered).toFixed(1)) : 0;
        this.multipleModuleInput.currentTemp = this.avgCurrentTemp;
    }



    findHighestDeadbands() {
        this.coolingDeadbandArray = [];
        this.heatingDeadbandArray = [];
        this.profiles.forEach(profile => {
            if (profile != undefined) {
                this.coolingDeadbandArray.push(this.zoneSettings[profile.profile.name].coolingDeadband.val);
                this.heatingDeadbandArray.push(this.zoneSettings[profile.profile.name].heatingDeadband.val);
            }
        });

        this.multipleModuleInput.coolingDeadband = (Math.max(...this.coolingDeadbandArray)).toString();
        this.multipleModuleInput.heatingDeadband = (Math.max(...this.heatingDeadbandArray)).toString();
        return this.multipleModuleInput
    }


    getEquipRef(zoneId: string) {
        let equipRef;

        let floorData = this.buildings.floors.map(floor => floor.entities.filter(room => room._id === zoneId))
        floorData.map(floor => {
            if (floor.length > 0) {
                this.roomObj = floor;
                equipRef = floor.map(equip => equip.entities.filter(entity => entity.entities.length > 0))[0][0];
                this.refs.roomRef = equipRef ? equipRef.referenceIDs.room : '';
                this.refs.ccuRef = equipRef ? equipRef.referenceIDs.ccu : '';
                this.refs.equipRef = equipRef ? equipRef._id : undefined;
                this.refs.ahuRef = equipRef ? (equipRef.referenceIDs.ahu ?? equipRef.referenceIDs.gateway) : undefined ;
            }
        })
    }

    formatZoneName(equipName: any) {
        let profileName = this.helperService.setZoneProfileName(equipName);
        return profileName;
    }

    async getBuildingDetails(siteId) {
        let sub = this.siteService.getReadById(siteId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(({ rows }) => {
                this. buildings.buildingDetails = rows;
                if(sub){
                    sub.unsubscribe();
                }
               
            });
    }

    onSettingsChange(settingType: string = undefined, event: any, profileEquipName: string = undefined) {
        let settingValue = (settingType) ? event.target.value : event.value;
        let settingsObj = (profileEquipName) ? this.zoneSettings[profileEquipName] : this.zoneSettings;
        settingType = (settingType) ? settingType : event.mode;

        this.onControlChange(settingType, settingValue, settingsObj);
    }

    updateScheduleTypeOnButtonClick(data: any) {
        if (data.type.includes('scheduleType')) {
            this.oldscheduleTypeVal = this.scheduleTypeVal;
            this.scheduleTypeVal = data.value.toString();
            if (parseInt(data.value) != 2) {
                let refs = ObjectUtil.deepCopy(this.refs);
                refs['scheduleId'] = this.helperService.getPointIdbyTags(this.buildings, ['zone', 'schedule', 'days'], this.refs.roomRef);
                refs['floorRef'] = this.roomObj[0]['referenceIDs']['floor']
                this.namedScheduleService.refs = refs;
            }
        }
    }

		updateMultiModuleProfileOnBtnClick(event) {
			const data = [];
			this.helperService.multiModulePointsOverride = true;
			if (event.type.includes('zonePriority')) {
					this.multiModuleZonePriorityList.forEach((_item) => {
							const settingValue = event.value;
							const settingsObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
							data.push({
									type: event.type,
									value: settingValue,
									priorityArray: settingsObj[event.type].priorityArray || []
							});
					});
                    this.setUserUpdatedValue();
					this.helperService.handleInputChange(data, true);
			} 
			if (event.type.includes('scheduleType')) {
				this.updateScheduleTypeOfMultiModuleProfile(event);
			}

			this.helperService.multiModulePointsOverride = false;
		}

		updateScheduleTypeOfMultiModuleProfile(event: any) {
			const data = [];
			if (parseInt(event.value) == 1) {
				// zone containment
				const conflictArr = this.schValidation(event);
				// if there's no conflict
				if (!conflictArr.length) {
						this.multiModuleScheduleTypeList
								.forEach((item) => {
										const settingVal = event.value;
										const settingsObj = (item) ? this.zoneSettings[item.profile] : this.zoneSettings;
										data.push({
												type: event.type,
												value: settingVal,
												priorityArray: settingsObj[event.type].priorityArray || []
										});

								});
                        this.setUserUpdatedValue();
						this.helperService.handleInputChange(data, true);
				} else {
						this.showForceTrimPopup(event);
				}
		} else {
				this.multiModuleScheduleTypeList
						.forEach((_item) => {
								const settingValue = event.value;
								const settingObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
								data.push({
										type: event.type,
										value: settingValue,
										priorityArray: settingObj[event.type].priorityArray || []
								});
						});
                this.setUserUpdatedValue();
				this.helperService.handleInputChange(data, true);
			}
		}

    onBtnClick(event: any) {
        this.refs.siteDetails = this.buildings.buildingDetails?.find(building => building.id === `r:${this.refs.siteRef}`) || {};
        if (event.type.includes("zonePriority")) {
            this.oldZonePriorityVal = this.zonePriorityVal;
            this.zonePriorityVal = event.value.toString();
        }
        this.updateScheduleTypeOnButtonClick(event);
        if ((event.type.includes('scheduleType') && parseInt(event.value) == 2)) {
            this.scheduleTypeVal = 2;
        } else {
            if ((event.type.includes("zonePriority") || event.type.includes("scheduleType")) && this.multiModuleProfile) {
                this.updateMultiModuleProfileOnBtnClick(event);
            }
            else {
                this.updateScheduleTypeForSingleModuleProf(event);
            }
        }
    }

		updateScheduleTypeForSingleModuleProf(data) {
			if (data.type.includes('scheduleType')) {
				if (parseInt(data.value) == 1) {
						const conflictArr = this.schValidation(data);
						// if there's no conflict
						if (!conflictArr.length) {
								this.onControlChange(data.type, data.value, this.zoneSettings);
						} else {
								this.showForceTrimPopup(data);
						}
				} else {
						this.onControlChange(data.type, data.value, this.zoneSettings);
				}
			} else {
				this.onControlChange(data.type, data.value, this.zoneSettings);
			}
		}


    onControlChange(mode: string, inputData: any, priorityArray: any) {
        let data = []
				const addEntity = mode.includes('scheduleType');
        data.push({
            type: mode,
            value: inputData,
            priorityArray: priorityArray[mode].priorityArray || []
        })
        this.setUserUpdatedValue();
        this.helperService.handleInputChange(data, addEntity);
    }

    UpdateBuildingsDataForVacationEdit(entityid: any) {
        this.siteService.getEntitiesInBuilding(this.refs.siteRef).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
        ).subscribe(
            (response: any) => {
                this.buildingMaster = response;
                this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                this.buildings['devices'] = this.getNodes(response, ['device']);
                this.buildings['equips'] = this.getNodes(response, ['equip']);
                this.buildings["floors"] = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
                this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule') || [];
                this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy');
                if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click') {
                    if (this.refs.roomRef) {
                        this.updateZoneEntitesOnMessage(this.refs.roomRef);
                    }
                }
                if (this.ccuOrZone.actionUsed == 'click' && this.ccuOrZone.type == 'ccu') {
                    this.getScheduleInfoForCCU(this.refs.ahuRef);
                }
            },
        );
    }

    updateZoneEntitesOnMessage(roomRef) {
        this.getZoneSettings('zoneVacation', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'vacation'], roomRef), 'vacation');
        this.getZoneSettings('zoneSpecialSchedule', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'special'], roomRef), 'vacation');
        this.getUserSettingData("vacationAdded");
    }

    getUserPref(siteRef) {
        this.userPref.getUserPerf(siteRef).subscribe(res=>{
            this.userPrefData['ccuOrder'] = res['ccuIdsByInterest'];
            this.setCCUOrder();
            this.setCcuColors();
        },err=>{
            console.log(err);
        })
    }

    getBuildingFloors(siteRef: string, isReload = false) {
        this.helperService.tunersInfo = new Map();
        this.getUserPref(siteRef);
        this.getEntitySubscription =
            this.siteService.getEntitiesInBuilding(siteRef).pipe(
                map(this.helperService.listEntities),
                map(this.helperService.listTuners),
                map(this.helperService.createEntityStructure),
            ).subscribe(
                async (response: any) => {
                    this.buildingMaster = response;
                    this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                    const entities = ArrayUtil.deepFlatten(response, 'entities');
                    this.buildings['devices'] = this.getNodes(response, ['device']);
                    this.buildings['equips'] = this.getNodes(response, ['equip']);
                    const allEquips = this.getNodes(entities, ['equip']);
                    this.allDevices = this.getNodes(entities, ['cm', 'device']);
                    this.allNodes = this.getNodes(entities, ['node']);
                    this.allEquips = [...allEquips, ...this.allDevices, ...this.allNodes];

                    let floors = (this.helperService.getBuildingComponents(this.buildingMaster, 'floor')||[]).map(floor=>{
                        floor['floorNum'] = Number(floor['floorNum']);
                        return floor;
                    });
                    if (this.compareValues(floors, this.buildings.floors)) {
                        this.buildings.floors = floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
                    } else {
                        for (let i = 0; i < this.buildings.floors.length; i++) {
                            this.buildings.floors[i].entities = floors[i].entities
                        }
                    }
                    
                    this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule');
                    this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy');
                    this.buildings.floorsMaster = this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
                    this.buildings.ccus = this.buildings.floorCcu = this.helperService.getBuildingComponents(this.buildingMaster, 'ccu');
                    this.buildings['schedulable'] = entities.filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                    const allZones = this.getNodes(entities, ['room']);
                    this.buildings.zones = allZones;
                    this.setCCUOrder();
                    this.setCcuColors();

                    let concatQuery = '';
                    this.buildings.ccus.forEach((ccuObj, i) => {
                        concatQuery += 'ahuRef==@' + ccuObj.referenceIDs.ahu + `${this.buildings.ccus.length - 1 == i ? '' : ' or '}`
                    });
            
                    //For the following htig we are checkong of  bypass damper and oao profile ad these are different equips form the sytem profile
                    const combinedQuery = `${concatQuery} and ((equip and (bypassDamper or (oao))))`;
                    this.siteService.findByQuery(combinedQuery).subscribe(res => {
                        res = this.helperService.stripHaystackTypeMapping(res);

                        // grouping the rows based on a  ahuRef and  and  system profile type
                        // Checking if the current 'ahuRef' exists in the data object
                        const groupedRows = res.rows.reduce((data, row) => {

                            if (!data[row.ahuRef]) {
                                data[row.ahuRef] = {};
                            }
                            // Storing the row data under the profile within the ahuRef object
                            data[row.ahuRef][row.profile] = { ahuRef: row.ahuRef, profile: row.profile, id: row.id };
                            return data;
                        }, {});

                        this.buildings.ccus.forEach(ccuObj => {
                            const bypassRow = groupedRows[ccuObj.referenceIDs?.ahu]?.BYPASS_DAMPER;
                            const oaoRow = groupedRows[ccuObj.referenceIDs?.ahu]?.OAO;
                            if (bypassRow) {
                                ccuObj.hasBypassProfile = true;
                            }

                            if (oaoRow) {
                                ccuObj.hasOAOProfile = true;
                                ccuObj.referenceIDs['oaoRef'] = oaoRow.id;
                            }
                        });
                    });

                    // Also make the call to get the global dashboard associated with this userId
                    // Get site Details
                    this.refs.siteDetails = this.buildings.buildingDetails?.find(building => building.id === `r:${siteRef}`) || {};

                    this.getGlobalDashboard(siteRef);

                    // Also make the call to get the tags for this site
                    this.getSiteTags(siteRef);

                    this.buildings.equips = this.helperService.getBuildingComponents(this.buildingMaster, 'equip');
                    this.buildings.tuners = this.getSiteObj(['tuner', 'equip']) || [];

                    this.heatmapService.setBuildingCcus(this.buildings.ccus);
                    this.heatmapService.setBuildingFloors(this.buildings.floors);
                    this.heatmapService.setBuildings(this.buildings);
                    this.fetchCCuListWithPairedZones();
                    await this.fetchColourScaleDeadbands();
                    this.skeletonContentLoaded = true;
                },
            );
        this.isFloorPlanLoaded = false;
        this.refs.siteRef = siteRef;
        this.getEquipsAndDevicesFromSiteId(siteRef);
        let buildingIDArray = this.refs.siteRef.split(' ');
        this.pubNubService.subscribe(buildingIDArray);
        //Set time zone
        this.vacationsService.getSIteTz(this.refs.siteRef);
        this.vacationsService.siteTimezone.subscribe(tz => this.siteTz = tz);
        this.resetChatSession();
    }

    resetChatSession () {
        this.chatSessionActive = false;
        this.aiDashboardData = null;
    }

    compareValues(arr1, arr2) {
        if (arr1.length != arr2.length) return true;
        arr1.filter((o1) => {
            return arr2.some((o2) => {
                return o1._id === o2._id && o1.name == o2.name; // return the ones with equal id
            });
        }).length != arr1.length;
    }

    async fetchColourScaleDeadbands() {
        this.heatingDeadBandRoomRefList = [];
        this.coolingDeadBandRoomRefList = [];
        this.buildings.zones.forEach((zone) => {
            this.heatingDeadBandRoomRefList.push({ 'pointId': this.pointIDOrPointIDsWithComma(this.buildings.schedulable, zone._id, ['heating', 'deadband', 'base','schedulable']), 'roomRef': zone._id, 'zoneName': zone.name, 'val': '' });
            this.coolingDeadBandRoomRefList.push({ 'pointId': this.pointIDOrPointIDsWithComma(this.buildings.schedulable, zone._id, ['cooling', 'deadband', 'base', 'schedulable']), 'roomRef': zone._id, 'zoneName': zone.name, 'val': '' });
            
        });
        if (this.heatingDeadBandRoomRefList.length || this.coolingDeadBandRoomRefList.length) {
            let pointsList = [];
            let hbList = this.heatingDeadBandRoomRefList.map(x => { if (x.pointId) { return x.pointId; } }).filter(notUndefined => notUndefined !== undefined);
            if (hbList.length > 0) {
                hbList = hbList.reduce(this.commaSeparatedPointIds).split(',');
                pointsList = [...pointsList, ...hbList];
            }
            let cbList = this.coolingDeadBandRoomRefList.map(x => { if (x.pointId) { return x.pointId; } }).filter(notUndefined => notUndefined !== undefined);
            if (cbList.length > 0) {
                cbList = cbList.reduce(this.commaSeparatedPointIds).split(',');
                pointsList = [...pointsList, ...cbList];
            }
            if (!pointsList.length) return;
            let result:any 
            try {
              result = await lastValueFrom(this.siteService.getCurrenthisReadMany(
                  pointsList
              ))
            } catch (error) {
              console.log(error);
            }
            
            let rows = result?.rows || [];;

            rows.forEach((res) => {
                let pointIdFromRes = this.helperService.stripHaystackTypeMapping(res.id.split(' ')[0]);
                if (res.cooling) {
                    this.coolingDeadBandRoomRefList.filter((x) => {
                        if (x?.pointId?.includes(pointIdFromRes) && x?.pointId?.includes(',')) {
                            x.val += (((res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '') + ',')
                        }
                        else if (x?.pointId?.includes(pointIdFromRes)) {
                            x.val = (res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '';
                        }

                    });
                }
                else if (res.heating) {
                    this.heatingDeadBandRoomRefList.filter((x) => {
                        if ((x?.pointId?.includes(pointIdFromRes)) && (x?.pointId?.includes(','))) {
                            x.val += (((res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '') + ',')
                        }
                        else if (x?.pointId?.includes(pointIdFromRes)) {
                            x.val = (res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '';
                        }
                    });
                }
            });

            let heatingDeadbandValues = [...this.heatingDeadBandRoomRefList.map(x => {
                if (x.pointId) {
                    if (x.val?.includes(',')) {
                        x.val = this.findMaxValuefromCommaSeparated(x.val);
                        return x.val.toString();
                    }
                    else { return x.val; }
                }
            })].filter(notUndefined => notUndefined !== undefined);
            let coolingDeadbandValues = [...this.coolingDeadBandRoomRefList.map(x => {
                if (x.pointId) {
                    if (x?.val.includes(',')) {
                        x.val = this.findMaxValuefromCommaSeparated(x.val);
                        return x.val.toString();
                    }
                    else { return x.val; }
                }
            })].filter(notUndefined => notUndefined !== undefined);
            this.setSpreadRange(this.heatmapService.getMostOccuringHighestArrayValue(heatingDeadbandValues), this.heatmapService.getMostOccuringHighestArrayValue(coolingDeadbandValues));
            this.heatmapService.setDeadbandScale(this.deadbandScale);
            this.heatmapService.setDeadbandRoomRefList(this.heatingDeadBandRoomRefList, this.coolingDeadBandRoomRefList);

        }
    }

    setSpreadRange(heatingDeadband, coolingDeadband) {
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            heatingDeadband = this.unitService.convertingDeadBandValue(heatingDeadband)
            coolingDeadband = this.unitService.convertingDeadBandValue(coolingDeadband)
        }
        const deadbandScaleValues: number[] = [
            heatingDeadband > 0 ? -(3 * heatingDeadband) : 0,
            heatingDeadband ? -(2 * heatingDeadband) : 0,
            heatingDeadband ? -(1 * heatingDeadband) : 0,
            0,
            1 * coolingDeadband,
            2 * coolingDeadband,
            3 * coolingDeadband
        ];

        this.deadbandScale = deadbandScaleValues.map(value => Math.round((value + Number.EPSILON) * 100) / 100);
        this.heatmapService.setDeadbandScale(this.deadbandScale);
        this.heatmapService.setDeadbandRoomRefList(this.heatingDeadBandRoomRefList, this.coolingDeadBandRoomRefList);
    }

    fetchCCuListWithPairedZones() {
        let concatQuery = '';
        this.buildings.ccus.forEach((ccuObj, i) => {
            concatQuery += 'ahuRef==@' + ccuObj.referenceIDs.ahu + ' or gatewayRef==@' + ccuObj.referenceIDs.ahu + `${this.buildings.ccus.length - 1 == i ? '' : ' or '}`
        });

        let query = ' not ccu and (' + concatQuery + ')';
        this.ccusWithPairedZones = [];

        this.siteService.getHaystackDataByQuery(query).subscribe(res => {
            res = this.helperService.stripHaystackTypeMapping(res);
            if (res.rows.length > 0) {
                this.buildings.ccus.forEach((ccu) => {
                    let rows = res.rows.filter(data => (data.ahuRef == ccu.referenceIDs.ahu || data.gatewayRef == ccu.referenceIDs.ahu));
                    if (rows.length > 0) {
                        const zoneList = [];
                        rows.forEach((row) => {
                            const zoneId = row.roomRef;
                            const zoneExists = zoneList.filter((x) => { this.getFilterZone(x, zoneId); });
                            if (!zoneExists || (zoneExists.length == 0)) {
                                zoneList.push(zoneId);
                            }
                        });
                        this.ccusWithPairedZones.push({ 'id': ccu._id, 'CCUName': ccu.name, 'ahuRef': ccu.referenceIDs.ahu, 'pairedZoneList': zoneList });
                    } else {
                        this.ccusWithPairedZones.push({ 'id': ccu._id, 'CCUName': ccu.name, 'ahuRef': ccu.referenceIDs.ahu, 'pairedZoneList': [] });
                    }
                });
            }
            this.heatmapService.setCcusWithPairedZones(this.ccusWithPairedZones);
            this.loadFloorLayouts(this.buildings.floorsMaster);
        },error =>{
            this.heatmapService.setCcusWithPairedZones(this.ccusWithPairedZones);
            this.loadFloorLayouts(this.buildings.floorsMaster);
        });
    }

    getFilterZone(x, zoneId) {
        if (x.zoneId == zoneId) { return x; }
    }
    
    fetchTimeSeriesData(daterange, adIdentifier: string = "") {
        //site timezone
        let tz: string = this.siteService.getIANATimeZone(this.siteTz);
        const utcOffset = dayjs().tz(tz).utcOffset();
        let id = this.ccuOrZone.type + this.ccuOrZone.actionUsed + (this.ccuOrZone.type == 'ccu' ? this.refs.ahuRef : this.refs.roomRef) + adIdentifier + daterange.startDate.toString() + '-' + daterange.endDate.toString(); // this will serve as unique id subs handling

        //Set up check for download widgets status of data fetch
        if (this.ccuOrZone.actionUsed == 'click') {
            this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
                if (singlePoint != undefined) {
                    this.checkWidgestLoaded[key.replace(singlePoint, '')] = false;
                }
            });
        }

        let alreadySubscribed = (this.subscriptionCollection.filter(subs => {
            return subs.id == id
        }).length > 0)

        if (!alreadySubscribed) {
            this.enableDownLoad = false;
            let pointList = [...this.graphService.graphPointsCollection.values()].filter(notUndefined => notUndefined !== undefined);
            if(pointList.length > 0) {
                let hisReadManySubject = this.siteService.gethisReadManyInterpolate(
                    pointList,
                    daterange,
                    tz
                ).subscribe((res) => {
                    let tempZones = [];
                    if (res?.rows) {
                        this.enableDownLoad = true;
                        let rows = this.filterTimeSeriesData(res);
                        this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
                            // singlePoint
                            if (singlePoint != undefined) {
                                let filteredPointResponse = ObjectUtil.deepClone(rows.filter((x) => {
                                    let ref = this.helperService.stripHaystackTypeMapping(x.id).split(' ')[0];
                                    if ((typeof (singlePoint) != undefined) && ref.includes(singlePoint)) { return x; }
                                })[0]);
                                if (filteredPointResponse) {
                                    let hisData = [];

                                    hisData = [].concat(filteredPointResponse.data).map((row => {
                                        row.val = row['d_d'] ? row['d_d'].split(':')[1] : null;
                                        row.ts = DateUtil.convertUTCtoTimezone(row.time.split(' UTC')[0].split('t:')[1], utcOffset).format('YYYY-MM-DDTHH:mm:ss');
                                        delete row.time;
                                        delete row.d;
                                        delete row.d_d;
                                        if (row.val !== '' && row.ts != '') {
                                            return row;
                                        }
                                    }));

                                    let zoneType = key.replace(singlePoint, '');

                                    //Handle cooling and heating user limits for multi module
                                    if (zoneType.includes('heatingUserLimitMin') || zoneType.includes('heatingUserLimitMax') || zoneType.includes('coolingUserLimitMax') || zoneType.includes('coolingUserLimitMin')) {
                                        let tempHisData = [];
                                        if (!this.heatCoolLimitsHisData.has(singlePoint)) {
                                            //Cache the limits in service
                                            tempHisData = hisData.map(x =>Object.assign({}, x));
                                            this.heatCoolLimitsHisData.set(singlePoint, tempHisData);

                                        }
                                        else {
                                            //Check if it exists in cache in case of multi module
                                            tempHisData = this.heatCoolLimitsHisData.get(singlePoint);
                                        }
                                        tempZones[zoneType] = tempHisData;
                                    }
                                    else {
                                        let tempHisData = [];
                                        tempHisData = hisData.map(x => Object.assign({}, x));
                                        tempZones[zoneType] = tempHisData;
                                    }

                                    if (this.ccuOrZone.actionUsed == 'click') {
                                        this.checkWidgestLoaded[zoneType] = true;
                                    }
                                    //added data to be exported
                                    if (this.ccuOrZone.actionUsed == 'click' && hisData.length > 0) {
                                        this.dataForExport[zoneType] = hisData.map(x => Object.assign({}, x));
                                    }
                                }
                            }
                        });
                        this.subscriptionCollection.find(subs => subs.id == id).subscription.unsubscribe();
                    }
                    this.graphService.setData(tempZones);
                    if (this.systemWidgets?.[0]?.name?.includes("Default System Profile")) {
                        this.heatMapToolTipService.deletefromOrigGraphData("comfortIndex" + this.refs.ahuRef);
                        this.heatMapToolTipService.deletefromOrigGraphData("modes" + this.refs.ahuRef);
                        //Hide the accordian
                        let runtimeAccordian = (<HTMLInputElement>document.getElementById('accordian_' + this.systemWidgets[0].id + this.refs.ahuRef))
                        runtimeAccordian.click();
                    }
                }, noResponse => {
                    this.graphService.resetDataonInvalidDate();
                });
                this.subscriptionCollection.push({ id: id, subscription: hisReadManySubject });
            } else {
                this.enableDownLoad = true;
            }
        } else {
            this.enableDownLoad = true;
        }
    }

//***************************************Conversion Functions for graph data*************************************************************
filterTimeSeriesData(data) {
    let res = this.ccuOrZone.type == 'ccu' ? data['rows'] : this.zoneSingleDualGraph(data);
    res = this.convertStagedFanValuetoVolt(res);
    let dataUnitExist = res?.filter(item => this.unitService.unitListArray.includes(item.unit)) || [];
    const dataUnitNotExist = res?.filter(item => !this.unitService.unitListArray.includes(item.unit)) || [];
    if (dataUnitExist.length > 0) {
        dataUnitExist = this.convertDataOnUnit(dataUnitExist);
    }
    return dataUnitNotExist.concat(dataUnitExist) ;
}

    convertStagedFanValuetoVolt(data) {
        const dataChanges = [];
        data.forEach(x => {
            if (x.hasOwnProperty('predefined') && x.hasOwnProperty('fan') && x.hasOwnProperty('speed')) {
                x.data.forEach(y => {
                    if (y.d_d) {
                        const value = Number(this.helperService.stripHaystackTypeMapping(y.d_d)) * 10 / 100;
                        const valueToString = value.toString();
                        y.d_d = `n:${valueToString}`

                    }
                })
                dataChanges.push(x)
            } else {
                dataChanges.push(x)
            }
        })
        return dataChanges;
    }

convertDataOnUnit(data) {
    let userPreference = this.authService.getLoggedInUserPreferences();
    let temperaturePoints = data.filter(item => this.unitService.temperatureUnitListArray.includes(item.unit));
    let energyConsumptionPoints = data.filter(item => this.unitService.energyConsumptionUnitListArray.includes(item.unit));
    let airflowVolumePoints = data.filter(item => this.unitService.airflowVolumeUnitListArray.includes(item.unit));
    let airPressurePoints = data.filter(item => this.unitService.airPressureUnitListArray.includes(item.unit));
    let waterPressurePoints = data.filter(item => this.unitService.waterPressureUnitListArray.includes(item.unit));
    let waterFlowPoints = data.filter(item => this.unitService.waterFlowUnitListArray.includes(item.unit));
    if(temperaturePoints.length > 0) {
        this.temperaturepoints(temperaturePoints, userPreference)
    }
    if(energyConsumptionPoints.length > 0) {
        this.energyConsumptionPoints(energyConsumptionPoints, userPreference)
    }
    if(airflowVolumePoints.length > 0) {
        this.airflowVolumePoints(airflowVolumePoints, userPreference)
    }
    if(airPressurePoints.length > 0) {
        this.airPressurePoints(airPressurePoints, userPreference)
    }
    if(waterPressurePoints.length > 0) {
        this.waterPressurePoints(waterPressurePoints, userPreference)
    }
    if(waterFlowPoints.length > 0) {
        this.waterFlowPoints(waterFlowPoints, userPreference)
    }
    return [...temperaturePoints, ...energyConsumptionPoints, ...airflowVolumePoints, ...airPressurePoints, ...waterPressurePoints, ...waterFlowPoints];
}
//Temperature points
temperaturepoints(temperaturePoints, userPreference) {
    if (temperaturePoints.length > 0) {
        const userTemperaturePreference = userPreference.temperatureUnit;
        for (let tempPoints of temperaturePoints) {
            let res = tempPoints.data;
            if (tempPoints?.unit != userTemperaturePreference) { // if unit is not equal to user preference
                for (let conversionData of res) {
                    if (conversionData.d_d != null) {
                        var pointId = (tempPoints.dis).split('-').slice(-1).pop();
                        let value = parseFloat(conversionData.d_d.split(':')[1]);
                        let convertedValue;
                        if (userTemperaturePreference == this.unitService.temperatureUnitListArray[0]) {
                            convertedValue = this.unitService.fahrenheitToCelecius(value, pointId);
                        } else {
                            convertedValue = this.unitService.celeciusToFahrenheit(value, pointId);
                        }
                        let feedbackData = 'n:' + convertedValue;
                        conversionData['d_d'] = feedbackData;
                    }
                }
            }

        }
    }
}
//energyConsumptionPoints
energyConsumptionPoints(energyConsumptionPoints, userPreference) {
    if (energyConsumptionPoints.length > 0) {
        const userEnergyConsumptionPreference = userPreference.energyConsumptionUnit;
        for (let energyPoints of energyConsumptionPoints) {
            let res = energyPoints.data;
            if (userEnergyConsumptionPreference != energyPoints.unit) {
                for (let conversionData of res) {
                    this.energyConversionpoints(conversionData, userEnergyConsumptionPreference)
                }
            }
        }
    }
}
//energyConversionPoints
energyConversionpoints(conversionData, userEnergyConsumptionPreference) {
    if (conversionData.d_d != null) {
        let value = parseFloat(conversionData.d_d.split(':')[1]);
        let convertedValue
        if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[1]) {
            convertedValue = this.unitService.energyConsumptionKBTU(value);
        } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[2]) {
            convertedValue = this.unitService.energyConsumptionTonrefh(value);
        } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[3]) {
            convertedValue = this.unitService.energyConsumptionGigajoules(value);
        }
        let feedbackData = 'n:' + convertedValue;
        conversionData.d_d = feedbackData;
    }
}
//airflowVolumePoints
airflowVolumePoints(airflowVolumePoints, userPreference) {
    if (airflowVolumePoints.length > 0) {
        const userAirflowVolumePreference = userPreference.airflowVolumeUnit;
            for (let airflowVolume of airflowVolumePoints) {
                if (userAirflowVolumePreference != airflowVolume.unit) {
                    let res = airflowVolume.data;
                    for (let conversionData of res) {
                        this.airFlowVolumeConversionPoints(conversionData, userAirflowVolumePreference)
                    }
                }
            }
    }
}
//airflow volume conversion points
airFlowVolumeConversionPoints(conversionData, userAirflowVolumePreference) {

    if (conversionData.d_d != null) {
        let value = parseFloat(conversionData.d_d.split(':')[1]);
        let convertedValue
        if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[1]) {
            convertedValue = this.unitService.airFlowMeterCubePerHour(value);
        } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[2]) {
            convertedValue = this.unitService.airFlowLiterPerMinute(value);
        } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[3]) {
            convertedValue = this.unitService.airFlowLiterPerSecond(value);
        }
        let feedbackData = 'n:' + convertedValue;
        conversionData.d_d = feedbackData;
    }

}
//airPressurepoints
airPressurePoints(airPressurePoints, userPreference) {
    if (airPressurePoints.length > 0) {
        const userAirPressurePreference = userPreference.airPressureUnit;
            for (let airPressure of airPressurePoints) {
                if (userAirPressurePreference != airPressure.unit) {
                    let res = airPressure.data;
                    for (let conversionData of res) {
                        this.airPressureConversionPoints(conversionData, userAirPressurePreference)
                    }
                }
            }
    }
}
airPressureConversionPoints(conversionData, userAirPressurePreference) {
    if (conversionData.d_d != null) {
        let value = parseFloat(conversionData.d_d.split(':')[1]);
        let convertedValue
        if (userAirPressurePreference == this.unitService.airPressureUnitListArray[1]) {
            convertedValue = this.unitService.airPressureMmofWater(value);
        } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[2]) {
            convertedValue = this.unitService.airPressureCmOfWater(value);
        } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[3]) {
            convertedValue = this.unitService.airPressurePascle(value);
        }
        let feedbackData = 'n:' + convertedValue;
        conversionData.d_d = feedbackData;
    }
}
//waterpressurepoints
waterPressurePoints(waterPressurePoints, userPreference) {
    if (waterPressurePoints.length > 0) {
        const userWaterPressurePreference = userPreference.waterPressureUnit;
        for (let waterPressure of waterPressurePoints) {
            if (userWaterPressurePreference != waterPressure.unit) {
                let res = waterPressure.data;
                for (let conversionData of res) {
                    this.waterPressureConversionPoints(conversionData, userWaterPressurePreference);
                }
            }
        }
    }
}
waterPressureConversionPoints(conversionData, userWaterPressurePreference) {
    if (conversionData.d_d != null) {
        let value = parseFloat(conversionData.d_d.split(':')[1]);
        let convertedValue
        if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[1]) {
            convertedValue = this.unitService.waterPressurePsi(value);
        } else if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[2]) {
            convertedValue = this.unitService.waterPressureKpi(value);
        }
        let feedbackData = 'n:' + convertedValue;
        conversionData.d_d = feedbackData;
    }
}
//waterFlow
waterFlowPoints(waterFlowPoints, userPreference) {
    if (waterFlowPoints.length > 0) {
        const userWaterFlowPreference = userPreference.waterFlowUnit;
        for (let waterFlow of waterFlowPoints) {
            if (userWaterFlowPreference != waterFlow.unit) {
                let res = waterFlow.data;
                for (let conversionData of res) {
                    this.waterFlowConversionPoints(conversionData, userWaterFlowPreference);
                }
            }
        }
    }
}
waterFlowConversionPoints(conversionData, userWaterFlowPreference) {
    if (conversionData.d_d != null) {
        let value = parseFloat(conversionData.d_d.split(':')[1]);
        let convertedValue
        if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[1]) {
            convertedValue = this.unitService.waterFlowMeterCubePerHour(value);
        } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[2]) {
            convertedValue = this.unitService.waterFlowLiterPerMinute(value);
        } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[3]) {
            convertedValue = this.unitService.waterFlowLiterPerSecond(value);
        }
        let feedbackData = 'n:' + convertedValue;
        conversionData.d_d = feedbackData;
    }
}
//******************************************************************Conversion FUnctions Ends************************************************
    getSystemProfileName(profile) {
        const isCarrier = this.configService.getConfig('appDisplayName') == 'ClimaVision';
        switch (profile) {
            
            case 'SYSTEM_DEFAULT':
                return 'Default System Profile';
            case 'SYSTEM_VAV_ANALOG_RTU':
            case 'vavFullyModulatingAhu':
                return 'VAV Fully Modulating AHU';
            case 'SYSTEM_VAV_STAGED_RTU':
            case 'vavStagedRtu':
                return 'VAV Staged RTU';
            case 'SYSTEM_VAV_HYBRID_RTU':
                return 'VAV Advanced Hybrid AHU';
            case 'SYSTEM_VAV_STAGED_VFD_RTU':
            case 'vavStagedRtuVfdFan':
                return 'VAV Staged RTU with VFD Fan';
            case 'SYSTEM_VAV_IE_RTU':
                return 'VAV Daikin IE';
            case 'SYSTEM_DAB_IE_RTU':
                return 'DAB Daikin IE';
            case 'SYSTEM_DAB_ANALOG_RTU':
                return isCarrier ? 'VVT-C Fully Modulating AHU' : 'DAB Fully Modulating AHU';
            case 'SYSTEM_DAB_STAGED_RTU':
                return isCarrier ? 'VVT-C Staged RTU' : 'DAB Staged RTU';
            case 'SYSTEM_DAB_HYBRID_RTU':
                return isCarrier ? 'VVT-C Advanced Hybrid AHU' : 'DAB Advanced Hybrid AHU';
            case 'SYSTEM_DAB_STAGED_VFD_RTU':
                return isCarrier ? 'VVT-C Staged RTU with VFD Fan' : 'DAB Staged RTU with VFD Fan';
            case 'dabExternalAHUController':
                return isCarrier ? 'VVT-C External Ahu' : 'DAB External Ahu';
            case 'vavExternalAHUController':
                return 'VAV External Ahu';
            case 'vavAdvancedHybridAhuV2':
                return 'VAV Advanced Hybrid AHU v2';
            default:
                return profile;
        }
    }

    getSystemprofileEquiNames() {
        localStorage.setItem('refs', this.refs);
        this.deviceHelper.systemProfileSubject.pipe(first()).subscribe((systemProfile) => {
            this.equipGraphicSystemProfile = systemProfile['systemProfileName']
            this.equipGraphicsProfiles = this.getSystemProfileName(systemProfile['systemProfileName']);
           
            if(this.equipGraphicsProfiles == 'Default System Profile' || this.equipGraphicsProfiles ==  "VAV Daikin IE"|| this.equipGraphicsProfiles == "DAV Daikin IE"
            || ["VVT-C External Ahu", "DAB External Ahu","VAV External Ahu"].indexOf(this.equipGraphicsProfiles) > -1) {
                this.showEquipGraphics = false 
            } else {
                this.showEquipGraphics = true 
            }
            this.getSystemProfileConfig()
        });
    }

    checkFilesTaggedforProfile(siteRef, profileID){
        if(this.fileUpload) {
            this.notesService.getTaggedFiles(siteRef,profileID).subscribe((res) => {
                this.taggedFilesListSystemProfile = res;
                if(res && res.length > 0){
                    this.enableViewAssetsSystemProfile = true;
                } else {
                    this.enableViewAssetsSystemProfile = false;
                }
            }, (error) => {
                console.log('Error in fetching tagged files', error);
            })
        }
    }

    async getSystemSettingsOnClick(ccuObj: any, fromSummaryTable = false) {
        this.analyticsLayoutService.exportAnalyticsExcelData = [];
        this.analyticsLayoutService.totalAnalyticsCount = 0;
        this.analyticsLayoutService.isAnalyticsListLoading = false;
        this.isDownloadExcelPending = false;
        let modalLeftPos: number = 120;
        this.getSystemprofileEquiNames()
        this.hoverdOrClickEventFalg = true; 
        this.showNotesAccordion = false;
        this.modalPopupOpened = true;
        (<HTMLElement>document.getElementsByClassName('fs-heatmap-wrapper__content')[0]).style.position = 'fixed';
        this.ccuOrZone = { type: 'ccu', actionUsed: 'click' };
        //Clean up the hovered modal
        if (this.ccuOrZone.actionUsed == "click" && this.ccuOrZone.type == "zone") {
            return;
        }
        this.closeModal();
  
        this.intializeDate();
        ccuObj = this.setCCUData(ccuObj);
        if(this.fileUpload){
            this.checkFilesTaggedforProfile(ccuObj.siteRef, ccuObj.ccuId);
        }
        this.refs.ccuName = ccuObj.ccuName;
        this.refs.ahuRef = ccuObj.ccuAhu;
        this.refs.siteRef = ccuObj.siteRef;
        this.refs.equipRef = ccuObj.equipRef;
        this.refs.ccuRef = ccuObj.ccuId;
        this.refs.oaoRef = ccuObj.oaoRef;
        this.refs.gateway = ccuObj.gateway;
        this.refs.ccuId = ccuObj.ccuId;
        let humidifierEnabled:any;
        const equipObj = this.getEquip(['system', 'equip', 'profile']);
        const hasDomainName = equipObj?.markers?.includes('domainName');
        this.isdomainName = hasDomainName;
        let connectivityWidgetDetails = this.getConnectivityWidgetStatus();
        if(equipObj) {
            const humidifierRef = this.helperService.getPointIdbyTags(equipObj, ['point', 'system', 'config', 'enabled', 'output', 'sp', 'humidification'])
            if(humidifierRef?.length>0) {
                this.siteService.getWritablePointData(humidifierRef[0]).subscribe((response) => {
                    let humidityValue = response.rows[0];
                    if(this.helperService.stripHaystackTypeMapping(humidityValue.val).split(' ')[0] == 1){
                        humidifierEnabled =  true
                    }
                });
            } 
        }
        this.ccuOrZone = { type: 'ccu', actionUsed: 'click' };
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;

        this.dataForExport = {};
        this.checkWidgestLoaded = {};
        this.siteNameModalStyleClickedFlag = true;
        this.siteNameModalStyleOnClicked = {
            top: 45 +  window.scrollY + 'px',
            left: modalLeftPos + 'px',
            height: 'auto',
            width: '82%',
            display: 'block'
        };

        if (fromSummaryTable) {
            this.siteNameModalStyleOnClicked.top = '45px';
        }
        this.hideSummaryTableSection = true;

        this.loadTunerData(ccuObj);
        this.loadSummaryView = true;
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('right-arrow');
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('left-arrow');
        this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj,true,humidifierEnabled,connectivityWidgetDetails);
        this.getSystemProfile();
        this.getSystemProfileType()
        this.loadSystemWidgetsData();
        this.getCcuSettings(this.refs.ahuRef);
        setTimeout(() => {
            this.checkIfOAOIsPaired(this.refs.ahuRef);
            /**
                * Note this is specially for vavAdvancedHybridAhuV2 that to connect module is paired with equip.
                * As of now, without load and sat based, we cannot configure the connect module. In the future, 
                * there are chances to configure the connect module directly.
                * Handling in the different method this method is used only for conditioning mode check.
                * As of the current implementation, we are not implementing connect module in widgets and equip graphics.
                * In the future, we can implement the connect module in widgets and equip graphics based on requirements.
                * Sat based will not come in the connect module.
            */
            if (this.equipGraphicSystemProfile === "vavAdvancedHybridAhuV2") {
                this.checkIfConnectModuleIsPaired(this.refs.ahuRef);
            }
        }, 1000);
      
      
    
        if (this.ccuOrZone.actionUsed == "click" && this.ccuOrZone.type == "ccu") {
            this.getSystemProfileConfig();
            this.getScheduleInfoForCCU(this.refs.ahuRef);
        }
      
        this.getNotesList(ccuObj.ccuId, "system")
        this.assimilateEntityCustomWidgets();
        setTimeout(() => {
            window.scroll(0,0);
        },50);
        //start the setInterval call to call the api to update the data change if any every 5 sec
        this.pollingFunction();
        this.pollGraphData();
    }


    async getNotesList(id, type) {
        this.notesList = await this.notesService.fetchNotes(this.refs.siteRef, "", ["pinned"], [], null);
        let notes = this.notesService.checkForZoneCcuReName(this.notesList, this.buildings.allZones, this.buildings.ccus)
        if (notes?.length > 0) {
            this.notesList = notes
        }
        this.filterNotesList(id, type);
    }
    
    filterNotesList(id, type) {
        this.filteredNotesList = [];
        this.notesList.forEach(note => {
            if (type == 'system' && !note.archived && ((note.taggedCcus.indexOf(id) > -1) || (!note.taggedCcus.length && !note.taggedZones.length))) {
                this.filteredNotesList.push(note)
            } else if (!note.archived && (note.taggedZones.indexOf(id) > -1) || (!note.taggedCcus.length && !note.taggedZones.length)) {
                this.filteredNotesList.push(note)
            }
        });
        this.filteredNotesList = this.notesService.sortNotesList(this.filteredNotesList);
        if (this.filteredNotesList?.length) {
           this.notesService.getHierarchy(this.refs.siteRef).toPromise().then(siteHierarchy => {
              this.filteredNotesList  = this.notesService.addTooltip(this.filteredNotesList, siteHierarchy);
            }).catch((err) => {
              this.alertService.error("Failed to fetch CCU and Zone hierarchy.");
            })
        }
    }


    pollingFunction() {
        clearTimeout(this.messagingSubscribe);
        this.messagingSubscribe = setTimeout(() => {
            this.helperService.clearWritablePointsUpdatedStatus();
            this.helperService.getPointData().subscribe(res => {
                this.pollingFunction();
            });
        },5000);
    }

    getSystemProfile(){
        let subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
            //Modify the runtime graph object
            if (systemProfile) {
                //Runtime graphs logic
                if(systemProfile.ccuRef != this.refs.ahuRef) {
                    return
                }                
                if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                    this.systemWidgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                    this.loadRuntimeWidgetData(this.refs.ahuRef);
                    subs_sysProfile.unsubscribe();
                }
                else {
                    let subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                        if (runtimeProfile) {
                            //We can directly use after fetching once
                            this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef,runtimeProfile.widgetData);
                            this.systemWidgets[0] = runtimeProfile.widgetData;
                            this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                            subs_runtimeSub.unsubscribe();
                            subs_sysProfile.unsubscribe();
                        }
                    });
                }
                const equipObj = this.getEquip(['system', 'equip', 'profile']);
                const hasDomainName = equipObj?.markers?.includes('domainName');
                this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, false, this.refs.gateway,this.refs.ccuRef,this.refs.ccuName,hasDomainName);
                this.onSystemMode = false;
                this.setSystemWidget(systemProfile);
            }
            else {
                //means no runtime for this profile , fetch the data
                //All points have been fetched , get bulk read
                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
            }
            this.getSystemReconfigurationData(this.systemReconfigurationSettings);
        });
    }

    setSystemWidget(systemProfile){
        //fetch Comfort index profile
        if (systemProfile.systemProfileName !== "vavAdvancedHybridAhuV2" && systemProfile.systemProfileName.includes("VAV") || systemProfile.systemProfileName.includes("vav")) {
            this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemVavComfortIndexGraphWidget()).getGraphWidget();
        }
        else if (systemProfile.systemProfileName.includes("DAB") || systemProfile.systemProfileName.includes("dab")) {
            this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemDabComfortIndexGraphWidget()).getGraphWidget();
        }
        else if (systemProfile.systemProfileName.includes("DEFAULT") ) {
            this.disableAllInputs();
            //for defualt profile remove comfortindex,modes widget and runtime widgt
            this.systemWidgets = this.systemWidgets.filter(widget => widget.id != "comfortIndex");
            this.systemWidgets = this.systemWidgets.filter(widget => widget.id != "modes");
            this.systemWidgets = this.systemWidgets.filter(widget => widget.id != 'runtimesystemprofile');
        }
    }

    //check All data for export loaded or not
    checkWidgetDataLoaded() {
        if (this.ccuOrZone.type == 'ccu') {
            return (this.systemWidgets.length + this.analyticsLayoutService.totalAnalyticsCount) > 0
        } else if(this.ccuOrZone.type == 'zone') {
            let totalWidgetLength = 0;
            this.profiles.forEach(x => totalWidgetLength += x?.widgets?.length);
            return (totalWidgetLength + this.customWidgets?.global?.length + this.customWidgets?.local?.length + this.analyticsLayoutService.totalAnalyticsCount) > 0
        }
    }

    disableAllInputs() {
        this.onSystemMode = true;
    }

    enableAllInputs() {
        document.querySelectorAll('input').forEach(element => {
            element.disabled = false;
        });
        this.onSystemMode = false;
    }
    callSystemSettingsAfterDelay($event){
        var $this = this;
        this.getSystemprofileEquiNames()
        this.hoverdOrClickEventFalg = false;
        this.ccuHoverCheckFlag = true;
        this.clearHoverTimer();
        this.hoverEnterTimer = setTimeout(async ()=> {
            await $this.getSystemSettingsOnHover($event);
        }, 1000); 
    }
    clearHoverTimer(){
        window.clearTimeout(this.hoverEnterTimer);
    }
    async getSystemSettingsOnHover(ccuObj: any) {
        let x = ccuObj.$event.target.getBoundingClientRect();
        //clean up previous hovered ccu obj
        if (this.ccuOrZone.actionUsed == 'click') {
            return;
        }
        if (this.refs.ccuName == ccuObj.$event.target.innerText) {
            return;
        }
        this.messageService.scrollIndex = window.scrollY
        //to dismiss select dropdown on hover
        document.body.click();
        let ele: any = document.activeElement
        if (ele?.blur) {
            ele.blur();
        }

        this.closeModal();
        this.intializeDate();
        this.ccuOrZone = { type: 'ccu', actionUsed: 'hover' };
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;
        ccuObj = this.setCCUData(ccuObj);
        if(this.fileUpload){
            this.checkFilesTaggedforProfile(ccuObj.siteRef, ccuObj.ccuId);
        }
        this.refs.ccuName = ccuObj.ccuName;
        this.refs.ahuRef = ccuObj.ccuAhu;
        this.refs.siteRef = ccuObj.siteRef;
        this.refs.equipRef = ccuObj.equipRef;
        this.refs.oaoRef = ccuObj.oaoRef;
        this.refs.gateway = ccuObj.gateway;
        this.refs.ccuId = ccuObj.ccuId;
        this.refs.ccuRef = ccuObj.ccuId;
        let connectivityWidgetDetails = this.getConnectivityWidgetStatus();
        let humidifierEnabled:any;
        const equipObj = this.getEquip(['system', 'equip', 'profile']);
        const humidifierRef = await this.helperService.getPointIdbyTags(equipObj, ['point', 'system', 'config', 'enabled', 'output', 'sp', 'humidification'])
        const hasDomainName = equipObj?.markers?.includes('domainName');
        if(humidifierRef.length>0) {
            await this.siteService.getWritablePointData(humidifierRef[0]).subscribe((response) => {
                let humidityValue = response.rows[0];
                if(this.helperService.stripHaystackTypeMapping(humidityValue.val).split(' ')[0] == 1){
                    humidifierEnabled =  true
                    this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj, true,humidifierEnabled,connectivityWidgetDetails);
                }
            });
        } 
            this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj, true,humidifierEnabled,connectivityWidgetDetails);

        let subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
            //Modify the runtime graph object
            if (systemProfile) {
                if(systemProfile.ccuRef != this.refs.ahuRef) {
                    return
                }
                //Runtime graphs logic
                if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                    this.systemWidgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                    this.loadRuntimeWidgetData(this.refs.ahuRef);
                    subs_sysProfile.unsubscribe();
                }
                else {
                    let subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                        if (runtimeProfile) {
                            //We can directly use after fetching once
                            this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef,runtimeProfile.widgetData);
                            this.systemWidgets[0] = runtimeProfile.widgetData;
                            this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                            subs_runtimeSub.unsubscribe();
                            subs_sysProfile.unsubscribe();
                        }
                    });
                    this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, true, this.refs.gateway,this.refs.ccuRef,this.refs.ccuName,hasDomainName);
                }
                //fetch Comfort index profile
                if (systemProfile.systemProfileName.includes("VAV")) {
                    this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemVavComfortIndexGraphWidget()).getGraphWidget();
                }
                else if (systemProfile.systemProfileName.includes("DAB")) {
                    this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemDabComfortIndexGraphWidget()).getGraphWidget();
                }
                else if (systemProfile.systemProfileName.includes("DEFAULT")) {
                    //for defualt profile remove comfortindex modes widget and runtime widget
                    this.systemWidgets = this.systemWidgets.filter(widget => widget.id != "comfortIndex");
                    this.systemWidgets = this.systemWidgets.filter(widget => widget.id != "modes");
                    this.systemWidgets = this.systemWidgets.filter(widget => widget.id != 'runtimesystemprofile');
                    console.log("this.systemWidgets---->Hover", this.systemWidgets);
                }
            }
            else {
                //means no runtime for this profile , fetch the data
                //All points have been fetched , get bulk read
                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
            }
        });
        this.loadSystemWidgetsData();
        let isSideOpened = document.getElementsByClassName('fs-sidebar ng-star-inserted expand');

        if (isSideOpened.length > 0) {
            this.sideMenu = true;
        } else {
            this.sideMenu = false;
        }
        let modalLeftPos: number = this.sideMenu ? x.right - 35 - 141 : x.right - 35;
        let modalRightPos: number = this.sideMenu ? ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 15 : ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 10
        this.siteNameModalStyleClickedFlag = false;

        if (((window.innerWidth || document.documentElement.clientWidth) - x.right) > ((window.innerWidth || document.documentElement.clientWidth) * 0.42) + 140) {
            this.siteNameModalStyle = {
                top: this.isHeaderFixed ? Math.abs(document.body.getBoundingClientRect().top) + 96 + 'px' : ccuObj.$event.target.offsetTop+40 + 'px',
                left: modalLeftPos + 5 + 'px',
                height: window.screen.availHeight,
                width: 48 + '%',
                display: 'block'
            };

            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('right-arrow');
            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.add('left-arrow');
        } else {
            this.siteNameModalStyle = {
                top: this.isHeaderFixed ? Math.abs(document.body.getBoundingClientRect().top) + 96 + 'px' : ccuObj.$event.target.offsetTop+40 + 'px',
                right: modalRightPos + 25 + 'px',
                height: window.screen.availHeight,
                width: 48 + '%',
                display: 'block'
            };

            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.add('right-arrow');
            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('left-arrow');
        }

        this.assimilateEntityCustomWidgets();

        this.messageService.getMessage().subscribe(message => {
            if (message.isExpanded !== undefined) {
                this.sideMenu = message.isExpanded
            }
        });
    }

    getConnectivityWidgetStatus() {
        const OAOObj = this.getOAOEquip(['oao', 'equip', 'profile']);
        const btuObj = this.getBtuObj(['btu', 'equip', 'profile']);
        const getEMRObj = this.getEMRObj(['emr', 'equip', 'profile']);
        const diagnosticObj = this.getDiagObj(['equip', 'diag']);
        let connectivity = {
            oao:'',
            btu:'',
            emr:'',
            cloud:''
        }
         //contectivity
         if(OAOObj) {
            connectivity.oao =  this.helperService.getPointIdbyTags(OAOObj, ['heartbeat','oao'])[0];
        }
        if(btuObj) {
            connectivity.btu = this.helperService.getPointIdbyTags(btuObj, ['heartbeat','btu'])[0];
        }
        if(getEMRObj) {
            connectivity.emr =  this.helperService.getPointIdbyTags(getEMRObj, ['heartbeat','emr'])[0];
        }
        if(diagnosticObj) {
            connectivity.cloud =  this.helperService.getPointIdbyTags(diagnosticObj, ['cloud', 'diag', 'connected'])[0];
        }
        return connectivity;
    }

    getCCuSettingData() {
        this.helperService.getPointData()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
							if (res) {
								const buildingSettings = ObjectUtil.deepClone(this.buildingDataTempHolder);
								const response = ObjectUtil.deepClone(res);
							
								if(buildingSettings?.cloudLastUpdated) {
									delete buildingSettings.cloudLastUpdated;
								}
								if(response?.oaoLastUpdated) {
									delete response.oaoLastUpdated;
								}
								if (!ObjectUtil.isEqual(response,buildingSettings)) {
									this.ccuSetting = (res) ? ObjectUtil.deepClone(res) : this.ccuSetting;
									this.buildingDataTempHolder = ObjectUtil.deepClone(res);
									this.getSystemReconfigurationData(res);
                                    if(this.equipGraphicSystemProfile === "vavAdvancedHybridAhuV2") {
                                        this.ahuProfilesGlobalisationCheck();
                                    }
									this.setOTAandCCUSettings();
									this.isOnline('btu');
									this.isOnline('emr');
									this.isOAOOnline();
									// Setting Intrinsic schedule data.
									this.setIntrinsicScheduleData(res);
								}
							} else {
								this.setOTAandCloudStatusSettings(res);
							}
            });

         }

	setIntrinsicScheduleData(data: any) {
		if (!data) {return};
        const intrinsicScheduleData = {};
        intrinsicScheduleData['buildingSchedules'] = data?.buildingOccupancy?.id ? data.buildingOccupancy?.val : [];
		intrinsicScheduleData['buildingVacations'] = data?.buildingVacations && data?.buildingVacations?.length ? data.buildingVacations : [];
		intrinsicScheduleData['buildingSpecialSchedule'] = data?.buildingSpecialSchedule?.length ? data?.buildingSpecialSchedule : [];
		const mappedZones =
			(this.ccusWithPairedZones.find((ccusWithPairedZone: any) => ccusWithPairedZone.ahuRef === this.refs.ahuRef))?.pairedZoneList || [];
		let zoneList = this.buildings.zones.filter((option: any) => mappedZones.indexOf(option._id) !== -1);
		intrinsicScheduleData['zones'] = [];
        zoneList = zoneList.map(zone => {
            return this.checkProfileTypeOfZones(zone);
        }).filter(r => r);
		zoneList.forEach(zone => {
			if (data[zone.name]?.zoneSchedule && !(data[zone.name]?.zoneSchedule?.error) && (data[zone.name]?.scheduleType?.val > -1)) {
				const obj = {
					id: zone._id,
					name: zone.name,
					scheduleType: data[zone.name]?.scheduleType?.val,
					zoneSchedule: data[zone.name]?.zoneSchedule,
					zoneVacations: data[zone.name]?.zoneVacations,
					zoneSpecialSchedule: data[zone.name]?.zoneSpecialSchedule
				};
				intrinsicScheduleData['zones'].push(obj);
			}
		});
        
        if(!ObjectUtil.isEqual(this.intrinsicScheduleData,intrinsicScheduleData)) {
            this.intrinsicScheduleData = ObjectUtil.deepCopy(intrinsicScheduleData);
        }
		
	}

    getCcuSettings(ccuAhu: any) {
        //NOTE: For conditioning mode , due to tag change adding a work around for backword compatibility , in future if all builds
        //of CCU >=523 then we can use the updated tags , till then the check will be in place
        let equipObj = this.getEquip(['system', 'equip', 'profile']);
        let systemMode = equipObj?this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'rtu'])[0]:null;
		const systemPoints = [
			{
				pointName: 'systemMode',
				query: `(equipRef==@${ccuAhu})`,
				type: 'write',
				tags: ['system', 'userIntent', 'mode', `${!systemMode ? 'conditioning' : 'rtu'}`],
				domainName: "conditioningMode"
			},
			{
				pointName: 'cloudLastUpdated',
				query: `(equipRef==@${this.refs.equipRef})`,
				type: 'read',
				tags: ['diag', 'cloud', 'connected']
			},
			{
				pointName: 'desiredComfortIndex',
				type: 'write',
				tags: ['system', 'userIntent', 'ci', 'desired']
			},
			{
				pointName: 'demandResponse',
				type: 'write',
				tags: ['userIntent', 'demand', 'system', 'response']
			},
			{
				type: 'write',
				pointName: 'minHumidity',
				tags: ['min', 'system', 'target', 'humidity'],
				domainName: "systemtargetMinInsideHumidity"
			},
            {
				type: 'write',
				pointName: 'maxHumidity',
				tags: ['max', 'system', 'target', 'humidity'],
				domainName: "systemtargetMaxInsideHumidity"
			},
			{
				pointName: 'equipStatus',
				type: 'write',
                tags: ['system', 'status', 'message'],
				domainName: "equipStatusMessage"
			},
			{
				pointName: 'occupancyStatus',
				type: 'write',
				tags: ['system', 'scheduleStatus'],
				domainName: "equipScheduleStatus"
			},
			{
				pointName: 'occStatus',
				type: 'read',
				tags: ['occStatus']
			},
			{
				pointName: 'ductStaticPressureSetPoint',
				type: 'read',
				domainName: "ductStaticPressureSetpoint"
			}, {
				pointName: 'supplyAirflowTempSetpoint',
				type: 'read',
				domainName: "supplyAirflowTemperatureSetpoint"
			}, {
				pointName: 'dcvDamper',
				type: 'read',
				domainName: 'dcvDamperCalculatedSetpoint'
            }, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'airTempHeatingSp',
                type: 'read',
                domainName: 'airTempHeatingSp'
            }, {
                pointName: 'airTempCoolingSp',
                type: 'read',
                domainName: 'airTempCoolingSp'
            }, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'operatingMode',
                type: 'write',
                domainName: 'operatingMode'
            },{
                pointName: 'demandResponseActivation',
                type: 'write',
                tags: [ 'demand', 'activation', 'response']
            },{
                pointName: 'relay-1',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay1'],
                domainName: 'relay1OutputEnable',
            },{
                pointName: 'relay-2',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay2'],
                domainName: 'relay2OutputEnable',
            },
            {
                pointName: 'relay-3',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay3'],
                domainName: 'relay3OutputEnable',
            },
            {
                pointName: 'relay-4',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay4'],
                domainName: 'relay4OutputEnable',
            },
            {
                pointName: 'relay-5',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay5'],
                domainName: 'relay5OutputEnable',
            },
            {
                pointName: 'relay-6',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay6'],
                domainName: 'relay6OutputEnable',
            },
            {
                pointName: 'relay-7',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay7'],
                domainName: 'relay7OutputEnable',
            },
            {
                pointName: 'analog-out1',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog1'],
                domainName: "analog1OutputEnable"
            },
            {
                pointName: 'analog-out2',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog2'],
                domainName: "analog2OutputEnable"
            },
            {
                pointName: 'analog-out3',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog3'],
                domainName: "analog3OutputEnable"
            },
            {
                pointName: 'analog-out4',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog4'],
                domainName: "analog4OutputEnable"
            },
            {
                pointName: 'relay-1Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay1'],
                domainName: 'relay1OutputAssociation',
            },
            {
                pointName: 'relay-2Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay2'],
                domainName: 'relay2OutputAssociation',
            },
            {
                pointName: 'relay-3Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay3'],
                domainName: 'relay3OutputAssociation',
            },
            {
                pointName: 'relay-4Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay4'],
                domainName: 'relay4OutputAssociation',
            },
            {
                pointName: 'relay-5Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay5'],
                domainName: 'relay5OutputAssociation',
            },
            {
                pointName: 'relay-6Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay6'],
                domainName: 'relay6OutputAssociation',
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay7'],
                domainName: 'relay7OutputAssociation',
            },
            {
                pointName: 'daikin-heating',
                type: 'write',
                tags: ['system', 'config', 'heating', 'enabled']
            },
            {
                pointName: 'daikin-cooling',
                type: 'write',
                tags: ['system', 'config', 'cooling', 'enabled']
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'humidifier', 'sp', 'relay7']
            },


            //VAV Advanced AHU Points
            {
                pointName: 'supplyAirflowTemperatureSetpoint',
                type: 'read',
                domainName: 'supplyAirflowTemperatureSetpoint'

            }, {
                pointName: 'ductStaticPressureSetpoint',
                type: 'read',
                domainName: 'ductStaticPressureSetpoint'
            },
            {
                pointName: 'supplyAirflowTempControl',
                type: 'write',
                domainName: 'supplyAirTempControlOn'
            }, {
                pointName: 'pressureBasedFanControl',
                type: 'write',
                domainName: 'pressureBasedFanControlOn'
            },
            //Supply Air Temp Points 
            {
                pointName: 'supplyAirTemperature1',
                type: 'read',
                domainName: 'supplyAirTemperature1'
            }, {
                pointName: 'supplyAirTemperature2',
                type: 'read',
                domainName: 'supplyAirTemperature2'
            }, {
                pointName: 'supplyAirTemperature3',
                type: 'read',
                domainName: 'supplyAirTemperature3'
            }, {
                pointName: 'averageSat',
                type: 'read',
                domainName: 'averageSat'
            }, {
                pointName: 'satSpMin',
                type: 'read',
                domainName: 'minSat'
            }, {
                pointName: 'satSpMax',
                type: 'read',
                domainName: 'maxSat'
            },
            //Duct static pressure points
            {
                pointName: 'ductStaticPressureSensor1',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_2'
            },
            {
                pointName: 'ductStaticPressureSensor2',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_2'
            }, {
                pointName: 'ductStaticPressureSensor3',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_2'
            }, {
                pointName: 'averagePressure',
                type: 'read',
                domainName: 'averagePressure'
            }, {
                pointName: 'minPressure',
                type: 'read',
                domainName: 'minPressure'
            }, {
                pointName: 'maxPressure',
                type: 'read',
                domainName: 'maxPressure'
            }, {
                pointName: "coolingstage1",
                domainName: "loadCoolingStage1",
                type: "read",
            }, {
                pointName: "coolingstage2",
                domainName: "loadCoolingStage2",
                type: "read",
            }, {
                pointName: "coolingstage3",
                domainName: "loadCoolingStage3",
                type: "read",
            }, {
                pointName: "coolingstage4",
                domainName: "loadCoolingStage4",
                type: "read",
            }, {
                pointName: "coolingstage5",
                domainName: "loadCoolingStage5",
                type: "read",
            }, {
                pointName: "heatingstage1",
                domainName: "loadHeatingStage1",
                type: "read",
            }, {
                pointName: "heatingstage2",
                domainName: "loadHeatingStage2",
                type: "read",
            }, {
                pointName: "heatingstage3",
                domainName: "loadHeatingStage3",
                type: "read",
            }, {
                pointName: "heatingstage4",
                domainName: "loadHeatingStage4",
                type: "read",
            }, {
                pointName: "heatingstage5",
                domainName: "loadHeatingStage5",
                type: "read",
            },{
                pointName: "satcoolingstage1",
                domainName: "satCoolingStage1",
                type: "read",
            }, {
                pointName: "satcoolingstage2",
                domainName: "satCoolingStage2",
                type: "read",
            }, {
                pointName: "satcoolingstage3",
                domainName: "satCoolingStage3",
                type: "read",
            }, {
                pointName: "satcoolingstage4",
                domainName: "satCoolingStage4",
                type: "read",
            }, {
                pointName: "satcoolingstage5",
                domainName: "satCoolingStage5",
                type: "read",
            }, {
                pointName: "satheatingstage1",
                domainName: "satHeatingStage1",
                type: "read",
            }, {
                pointName: "satheatingstage2",
                domainName: "satHeatingStage2",
                type: "read",
            }, {
                pointName: "satheatingstage3",
                domainName: "satHeatingStage3",
                type: "read",
            }, {
                pointName: "satheatingstage4",
                domainName: "satHeatingStage4",
                type: "read",
            },{
                pointName: "satheatingstage5",
                domainName: "satHeatingStage5",
                type: "read",
            },{
                pointName: "loadbasedcoolingcontrol",
                domainName: "loadBasedCoolingControl",
                type: "read",
            },{
                pointName: "satbasedcoolingcontrol",
                domainName: "satBasedCoolingControl",
                type: "read",
            },{
                pointName: "satbasedheatingcontrol",
                domainName: "satBasedHeatingControl",
                type: "read",
            },{
                pointName: "loadbasedheatingcontrol",
                domainName: "loadBasedHeatingControl",
                type: "read",
            },{
                pointName: 'dcvDamperControl',
                type: 'read',
                domainName: ['co2BasedDamperControl']
            },{
				pointName: 'advancedAhuOperatingMode',
				type: 'read',
				domainName: 'operatingMode'
			}, {
                pointName: 'compositSignal',
				type: 'read',
				domainName: 'compositeSignal'
            }
		];
        this.systemPoints = systemPoints;
    }


    getCCUSettingData() {
        const domainNames = ['supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'minSat', 'maxSat', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor3_2', 'averagePressure', 'minPressure', 'maxPressure'];
        this.siteService.getHaystackDataByQuery(this.filterQuery()).subscribe(({ rows }) => {
            if (rows?.length > 0) {
                this.systemPoints = this.systemPoints.map(r => {
                    rows.forEach(row => {
                        let arr = 0;
                        if (row?.domainName) {
                            //Here we are specifically checking for vavAdvancedHybridAhuV2 profile to get the correct id for connect module points
                            // As we are getting the samee domain name for connect module points that we need to show in the UI excluding above domianNames so we are adding this additionial condition check.
                            if (this.equipGraphicSystemProfile === "vavAdvancedHybridAhuV2" && row?.domainName == r?.domainName) {
                                if (row?.hasOwnProperty('connectModule') && domainNames.includes(row?.domainName)) {
                                    return;
                                } else {
                                    r['id'] = row?.id?.replace('r:', '');
                                }
                            } else if (row?.domainName == r?.domainName) {
                                r['id'] = row?.id?.replace('r:', '');
                            }
                        } else {
                            r?.tags?.forEach(tag => {
                                if (row[tag]) {
                                    arr += 1;
                                }
                            });
                            if (r?.tags?.length == arr) {
                                r['id'] = row?.id?.replace('r:', '');
                            }
                        }
                    });
                    return r;
                });
                this.systemPoints.forEach(point => {
                    if (point.id) {
                        this.ccuSettingObj[point.pointName] = {};
                        this.ccuSettingObj[point.pointName]['id'] = point.id;
                        this.helperService.assemblePointIdData(point.id, point.type, point.pointName, undefined, 'update');
                        Object.assign(this.priorityArray, { [point.pointName]: {} });
                        this.priorityArray[point.pointName]['id'] = point.id;
                    }
                });
                this.getCCuSettingData();
            }
        });
    }

    filterQuery() {
		let concatQuery = 'point and ';
		const uniquePoints = [...new Set(this.systemPoints.map(item => item.query).filter(item => item))]
		uniquePoints.forEach((r, i) => {
			concatQuery += r + `${uniquePoints.length - 1 == i ? '' : ' or '}`
		});
		 return concatQuery;
	}


    onSystemModeChange(event: any, mode: string) {
        this.onCCUControlChange('systemMode', this.modeList.indexOf(mode), this.ccuSetting);
    }

    onSliderValueChange(onSliderValueChange: any) {
        let sliderValue = String(onSliderValueChange.value);
        setTimeout(() => {
            this.onControlChange('desiredComfortIndex', this.rangeSliderHack[sliderValue], this.ccuSetting);
        }, 200);
    }

    onToggleBtnChange(toggleValue: any, mode: string) {
        const toggleBtnValue = (toggleValue.checked) ? 1 : 0;
        this.onCCUControlChange(mode, toggleBtnValue, this.ccuSetting);
    }

    onNumberTickerChange(event: MatSelect, mode: string) {
        let numberValue = event.value;

        this.onCCUControlChange(mode, numberValue, this.ccuSetting);
    }

    onCCUControlChange(mode, inputData: any, ccuSettingObj: any) {
        let data = []
        data.push({
            type: mode,
            value: inputData,
            priorityArray: ccuSettingObj[mode].priorityArray || []
        })
        this.setUserUpdatedValue();
        this.helperService.handleInputChange(data);
    }

    getRoomNames(roomRef: string) {
        return this.buildings?.rooms?.filter(room => this.helperService.stripHaystackTypeMapping(room?.id)?.split(' ')[0] === this.helperService.stripHaystackTypeMapping(roomRef));
    }

    getRoomObj(roomRef: string) {
        return this.buildings?.floors?.map(floor => floor?.entities?.filter(room => room?.name === roomRef));
    }

    getEquip(equipTags: any) {
        return this.buildings?.equips
            ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?._id == this.refs?.ahuRef)[0];
    }

    getDiagObj(equipTags: any, zone=false) {
        return this.buildings?.equips
        ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?._id == (zone?this.refs?.ccuEquipRef:this.refs?.equipRef))[0];
    }
    getEMRObj(equipTags: any) {
        return this.buildings?.equips
            ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?.referenceIDs?.gateway == this.refs?.gateway)[0];
    }

    getBtuObj(equipTags: any) {
        return this.buildings?.equips
            ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?.referenceIDs?.gateway == this.refs?.gateway)[0];
    }

    getOAOEquip(equipTags: any) {
        return this.buildings?.equips?.filter(equip => equipTags.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?._id == this.refs?.oaoRef)[0];
    }

    getEquipObjBytags(equipTags: any) {
        return this.buildings.equips
            .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef ))[0];
    }


    getSiteObj(equipTags: any) {
        return this.buildings?.equips
            ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1))[0];
    }

    unHighLighZones($event: any) {
        let x = $event.target.getBoundingClientRect();

        if ($event.clientX < x.right && document.querySelector('.ccu-modal-wrapper') && !document.querySelector('.ccu-modal-wrapper').matches(':hover')) {

            this.siteNameModalStyle = {
                display: 'none'
            };
            if (this.ccuOrZone.actionUsed != 'click')
                this.closeModal(true);
        }
        this.ccuHoverCheckFlag=false;
    }

    getRoomNameById(roomRef: string) {
        return this.buildings.floors.map(floor => floor.entities.filter(room => room._id === roomRef)[0])[0];
    }

    loadFloorInfo(floorRef) {
        this.allFLoor = [];
        this.selectedFloorRef = floorRef;
        if (floorRef?.length) {
            floorRef.forEach(x => {
                if (x._id == 'all') {
                    this.allFLoor.push(x);
                    this.allDetails = true;
                }
            })
            this.refs.floorRef = floorRef;
            this.buildings.floors = floorRef.filter(floor => {
                for (let i of this.buildings.floorsMaster) {
                    if (i._id == floor._id) {
                        return floor;
                    }
                }
            });

            this.setFloorCCuId(floorRef);
            this.getZonesByCCU(this.buildings.floors, false);
        } else {
            this.selectedFloorRef = 'all';
            this.singleFloorFlagStyle = true;
            this.buildings.floors = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
            this.buildings.floorCcu = this.buildings.ccus;
            this.buildings.floors = ArrayUtil.clone(this.buildings.floorsMaster);
            this.buildings.floors = this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
            this.getZonesByCCU('all');
            this.lazyLoadFloors = ArrayUtil.clone((this.buildings.floors.length == this.lazyLoadFloors.length) ? this.buildings.floors : this.buildings.floors.slice(0, 2 * this.floorheight));

        }
        this.multiFloorSelected = floorRef.length >= 2 && this.compareFloorChanges.length == 1;
        this.buildings.floors.map((floor) => {
            this.getFloorPlanDimensions(floor);
        });
        this.compareFloorChanges = floorRef?.filter(x =>x)
    }

    setFloorCCuId(floorRef) {
        this.buildings.zones.forEach((zone) => {
            let ccu = this.ccusWithPairedZones.find(ccu => {
                if (ccu.pairedZoneList.indexOf(zone._id) > -1) {
                    return ccu;
                }
            });
            if (ccu) {
                zone['ccuId'] = ccu.id;
            }
        })

        const ccus = [];

        const filteredZones = [];
        this.buildings.zones.forEach(x => floorRef.forEach(y => {
            if (y._id == x.referenceIDs.floor) {
                filteredZones.push(x)
            }
        }))

        filteredZones.forEach((zone) => {
            if (ccus.indexOf(zone.ccuId) == -1) { ccus.push(zone.ccuId); }
        });


        this.buildings.floorCcu = this.buildings.ccus.filter(ccu => {
            return ccus.indexOf(ccu._id) > -1;
        });
    }

      changeCCU(ccuRef) {
        this.allCcus =[];
        this.selectedCCu = ccuRef;
        this.getZonesByCCU(ccuRef,true);
      }

      changeZone(zoneRef) {
        let floors = [];
        if ((!this.ccuForLayout.length && !this.selectedFloorRef) || this.allDetails || !zoneRef.length ) {
            floors = this.buildings.floors
        } else if (!this.ccuForLayout.length && this.selectedFloorRef) {
            floors = this.selectedFloorRef.floors
        } else {
            floors = this.buildings.floors
        }

        //Set the zone data for highlight and load respective floors:
        if(!zoneRef.length) {
            this.selectedZone = ''
            this.lazyLoadFloors = ArrayUtil.clone(floors);
        } else {
            this.selectedZone = zoneRef;
            this.lazyLoadFloors = ArrayUtil.clone((this.buildings.floors.length == this.lazyLoadFloors.length)?this.buildings.floors:this.buildings.floors.slice(0,2*this.floorheight));
        }
              setTimeout(() => {
                  this.scrollZoneIntoView();
              }, 50);
              this.scrollZoneIntoView();
        
            }

    scrollZoneIntoView() {
        let ele = document.getElementById(this.selectedZone);
        if (ele) {
            setTimeout(() => {
                ele.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }, 50)

        }
    }
    searchChange(event) {
        this.searchText = event.searchText;
        this.searchMatchingZones = event.zones;
        if (this.searchText) {
            window.scrollTo(0, 0)
        }
    }



    getZonesByCCU(ccuRef,ccuSelected?) {
        this.ccuForLayout = ccuRef;
        let floors = ArrayUtil.clone(this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum)));
      if (this.selectedFloorRef && this.selectedFloorRef != 'all'  && ccuSelected) {
            this.buildings.floors = [];
            this.buildings.floors = this.selectedFloorRef.filter(floor => {
              for(let i of this.buildings.floorsMaster) {
                    if (i._id == floor._id) {
                    return floor;
                }
                }
            })
        }
        this.setLazyLoadFloorData(ccuSelected,ccuRef,floors);
        if (Array.isArray(this.lazyLoadFloors)) {
            this.lazyLoadFloors.forEach(floor => {
            this.getFloorPlanDimensions(floor);
            });
        }
    }

    setLazyLoadFloorData(ccuSelected, ccuRef, floors) {
        if (ccuSelected && ccuRef?.length) {
            if (ccuRef.forEach(x => x.hasOwnProperty('referenceIDs'))) {
                this.lazyLoadFloors = [];
               this.lazyLoadFloors = ArrayUtil.clone(ccuRef);
                            } else {
                this.lazyLoadFloors = ArrayUtil.clone((this.lazyLoadFloors.length == floors.length) ? floors : floors.slice(0, 2 * this.floorheight));
                            }
        } else if (!ccuSelected && ccuRef?.length) {
            this.lazyLoadFloors = []
            this.lazyLoadFloors = ArrayUtil.clone(ccuRef);
                    }
    }

    loadMoreFloors() {
        if(this.selectedFloorRef && this.selectedFloorRef !='all'){
            return;
        }
        if(this.lazyLoadFloors.length == this.buildings.floors.length) return;
        this.showLazyLoader = true;
        setTimeout(()=>{
            let floors = ArrayUtil.clone(this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum)));
            let index = this.lazyLoadFloors.length+2 > floors.length ? floors.length : this.lazyLoadFloors.length+2;
            let nextFloors = floors.slice(this.lazyLoadFloors.length,index) || [];
            nextFloors.forEach(floor => {
                this.getFloorPlanDimensions(floor);
            });
            this.lazyLoadFloors = this.lazyLoadFloors.concat(nextFloors);
            this.showLazyLoader = false;
        }, 1500)
    }

    getCcus(ccu, ccuFloor) {
        return ccuFloor.filter(ccuF => ccuF === ccu.referenceIDs.ahu || ccuF === ccu.referenceIDs.gateway);
    }

    getCcusByRoom(ahuRef: string) {
        return this.buildings.ccus.filter(ccu => {
            if (ccu.referenceIDs.ahu === ahuRef || ccu.referenceIDs.gateway === ahuRef) {
                return true;
            } else {
                return false;
            }
        });
    }

    loadFloorLayouts(floors: any[]) {
        if (floors.length) {
            floors.forEach(floor => {
                floor.image = 'Loading...';
            });
            let floor = floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
            if (this.lazyLoadFloors.length != floor.length) {
                this.lazyLoadFloors = floor.slice(0, 2 * this.floorheight);
            }
        } else {
            this.loaderService.active(false);
        }
    }

    checkAllZonesLoaded(i) {
        if (i <= 0)
            this.getZonesByCCU('all');
    }

    checkFloorZonesLoaded(floor, i) {
        if(floor.entities.length == floor.droppeditem.length) i--;
        return i;
    }

    getCCUPariedWithZone(zoneId: string) {
        if (!this.ccusWithPairedZones.length) return null;
        for (var i = 0; i < this.ccusWithPairedZones.length; i++) {
            if (this.ccusWithPairedZones[i].pairedZoneList.indexOf(zoneId) > -1) return [this.ccusWithPairedZones[i].id, this.ccusWithPairedZones[i].CCUName]
        }
    }

    getCCUColor(ccuId) {
        if (ccuId) {
            let ccu = this.buildings.ccus.find((ccu) => {
                return ccu._id == ccuId;
            })
            return ccu.color;
        }
        return '';
    }

    setCcuColors() {
        let i = 0;
        for (let ccu of this.buildings.floorCcu) {
            ccu.color = this.ccuColors[i % this.ccuColors.length];
            i++;
        }
        this.buildings.floorCcu = ObjectUtil.deepClone(this.buildings.floorCcu);
        this.heatmapService.setBuildingCcus(this.buildings.floorCcu);
    }


    getCcuColor(roomName: string = '') {
        this.buildings.ccus.forEach((ccu, index) => {
            this.highLightZones(ccu.referenceIDs.ahu, '#DAE668', this.ccuColors[index % this.ccuColors.length]);
        });
    }

    highLightZones(ccuAhuRef: string, fillColor = 'rgba(0,0,0,0.5)', strokeColor = 'black') {
        this.ccuEquips = this.buildings.floors.map(floor =>
            floor.entities.map(room => {
                if (typeof room.entities[0] !== 'undefined') {
                    return room.entities.filter(entity => (ccuAhuRef === entity.referenceIDs.ahu || ccuAhuRef === entity.referenceIDs.gateway) ? entity : []);
                }
            })
        )[0];

    }

    highlightRooms(fillColor: string, strokeColor: string) {
        let roomRects = document.getElementsByClassName("fs-heatmap-wrapper__content__box");
        Array.prototype.slice.call(roomRects).forEach((roomRect) => {

            let equips = this.ccuEquips.map(equip => equip.filter(device => device.entities.length > 0));
            let activeRooms = equips.filter(room => (typeof room[0] !== 'undefined') ? this.getRoomNameById(room[0].referenceIDs.room).name == roomRect.innerText : '');
            if (activeRooms) {
                roomRect.style.backgroundColor = fillColor;
                roomRect.style.stroke = strokeColor;
            }

            let roomObj = this.getRoomObj(roomRect.innerText);
            let equipRef = roomObj.map(equip => equip.entities.filter(entity => entity.entities.length > 0)[0])[0];
            if (typeof equipRef !== 'undefined') {

                let ref = this.helperService.getPointIdbyTags(this.buildings, ['current', 'temp'], equipRef.referenceIDs.room)[0];

                let alreadySubscribed = (this.highlightRoomsSubscriptionCollection.filter(subs => {
                    return subs.id == ref
                }).length > 0)

                if (!alreadySubscribed) {

                    let subs = this.siteService.getHisPointData(ref, 'current')
                        .pipe(
                            map(this.helperService.stripHaystackTypeMapping),
                        )
                        .subscribe(({ rows }) => {
                            if (rows.length > 0) {
                                this.currentTemperatureRef[roomRect.innerText] = rows[0].val;
                                this.highlightRoomsSubscriptionCollection.find(subs => subs.id == ref).subscription.unsubscribe();
                            }
                        });

                    this.highlightRoomsSubscriptionCollection.push({ id: ref, subscription: subs });
                }
            }
        });
    }

    sanitizeSvgUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    closeModal(onCcuHover = false) {
        this.vacationSkeletonLoader = true
        this.ccuOrZone.type = '';
        this.ccuOrZone.actionUsed = '';
        this.equipGraphicData = []
        this.equipGraphicSystemProfile = ''
        this.systemReconfigurationSettings = null;
        this.humidifier = false;
        this.deHumidifier = false;
        this.oaoArcValue = 0;
        this.heatMapToolTipService.ccuorzone = null;
        this.activeTile = '';
        this.zoneId = "";
        this.refs.ccuName = "";
        this.refs.ccuAhu = "";
        this.systemWidgets = [];
        this.heatCoolLimitsHisData.clear();
        this.multiModuleProfileHasHPU = false;
        this.multiModuleProfileHasCPU = false;
        this.btuMeterType = "";
        this.emrMeterType = "";
        this.showEmr = false;
        this.showBtu = false;
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('right-arrow');
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('left-arrow');
        this.highlightCCU = '';
        this.tempZoneSettingsHolder = null;
        this.customWidgetDataForExport = {};
        this.dataForExport = {};
        this.hoverZoneData = null;
        this.refs.ccuEquipRef = null;
        this.overrideCancelled = false;
        this.equipGraphicData = []
        this.equipGraphicSystemProfile = ''
        this.equipGraphicsProfiles = '';
        this.systemReconfigurationSettings = null;
        this.modalStyleClickedFlag = false;
        this.siteNameModalStyleClickedFlag = false;
        this.enableDownLoad = false;
        this.profiles = [];
        this.enableViewAssetsSystemProfile = false;
        this.version = '';
        this.cmVersion = '';
        this.homeAppVersion = '';
        this.remoteAccessAppVersion = '';
        this.backnetAppVersion = '';
        this.versionText = "";
        // to force body to render height calculation
        window.scrollBy(0, -1);
        window.scrollBy(0, 1);
        //to dismiss select dropdown on hover
        document.body.click();

        this.siteNameModalStyle = {
            display: 'none'
        }
        this.siteNameModalStyleOnClicked = {
            display: 'none'
        }

        this.modelStyle = {
            display: 'none'
        };

        this.modelStyleOnClicked = {
            display: 'none'
        }

        this.overlayHeight = this.getOverlayHeight(true);

        if (this.subscriptionCollection.length) {
            this.subscriptionCollection.forEach(subs => {
                subs.subscription.next();
                subs.subscription.unsubscribe();
                subs.subscription.complete();
            })
            this.subscriptionCollection = [];
        }

        Object.keys(this.getUserSettingDataSubscriptionCollection).forEach((e) => {
            this.getUserSettingDataSubscriptionCollection[e].unsubscribe();
            delete this.getUserSettingDataSubscriptionCollection[e];
        });

        if (this.getCCUSettingDataSubscriptionCollection.length) {
            this.getCCUSettingDataSubscriptionCollection.forEach(subs => {
                subs.subscription.next();
                subs.subscription.unsubscribe();
                subs.subscription.complete();
            })
            this.getCCUSettingDataSubscriptionCollection = []
        }

        if (this.getCCUSettingDataChannelSubscriptionCollection.length) {
            this.getCCUSettingDataChannelSubscriptionCollection.forEach(subs => {
                subs.subscription.next();
                subs.subscription.unsubscribe();
                subs.subscription.complete();
            })
            this.getCCUSettingDataChannelSubscriptionCollection = [];
        }

        //clear pending requests on runtime-graph-service
        this.runTimeGraphsService.cancelPendingRequestsSubject.next(true);
        this.runTimeGraphsService.cancelPendingRequestsSubject.complete();

        //clear pending requests on device-helper-service
        this.deviceHelper.cancelPendingRequestsSubject.next(true);
        this.deviceHelper.cancelPendingRequestsSubject.complete();

        this.multiModuleProfile = false;
        this.buildingLimit = {
            min: undefined,
            max: undefined
        };
        this.sliderInput = {
            currentTemp: undefined,
            desiredTempHeating: undefined,
            desiredTempCooling: undefined,
            heatingUserLimitMin: undefined,
            heatingUserLimitMax: undefined,
            coolingUserLimitMin: undefined,
            coolingUserLimitMax: undefined,
            coolingDeadband: undefined,
            heatingDeadband: undefined,
        };
        this.helperService.clearData();
        this.sliderUserIntentService.clearData();
        this.headerUserIntent = false;
        this.displayRangeSlider = undefined;
        this.zoneVacations = [];
        this.multiModuleZonePriorityList = new Map();
        this.multiModuleScheduleTypeList = new Map();
        //clearing graphPointsCollection for second hover
        //Note : will update this logic while supporting multiple profiles case
        this.graphService.graphPointsCollection.clear();
        //clean up tool tip service
        this.heatMapToolTipService.clearTooltipData();
        //Clean up graph heights
        this.heatMapToolTipService.graphHeights.clear();
        //Clean up stale graph data
        this.graphService.resetGraphData();
        //Clean runtime flag for multi module
        this.runTimeGraphsService.isRuntimeRendered = false;
        this.runTimeGraphsService.moduleWithRuntime = null;
        this.zoneSettings = null;
        if (this.checkForEventType == 'leave' && !onCcuHover) {
            document.documentElement.scrollTop = this.messageService.scrollIndex;
        }
       // document.documentElement.scrollTop = this.messageService.scrollIndex;
        //To clear the messaging subscribe[Internally we are calling the api for every 5 sec]
        clearInterval(this.messagingSubscribe);
        clearInterval(this.timeSeriesinteval);
        this.checkForEventType = "";
        this.runTimeGraphsService.iscmBoardPortsMappingsCollectionRequested.clear();
        this.runTimeGraphsService.iscmBoardPortsMappingsCollectionFetched.clear();
        this.runTimeGraphsService.iscmBoardPortsMappingsForDaikinCollectionFetched.clear();
        this.runTimeGraphsService.iscmBoardPortsMappingsForDaikinCollectionFetched.clear();
        this.runTimeGraphsService.isDaikinCollectionRequested.clear();
        this.runTimeGraphsService.isFullyModulatingProfileTagsFetched.clear();
        this.runTimeGraphsService.isFullyModulatingProfileTagsCollectionRequested.clear();
        this.loadSummaryView = false;
        document.body.style.overflowX = 'hidden';
    }


    getZoneSettings(settingType: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
        if (pointID) {
            pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, settingType, profileEquipName, 'update'));
            Object.assign(this.priorityArray, { [settingType]: {} });
            this.priorityArray[settingType]['id'] = pointID;
        }
    }

    setDateRange(event: any) {
        if (event.startDate !== null) {
            //Clear subs for time series so we can go back to that date
            this.subscriptionCollection = [];

            let tz: string = this.siteService.getIANATimeZone(this.siteTz);
            let offset = dayjs().tz(tz).utcOffset();
            let centralOffset = dayjs().utcOffset();
            let startDate = event.startDate.toDate().toString();
            startDate = startDate.substring(0, startDate.lastIndexOf(' ')).trim();
            this.selected.startDate = dayjs(new Date(startDate)).startOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');

            let endDate = event.endDate.toDate().toString();
            endDate = endDate.substring(0, endDate.lastIndexOf(' '));
            
            //Check if end date is before today's date
            if (dayjs(new Date(event.endDate)).endOf('day').isBefore(dayjs(Date.now()))) {
                this.selected.endDate = dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
            }
            else if ((new Date(event.endDate).toDateString()) == (new Date(Date.now()).toDateString())) {
                const day  = dayjs(Date.now()).tz(tz)
                const currentDay = this.checkDateconversion(day.year(),day.month(),day.date(),day.hour(),day.minute(),day.second(),day.millisecond())
                this.selected.endDate = currentDay == new Date(event.endDate).toDateString() ? dayjs(Date.now()).tz(tz) :  dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');;
            }
            else if (dayjs(new Date(event.endDate)).endOf('day').isAfter(dayjs(Date.now()))) {
                this.selected.endDate = dayjs(Date.now()).tz(tz);
            }
            else {
                this.selected.endDate = dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
            }

            this.selected['timeZone'] = tz;
            this.heatMapToolTipService.selectedDateRange = this.selected;
            // Reloading the Analytics charts based on the selected date range
            this.analyticsLayoutService.dateRangeUpdated.next(true);
            this.dataForExport = {};
            this.checkWidgestLoaded = {};
            this.heatCoolLimitsHisData.clear();
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);

            // Also make the calls for getting custom widgets data
            this.fetchNewDataForCustomWidgets(this.heatMapToolTipService.selectedDateRange);
        }
    }

    checkdisplayprofile() {
        if (this.headerUserIntent == true) {
            //Multimodule profile cannot be pi and emr
            if (this.multiModuleProfile) {
                if (typeof (this.displayRangeSlider) != undefined) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                if (this.profiles?.length == 1) {
                    if ((this.profiles[0]?.profileType == 'pi' || this.profiles[0]?.profileType == 'emr'))
                        return true;
                    else {
                        if(this.profiles[0]?.profileType == 'sense' || this.profiles[0]?.profileType == 'monitoring') {
                            this.senseProfilecheck()
                        }
                        if (typeof (this.displayRangeSlider) != undefined) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
            }
        }
    }


    senseProfilecheck () {
        this.updateSliderInput()
        this.displayRangeSlider = true;
     }

     checkDateconversion(year,month,date,hour,minute,second,millisecond) {
        return new Date(year,month,date,hour,minute,second,millisecond).toDateString()
      }
 


    desiredTempsChangedHandler(changedbuttonid: PucUserIntentTempSliderValueChangedIds) {
        let datalastupdatedby = this.sliderUserIntentService.getLatestUpdatedBy(this.refs.ccuName);
        let sliderdata = this.sliderUserIntentService.getData(datalastupdatedby, this.refs.ccuName);
        let  heatingdesiredvalue
        let  coolingdesiredvalue
        //cel support writing the data
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            heatingdesiredvalue =  this.unitService.celeciusToFahrenheit(sliderdata.desiredTempHeating)
            coolingdesiredvalue =  this.unitService.celeciusToFahrenheit(sliderdata.desiredTempCooling)
        } else {
            heatingdesiredvalue = sliderdata.desiredTempHeating;
            coolingdesiredvalue = sliderdata.desiredTempCooling;
        }
        if (changedbuttonid == PucUserIntentTempSliderValueChangedIds.Heating || changedbuttonid == PucUserIntentTempSliderValueChangedIds.Deadband)

            this.heatingDesriedTempChangeHolder.push({
                "type": 'desiredTempHeating',
                "temp": heatingdesiredvalue
            });
        if (changedbuttonid == PucUserIntentTempSliderValueChangedIds.Cooling || changedbuttonid == PucUserIntentTempSliderValueChangedIds.Deadband)
            this.coolingDesriedTempChangeHolder.push({
                "type": 'desiredTempCooling',
                "temp": coolingdesiredvalue
            });
        this.onChangeDesiredTempSubject.next({
            type: '', temp: ''
        })
    }

    onChangeDesiredTemp(settingType: any[]) {
        let data = [];
        if (this.multiModuleProfile) {
            if (!settingType) settingType = [];
            settingType.forEach((type) => {
                this.profiles.forEach(profile => {
                    if (profile != undefined) {
                        let profileEquipName = profile.profile.name;
                        let settingsObj = (profileEquipName) ? this.zoneSettings[profileEquipName] : this.zoneSettings;
                        data.push({
                            type: type.name,
                            value: type.value,
                            priorityArray: this.zoneSettings[profileEquipName][type.name].priorityArray || []
                        })
                    }
                });


            })
            this.setUserUpdatedValue();
            this.helperService.handleInputChange(data);

        } else {
            if (!settingType) settingType = [];
            settingType.forEach((type) => {
                this.profiles.forEach(profile => {
                    if (profile != undefined) {
                        let profileEquipName = profile.profile.name;
                        let settingsObj = (profileEquipName) ? this.zoneSettings[profileEquipName] : this.zoneSettings;
                        data.push({
                            type: type.name,
                            value: type.value,
                            priorityArray: this.zoneSettings[type.name].priorityArray || []
                        })
                    }
                });

            })
            this.setUserUpdatedValue();
            this.helperService.handleInputChange(data);
        }
    }


    setXAxisCords(xVals: any) {
        if (xVals?.length) {
            //clean up any item from previous selection
            this.xCords = [];
            xVals.forEach((x, i) => {
                if (x.textContent.toString() != "" && !x.textContent.toString().includes(".")) {
                    this.xCords[i] = x.textContent.toString();
                }
            });
        }
    }

    schValidation(event) {
        if (this.zoneSettings.zoneSchedule?.val && this.zoneSettings.buildingOccupancy?.val) {
            this.conflictMsg = '';
            this.forceTrimArray = [];

            let arr = (a, b) => {
                return parseInt(a.day) - parseInt(b.day) || parseInt(a.sthh) - parseInt(b.sthh);
            }
            let zoneSchInfo = this.zoneSettings?.zoneSchedule?.val;
            zoneSchInfo.sort(arr);

            zoneSchInfo.forEach(zoneSch => {
                this.zoneContainment(zoneSch);
            });


            return this.forceTrimArray;

        }
    }

    //method to force trim
    forceTrim() {
        if (this.forceTrimArray.length) {
            let zoneInfo = this.zoneSettings?.zoneSchedule?.val;
            return this.schedulerService.forceTrim(this.forceTrimArray,zoneInfo);
        }
    }


    showForceTrimPopup(event) {

        this.conflictMsg = 'Zone Schedule is outside building occupancy. The Schedule is outside by' ;
        this.forceTrimArray.map((item) => {
            let week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            this.conflictMsg = `${this.conflictMsg}\n${week[parseInt(item.index)]} from ${item.trim}`;
        })
        //Handling popup
        const dialogRef: any = this.dialog.open(ConfirmModalComponent, {
            width: '500px',

        });
        let htmlContent = `${this.conflictMsg}`;
        dialogRef.componentInstance.title = 'Force-Trim';
        dialogRef.componentInstance.confirmBtnText = 'Force-Trim';
        dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
        if (this.forceTrimArray.length) {
            dialogRef.afterClosed().subscribe(result => {
                if (!result) {
                    this.scheduleTypeVal = this.oldscheduleTypeVal;
                }
                if (result == 'confirm') {
                    let zoneSchInfo = this.forceTrim();
                    zoneSchInfo = zoneSchInfo.map(sch => {
                        return ObjectUtil.removeKey('isSplit', sch);
                    })
                    //save zone schedule
                    let schId = this.helperService.getPointIdbyTags(this.buildings.entites, ['zone', 'schedule', 'days'], this.refs.roomRef)
                     this.siteService.updateSchedule(
                        schId[0],
                        this.helperService.stripHaystackTypeMapping(this.refs.roomRef),
                        this.helperService.stripHaystackTypeMapping(this.refs.ccuRef),
                        this.helperService.stripHaystackTypeMapping(JSON.stringify(zoneSchInfo)).replace(/['"]+/g, '').replace(/(:[\d.]+)(,)/g, '$1 '),
                        'zoneRef',
                        this.helperService.stripHaystackTypeMapping(this.refs.siteRef),
                        this.zoneSettings?.zoneSchedule?.unoccupiedZoneSetback,
                        this.zoneSettings?.zoneSchedule?.followBuilding,
                    ).pipe(map(this.helperService.stripHaystackTypeMapping),  takeUntil(this.unsubscribe)
                    ).subscribe(res => {
                        if (res.rows) {
                            this.zoneSettings.zoneSchedule.val = res.rows[0].days;
                        }
                    });
                    if (event.type.includes("zonePriority") || event.type.includes("scheduleType") && this.multiModuleProfile) {

                        this.multiModuleScheduleTypeList
                            .forEach((_item) => {
                                let settingValue = event.value;
                                let settingsObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
                                this.onControlChange(event.type, settingValue, settingsObj)
                            })
                    }
                    else {
                        this.onControlChange(event.type, event.value, this.zoneSettings);
                    }
                }
            });
        }
        else {
            dialogRef.close();
        }
    }


    //checks If ZoneSchedule's Exceeding BuildingSchedule
    //returns true or false
    zoneContainment(zoneSch) {

        let zoneSchStartTime = dayjs(zoneSch['sthh'] + ':' + zoneSch['stmm'], 'HH:mm');
        let zoneSchEndTime = dayjs(zoneSch['ethh'] + ':' + zoneSch['etmm'], 'HH:mm');

        let buildingSchInfo = this.zoneSettings.buildingOccupancy.val;

        let forceTrimArray = this.schedulerService.zoneContainment(buildingSchInfo,zoneSch.day,zoneSchStartTime,zoneSchEndTime);
        this.forceTrimArray = this.forceTrimArray.concat(forceTrimArray);
    }

    revertChangedValues(paramList: any) {
        for (let i = paramList.length - 1; i >= 0; i--) {
            if (this.ccuOrZone.type == 'ccu' && this.ccuOrZone.actionUsed == 'click' && this.ccuSetting[paramList[i].mode]) {

                this.ccuSetting[paramList[i].mode].val = parseFloat(paramList[i].val);
                const cmRadioEle: any = document.getElementById('radio-'+ this.modeList[this.ccuSetting.systemMode.val].toLowerCase());
                if(cmRadioEle) {
                    cmRadioEle.checked = true;
                }
                if (paramList[i].mode == 'desiredComfortIndex') {
                    this.desiredCIValue = this.ccuSetting[paramList[i].mode].val;
                    this.desiredCISliderValue = this.rangeSliderHack[parseInt(this.desiredCIValue)];

                }
                else if (paramList[i].mode == 'compensateHumidity') {
                    this.CompensateHumidity.checked = parseInt(paramList[i].val);
                    this.CompensateHumidity._checked = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'demandResponse') {
                    this.DemandResponse.checked = parseInt(paramList[i].val);
                    this.DemandResponse._checked = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'smartPrepurge') {
                    this.ccuSetting.smartPrepurge.val = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'smartPostpurge') {
                    this.ccuSetting.smartPostpurge.val = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'enhancedVentilation') {
                    this.ccuSetting.enhancedVentilation.val = parseInt(paramList[i].val);
                }

                else if (paramList[i].mode == 'minHumidity') {
                    this.ccuSetting.minHumidity.val = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'maxHumidity') {
                    this.ccuSetting.maxHumidity.val = parseInt(paramList[i].val);
                }
            }
            else if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click' && this.zoneSettings[paramList[i].mode]) {

                if (!this.multiModuleProfile && this.zoneSettings[paramList[i].mode].val) {
                    this.zoneSettings[paramList[i].mode].val = parseFloat(paramList[i].val);
                }
            }
            if (paramList[i].mode == "zonePriority") {
                this.zonePriorityVal = this.oldZonePriorityVal;
            }
            if (paramList[i].mode == "scheduleType") {
                this.scheduleTypeVal = this.oldscheduleTypeVal;
            }
            paramList.splice(i);
            this.helperService.revertPointValues.splice(i);

        }
    }

    isInvalidDate = (m: any) => {
        //Start date should always be today or before but never after
        return m.isAfter(dayjs(), 'day')
    }

    getPointId(key: string, pointRef: string) {
        let id: Array<string> = new Array<string>();
        if(!pointRef) 
        return;
        //change the check from key -> pointRef by kuladeep 3.11.19
        if (this.graphService.graphPointsCollection.has(key + pointRef)) {
            id.push(this.graphService.graphPointsCollection.get(key + pointRef));
        }
        else {
            this.graphService.graphPointsCollection.set(key + pointRef, pointRef)
            id.push(pointRef);
        }

        return id;
    }

    //this will fill in details for enum and units
    getPointProperties(dataObj) {
        if (this.graphService.graphPointsCollection.size > 0) {
            if (dataObj?.entities) {
                dataObj.entities.map(entity => {
                    this.graphService.graphPointsCollection?.forEach((value, key) => {
                        if (value == entity._id) {
                            this.setGraphEnumUnit(entity,value,key);
                        }
                    });
                });
            }
        }
    }

    setGraphEnumUnit(entity,value,key) {
        let unitCategory: boolean = false;
        let unit;
        if (this.unitService.unitListArray.includes(entity.unit)) {
            unit = this.mapUnitBasedOnPreference(entity);
            unitCategory = true;
        }
        if(entity.markers.includes("predefined")) {
            this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), 'V');
            this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
        } else {
        this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), unitCategory ? unit : entity.unit);
        this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
        }
    }

    mapUnitBasedOnPreference(data) {
        let returnUnit;
        let userPreference = this.authService.getLoggedInUserPreferences();
        if (this.unitService.temperatureUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnTemperature(userPreference);
        } else if (this.unitService.energyConsumptionUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnEnergyConsumption(userPreference);
        } else if (this.unitService.airflowVolumeUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnAirFlowVolume(userPreference);
        } else if (this.unitService.airPressureUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnAirPressure(userPreference);
        } else if (this.unitService.waterPressureUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnWaterPressure(userPreference);
        } else if (this.unitService.waterFlowUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnWaterflow(userPreference);
        }
        return returnUnit;
    }

    convertUnitBasedOnTemperature(userPreference) {
        let temperatureUnit;
        const userTemperaturePreference = userPreference.temperatureUnit;
        if (userTemperaturePreference == this.unitService.temperatureUnitListArray[0]) {
            temperatureUnit = this.unitService.temperatureUnitListArray[0];
        } else {
            temperatureUnit = this.unitService.temperatureUnitListArray[1];
        }
        return temperatureUnit;
    }

    convertUnitBasedOnEnergyConsumption(userPreference) {
        let energyConsumptionUnit;
        const userEnergyConsumptionPreference = userPreference.energyConsumptionUnit;
        if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[1]) {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[1];
        } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[2]) {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[2];
        } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[3]) {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[3];
        } else {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[0];
        }
        return energyConsumptionUnit;
    }

    convertUnitBasedOnAirFlowVolume(userPreference) {
        let airflowVolumeUnit;
        const userAirflowVolumePreference = userPreference.airflowVolumeUnit;
        if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[1]) {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[1];
        } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[2]) {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[2];
        } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[3]) {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[3];
        } else {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[0];
        }
        return airflowVolumeUnit;
    }

    convertUnitBasedOnAirPressure(userPreference) {
        let airPressure;
        const userAirPressurePreference = userPreference.airPressureUnit;
        if (userAirPressurePreference == this.unitService.airPressureUnitListArray[1]) {
            airPressure = this.unitService.airPressureUnitListArray[1];
        } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[2]) {
            airPressure = this.unitService.airPressureUnitListArray[2];
        } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[3]) {
            airPressure = this.unitService.airPressureUnitListArray[3];
        } else {
            airPressure = this.unitService.airPressureUnitListArray[0];
        }
        return airPressure;
    }

    convertUnitBasedOnWaterPressure(userPreference) {
        let waterPressure;
        const userWaterPressurePreference = userPreference.waterPressureUnit;
        if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[1]) {
            waterPressure = this.unitService.waterPressureUnitListArray[1];
        } else if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[2]) {
            waterPressure = this.unitService.waterPressureUnitListArray[2];
        } else {
            waterPressure = this.unitService.waterPressureUnitListArray[0];
        }
        return waterPressure;
    }

    convertUnitBasedOnWaterflow(userPreference) {
        let waterFlow;
        const userWaterFlowPreference = userPreference.waterFlowUnit;
        if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[1]) {
            waterFlow = this.unitService.waterFlowUnitListArray[1];
        } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[2]) {
            waterFlow = this.unitService.waterFlowUnitListArray[2];
        } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[3]) {
            waterFlow = this.unitService.waterFlowUnitListArray[3];
        } else {
            waterFlow = this.unitService.waterFlowUnitListArray[0];
        }
        return waterFlow;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.ccuOrZone.actionUsed == "hover") {
            this.graphWidth = document.getElementsByClassName('fs-content')[0].clientWidth * 0.5 - 60;
        }
        else if (this.ccuOrZone.actionUsed == "click") {
            this.graphWidth = document.getElementsByClassName('fs-content')[0].clientWidth - 375 - 60;
        }
    }

    isProfilePI(equipName) {
        return ['pid','sense','monitoring'].indexOf(equipName?.profileType) > -1;
    }

    isModbusProfile(equipName) {
        /**if there are previous builds already installed in the site for that we are using  or condition*/
        if(equipName?.modBusEquip == "emr" || equipName?.modBusEquip == "emr_zone") {
            this.showModbusEmr = true;
        } else {
            this.showModbusEmr = false;
        }
        return ['modbus'].indexOf(equipName?.profileType) > -1;
    }

    getOverlayHeight(close = false) {

        if (!close) {
            let ele: any = document.getElementsByTagName('html')[0]
            return ele.scrollHeight + 'px'
        }
        let ele = document.getElementsByClassName('fs-content')[0];
        return ele.getBoundingClientRect().height + 'px'
    }
    exportExcel(type) {
        const analyticsExportCount = this.analyticsLayoutService.totalAnalyticsCount;
        const analyticsExportDataList = Object.keys(this.analyticsLayoutService.exportAnalyticsExcelData)?.length;
        this.isDownloadExcelPending = true;
        if (analyticsExportCount == analyticsExportDataList && this.analyticsLayoutService.isAnalyticsListLoading) {
            this.isDownloadExcelPending = false;
            clearInterval(this.timeSeriesinteval);
            clearInterval(this.clearExcelInterval);
            const fileName = type == 'Zone' ? this.refs?.zoneName : this.refs?.ccuName + '_' + this.refs?.ahuRef;
            let header = type + ' Data for ' + fileName;

            let allWidgets = { ...this.dataForExport };
            Object.keys(this.customWidgetDataForExport).forEach((key) => {
                allWidgets = { ...allWidgets, ...this.customWidgetDataForExport[key] };
            });
            let cols = Object.keys(allWidgets);
            const pointsDataLength = cols.map((col) => allWidgets[col]?.length ? allWidgets[col]?.length : 0);
            let maxArraylength = Math.max(...pointsDataLength);
            let index = pointsDataLength.indexOf(maxArraylength);
            let jsonData = [];
            if (cols.length > 0) {
                for (let i = 0; i < allWidgets[cols[index]]?.length; i++) {
                    let data = {}
                    for (let j = 0; j < cols.length; j++) {
                        if (j == 0) {
                            data['datetime'] = allWidgets[cols[j]][i] ? allWidgets[cols[j]][i].ts : ''
                        }
                        data[cols[j]] = allWidgets[cols[j]][i] ? allWidgets[cols[j]][i].val : ''
                    }
                    jsonData.push(data);
                }
            }
            cols.unshift('datetime');
            const analyticsData = this.exportData.formatGlobalExportData(jsonData);
            const analyticsCols = analyticsData.rows.filter(row => row != 'datetime');
            jsonData = analyticsData.sheetData;
            const analyticsColsKeys = analyticsCols.map(_col =>_col.key);
            let columns = cols.concat(analyticsCols.map(_col => _col.columnName));
            cols = cols.concat(analyticsColsKeys);
            columns = columns.map(_headerCol => _headerCol == 'datetime' ? 'Date | Time' : _headerCol);
            this.exportData.exportExcel(jsonData, fileName, header, cols, columns, this.isPartner);
            this.pollGraphData();
        } else {
            clearInterval(this.clearExcelInterval);
            clearInterval(this.timeSeriesinteval); // clearing the fetch time series data interval.
            this.clearExcelInterval = setInterval(() => {
                this.exportExcel(type);
            }, 1000);
        }
    }

    ngOnDestroy() {
        this.helperService.clearData();
        Object.keys(this.subscriptions).forEach(e => {
            this.subscriptions[e].unsubscribe();
        });
        this.pubNubService.unsubscribe();
        this.getEntitySubscription.unsubscribe();
        this.highlightRoomsSubscriptionCollection = [];
        this.vacationsService.updateVacationList = true;
        sessionStorage.clear();
        this.graphService.setData(null);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.cancelChanges = [];
        this.runTimeGraphsService.runTimeProfile.clear();
        //To clear the messaging subscribe[Internally we are calling the api for every 5 sec]
        clearInterval(this.messagingSubscribe);
        clearInterval(this.timeSeriesinteval);
    }

    getFloorPlanDimensions(floor) {
        const img = new Image();

        img.src = floor.urlFloorZoneIn;
        img.addEventListener('load', () => {
            const ele = document.getElementById(floor.id);
            if (ele) {
                let containerWidth = ele.getBoundingClientRect().width;
                let containerHeight = ele.getBoundingClientRect().height;

                const xScaleFactor = containerWidth / img.width;
                const yScaleFactor = containerHeight / img.height;
                floor['imageWidth'] = img.width * xScaleFactor;
                floor['imageHeight'] = img.height * yScaleFactor;
            }

        });
    }

    getSystemProfileConfig() {
        const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        const OAOObj = this.getOAOEquip(['oao', 'equip', 'profile']);
        const certificationLevel = this.authService.certificationLevel;
        // below is the method to get Equip Graphic points data;
        if((this.equipGraphicSystemProfile !=='dabExternalAHUController') || (this.equipGraphicSystemProfile !=='vavExternalAHUController')) {
            this.getEquipGraphicPoints();
        }
        // Loop all the profile tags array and get the required data for the system configuration setting based on system profile.
          systemProfileTagsArray.forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
            const key = _profileObj.key;
                if (key == 'dcwbEnabled') {
                    this.getZoneSettings(key, this.helperService.getPointIdbyTags(equipObj, systemProfileFilterTags.tags, null, systemProfileFilterTags.filterTags), _profileObj.action);
                } else {
                    this.getZoneSettings(key, this.helperService.getPointIdbyTags(equipObj, _profileObj.tags,null,_profileObj?.filterTags,_profileObj?.domainName), _profileObj.action);
                }
            }
        });
        if (OAOObj) {
            /** OAO **/
          systemProfileOaoTagsArray.forEach((_profileObj: any) => {
                if (_profileObj.certificationCheck.includes(certificationLevel)) {
                    if (_profileObj.key == 'outsideminopen') {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(OAOObj, systemProfileFilterOAOTags.tags, null, systemProfileFilterOAOTags.filterTags), _profileObj.action);
                    } else {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(OAOObj, _profileObj.tags), _profileObj.action);
                    }
                }
            });
        }
        this.getUserSettingData('reConfig');
    }


    checkIfSystemBTUIsPaired(sysBtuData) {
        let btuSystemData;
        this.siteService.findByQuery(`equipRef==@${sysBtuData[0]?.id}`).subscribe(sysBtuResponse => {
            btuSystemData = sysBtuResponse?.rows;
            this.fetchSystemModbusData(btuSystemData, 'btu', sysBtuData);
        }, error => {
            console.error("Error in ccusResponseObservable:", error);
        });
    }

    checkIfSystemEMRIsPaired(sysEmrData) {
        let emrSystemData;
        this.siteService.findByQuery(`equipRef==@${sysEmrData[0]?.id}`).subscribe(sysEmrResponse => {
            emrSystemData = sysEmrResponse?.rows;
            this.fetchSystemModbusData(emrSystemData, 'emr', sysEmrData);
        }, error => {
            console.error("Error in :", error);
        });
    }

    checkIfSystemModbusIsPaired(sysModbusData) {
        let modbusSystemData;
        this.siteService.findByQuery(`equipRef==@${sysModbusData[0]?.id}`).subscribe(sysModbusResponse => {
            modbusSystemData = sysModbusResponse?.rows;
            this.fetchSystemModbusData(modbusSystemData, 'modbus', sysModbusData);
            // This method is for fetching the user intent only for VAV/DAB EXTERNAL AHU
            this.modbusEquipId = sysModbusData[0]?.id;
            this.checkForModbusCurrentValues(this.modbusEquipId);
        }, error => {
            console.error("Error in :", error);
        });
    }

    //this method will fetch the system modbus data
    fetchSystemModbusData(systemData, modbusTypeCheck, res) {
        let isHeartBeat = false;
        if (systemData?.length > 0) {

            if (modbusTypeCheck === 'btu') {
                this.showBtu = true;
            }

            if (modbusTypeCheck === 'emr') {
                this.showEmr = true;
            }


            if (modbusTypeCheck === 'modbus') {
                this.showModbus = true;
            }

            // Note: Even though we are fetching data on an equipment basis, we will receive data specific to that particular equipment.
            // As per the requirement, we need to display points with the 'displayInUi' tag, hence the following filter is necessary.
            // Sometimes, we will receive schedule type and other points where the 'displayInUi' key may not exist.
            // In such cases, if we do not use the filter, it will break the code.

            let points = systemData.filter(p => p['displayInUi'] && p['equipRef']);
            const heartBeatPoints = systemData.filter(p => p['heartbeat'] && p['equipRef']) || [];
            isHeartBeat = heartBeatPoints.length > 0;
            points = heartBeatPoints.concat(points);

            const modbusProfileType = res[0].profile.split('_');
			const splitModbusDis = res[0]?.['dis']?.split('-');
			const modbusFromCCU = splitModbusDis[splitModbusDis?.length - 2]?.toLowerCase() == res[0]?.equipType?.toLowerCase();
			
			let modbusEquipName;
			
			// Condition to handle displayName from response depending on if it is from hayloft or not.
			if (modbusFromCCU) {
				const modbusProfile = res[0]?.['dis']?.substring(this.refs?.siteDetails?.dis?.length + 1).slice(0, -(res[0]?.group?.length + 1) - (modbusProfileType[1]?.length + 1));
				
                //format of value is not required for ahu profiles, as will be consuming the name from ccu directly.
                modbusEquipName = modbusTypeCheck === 'modbus' && ( this.equipGraphicSystemProfile == 'vavExternalAHUController' || this.equipGraphicSystemProfile == 'dabExternalAHUController') ?  `${res[0]?.['dis']?.substring(this.refs?.siteDetails?.dis?.length + 1)?.split('-')[0]}` +'-'+res[0]?.equipType+'(' +`${res[0]?.equipType?.toUpperCase()}` + `${res[0]?.group}` + ')' :  `${modbusProfile}-${modbusProfileType[1]?.toLowerCase()}` + '(' + `${modbusProfileType[1]}` + `${res[0]?.group}` + ')';
			} else {
			  modbusEquipName = `${splitModbusDis[splitModbusDis?.length - 2]}` + '(' + `${res[0]?.group}` + ')';
			}
			
			if (modbusTypeCheck === 'btu') {
				this.btuMeterType = modbusEquipName;
				this.hideDisplayUIBtuText = isHeartBeat ? points.length > 1 : true;
			}
			
			if (modbusTypeCheck === 'emr') {
				this.emrMeterType = modbusEquipName;
				this.hideDisplayUIText = isHeartBeat ? points.length > 1 : true;
			}
			
			if (modbusTypeCheck === 'modbus') {
				this.modbusType = modbusEquipName;
				this.hideDisplayUIModbusText = isHeartBeat ? points.length > 1 : true;
			}

            if (points.length > 0) {
                for (let i = 0; i < points.length; i++) {
                    const label = (i === 0 && isHeartBeat) ? 'lastUpdated' : `modbus${i + 1}`;
                    this.getSystemModbusSettings(
                        label,
                        [this.helperService.stripHaystackTypeMapping(points[i].id)],
                        points[i]['writable'] ? 'read&hisWrite' : 'read&hisRead',
                        modbusTypeCheck
                    );
                }
            } else {
                if (modbusTypeCheck === 'btu') {
                    this.hideDisplayUIBtuText = false;
                    this.getCCuSettingData();
                }

                if (modbusTypeCheck === 'emr') {
                    this.hideDisplayUIText = false;
                    this.getCCuSettingData();
                }

                if (modbusTypeCheck === 'modbus') {
                    this.hideDisplayUIModbusText = false;
                    this.getCCuSettingData();
                }
            }
        }
    }


    checkForModbusCurrentValues(modbusEquipId) {
        const systemPoints = [
            {
                pointName: 'ductStaticPressureCurrent',
                query: `(equipRef==@${modbusEquipId})`,
                type: 'read',
                tags: ['discharge', 'air', 'pressure', 'sensor','displayInUi','modbus','system'],
              },  {
                pointName: 'supplyAirflowTempCurrent',
                query: `(equipRef==@${modbusEquipId})`,
                type: 'read',
                tags: ['discharge', 'air', 'temp', 'sensor','displayInUi','modbus','system']
              },
        ];

        this.systemPoints = this.systemPoints.concat(systemPoints);
        this.getCCUSettingData();
    }



    getSystemModbusSettings(mode: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
        if (pointID) {
            pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, mode, profileEquipName, 'update'));
            this.ccuSettingObj[mode] = {};
            this.ccuSettingObj[mode]['id'] = pointID;
            this.helperService.assemblePointIdData(pointID, endPointType, mode, profileEquipName, 'update');
        }
    }

    getSystemProfileType() {
        //In the following method we are filtering  all the system modbus profiles with the gateway ref 
        // then we are  grouping the equip refs based on the profile if it is EMR or BTU or VAV/DAB extenal ahu
        const combinedQuery = `gatewayRef==@${this.refs.gateway} and (system and modbus and profile))`;
        this.siteService.findByQuery(combinedQuery)
            .subscribe(
                res => {
                    //
                    if (!res?.rows?.length) {
                        return;
                    }
                    res = this.helperService.stripHaystackTypeMapping(res);

                    const filteredRows = res.rows.filter(row => row.hasOwnProperty('profile') && row.profile !== undefined);
                    // Handle no rows with profile defined
                    if (filteredRows.length === 0) {
                        return;
                    }
                    // Grouping the rows based on profile so profile name will come as key and object will come as value
                    const groupedRows = filteredRows.reduce((data, row) => {
                        if (!data[row.profile]) {
                            data[row.profile] = [];
                        }
                        data[row.profile].push(row);
                        return data;
                    }, {});

                    // based on profiles we are excuting the respective mentods
                    Object.keys(groupedRows).forEach(profile => {
                        if (profile === 'MODBUS_EMR') {
                            this.checkIfSystemEMRIsPaired(groupedRows[profile]);
                        }

                        if (profile === 'MODBUS_BTU') {
                            this.checkIfSystemBTUIsPaired(groupedRows[profile]);
                        }

                        if (profile === 'dabExternalAHUController' || profile === 'vavExternalAHUController') {
                            this.checkIfSystemModbusIsPaired(groupedRows[profile]); 
                        }
                    });
                },
                error => {
                    console.error("Error occurred while fetching data:", error);
                }
            );
    }

    getSystemReconfigurationData(result: any) {
        const self = this;
        this.systemReconfigurationSettings = ObjectUtil.deepClone(result);
        let  equipData = ObjectUtil.deepClone(this.systemReconfigurationSettings)
        if(!self.systemReconfigProfileName || self.systemReconfigProfileName.trim()=="System Profile...")
        self.systemReconfigProfileName = self.systemWidgets[0] ? this.setSystemReconfigName(self.systemWidgets[0]?.name?.slice(8)) : '';
        if(this.isdomainName && ['VAV Staged RTU','VAV Staged RTU with VFD Fan','VAV Fully Modulating AHU'].includes(this.equipGraphicsProfiles)) this.setRelayMappingforVAV()
        self.systemReconfigTitle = self.systemWidgets[0] ? self.systemWidgets[0]?.name?.slice(8) : '';
                if(self.systemReconfigProfileName === "System Profile")  {
                    self.systemReconfigProfileName  = "Default System Profile";
                    self.systemReconfigTitle = 'Default System Profile';
        }
        this.profileReconfigCheck = !['vavExternalAHUController','dabExternalAHUController','vavAdvancedHybridAhuV2'].includes(this.equipGraphicSystemProfile);
          if(equipData?.['dcwbEnabled']?.hasOwnProperty('val') && equipData?.['dcwbEnabled']?.val == 1) 
          {
            this.equipGraphicsProfiles = this.configService.getConfig('appDisplayName') == 'ClimaVision' ? "VVT-C Fully Modulating AHU with DCWB":"DAB Fully Modulating AHU with DCWB"
          }
          this.equipGraphicData = ObjectUtil.deepClone(equipData);
    }


    hasCooling() {

        // For VvaAdvancedHybridAhu based on the configuration settings we need to set the condition mode 
        // We no need to check the any analogues only with the relay selection weather the point is present or not need to check and display the mode
         //here in the  array coolingKeys containing the names of the keys that represent heating stages.
        if (this.equipGraphicSystemProfile === "vavAdvancedHybridAhuV2") {

            if (this.ccuSetting.hasOwnProperty('compositSignal')) {
                return true;
            } else {
                const coolingKeys = ['coolingstage1', 'coolingstage2', 'coolingstage3', 'coolingstage4', 'coolingstage5', 'satcoolingstage5', 'satcoolingstage1', 'satcoolingstage2', 'satcoolingstage3', 'satcoolingstage4', 'satcoolingstage5', 'satbasedcoolingcontrol', 'loadbasedcoolingcontrol',
                    'connectmodulecoolingstage1', 'connectmodulecoolingstage2', 'connectmodulecoolingstage3', 'connectmodulecoolingstage4', 'connectmodulecoolingstage5', 'connectmoduleloadbasedcoolingcontrol'];
                const isCooling = this.checkStagesPresent(this.systemReconfigurationSettings, coolingKeys);
                return isCooling;
            }
        }

        if (!this.systemReconfigurationSettings  || this.equipGraphicSystemProfile == 'vavExternalAHUController' || this.equipGraphicSystemProfile == 'dabExternalAHUController') return true;
        const keys = ["relay-1", "relay-2", "relay-3", "relay-4", "relay-5", "relay-6", "analog-out1", "analog-out3", "analog-out4","daikin-heating","daikin-cooling"];
        let isCooling = false;
        for (let i = 0; i < keys.length; i++) {
            if (this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val)) {
                if(keys[i] == 'analog-out4' && (this.systemReconfigProfileName == 'DAB Advanced Hybrid AHU' || this.systemReconfigProfileName == 'VAV Advanced Hybrid AHU') && this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val) == 1) {
                    return true;
                } else if(this.ccuSetting['dcwbEnabled'] && this.ccuSetting['dcwbEnabled'].val == 1) {
                    if(keys[i]=="analog-out4") {
                        return true;
                    }
                }else if (keys[i] == "analog-out1" || keys[i] == "daikin-cooling" ) {
                    return true;
                }
                else if (this.systemReconfigurationSettings[keys[i] + "Mapping"] && parseInt(this.systemReconfigurationSettings[keys[i] + "Mapping"].val) < 5) {
                    return true;
                }
            }
        }
        return isCooling;
    }

    hasHeating() {


        // For VvaAdvancedHybridAhu based on the configuration settings we need to set the condition mode 
        // We no need to check the any analogues only with the relay selection weather the point is present or not need to check and display the mode
        //here in the  array heatingKeys containing the names of the keys that represent heating stages.
        if (this.equipGraphicSystemProfile === "vavAdvancedHybridAhuV2") {
            if (this.ccuSetting.hasOwnProperty('compositSignal')) {
                return true;
            } else {
                const heatingKeys = ['heatingstage1', 'heatingstage2', 'heatingstage3', 'heatingstage4', 'heatingstage5','satheatingstage1', 'satheatingstage2', 'satheatingstage3', 'satheatingstage4', 'satheatingstage5','satbasedheatingcontrol','loadbasedheatingcontrol',
                'connectmoduleheatingstage1','connectmoduleheatingstage2','connectmoduleheatingstage3','connectmoduleheatingstage4','connectmoduleheatingstage5','connectmoduleloadbasedheatingcontrol'];
                const isHeating = this.checkStagesPresent(this.systemReconfigurationSettings, heatingKeys);
                return isHeating;
            }
        }


        if (!this.systemReconfigurationSettings  || this.equipGraphicSystemProfile == 'vavExternalAHUController' || this.equipGraphicSystemProfile == 'dabExternalAHUController') return true;
        let keys = ["relay-1", "relay-2", "relay-3", "relay-4", "relay-5", "relay-6", "analog-out1", "analog-out3","analog-out4","daikin-heating","daikin-cooling"];
        if (this.systemReconfigProfileName != "VAV Fully Modulating AHU" && this.systemReconfigProfileName != "DAB Fully Modulating AHU") keys.push('relay-7');
        let isHeating = false;
        for (let i = 0; i < keys.length; i++) {
            if (this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val)) {
                if(keys[i] == 'analog-out4' && (this.systemReconfigProfileName == 'DAB Advanced Hybrid AHU' || this.systemReconfigProfileName == 'VAV Advanced Hybrid AHU') && this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val) == 1) {
                    return true;
                } else if (keys[i] == "analog-out3" && this.systemReconfigurationSettings[keys[i]].val || keys[i] == "daikin-heating" && this.systemReconfigurationSettings[keys[i]].val) {
                    return true;
                }
                else if (this.systemReconfigurationSettings[keys[i] + "Mapping"] && parseInt(this.systemReconfigurationSettings[keys[i] + "Mapping"].val) >= 5 && parseInt(this.systemReconfigurationSettings[keys[i] + "Mapping"].val) < 10) {
                    return true;
                }

            }
        }
        return isHeating;
    }

    isShowCMmode(mode) {
        if (mode == 1) {
            return this.hasCooling() && this.hasHeating();
        } else if (mode == 2) {
            return this.hasCooling()
        } else if (mode == 3) {
            return this.hasHeating();
        }
        return true;
    }

        //checkStagesPresent that takes two parameters:
      // 1. systemReconfigurationSettings: an object representing the system user intent data 
      // 2. requiredKeys: an array containing the names of the keys to be checked for existence It might be Heating Keys or cooling Keys
      checkStagesPresent(systemReconfigurationSettings, requiredKeys) {
        for (const key of requiredKeys) {
            if (systemReconfigurationSettings?.hasOwnProperty(key)) {
                // If the key is found in the systemReconfigurationSettings object, return true it might be Cooling key or heating key
                return true;
            }
        }
        // If none of the required keys are found in the systemReconfigurationSettings object, return false  it might be isCooling key or isHeating
        return false;
    }

    profileByID(index, profile) {
        return profile.profile._id;
    }

    floorsId(index, floor) {
        return floor._id;
    }

    autoRefreshChange(val) {
       this.handleAutoRefresh(val);
    }

    handleAutoRefresh(val) {
        // Refresh now
        if (this.autoRefreshSelection == val && val == 1) {
            this.autoRefreshSelection = -val;
            this.refresh();
        } else if (val == 1) {
            this.autoRefreshSelection = val;
            this.refresh();
        } else if (val !== 6) {
            this.clearAutoRefresh();
            this.setAutoRefresh(val);
            this.autoRefreshSelection = val;
        } else {
            // If the value is 6
            this.clearAutoRefresh();
        }
    }

    clearAutoRefresh() {
        if (this.autoRefreshSub) {
            this.autoRefreshSub.unsubscribe();
        }
    }

    setAutoRefresh(timer) {
        this.autoRefreshSub = interval(timer * 1000).subscribe(x => {
            this.refresh();
        });
    }

    refresh() {
        this.autoRefreshSubject.next(true);
    }

    /**************** Custom Heatmap Viz Code  ***********************************/

    refToCheck;
    mappedDashboardId;

    customDateRange;
    makeLocalWidgetsDataCall = false;

    getNodes(arr, tags) {
        if (!arr || (Array.isArray(arr) && !arr.length)) {
            return [];
        }
        return arr.filter((_filterItem) => _filterItem)
            .filter((_item) => {
                const found = (_item.markers && Array.isArray(_item.markers)) ? tags.every(elem => _item.markers.indexOf(elem) > -1) : false;
                if (found) {
                    return _item;
                }
            })
    }


    getGlobalDashboard(siteRef) {
        this.customHeatmapService.getGlobalDashboard(siteRef).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(
            resp => this.processGlobalDashboardResponse(resp),
            err => {
                console.log(err);
            }
        );
    }


    processGlobalDashboardResponse(response) {
        if (!response) {
            this.globalDashboardObject = undefined;
            this.heatmapConnect.setGlobalDashboardStatus({ showDFT: false });

            this.showEditGlobalViz = false;
            this.showAddButton = true;

            this.editAnalytics = true;

        } else {
            this.globalDashboardObject = response;

            const siteDashboardId = this.globalDashboardObject.entityDashboards[this.refs.siteRef];
              // If there is no siteDashboardId - then there is no point of editing.
            if (siteDashboardId) {
                this.getEntityDashboardDetails(siteDashboardId, true).subscribe();
            } else {
                this.showEditGlobalViz = false;
            }

            this.editAnalytics = !this.globalDashboardObject.isPublished;
            this.showAddButton = !this.globalDashboardObject.isPublished;

            this.heatmapConnect.setGlobalDashboardStatus(
                { published: this.globalDashboardObject.isPublished, updateDateTime: this.globalDashboardObject.updatedDateTime, showDFT: true }
            );
        }
    }


    fetchNewDataForCustomWidgets(dateRange) {
        this.customDateRange = dateRange;
    }

    chartDataReceivedView(data) {
        if (!data) return;
        if (this.ccuOrZone.actionUsed == 'click') {
            const tz: string = this.siteService.getIANATimeZone(this.siteTz);
            const exportData = {};
            const widget = data.widgetId;
            data.charts.forEach(chart => {
                const points = chart?.points;
                const chartId = chart?.chartId;
                points.forEach(point => {
                    const keyName = point.pointLabel in exportData ? point.pointLabel + '-' + chartId : point.pointLabel;
                    exportData[keyName] = point.timePeriods.map(timePeriod => {
                        return { ts: timePeriod.dateTime ? dayjs(timePeriod.dateTime.split(' UTC')[0]).tz(tz).format('YYYY-MM-DDTHH:mm:ss') : '', val: timePeriod.value };
                    });
                });
            });
            this.customWidgetDataForExport[widget.widgetId] = { ...exportData };
        }
    }

    chartDataReceived(event) {
        const data = event?.event;
        const widget = event.widgetData;
        if (!data) { return; }
        if (this.ccuOrZone.actionUsed == 'click') {
            const tz: string = this.siteService.getIANATimeZone(this.siteTz);
            const exportData = {};
            data.charts.forEach(chart => {
                const points = chart?.points;
                const chartId = chart?.chartId;
                points.forEach(point => {
                    const keyName = point.pointLabel in exportData ? point.pointLabel + '-' + chartId : point.pointLabel;
                    exportData[keyName] = point.timePeriods.map(timePeriod => {
                        return { ts: timePeriod.dateTime ? dayjs(timePeriod.dateTime.split(' UTC')[0]).tz(tz).format('YYYY-MM-DDTHH:mm:ss') : '', val: timePeriod.value };
                    });
                });
            });
            this.customWidgetDataForExport[widget.widgetId] = exportData;
        }
    }

    
      assimilateEntityCustomWidgets() {
        this.makeLocalWidgetsDataCall = false;
        this.hiddenAccordions = 0;

        // Maintaining the previous states
        this.prevState_editGloablViz = this.showEditGlobalViz;
        this.prevState_showAddButton = this.showAddButton;

        this.customWidgets = {
            local: [],
            global: []
        };

        this.customDateRange = undefined;
        this.mappedDashboardId = null;

        // removing any selected indices and anyVisBuilders should not be comping up
        this.clearBuilders();

        const entityRef = this.ccuOrZone.type === 'zone' ? this.refs['roomRef'] :
            this.buildings.ccus.filter(ccu => ccu.referenceIDs.ahu === this.refs.ahuRef)[0]._id;
        this.refToCheck = entityRef;

        let allAvailablePointIds = [];
        const mappedEquips = [];

        if (this.ccuOrZone.type === 'ccu') {
            const ccuSelected = this.buildings.ccus.filter(ccu => ccu.referenceIDs.ahu === this.refs.ahuRef)[0];
            const idsRelatedEntities = [ccuSelected.referenceIDs.ahu, ccuSelected.referenceIDs.gateway, ccuSelected.referenceIDs.equip, ccuSelected.referenceIDs.oaoRef].filter(x=>x);

            const equipPointIds = this.buildings.equips.map(equip => {
                // A modbus equip
                if ((equip.markers.includes('modbus') || equip.markers.includes('bypassDamper') || equip.markers.includes('connectModule') || equip.markers.includes('oao')) && (!equip.referenceIDs.room || equip.referenceIDs.room == "SYSTEM")) {
                    if (idsRelatedEntities.includes(equip.referenceIDs.gateway) || idsRelatedEntities.includes(equip.referenceIDs.ahu)) {
                        mappedEquips.push(...[equip._id, (equip.referenceIDs.gateway?equip.referenceIDs.gateway:equip.referenceIDs.ahu)]); // Mapped Equips is used for checking in custom queries
                        const allEntities = equip.entities;
                        return allEntities.map(entity => {
                            if (entity.type === 'point') {
                                return entity._id;
                            }
                        });
                    }
                } else if (idsRelatedEntities.includes(equip._id)) {
                    mappedEquips.push(equip._id);
                    const allEntities = equip.entities;
                    return allEntities.map(entity => {
                        if (entity.type === 'point') {
                            return entity._id;
                        }
                    });
                }
            }).flat();

            const devicePointIds = this.allDevices.map(device => {
                if (idsRelatedEntities.includes(device.referenceIDs.equip)) {
                    mappedEquips.push(...[device._id, device.referenceIDs.equip]); // Mapped Equips is used for checking in Custom Query
                    const allEntities = device.entities;
                    return allEntities.map(entity => {
                        if (entity.type === 'point') {
                            return entity._id;
                        }
                    });
                }
            }).flat();

            allAvailablePointIds = [...equipPointIds, ...devicePointIds];

        } else if (this.ccuOrZone.type === 'zone') {
            const idsRelatedEntities = [this.refs['roomRef']];

            const equipPointIds = this.allEquips.map(equip => {
                const equipReferenceRoomId = equip['referenceIDs']['room'];
                if (idsRelatedEntities.includes(equipReferenceRoomId)) {
                    mappedEquips.push(equip._id);
                    const allEntities = equip.entities;
                    return allEntities.map(entity => {
                        if (entity.type === 'point') {
                            return entity._id;
                        }
                    });
                }
            }).flat();

            const nodePointIds = this.allNodes.map(node => {
                if (idsRelatedEntities.includes(node.referenceIDs.room)) {
                    mappedEquips.push(...[node._id, node.referenceIDs.room, node.referenceIDs.equip]); // Mapped Equips is used for checking in Custom Query
                    const allEntities = node.entities;
                    return allEntities.map(entity => {
                        if (entity.type === 'point') {
                            return entity._id;
                        }
                    });
                }
            }).flat();

            let roomPointIds = []
            if(this.roomObj?.length > 0) {
                roomPointIds = this.roomObj[0]?.entities?.map(entity => { if(entity.type === 'point') return entity._id;});
            }
            allAvailablePointIds = [...equipPointIds, ...nodePointIds, ...roomPointIds];
        }

        // Find the dashboard related to this entity, but at this point maybe even the globalDashboard would not be present.
        const attachedCustomEntityDashboard = this.globalDashboardObject?.entityDashboards[entityRef];
        if (attachedCustomEntityDashboard) {
            this.mappedDashboardId = attachedCustomEntityDashboard;
            this.getEntityDashboardDetails(attachedCustomEntityDashboard, false).subscribe(
                _ => {
                    const localWidgets = this.sortWidgets(this.dashboardWidgetsMap[attachedCustomEntityDashboard] || []);
                    this.customWidgets['local'] = localWidgets.map(localWidget => {
                        localWidget.siteName = this.refs.siteDetails?.dis;
                        return localWidget;
                    });
                    this.makeLocalWidgetsDataCall = true;
                }
            );
        } else {
            this.makeLocalWidgetsDataCall = true;
        }

        const siteRef = this.refs.siteRef;
        const attachedSiteRefDashboard = this.globalDashboardObject?.entityDashboards[siteRef];

        // If there is a global level dashboard, then it would have the widgets already put in the map, by processing globalDashboardResponse.
        if (attachedSiteRefDashboard) {
            if (this.dashboardWidgetsMap?.[attachedSiteRefDashboard]?.length) {
                const globalWidgets = this.dashboardWidgetsMap[attachedSiteRefDashboard]?.filter(globalWidget => {
                    if (globalWidget.level === 'builder') {
                        const widgetSelectionsObj = globalWidget.siteSelections;
                        const entityArrayToCheck = this.ccuOrZone.type === 'zone' ? (widgetSelectionsObj.rooms || []) : (widgetSelectionsObj.devices || []);
                        return entityArrayToCheck.includes(entityRef);
                    } else if (globalWidget.level === 'custom') {
                        let matches = false;
                        for (const equipId of mappedEquips) {
                            if (globalWidget.filter.includes(`@${equipId}`)) {
                                matches = true;
                            } else if (this.ccuOrZone.type === 'zone' || this.ccuOrZone.type === 'ccu') {
                                // Then find if there are applicable points
                                const pointsSelected = globalWidget.siteSelections.points;
                                const pointsMatched = pointsSelected.filter(point => allAvailablePointIds.includes(point.pointRef)).length;
                                if (pointsMatched > 0) {
                                    matches = true;
                                }
                            }
                        }
                        return matches;
                    }
                }).map(globalWidget => {
                    globalWidget.applicablePointIds = allAvailablePointIds;
                    globalWidget.siteName = this.refs.siteDetails?.dis;
                    return globalWidget;
                });

                this.customWidgets['global'] = this.sortWidgets(globalWidgets || []);
            }
        }

        if (this.ccuOrZone.actionUsed === 'click') {
            this.showEditGlobalViz = false;
            this.showAddButton = false;
        }
    }


    sortWidgets(widgetList) {
        return widgetList.sort((widget1, widget2) => widget1.y - widget2.y);
    }


    clearHeatmapWidgets() {
        this.customWidgets.local = [];
        this.customWidgets.global = [];

        this.mappedDashboardId = null;

        // Restoring the previous states
        this.showEditGlobalViz = this.prevState_editGloablViz;
        this.showAddButton = this.prevState_showAddButton;
    }

    isOAOOnline() {
        if(!(this.ccuSetting?.oaoLastUpdated?.hasOwnProperty('val'))) return;
        this.ccuSetting['oaoLastUpdated']['val'] = dayjs.utc(this.ccuSetting?.oaoLastUpdated?.time).local();
        let timeDiffMinutes = Math.abs(this.ccuSetting?.oaoLastUpdated?.val.diff(dayjs(), 'minutes'))
        return timeDiffMinutes < 16;
    }
    
    isOnline(key) {
        if(!(this.ccuSetting?.[key]?.lastUpdated?.hasOwnProperty('val'))) return;
        this.ccuSetting[key]['lastUpdated']['val'] = dayjs.utc(this.ccuSetting[key].lastUpdated?.time).local();
        let timeDiffMinutes = Math.abs(this.ccuSetting[key]?.lastUpdated?.val.diff(dayjs(), 'minutes'))
        return timeDiffMinutes < 16;
    }

    dateFormat(val) {
        return DateUtil.dateFormat(val,this.siteService.getIANATimeZone(this.siteTz));
    }
    isProfileEMR(equipName) {
        return equipName && (equipName.profileType && equipName.profileType && equipName.profileType == 'emr');
    }
    heartBeathandle() {
        if(!this.zoneSettings) return;
        if (this.profiles && (this.profiles.length > 1 || this.isProfileEMR(this.profiles[this.profiles.length-1]))) {
          this.profiles.forEach(profile => {
            if(this.zoneSettings[profile.profile.name]?.lastUpdated?.time && this.zoneSettings[profile.profile.name]?.lastUpdated?.hasOwnProperty('val')) {
              this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name].lastUpdated.time)?.local();
            } 
          });
        } else if (this.zoneSettings?.lastUpdated?.time && this.zoneSettings?.lastUpdated?.hasOwnProperty('val')) {
            this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings?.lastUpdated?.time).local();
        }
        
      }

    isModuleOnline(profile) {
        if (this.profiles.length > 1 || this.isProfileEMR(this.profiles[this.profiles.length-1])) {
          if(!(this.zoneSettings?.[profile.profile.name]?.lastUpdated?.hasOwnProperty('val'))) return;
            this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name]?.lastUpdated?.time).local();
            let timeDiffMinutes = Math.abs(this.zoneSettings[profile.profile.name]?.lastUpdated?.val?.diff(dayjs(), 'minutes'))
            return timeDiffMinutes < 16;
        } else {
            if(!(this.zoneSettings?.lastUpdated?.hasOwnProperty('val'))) return;
            this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings.lastUpdated?.time).local();
            let timeDiffMinutes = Math.abs(this.zoneSettings?.lastUpdated?.val?.diff(dayjs(), 'minutes'))
            return timeDiffMinutes < 16;
        }
    }

    @HostListener('window: scroll', ['$event'])
    onWindowScroll($event) {
        this.isHeaderFixed = (window.scrollY > 40);
        let bottomReached = window.scrollY + window.innerHeight >= document.body.scrollHeight - 150;
        if(bottomReached && !this.modalPopupOpened) {
            this.loadMoreFloors()
        }
       
        if(this.summaryTableOpen){
            this.hideCCUHeader = this.calculateCCUHeaderVisibility('accordian_summary_table', this.summaryTableOpen);
        } else if(this.noteUploadOpen){
            this.hideCCUHeader = this.calculateCCUHeaderVisibility('accordian_notes_upload', this.noteUploadOpen);
        }
    }

    calculateCCUHeaderVisibility(elementId, isOpen) {
        if (isOpen && ((document.getElementById(elementId)?.getBoundingClientRect()?.top - document.getElementsByClassName('ccu-list-ctnr')[0]?.getBoundingClientRect()?.top) <= 37)) {
            return true;
        } else {
            return false;
        }
    }

    userPrefChange(event) {
        this.userPrefData['ccuOrder'] = event || [];
        this.setCCUOrder();
        this.setCcuColors();
        this.handleAutoRefresh(1);
    }

    setCCUOrder() {
        ArrayUtil.mapOrder(this.buildings.floorCcu,this.userPrefData.ccuOrder,'_id');
    }

    accordionHiddenEvt(event) {
        this.hiddenAccordions += 1;
    }

    /**************** Custom Heatmap Viz Code  End***********************************/

    async filterChangeHandle(event, fromHeaderComponent: boolean = true) {
        if (!event) event = { filterId: 'default', defaultInd: true };
        let filterWithPoints = event;
        if (!fromHeaderComponent) {
            filterWithPoints = ObjectUtil.deepClone(this.filterSrvc.getFilterFromList(event) || event);
        } else {
            filterWithPoints = event;
        }
        await this.setFilterPoints(filterWithPoints);
        if (!this.initialFilter) {
            await this.setUserPreferencesData(JSON.stringify(event), ["lastSelectedFilter"]);
        }
        if (this.initialFilter) {
            this.initialFilter = !this.initialFilter;
        }
    }

    async setFilterPoints(filterWithPoints) {
        if(filterWithPoints?.pointDefinitions?.length) {
            filterWithPoints = await this.heatmapService.getMatchedPointsFromSelectedFilter(filterWithPoints,this.refs.siteRef);
        }
        this.selectedFilter = filterWithPoints;
        this.heatmapService.selectedFilter = ObjectUtil.deepClone(this.selectedFilter);
    }

    defaultFilterChangeHandle(event) {
        if (!event) event = { filterId: 'default', defaultInd: true };
        this.defaultFilter = event;
        this.setUserPreferencesData(JSON.stringify(event), ["defaultFilter"]);
    }

    
    getScheduleInfoForCCU(selectedAhuRef) {
        const mappedZones =
        this.ccusWithPairedZones.find((ccusWithPairedZone: any) => ccusWithPairedZone.ahuRef === selectedAhuRef)?.pairedZoneList || [];
        let zoneList = this.buildings.zones.filter((option: any) => mappedZones.indexOf(option._id) !== -1);
        zoneList = this.getZonesSchedulesByCCURef(zoneList);
        // Ignore the zones have 'sense', emr, pid, modbus.
        zoneList = zoneList.map(zone => {
            return this.checkProfileTypeOfZones(zone);
        }).filter(r => r);
        // Get Zone Point Ids for all the zones.
        this.getAllZonePointIds(zoneList);
    }

    checkProfileTypeOfZones(zone) {
        const profile = this.deviceHelper.getZoneProfile([zone]);
        const includeProfiles = ['vav', 'dab', 'ti', 'dualDuct', 'series', 'parallel', 'otn', 'bpos','chilledBeam'];
        const found = profile.find(item => includeProfiles.includes(item.profileType));
        if (found) {
            return zone;
        }
    }

		getSchIdRefs(zoneId) {
			let schId = [];
			this.buildings.floors.forEach(element => {
				const found = element.entities.find(r => r._id == zoneId);
				if (found && Object.keys(found) && Object.keys(found).length) {
					schId = [found['referenceIDs']['schedule']];
				}	
			});
			return schId;
		}

    getZonesSchedulesByCCURef(zoneList) {
        return zoneList.map((zone) => {
            zone['scheduleId'] = this.getSchIdRefs(zone._id);
			zone['zoneSpecialSchIds'] = this.helperService.getPointIdbyTags(this.buildings, ['zone', 'schedule', 'range', 'special'], zone._id)
            zone['scheduleType'] =  this.helperService.getPointIdbyTags(this.buildings, ['zone', 'scheduleType'], zone._id);
            zone['zoneVacationIds'] = this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'vacation'], zone._id);
            return zone;
        });
    }

	getAllZonePointIds(zoneList) {
		zoneList.forEach(zone => {
			this.getZoneSettings('zoneSchedule', zone['scheduleId'], 'schedule', zone.name);
			this.getZoneSettings('zoneSpecialSchedule', zone['zoneSpecialSchIds'], 'vacation', zone.name);
			this.getZoneSettings('scheduleType', zone['scheduleType'], 'write', zone.name);
			this.getZoneSettings('zoneVacations', zone['zoneVacationIds'], 'vacation', zone.name);
		});
        this.getZoneSettings('buildingSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'days']) , null);
        this.getZoneSettings('buildingOccupancy', this.helperService.getPointIdbyTags(this.buildings.buildingOccupancy, ['building', 'occupancy','days']) , null);
		this.getZoneSettings('buildingSpecialSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'special', 'schedule', 'range'], null), 'vacation');
		this.getZoneSettings('buildingVacations', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'range', 'vacation'], null), 'vacation');
		this.getCCuSettingData();
	}

    UpdateBuildingsDataWhenNamedSchApply() {
			this.siteService.getEntitiesInBuilding(this.refs.siteRef).pipe(
					map(this.helperService.listEntities),
					map(this.helperService.createEntityStructure),
			).subscribe(
				(response: any) => {
						this.buildingMaster = response;
						this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                        this.buildings['devices'] = this.getNodes(response, ['device']);
                        this.buildings['equips'] = this.getNodes(response, ['equip']);

						this.buildings["floors"] = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
						this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule') || [];
                        this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                        this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy') || [];
						if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click') {
							const floorData = this.buildings["floors"].map(floor => floor.entities.filter(room => room.name === this.roomObj[0].name));
							floorData.forEach(floor => {
								this.updateRoomObj(floor);
							});
						}
				});

    }

		updateRoomObj(floorData) {
			if (floorData && floorData.length > 0) {
				let equipRefence;
				this.roomObj = ObjectUtil.deepClone(floorData);
				equipRefence = floorData.map(equip => equip.entities.filter(entity => entity.entities.length > 0))[0][0];
				this.refs.roomRef = equipRefence == undefined ? undefined : equipRefence.referenceIDs.room;
				this.refs.equipRef = equipRefence == undefined ? undefined : equipRefence._id;
				if (equipRefence == undefined) {
					this.refs.ahuRef = undefined;
				} else {
					if (equipRefence.referenceIDs.ahu == undefined) {
						this.refs.ahuRef = equipRefence.referenceIDs.gateway;
					} else {
						this.refs.ahuRef = equipRefence.referenceIDs.ahu;
					}
				}
			}
		}

        async setUserPreferencesData(event, data) {
            try {
                await this.getUserPreferencesData(false);
            } catch(e) {
                
            }
            if (data.length == 1) {
              this.userPreferenceData.heatmapPreferences[data] = event;
            } else {
              this.userPreferenceData.heatmapPreferences[data[0]][data[1]] = event;
            }

            Object.keys(this.userPreferenceData.heatmapPreferences).forEach((preferenceKey) => {
                if (preferenceKey.includes(",")) {
                    delete this.userPreferenceData.heatmapPreferences[preferenceKey];
                }
            });

           this.userPreferenceApi();
          }
          userPreferenceApi(){
            this.userPref
            .setUserPreferenceData(this.userPreferenceData)
            .subscribe((res) => {
              this.userPreferenceData = res;
              this.getUserPreferencesHelper(res);
            });
          }

          async getUserPreferencesData(firstCall:boolean) {
            const self = this;
            return new Promise<void>((resolve, reject) => {
                this.userPref.getUserPreferenceData().subscribe({
                  next: (res) => {
                    this.userPreferenceData = res;
                        if (firstCall) {
                            const heatmapPreferences = res["heatmapPreferences"];
                            const lastSelectedFilter = heatmapPreferences["lastSelectedFilter"];
                            const defaultFilter = heatmapPreferences["defaultFilter"];
                            this.filterChangeHandle(ObjectUtil.parseJSON(lastSelectedFilter), false);
                            const parsedDefaultFilter = defaultFilter ? ObjectUtil.parseJSON(defaultFilter) : {filterId:'default', defaultInd:true};
                            if (self.filterSrvc.getFilterFromList(parsedDefaultFilter)) {
                                this.defaultFilter = parsedDefaultFilter;
                            }
                        }
                    this.getUserPreferencesHelper(res);
                  },
                  error: (err) => {
                    this.filterChangeHandle(null, false);
                    this.defaultFilter = null;
                    reject(err);
                  },
                  complete: () => {
                    resolve();
                  }
                });
              });
              
          }

          getUserPreferencesHelper(res){
            this.zonePreDefinedWidgetsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["preDefinedWidgets"];
            this.zoneAlertsAccordionOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["alertsAccordion"]
            this.zoneCustomWidgetsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["customWidgets"];
            this.zoneCustomGlobalWidgetsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["customGlobalWidgets"];
            this.zoneSettingsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["zoneSettings"];
            this.CCUPreDefinedWidgetsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["preDefinedWidgets"];
            this.CCUAlertsAccordionOpenState =  res["heatmapPreferences"]['CCUHeatmapPreferences']["alertsAccordions"];
            this.CCUcustomWidgetsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["customWidgets"];
            this.CCUcustomGlobalWidgetsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["customGlobalWidgets"];
            this.CCUSettingsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["CCUSettings"];   
            this.zoneTunersOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["zoneTuners"]; 
            this.systemTunersOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["systemTuners"];
            this.systemLevelEquipGraphics = res["heatmapPreferences"]['CCUHeatmapPreferences']['systemEquipGraphics'];
            this.CCUreconfigurationOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["reconfiguration"];
            this.remoteAccessOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["remoteAccess"];
            this.zoneReconfigurationOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["zoneReconfiguration"];   
            this.terminalEquipGraphics = res["heatmapPreferences"]['zoneHeatmapPreferences']['accordionSectionSelction'];    
            this.ccuPointSummaryOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["pointSummary"];
            this.zonePointSummaryOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["pointSummary"];
            this.terminalEquipGraphics = res["heatmapPreferences"]['zoneHeatmapPreferences']['accordionSectionSelction'];
            this.showlastUpdatedToggleEnabled = res["heatmapPreferences"]['showLastUpdated'] || false;
            this.ccuAnalyticsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["analytics"];
            this.zoneAnalyticsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["analytics"];
        }
        setUserPreferencesDataForCustomWidget(data){
            this.userPreferenceData.heatmapPreferences[data.array[0]][data.array[1]] = data.value;
            this.userPreferenceApi();
        }
        openSchedulerModal(schedule) {
            document.body.style.overflow = 'hidden';
            this.openedSchedulerEditModal = true;
            this.schedulerEditDialogRef = this.dialog.open(SchedulerEditModalComponent, {
                panelClass: 'scheduler-occupancy-edit-modal',
                maxHeight: '1000px',
                maxWidth: '1100px',
                autoFocus: false,
                data: {
                    zoneName: this.refs.zoneName,
                    scheduleId: schedule.id,
                    orgName: this.refs?.siteDetails?.organization,
                    scheduleInfo: schedule.val,
                    buildingLimits: this.buildingLimit,
                    defaultLimits: this.defaultLimits,
                    sliderDisplayFlag : true,
                    roomRef: this.roomObj[0]?._id,
                    ccuRef:this.roomObj[0]?.referenceIDs?.ccu,
                    fromZonePage: true,
                    buildingScheduleInfo: this.zoneSettings?.buildingOccupancy?.val,
                    refId : this.refs.roomRef,
                    siteId: this.refs.siteRef,
                    zoneSpecialSchedules: this.zoneSettings?.zoneSpecialSchedule,
                    unoccupiedSetback: this.zoneSettings?.zoneLevelunoccupiedZoneSetback?.val,
                    followBuilding: this.zoneSettings['zoneSchedule']?.followBuilding,
                    buildingToZoneDifferential: this.zoneSettings?.buildingToZoneDifferential?.val ? this.userTempPreference == this.unitService.temperatureUnitListArray[0]? this.unitService.convertingDeadBandValue(this.zoneSettings.buildingToZoneDifferential.val) : this.zoneSettings.buildingToZoneDifferential.val : 3,
                    globalizationUnit: this.userTempPreference,
                    buildingHeatingUserLimitMax: this.zoneSettings?.buildingHeatingUserLimitMax?.val,
                    buildingCoolingUserLimitMin: this.zoneSettings?.buildingCoolingUserLimitMin?.val,
                    buildingHeatingUserLimitMin: this.zoneSettings?.buildingHeatingUserLimitMin?.val,
                    buildingCoolingUserLimitMax: this.zoneSettings?.buildingCoolingUserLimitMax?.val,
                    buildingHeatingDeadband: this.zoneSettings?.buildingHeatingDeadband?.val,
                    buildingCoolingDeadband: this.zoneSettings?.buildingCoolingDeadband?.val,
                    buildingUnoccupiedZoneSetback: this.zoneSettings?.buildingUnoccupiedZoneSetback?.val,
                    buildingLimitsMinFahrenheit: this.zoneSettings?.buildingLimitMin?.val,
                    buildingLimitsMaxFahrenheit: this.zoneSettings.buildingLimitMax?.val,
                    buildingToZoneDifferentialFahrenheit: this.zoneSettings?.buildingToZoneDifferential?.val ? this.zoneSettings.buildingToZoneDifferential.val: 3,
                    siteTz: this.siteTz
                }
            });
            this.schedulerEditDialogRef.afterClosed().subscribe(_onClose => {
                document.body.style.overflow = null;
                this.openedSchedulerEditModal = false;
            });
        }

        summaryTableOpened(event){
            this.summaryTableOpen =  event
        }

        notedUploadOpened(event){ 
            this.noteUploadOpen = event;
        }      

        get isValidCertificationLevel(): boolean {
            return this.authService.certificationLevel == 'L0' || this.authService.certificationLevel == 'L1';
        }

        get isSummaryTableCheckboxDisabled(): boolean {
            return this.authService.certificationLevel != 'L0';
        }

        updateLastedValues(res) {
            if(!res) return;
            if (this.profiles && this.profiles.length > 1 || this.isProfileEMR(this.profiles[this.profiles.length - 1])) {
                this.profiles.forEach(profile => {
                    if (res[profile.profile.name]?.lastUpdated?.time && res[profile.profile.name]?.lastUpdated?.hasOwnProperty('val')) {
                        this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(res[profile.profile.name].lastUpdated.time).local();
                    }
                });
            } else if(res?.lastUpdated?.time && res?.lastUpdated?.hasOwnProperty('val')) {
                    this.zoneSettings['lastUpdated']['val'] = dayjs.utc(res.lastUpdated.time).local();
            }
        }

        pollGraphData() {
            clearInterval(this.timeSeriesinteval);
            this.timeSeriesinteval = setInterval(() => {
                const tz: string = this.siteService.getIANATimeZone(this.siteTz);
                const offset = dayjs().tz(tz).utcOffset();
                const centralOffset = dayjs().utcOffset();
                let endDate = this.heatMapToolTipService.selectedDateRange.endDate
                 // Check if end date is before today's date
                 if (dayjs(new Date(endDate)).endOf('day').isBefore(dayjs(Date.now()))) {
                    this.selected.endDate = dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
                    return;
                } else if ((new Date(endDate).toDateString()) == (new Date(Date.now()).toDateString())) {
                    const day  = dayjs(Date.now()).tz(tz)
                    const currentDay = this.checkDateconversion(day.year(),day.month(),day.date(),day.hour(),day.minute(),day.second(),day.millisecond())
                    this.selected.endDate = currentDay == new Date(endDate).toDateString() ? dayjs(Date.now()).tz(tz) :  dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
                } else if (dayjs(new Date(endDate)).endOf('day').isAfter(dayjs(Date.now()))) {
                    this.selected.endDate = dayjs(Date.now()).tz(tz);
                } else {
                    this.selected.endDate = dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
                }
                  // Check if end date is before today's date
                  if (dayjs(new Date(this.selected.endDate)).isBefore(dayjs(new Date(this.selected.startDate)))) {
                    this.selected.endDate = dayjs(Date.now()).tz(tz);
                }
                  this.heatMapToolTipService.selectedDateRange = this.selected;
      
                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
      
                // Also make the calls for getting custom widgets data
                this.customHeatmapService.setRefreshSubject();
            }, 60000);
        }

      checkForHeatingOrCooling(current,zoneDetails) {
        if (zoneDetails['singleDual']?.val == "1" && (current.hasOwnProperty('heating') && current.hasOwnProperty('desired'))) {
            return 'cooling';
        } else if (zoneDetails['singleDual']?.val == "2" && (current.hasOwnProperty('cooling') && current.hasOwnProperty('desired'))) {
            return 'heating';
        }

        if (zoneDetails['singleDual']?.val == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('heating'))) {
            return 'cooling';
        } else if (zoneDetails['singleDual']?.val == "2" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('cooling'))) {
            return 'heating'
        }
    }

    filterWidgetTempValues(graphIndexCooling,graphIndexHeating,res) {
        if (graphIndexCooling.length) {
            let coolArray = graphIndexCooling.map((i) => i.id);
            for (let i = 0; i <= graphIndexCooling.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return coolArray.indexOf(item.id) === -1;
                });
            }
        } else if (graphIndexHeating.length) {
            let heatArray = graphIndexHeating.map((i) => i.id);
            for (let i = 0; i <= graphIndexHeating.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return heatArray.indexOf(item.id) === -1;
                });
            }

        }
        return res;
    }

    zoneSingleDualGraph(res) {
        const graphIndexCooling = [];
        const graphIndexHeating = [];
        res['rows'].find((current) => {
            if (this.hoverZoneData?.singleDual == "1" && (current.hasOwnProperty('heating') && current.hasOwnProperty('desired'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2" && (current.hasOwnProperty('cooling') && current.hasOwnProperty('desired'))) {
                graphIndexHeating.push(current);
            }

            if (this.hoverZoneData?.singleDual  == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('heating'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2"  && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('cooling'))) {
                graphIndexHeating.push(current);
            }

            if (this.hoverZoneData?.singleDual  == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('heating'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2"  && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('cooling'))) {
                graphIndexHeating.push(current);
            }
        });


        if (graphIndexCooling.length) {
            let coolArray = graphIndexCooling.map((i) => i.id);
            for (let i = 0; i <= graphIndexCooling.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return coolArray.indexOf(item.id) === -1;
                });
            }
        } else if (graphIndexHeating.length) {
            let heatArray = graphIndexHeating.map((i) => i.id);
            for (let i = 0; i <= graphIndexHeating.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return heatArray.indexOf(item.id) === -1;
                });
            }

        }
        return res['rows'];
    }

	setOTAandCCUSettings() {
		if (this.ccuSetting?.desiredComfortIndex?.hasOwnProperty('val')) {
			this.desiredCIValue = this.ccuSetting.desiredComfortIndex?.val;
			this.desiredCISliderValue = this.rangeSliderHack[parseInt(this.desiredCIValue)];
		}
		if (this.ccuSetting?.occStatus) {
			let occstatus = this.ccuSetting.occStatus?.val;
			this.OccStatus = this.occStatusValues[occstatus];
		}
		if (this.ccuSetting?.oao?.val) {
			this.oaoArcValue = parseInt(this.ccuSetting.oao.val);
		}
		if (this.ccuSetting?.cloudLastUpdated?.hasOwnProperty('val')) {
			this.ccuSetting['cloudLastUpdated']['val'] = dayjs.utc(this.ccuSetting.cloudLastUpdated.time).local();
		}
		if (this.ccuSetting?.oaoLastUpdated?.hasOwnProperty('val')) {
			this.ccuSetting['oaoLastUpdated']['val'] = dayjs.utc(this.ccuSetting.oaoLastUpdated.time).local();
		}
	}

	setOTAandCloudStatusSettings(res) {
		this.buildingDataTempHolder = ObjectUtil.deepClone(res);
		if (res?.cloudLastUpdated.hasOwnProperty('val')) {
			this.ccuSetting['cloudLastUpdated']['val'] = dayjs.utc(res.cloudLastUpdated.time).local();
		}
		if (this.ccuSetting?.oaoLastUpdated?.hasOwnProperty('val')) {
			this.ccuSetting['oaoLastUpdated']['val'] = dayjs.utc(res.time).local();
		}
	}

	checkCertificationAccess(view) {
		return this.authService.isUserAllowedToCheck(view);
	}
    async loadZoneTuners(roomInfo) {
        this.zoneTuners = [];
        this.helperService.tunersInfo.forEach(async (_item, _key) => {
            let obj = {};
            const promise = new Promise((resolve) => {
                const filteredItems: any[] = _item.filter((_filterItem) => {
                    const zoneTunerFound = (_filterItem.markers && Array.isArray(_filterItem.markers)) ? ['roomRef', 'tuner'].every(elem => _filterItem.markers.indexOf(elem) > -1) : false;
                    if (zoneTunerFound && _filterItem['referenceIDs']['room'] && (_filterItem['referenceIDs']['room'] == roomInfo[0]['_id'])) {
                        return _filterItem;
                    }
                });
                resolve(filteredItems);
            });
            const filteredItems: any = await promise;
            if(Object.keys(filteredItems).length > 0) {
                this.appendZoneTuners(_item, _key, filteredItems, obj);
            }
        });

    }

    appendZoneTuners(_item, _key, filteredItems, obj) {
        if (filteredItems && filteredItems.length > 0) {
            if ((Array.isArray(_item) && _item.length) && Array.isArray(filteredItems) && filteredItems.length) {
                const names: Array<any> = _key.split('_') || [];
                const name = (names.length) ? names[0] : _key;
                obj['name'] = name;
                obj['values'] = filteredItems;
                obj['unit'] = _item[0]['unit'] || '';
                obj['minVal'] = _item[0]['minVal'] || '';
                obj['maxVal'] = _item[0]['maxVal'] || '';
                obj['incrementVal'] = _item[0]['incrementVal'] || '';
                obj['tunerGroup'] = _item[0]['tunerGroup'] || '';
                if (obj['unit'] && this.unitService.unitListArray.includes(obj['unit'])) {
                    obj = this.unitService.formatTunerList(obj, true);
                }
                this.zoneTuners.push(obj);
            }
        }
    }

    onTunerChange(event) {
        this.tunerDataChanged = event || false;
    }

    async loadTunerData(ccuInfo) {
        this.deviceHelper.systemProfileSubject.pipe(first()).subscribe((systemProfile) => {
            if (systemProfile.ccuRef != this.refs.ahuRef) {
                return
            }
            const equipZones = this.helperService.equipZones;

            let ccuType;
            if (systemProfile.systemProfileName && systemProfile.systemProfileName.toLowerCase().includes('vav')) {
                ccuType = 'VAV';
            } else if (systemProfile.systemProfileName && systemProfile.systemProfileName.toLowerCase().includes('dab')) {
                ccuType = 'DAB';
            } 

            this.tuners = [];
            this.checkForSystemLevelTuner(ccuInfo,equipZones,ccuType)
        });
    }

    checkForSystemLevelTuner(ccuInfo, equipZones, ccuType) {
        this.helperService.tunersInfo.forEach(async (_item, _key) => {
            let obj = {};
            const promise = new Promise((resolve) => {
                const filteredItems = _item.filter((_filterItem) => {
                    const systemFound = (_filterItem.markers && Array.isArray(_filterItem.markers)) ? ['system', 'tuner'].every(elem => _filterItem.markers.indexOf(elem) > -1) : false;
                    const oaoFound = (_filterItem.markers && Array.isArray(_filterItem.markers)) ? ['oao', 'tuner'].every(elem => _filterItem.markers.indexOf(elem) > -1) : false;
                    if ((oaoFound && ccuInfo['oaoRef'] == _filterItem['referenceIDs']['equip']) || (systemFound && ccuInfo['ccuAhu'] == _filterItem['referenceIDs']['equip'])) {
                        return _filterItem;
                    } else {
                        const found = ArrayUtil.findInArray(equipZones, '_id', _filterItem['referenceIDs']['equip']);
                        if (found && (ccuInfo['ccuAhu'] == found['referenceIDs']['ahu'] || ccuInfo['ccuAhu'] == found['referenceIDs']['gateway'])) {
                            return _filterItem;
                        }
                    }
                });
                resolve(filteredItems);
            });
            const filteredItems = await promise;
            if(Object.keys(filteredItems).length > 0) {
                this.appendTunerData(_item, _key, filteredItems, obj, ccuType);
            }
        });
    }

    appendTunerData(_item, _key, filteredItems, obj, ccuType) {
        if (filteredItems && filteredItems.length > 0 ) {
            if ((Array.isArray(_item) && _item.length && ((_item[0]['tunerGroup'] != 'VAV' && _item[0]['tunerGroup'] != 'DAB') || ccuType == _item[0]['tunerGroup'])) || !ccuType) {
                const names: Array<any> = _key.split('_') || [];
                const name = (names.length) ? names[0] : _key;
                obj['name'] = name;
                obj['values'] = filteredItems;
                obj['unit'] = _item[0]['unit'] || '';
                obj['minVal'] = _item[0]['minVal'] || '';
                obj['maxVal'] = _item[0]['maxVal'] || '';
                obj['incrementVal'] = _item[0]['incrementVal'] || '';
                obj['tunerGroup'] = _item[0]['tunerGroup'] || '';
                if (obj['unit'] && this.unitService.unitListArray.includes(obj['unit'])) {
                    obj = this.unitService.formatTunerList(obj, true);
                }
                this.tuners.push(obj);
            }
        }
    }
    
    onAddNewAnalytics() {
        const self = this;
        const dialogRef = this.dialog.open(AnalyticsListComponent, {
            panelClass: 'pointsummary-padding-dialog',
          });

          dialogRef.componentInstance.siteId = this.refs.siteRef;
          dialogRef.componentInstance.siteNameRef = this.siteName;
          dialogRef.componentInstance.analyticsFrom = 'global';

          dialogRef.afterClosed().subscribe(() => {
            this.clickMenu();
          });
      }

    onAddUpdateAnalytics(mode, modalType?, type?, id?) {
        const self = this;
        type = type || 'global';
        // Since you would only show a modal if you are doing global, else it would be inline.
        if (this.editAnalytics && modalType === 'global') {
            this.refToCheck = this.refs.siteRef;
            const entityName = this.refs['siteDetails'].dis;
            let getAllGlobalWidgetNames = [];
            const mappedDashboardId = this.globalDashboardObject?.entityDashboards[this.refToCheck];
            if (mappedDashboardId && this.dashboardWidgetsMap[mappedDashboardId]?.length) {
                getAllGlobalWidgetNames = this.dashboardWidgetsMap[mappedDashboardId].map(r => {
                    return r.name;
                });
            }
            // Temporary - You have the globalDashboardObject, but does it have a dashboard related to the siteRef
            this.mappedDashboardId = mappedDashboardId;
            const createMappingDashboard = mappedDashboardId ? false : true;
            if (mode === 'create') {
                // Create Global widget popup.
                this.openGlobalWidgetPopup(mode, type, mappedDashboardId, createMappingDashboard, entityName, getAllGlobalWidgetNames);
            } else if (mode === 'edit' && !createMappingDashboard) { 
                // This is global widget Edit popup.
                this.editGlobalWidgetPopup(mode, type, mappedDashboardId, getAllGlobalWidgetNames);
            }
        } else if (!this.editAnalytics) {
            this.alertService.warning(`Editing Heatmap Analytics is not enabled.`);
        }
    }

    openGlobalWidgetPopup(mode, type, mappedDashboardId, createMappingDashboard, entityName, getAllGlobalWidgetNames) {
        const self = this;
        this.createGlobalDashboard(this.refs.siteRef).subscribe(
            async _ => {
                const dialogRef = self.dialog.open(AnalyticsVisBuilderModalComponent, {
                    width: '80%',
                    panelClass: 'analytics-modal-container',
                    disableClose: true,
                    data: {
                        entityRef: this.refToCheck,
                        mode: mode,
                        type: type,
                        ccus: Array.from(this.buildings.ccus),
                        zones: Array.from(this.buildings.zones),
                        equips: Array.from(this.allEquips),
                        siteRef: this.refs.siteRef,
                        yPosition: (this.dashboardWidgetsMap[mappedDashboardId] || []).length + 1, // By default it will be whichever position the user has selected Or at the end
                        mappedDashboardId: mappedDashboardId,
                        widgetSelected: { timeConstraint: 'today', entityName: entityName },
                        createMappingDashboard: createMappingDashboard,
                        taglist: this.tagList,
                        customList: type === 'global' ? getAllGlobalWidgetNames : 0
                    }
                });
                dialogRef.updatePosition({ top: '72px' });
                dialogRef.afterClosed().subscribe(() => {
                    this.clickMenu();
                });
            },
            err => {
                console.log(err);
            }
        );
    }

    editGlobalWidgetPopup(mode, type, mappedDashboardId, getAllGlobalWidgetNames) {
        const self = this;
        const dialogRef = self.dialog.open(AnalyticsVisBuilderModalComponent, {
            width: '80%',
            panelClass: 'analytics-modal-container',
            disableClose: true,
            data: {
                entityRef: this.refToCheck,
                mode: mode, // will be edit
                type: type, // Will be global
                ccus: Array.from(this.buildings.ccus),
                zones: Array.from(this.buildings.zones),
                equips: Array.from(this.allEquips),
                siteRef: this.refs.siteRef,
                allWidgets: this.sortWidgets(this.dashboardWidgetsMap[mappedDashboardId]),
                widgetSelected: {},
                mappedDashboardId: mappedDashboardId,
                createMappingDashboard: false,
                taglist: this.tagList,
                customList: type === 'global' ? getAllGlobalWidgetNames : 0
            }
        });
        dialogRef.updatePosition({ top: '72px' });
        dialogRef.afterClosed().subscribe(() => {
            this.clickMenu();
        });
    }

    // Gets called when the + button is clicked, also that would open the modal for adding a chart.
    createGlobalDashboard(siteRef) {
        return new Observable(observer => {
            if (!this.globalDashboardObject || !this.globalDashboardObject.globalDashboardId) {
                this.customHeatmapService.createGlobalDashboard(siteRef).pipe(
                    takeUntil(this.unsubscribe)
                ).subscribe( {
                    next:(resp) => {
                        this.processGlobalDashboardResponse(resp);
                        observer.next(true);
                    },error: (err) => {
                        console.log(err)
                    }
                });
            } else {
                observer.next(true);
            }
        });
    }

    getSiteTags(siteRef) {
        this.customHeatmapService.getSiteTags(siteRef)
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(({ rows }) => {
                this.tagList = uniq(rows[0].tags);
            });
    }

	getEntityDashboardDetails(dashboardId, isGlobal) {
		return new Observable(observer => {
			this.customHeatmapService.getDashboardDetails(dashboardId, isGlobal, this.globalDashboardObject.id).pipe(
				takeUntil(this.unsubscribe)
			).subscribe(resp => {
				this.dashboardWidgetsMap[dashboardId] = resp;
				if (isGlobal) {
					this.showEditGlobalViz = !this.globalDashboardObject.isPublished && this.dashboardWidgetsMap[dashboardId].length > 0;
				}
				observer.next(true);
			},
				err => {
					let dashboardDeleted = false;
					const entityDashboards = this.globalDashboardObject.entityDashboards;
					if ((err.status === 500 || err.status === 404) && err.error?.error === 'Invalid heatmap dashboard for site') {
						// Rogue site Dashboard
						delete entityDashboards[this.refs.siteRef];
						dashboardDeleted = true;
					} else if (err.status === 404 && err.error?.error === 'Requested dashboard not found') {
						delete entityDashboards[this.refToCheck];
						dashboardDeleted = true;
					}

					if (dashboardDeleted) {
						this.customHeatmapService.saveGlobalDashboard(this.refs.siteRef, {
							entityDashboards: entityDashboards,
							publish: false
						}).pipe(
							takeUntil(this.unsubscribe)
						).subscribe(
							_ => this.getGlobalDashboard(this.refs.siteRef)
						);
					}
				});
		});
	}

		
	workOnDraftAction(action) {
		switch (action) {
			case 'delete':
				// First show the modal - ask for confirmation
				this.deleteDraft();
				break;
			case 'publish':
				this.publishDraft();
				break;
			case 'editAnalytics':
				// First make a Post call to the backend i.e create a new draft dashboard and work on it
				const siteRef = this.refs.siteRef;

				// Resetting all effective selections
				this.globalDashboardObject = undefined;
				this.dashboardWidgetsMap = {};

				this.createGlobalDashboard(siteRef).subscribe();
				break;
			default:
				break;
		}
	}


	deleteDraft() {
			const siteRef = this.refs.siteRef;
			const dialogRef = this.dialog.open(ConfirmModalComponent, {
					width: '360px',
					panelClass: 'deleteDialog',
					disableClose: true
			});
			dialogRef.componentInstance['type'] = 'confirm';
			dialogRef.componentInstance['confirmBtnText'] = 'Delete';
			dialogRef.componentInstance['title'] = 'Delete Draft';
			dialogRef.componentInstance['htmlContent'] = `Are you sure you want to delete this Draft of <b>Heatmap Analytics</b>?`;
			dialogRef.componentInstance['showConfirmIcon'] = false;

			dialogRef.afterClosed().pipe(
					takeUntil(this.unsubscribe)
			).subscribe(result => {
					if (result) {

							this.customHeatmapService.deleteGlobalDashboard(siteRef).subscribe(
									resp => {
											// Resetting all effective selections
											this.globalDashboardObject = undefined;
											this.dashboardWidgetsMap = {};

											this.alertService.success('Heatmap Analytics draft has been deleted successfully.');

											// Make a call to get the published version, if exists for the site
											this.getGlobalDashboard(siteRef);
									}, err => {
											this.alertService.error('Heatmap Analytics draft deletion failed.');
									}
							);
					}
			});
	}


	publishDraft() {
			if (this.publishingDraft) {
					return false;
			}
			this.publishingDraft = true;
			const siteRef = this.refs.siteRef;
			const entityDashboards = this.globalDashboardObject.entityDashboards;
			this.customHeatmapService.saveGlobalDashboard(this.refs.siteRef, {
					entityDashboards: entityDashboards,
					publish: true
			}).pipe(
					takeUntil(this.unsubscribe)
			).subscribe(
					_ => {
							this.alertService.success('Heatmap Analytics has been published successfully.');
							this.getGlobalDashboard(siteRef);
							this.publishingDraft = false;
					}, err => {
							this.alertService.error('Heatmap Analytics publish failed.');
							this.publishingDraft = false;
					}
			);
	}

	// Mostly needs to be called only when a new dashboard is added - otherwise all changes are already getting saved.
	updateGlobalDashboard(entityRef, dashboardId, type, widget?, op?) {
			const entityDashboards = this.globalDashboardObject.entityDashboards;
			if (entityRef) {
					entityDashboards[entityRef] = dashboardId;
			}
			delete entityDashboards['undefined'];
			this.customHeatmapService.saveGlobalDashboard(this.refs.siteRef, {
					entityDashboards: entityDashboards,
					publish: false
			}).pipe(
					takeUntil(this.unsubscribe)
			).subscribe();
			// Independent of the save
			if (widget) {
					let existingWidgetsList = this.sortWidgets(this.dashboardWidgetsMap[dashboardId] || []);
					if (op === 'create') {
							let incrementPosition = false;
							existingWidgetsList = existingWidgetsList.map(_widget => {
									if (_widget.y === widget.y || incrementPosition) {
											// So the incoming widget is at the same position as this widget
											incrementPosition = true;
											_widget.y += 1;
									}
									return _widget;
							});
							widget.siteName = this.refs.siteDetails?.dis;
							existingWidgetsList.splice((widget.y - 1), 0, widget);
							if (incrementPosition) {
									// then also update the positions
									this.persistUpdatedPositions(dashboardId, existingWidgetsList);
							}
					} else if (op === 'edit') {
							existingWidgetsList = existingWidgetsList.map(_widget => {
									return _widget.widgetId === widget.widgetId ? widget : _widget;
							});
							delete this.customWidgetDataForExport[widget.widgetId];

					} else if (op === 'delete') {
							existingWidgetsList = existingWidgetsList.filter(_widget => {
									return _widget.widgetId !== widget.widgetId;
							});
					}

					this.dashboardWidgetsMap[dashboardId] = existingWidgetsList;

					if (type !== 'global') {
							this.customWidgets['local'] = existingWidgetsList;
					} else if (type === 'global') {
							this.showEditGlobalViz = existingWidgetsList.length > 0;
					}
			}

			// Also send a update status
			this.heatmapConnect.setGlobalDashboardStatus(
					{ published: false, updateDateTime: new Date(), showDFT: true }
			);
	}

	persistUpdatedPositions(dashboardId, widgetsList) {
		const widgetPositionUpdateData =
			widgetsList.map((widget, index) => {
				return {
					widgetId: widget.widgetId,
					width: 0,
					height: widget.rows,
					horizontalStartPosition: 0,
					verticalStartPosition: index + 1
				};
			});

		if (this.editAnalytics) {
			this.customHeatmapService.updateWidgetsPosition(dashboardId, { widgets: widgetPositionUpdateData })
				.pipe(
					takeUntil(this.unsubscribe)
				).subscribe();
		}
	}

	onVisBuilderClosed() {
		this.showInlineVisBuilder = false;
		this.selectedScopedEntityId = null;
	}

	onAddScopedAnalytics(data, selectedWidget?) {
		const mode = data.action;
		const type = data.ccuOrZone;
		const index = data.index;
		if (this.editAnalytics) {

			this.refToCheck = this.ccuOrZone.type === 'zone' ? this.refs['roomRef'] :
				this.buildings.ccus.filter(ccu => ccu.referenceIDs.ahu === this.refs.ahuRef)[0]._id;
			const entityName = this.ccuOrZone.type === 'zone' ? this.refs['zoneName'] : this.refs.ccuName;
			let getAllCustomWidgetNames = [];
			// Temporary - You have the globalDashboardObject, but does it have a dashboard related to the siteRef
			const mappedDashboardId = this.globalDashboardObject?.entityDashboards[this.refToCheck];
			if (mappedDashboardId && this.dashboardWidgetsMap[mappedDashboardId]?.length) {
				getAllCustomWidgetNames = this.dashboardWidgetsMap[mappedDashboardId].map(r => {
					return r.name;
				});
			}
			this.mappedDashboardId = mappedDashboardId;

			const createMappingDashboard = mappedDashboardId ? false : true;

			if (mode === 'create') {
				this.createGlobalDashboard(this.refs.siteRef).subscribe(
					async _ => {
						// first create an appropriate data object, and call it - scopedData, that inside this AnalyticsVisBuilderComponent, will be assigned to data.
						const data = {
							entityRef: this.refToCheck,
							mode: mode,
							type: type, // ccu or zone,
							modalType: 'inline',
							ccus: Array.from(this.buildings.ccus),
							zones: Array.from(this.buildings.zones),
							equips: Array.from(this.allEquips),
							siteRef: this.refs.siteRef,
							yPosition: index === -1 ? (this.customWidgets.local.length || 0) + 1 : index + 1, // By default it will be whichever position the user has selected Or at the end
							widgetName: `${entityName} Widget - ${mappedDashboardId ? (this.customWidgets.local.length || 0) + 1 : 1}`,
							mappedDashboardId: mappedDashboardId,
							widgetSelected: { timeConstraint: 'today', entityName: entityName },
							createMappingDashboard: createMappingDashboard,
							customList: type === 'ccu' || 'zone' ? getAllCustomWidgetNames : 0
						};
						this.scopedData = data;
						this.showInlineVisBuilder = true;
					});

			} else if (mode === 'edit') {
				const data = {
					entityRef: this.refToCheck,
					mode: mode, // will be edit
					type: type,
					modalType: 'inline',
					ccus: Array.from(this.buildings.ccus),
					zones: Array.from(this.buildings.zones),
					equips: Array.from(this.allEquips),
					siteRef: this.refs.siteRef,
					yPosition: index + 1, // By default it will be whichever position the user has selected Or at the end
					widgetName: selectedWidget.name,
					widgetSelected: { ...selectedWidget, entityName: entityName },
					mappedDashboardId: mappedDashboardId,
					createMappingDashboard: false,
					customList: type === 'ccu' || 'zone' ? getAllCustomWidgetNames : 0
				};
				this.scopedData = data;
				this.showInlineVisBuilder = true;
			}

			this.selectedScopedEntityIndex = index;
		} else {
			this.alertService.warning(`Editing Heatmap Analytics is not enabled.`);
		}

	}

	// This has the widgetId and the index and the scope
	editWidgetEvt(event) {
		this.onAddScopedAnalytics({ action: 'edit', ccuOrZone: event.scope, index: event.index }, event.widget);
		this.showInlineVisBuilder = true;
		this.selectedScopedEntityId = event.widget.widgetId;
		this.selectedScopedEntityIndex = event.index;
	}

	deleteWidget(event) {
		this.selectedWidgetForDeletion = event.widget;
		const dialogRef = this.dialog.open(ConfirmModalComponent, {
			width: '360px',
			panelClass: 'deleteDialog',
			disableClose: true
		});
		dialogRef.componentInstance['type'] = 'confirm';
		dialogRef.componentInstance['confirmBtnText'] = 'Delete';
		dialogRef.componentInstance['title'] = 'Delete Custom Widget';
		dialogRef.componentInstance['htmlContent'] = `Are you sure you want to delete <b>${this.selectedWidgetForDeletion.name}</b>?`;
		dialogRef.componentInstance['showConfirmIcon'] = false;


		dialogRef.afterClosed().pipe(
			takeUntil(this.unsubscribe)
		).subscribe(result => {
			if (result) {
				const vizName = this.selectedWidgetForDeletion.name;
				const vizId = this.selectedWidgetForDeletion.widgetId;
				this.serverDeleteInProgress = true;
				this.customHeatmapService.deleteWidget(vizId).pipe(
					takeUntil(this.unsubscribe)
				).subscribe(
					_ => {
						this.serverDeleteInProgress = false;
						this.clearBuilders();

						this.selectedWidgetForDeletion = {};

						this.alertService.success(`${vizName} has been deleted successfully.`);
						this.updateGlobalDashboard(this.refToCheck, this.mappedDashboardId, this.ccuOrZone.type, { widgetId: vizId }, 'delete');
						delete this.customWidgetDataForExport[vizId];
					}, _ => {
						this.serverDeleteInProgress = false;
						this.alertService.error(`${vizName} deletion failed. Please try again later.`);
					}
				);
			} else {
				this.serverDeleteInProgress = false;
				this.selectedWidgetForDeletion = {};
			}
		});
	}

	clearBuilders() {
		this.selectedScopedEntityIndex = null;
		this.selectedScopedEntityId = null;
		this.showInlineVisBuilder = false;
	}

	updatePosition(event) {
		moveItemInArray(this.customWidgets.local, event.previousIndex, event.currentIndex);
		// Need to persist the new order - changing the horizontal position

		this.persistUpdatedPositions(this.mappedDashboardId, this.customWidgets.local);
	}

    //This method returns the profile name to systemReconfigProfileName.VVT-C is changed to DAB here, to avoid impact on logics being done for DAB profiles. 
    setSystemReconfigName(profile) {
        if (profile?.includes('VVT-C')) {
            return profile?.replace('VVT-C', 'DAB')
        }
        return profile;
    }

    /**
     * Returns true if the chat-feature is enabled on the Heatmap **and** a site is selected.
     */
    get chatEnabled() {
        return this.show75FChatButton === true && this.refs.siteRef;
    }

    /**
     * Toggles the chat session.
     */
    chatSessionActive: boolean = false;
    onToggleChat() {
        this.chatSessionActive = !this.chatSessionActive;
    }

    updateTimeToggle(event) {
        this.setUserPreferencesData(event,['showLastUpdated']);
    }

    setTerminalGraphicValues(res) {
        this.equipGraphicData = ObjectUtil.deepClone(res);
        const profileTypesToCheck = ['chilledBeam', 'dab', 'dualDuct', 'sse','series','pipe2', 'pipe4','hpu','vav', 'parallel','cpu'];
        const checkProfileTypeMatch = this.profiles.some((obj: { profileType: string }) => profileTypesToCheck.includes(obj.profileType));
         if (checkProfileTypeMatch || this.checkHyperStatProfile === `hyperstatsplit`) {
                this.showTerminalEquipGraphics = true
        }
    }
      checkForUnitConversion(equipData) {
        if (this.userTempPreference == '°C') {
            equipData['Outside Air Temp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['Outside Air Temp']?.val?.split(' ')[0]), 'outsideAirTemp').toString() + this.userTempPreference;
            equipData['currentTemp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['currentTemp']?.val?.split(' ')[0]), 'currentTemp').toString() + this.userTempPreference;
            equipData['mixedAirTemp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['mixedAirTemp']?.val?.split(' ')[0]), 'mixedAirTemp').toString() + this.userTempPreference;
            equipData['desiredTempCooling'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['desiredTempCooling']?.val), 'desiredTempCooling').toString() + this.userTempPreference;
            equipData['desiredTempHeating'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['desiredTempHeating']?.val), 'desiredTempHeating').toString() + this.userTempPreference;
            equipData['supplyAirTemp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['supplyAirTemp']?.val), 'supplyAirTemp').toString() + this.userTempPreference;
        } else {
            const tempArray = ['Outside Air Temp', 'currentTemp', 'mixedAirTemp','desiredTempCooling','desiredTempHeating','supplyAirTemp'];

            for (let i = 0; i <= tempArray.length; i++) {
                if (equipData && equipData[tempArray[i]]?.hasOwnProperty('val')) {
                    if (!equipData[tempArray[i]]?.val?.toString().includes('°F')) {
                        equipData[tempArray[i]].val = `${equipData[tempArray[i]].val} °F`;
                    }
                }
            }
        }
        return equipData;
    }

    scrollNExpandNotes(event){
        this.expandNotesSection = event;
        this.lazyLoadFloors = this.buildings.floors;
    }

    //Advanced Ahu User Intent
    getSupplyAirflowTempValue(ccuSetting) {
        const val = ccuSetting?.supplyAirflowTempControl?.val;
        const propertyNames = ['supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'satSpMin', 'satSpMax'];
        switch (val) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                const propertyName = propertyNames[val];
                return ccuSetting.hasOwnProperty(propertyName) && ccuSetting[propertyName]?.val != null ? ccuSetting[propertyName].val : '-';
            default:
                return '-';
        }
    }


    getDischargeAirflowTempValue(ccuSetting) {
        const val = ccuSetting?.pressureBasedFanControl?.val;
        const propertyNames = ['ductStaticPressureSensor1', 'ductStaticPressureSensor2', 'ductStaticPressureSensor3', 'averagePressure', 'minPressure', 'maxPressure'];
        switch (val) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                const propertyName = propertyNames[val];
                return ccuSetting.hasOwnProperty(propertyName) && ccuSetting[propertyName]?.val != null ? ccuSetting[propertyName].val : '-';
            default:
                return '-';
        }
    }
    getOperatingMode(ccuSetting) {
        if (!ccuSetting || !ccuSetting.advancedAhuOperatingMode) {
            return '-';
        }
        switch (Number(ccuSetting.advancedAhuOperatingMode.val)) {
            case 0:
                return 'Off';
            case 1:
                return 'Cooling';
            case 2:
                return 'Heating';
            case 3:
                return 'Temp Dead';
            default:
                return '-';
        }
    }

        /**  In this method we will ccheck for loaded based point sif load based ispaired we will not show 
    supply air temp section in the user intent*/
    shouldDisplaySupplyAirTemp() {
        const  loadBasedConfigCheck= [
            'satbasedheatingcontrol','satbasedcoolingcontrol'
        ];
        for (let prop of loadBasedConfigCheck) {
            if (this.ccuSetting.hasOwnProperty(prop)) {
                return true;
            }
        }
    }
    

     //This method helps to set the default relay mapping values for system level VAV profiles, since relay-mapping points aren't created by CCU when associated relay is in OFF state.
     setRelayMappingforVAV() {
        const relayAssociations = this.systemReconfigProfileName?.includes('VAV Fully Modulating AHU') ? { "relay-7Mapping": { val: 0 }, "relay-3Mapping": { val: 0 }, "analogOut1MinCooling": { val: 2 }, "analogOut1MaxCooling": { val: 10 }, "analogOut2MinStatic": { val: 2 }, "analogOut2MaxStatic": { val: 10 }, "analogOut3MinHeating": { val: 2 }, "analogOut3MaxHeating": { val: 10 }, "analogOut4MinAir": { val: 2 }, "analogOut4MaxAir": { val: 10 } } : { "relay-1Mapping": { val: 0 }, "relay-2Mapping": { val: 1 }, "relay-3Mapping": { val: 10 }, "relay-4Mapping": { val: 5 }, "relay-5Mapping": { val: 6 }, "relay-6Mapping": { val: 11 }, "relay-7Mapping": { val: 15 } };
        for (const key in relayAssociations) {
            // here we are using the  Object.prototype.hasOwnProperty.call instead of hasOwnProperty to safely check if a property exists on an object, avoiding issues with overridden hasOwnProperty methods
            //Ref: link https://stackoverflow.com/questions/12017693/why-use-object-prototype-hasownproperty-callmyobj-prop-instead-of-myobj-hasow
            if (!Object.prototype.hasOwnProperty.call(this.systemReconfigurationSettings, key) ||
                !Object.prototype.hasOwnProperty.call(this.systemReconfigurationSettings[key] || {}, 'val')) {
                this.systemReconfigurationSettings[key] = relayAssociations[key];
            }
      }
  }


    ahuProfilesGlobalisationCheck() {
        const userTempPreference = this.authService.getLoggedInUserPreferences();
        if (this.userTempPreference === "°C") {
            ["airTempHeatingSp", "airTempCoolingSp", 'supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'satSpMin', 'satSpMax'].forEach(key => {
                if (this.ccuSetting[key]?.val !== undefined && this.ccuSetting[key]?.val !== null) {
                    let value = this.ccuSetting[key].val.split(' ')[0];
                    this.ccuSetting[key].val = this.unitService.fahrenheitToCelecius(value, key) + this.userTempPreference;
                }
            });
        }
        if (userTempPreference.airPressureUnit !== 'inH₂O') {
            ['ductStaticPressureSetPoint', 'ductStaticPressureSensor1', 'ductStaticPressureSensor2', 'ductStaticPressureSensor3', 'averagePressure', 'minPressure', 'maxPressure'].forEach(key => {
              if (this.ccuSetting[key]?.val !== undefined && this.ccuSetting[key]?.val !== null) {
                    let value = this.ccuSetting[key].val.split(' ')[0];
                    this.ccuSetting[key].val = this.unitService.checkForCFMConversion(value, userTempPreference); + this.userTempPreference;
                }
            });
        }
    }

    isValidProfile(profiles) {
       return this.deviceHelper.isZoneNon75FProfile(profiles);
    }

    /**
     * Sets the CCU data based on the provided CCU object.
     * @param ccuObj The CCU object containing the necessary data.
     * @returns An object with the CCU data.
     */
    setCCUData(ccuObj) {
        let selectedCCUObj: any = this.buildings.ccus.find(ccuData => ccuData._id == ccuObj.ccuId);
        return {
            ccuId: ccuObj.ccuId,
            "siteRef": selectedCCUObj.referenceIDs.site,
            "ccuName": selectedCCUObj.name,
            "ccuAhu": selectedCCUObj.referenceIDs.ahu,
            "ccuOAOCheck": selectedCCUObj.hasOAOProfile,
            "ccuBypassDamperCheck": selectedCCUObj.hasBypassProfile,
            "equipRef": selectedCCUObj.referenceIDs.equip,
            "gateway": selectedCCUObj.referenceIDs.gateway,
            "oaoRef": selectedCCUObj.referenceIDs.oaoRef,
            $event: ccuObj?.$event
        }
    }

    getEquipsAndDevicesFromSiteId(siteRef) {
        this.siteService.getQuerybySite(siteRef,"(equip or device or ccu or room or floor)").subscribe((res) => {
            res = this.helperService.stripHaystackTypeMapping(res);
            this.entitiesList = res.rows;
        }, (err) => {
            console.log(err, 'something went wrong');
        });
    }
}
