import {
    AlertService,
    ConfirmModalComponent,
    HelperService,
    LoaderService,
    ObjectUtil,
    PlannerLayoutComponent,
    SiteService,
    SortOrderComponent,
    ArrayUtil,
    AuthenticationService
} from '@75f/portal-ui-components';
import { takeUntil } from 'rxjs/operators';
import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    SecurityContext,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {
    CCUALERTService
} from '@shared/_services';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/internal/operators/map';
import sortBy from 'lodash-es/sortBy';
import { Subject } from 'rxjs/internal/Subject';
import { MatSelect } from '@angular/material/select';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import { constants } from '@/shared/constants/constants';
import { BackfillOptionsLayoutComponent } from '@75f/portal-ui-components';
import { FeatureToggleService } from '@75f/portal-ui-components';
import { ActivatedRoute } from '@angular/router';
import { settingsMenu } from '@/modules/settings/menu-constant';
import { HelperServiceFacilisight } from '@/shared/_services/helper.service';


@Component({
    selector: 'fs-settings-floor-plan-layout',
    templateUrl: './settings-floor-plan-layout.component.html',
    styleUrls: ['./settings-floor-plan-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SettingsFloorPlanLayoutComponent implements OnInit {
    filteredfloor: any;
    showRevampedMasterController: boolean = false;
    showRemoteAccess: boolean = false;
    @HostListener("window:scroll", ['$event'])
    scrollMe(event, val?) {
        if (val) {
            const scrollTop = event.target.scrollTop;
            if (scrollTop >= 100) {
                this.showTuners = true;
            }
        }
    }
    isHighlighted = false;
    isSubmenuOpen = false; 
    selectedFloor = '';
    showBuildingOptions: boolean;
    submenus: any[] = settingsMenu;
    changedOrientationValue: any;
    noFLoorPlan: boolean;
    uploadFloorPlanLoader: boolean;
    showFloorPlan: boolean = true;
    displayControls: boolean = false;
    remoteControls: any;
    remoteDataArray = [];
    showTuners: boolean = false;
    isSiteActive: Boolean = false;
    isFloorActive: Boolean = false;
    isUploadProgress: Boolean = false;
    filesSelected: Boolean = false;
    zoneAttribute: any = {
        minWidth: 99,
        minHeight: 81
    };
    url: any;
    rooms: any[] = [];
    originalRooms: any[] = [];
    svgElement: any;
    selectedElement: any;
    offset: any;
    transform: any;
    buildings: Object = [];
    floors: any = [];
    loadingVal = "loading...";
    isEmpty = true;
    siteRef: string;
    floorRef: string;
    imageUri: string;
    floorPlanSvg: any;
    orientationSelectValues: any;
    displayOrientation: Boolean = false;
    orientationangle: number = 0;
    droppeditem = [];
    currentBox?: string;
    zonesToBepassed = [];
    onActiveFloorPlan: Boolean = false;
    uploadFloorPlan: Boolean = false;
    noFloorFlag: Boolean = true;
    totalFloorZone;
    resetAllZone = false;
    floorInfo: any;
    selectedFile: File;

    @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;
    @ViewChild(PlannerLayoutComponent, { static: false }) planner: PlannerLayoutComponent;
    @ViewChild(BackfillOptionsLayoutComponent, { static: false }) header: BackfillOptionsLayoutComponent;

    selectedSite: any;

    predefinedShapes = ['square', 'circle', 'triangle', 'parallelogram', 'pentagon', 'hexagon', 'octagon'];
    selectedShapeType = null;

    floorSelected: Boolean = false;
    imageHeight = 0;
    imageWidth = 0;
    private unsubscribe: Subject<void> = new Subject();
    constructor(
        public siteService: SiteService,
        public helperService: HelperService,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        public alertService: AlertService,
        private loaderService: LoaderService,
        public ccuAlertService: CCUALERTService,
        public dialog: MatDialog,
        public featureToggleService: FeatureToggleService,
        private dom: DomSanitizer,
        private analyticsService: AnalyticsService,
        public facHelperService: HelperServiceFacilisight
    ) {
    }

    ngOnInit() {
        this.featureToggleService.flagChange.pipe(takeUntil(this.unsubscribe)).subscribe((flags) => {
            this.setFlags(flags);
        })
        const param1Value = this.route.snapshot.paramMap.get('param1');
        if (param1Value == 'floorPlan') {
            this.showFloorPlan = false;
            this.isHighlighted = true
        }
        this.useFeatureToggles();
        this.helperService.clearData();
        this.getAllBuildings();
        this.orientationSelectValues = Array.from(Array(361), (x, i) => i);
        this.ccuAlertService.refreshAlertCountEventTrigger();
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
		this.getBuildingFloors(buildingDetails.siteId);
    }

    ngAfterViewInit() {
        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }

    ngOnChanges(changes: SimpleChanges) {
        const buildings: SimpleChange = changes.buildings;

        this.buildings = buildings.currentValue;
    }
    /**
     *  This method call the siteService and returns all the buidings
     */
    getAllBuildings() {
        this.siteService.getSites().pipe(
            map(this.helperService.sortResponse)).subscribe(({ rows }) => this.buildings = rows);
    }
    /**
     *  This method call the siteService and returns all the buiding floors
     */

    useFeatureToggles() {
        let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags) => {
            this.setFlags(flags);
            sub.unsubscribe();
        });
        this.featureToggleService.getFlags();
    }

    setFlags(flags) {
        this.showBuildingOptions = flags.hasOwnProperty('building-options-certificates') ? flags['building-options-certificates'].value : false;
        this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value : false;
    }
    getBuildingFloors(siteRef: string) {
        this.isSiteActive = true;
        this.rooms = [];
        this.siteRef = this.stripHayStackTags(siteRef).split(' ')[0];
        this.selectedFloor = '';
        this.siteService.getSiteFloors(this.siteRef).subscribe(({ rows }) => {
            this.loadingVal = "No Results Found";
            this.isEmpty = false;
            this.floors = sortBy(rows, [floor => floor.dis.toLowerCase()], ['asc']);
            //get rooms associated with floor
            this.floors.forEach(floor => {
                let floorId = this.stripHayStackTags(floor.id).split(' ')[0];
                this.siteService.getFloorRooms(floorId).subscribe(({ rows }) => {
                    let index = this.floors.findIndex(f => this.stripHayStackTags(f.id).split(' ')[0] == floorId);
                    if (index > -1) {
                        this.floors[index]['rooms'] = rows;
                        this.floors[index]['showDelete'] = rows.length;
                    }

                }, err => {
                });
            });
        });
        this.displayOrientation = false;


    }

    /**
     *  This method call the siteService and returns all the floor rooms
     */
    getFloorRooms(event) {
        if (!event.length) {
            return;
        }
        const selectedFloor = this.stripHayStackTags(event[0].id).split(' ')[0];
        if (this.floorRef == selectedFloor) {
            return;
        }

        this.uploadFloorPlanLoader = true;
        this.isFloorActive = true;
        this.showTuners = false;
        this.noFLoorPlan = false
        this.onActiveFloorPlan = true;
        this.selectedShapeType = 'square';

        this.url = "";
        this.floorSelected = selectedFloor ? true : false;
        this.displayOrientation = false;

        this.floorInfo = this.floors.filter(x => x.id == event[0].id);
        this.orientationangle = parseInt(this.stripHayStackTags(this.floorInfo[0]?.orientation).split(' ')[0], 10);
        this.rooms = [];
        this.originalRooms = [];
        this.totalFloorZone = [];
        this.floorRef = selectedFloor;  // This would be the floorId
        this.siteService.getFloorRooms(this.floorRef).subscribe(({ rows }) => {
            this.rooms = rows;
            this.originalRooms = ObjectUtil.deepCopy(rows);
            this.totalFloorZone = rows;
            this.displayOrientation = true;
        }

        );
        this.loaderService.active(true);
        try {
            this.fileUploader.nativeElement.value = '';
        } catch (e) {

        }
    }



    stripHayStackTags(dataInput) {
        return this.helperService.stripHaystackTypeMapping(dataInput);
    }

    /**
     *  This method is triggered when the user add a floor plan 
     */
    onAdd(event: any) {
        this.noFLoorPlan = false;
        if (event.target.files && event.target.files[0] && event.target.files[0].size < 5000000) {
            if (['image/jpeg', 'image/png', 'image/gif'].indexOf(event.target.files[0].type) >= 0) {
                let reader = new FileReader();
                reader.onload = _ => {
                    this.url = reader.result;
                    // this.getFloorPlanDimensions();
                }

                this.selectedFile = event.target.files[0];
                reader.readAsDataURL(event.target.files[0]);

                if (this.noFloorFlag == true) {
                    this.droppeditem = []
                } else {
                    this.droppeditem
                }

                if (this.droppeditem) {
                    this.onActiveFloorPlan = true;
                    this.uploadFloorPlan = false;
                    this.noFloorFlag = false;
                } else {
                    this.onActiveFloorPlan = false;
                    this.uploadFloorPlan = true;
                    this.noFloorFlag = false;
                }
                this.zonesToBepassed = this.rooms;
                this.rooms = this.originalRooms;
            }
            else {
                this.alertService.error('please select a file in image format');
            }
        } else {
            this.alertService.error('please select a file below 5mb');
        }
    }
    /**
     *  This method call the siteService and returns all the buidings
     */
    getBuildings() {
        this.siteService.getSites().pipe(
            map(this.helperService.sortResponse)).subscribe(({ rows }) => this.buildings = rows);
    }
    /**
     *  This method sets the orientationangle property
     */
    setOrientation(event) {
        this.orientationangle = parseInt(event.value);
    }
    /**
     *  This method call saveSelections method when the user uploads a floor
     */
    uploadFloor() {
        this.planner?.saveSelections(this.siteRef, this.orientationangle);
        if (this.orientationangle != parseInt(this.stripHayStackTags(this.floorInfo[0]['orientation']).split(' ')[0])) {
            if (this.floorInfo) {
                this.floors.forEach((floor) => {
                    const payload = this.getFloorEntityPayload(floor, this.orientationangle, floor['floorNum']);
                    this.siteService.addEntity(payload).subscribe(() => {
                        floor['orientation'] = `n:${this.orientationangle}`;
                        this.floorInfo['orientation'] = this.orientationangle;
                    })
                })
            }
        }
    }

    getFloorEntityPayload(floor, orientationangle, floorOrderNumber) {
        let keys = Object.keys(floor);
        return `ver:"2.0"\nid,orientation${floorOrderNumber ? ',floorNum' : ''},dis,siteRef,floor${(keys.indexOf(constants.CREATED_BY_APPLICATION) > -1) ? `,${constants.CREATED_BY_APPLICATION}` : ''}${(keys.indexOf(constants.SPACE) > -1) ? `,${constants.SPACE}` : ''}\n@${this.stripHayStackTags(floor.id)},${orientationangle}${floorOrderNumber ? "," + this.stripHayStackTags("" + floorOrderNumber) : ''},"${floor.dis}",@${this.stripHayStackTags(floor.siteRef)},M${(keys.indexOf(constants.CREATED_BY_APPLICATION) > -1) ? `,"${floor[constants.CREATED_BY_APPLICATION]}"` : ''}${(keys.indexOf(constants.SPACE) > -1) ? ',M' : ''}`;
    }

    /**
     *  This method call resetSelections method  assign the resetAllZone to true and orientationangle as 0.
     */
    resetFloor() {
        this.resetAllZone = true;
        this.orientationangle = parseInt(this.stripHayStackTags(this.floorInfo ? this.floorInfo[0]['orientation'] : 0).split(' ')[0], 10);
        this.planner.resetSelections();
    }


    /**
     *  This method sets the selectedShapeType property.
     */
    selectPredefinedShape(shape) {
        this.selectedShapeType = shape;
    }
    /**
     *  This method is called when the floor plan is dragged and dataTransfer.dropEffect as 'move'
     */
    roomDragged(event, room) {
        this.currentBox = room; // This is the room Ref
        const pointerInsideZone = {
            x: event.x - event.target.getBoundingClientRect().left,
            y: event.y - event.target.getBoundingClientRect().top
        };
        event.dataTransfer.setData('text/plain', JSON.stringify(pointerInsideZone));
        event.dataTransfer.dropEffect = 'move';
    }
    /**
     *  In this method if the action = added the it sets the rooms property. If the action= removed then it removes that room.
     */
    roomMappingChanged(event) {
        // console.log(event);
        if (event.action === 'added') {
            this.rooms = this.rooms.filter(room => room && (room.id !== event.roomId));
        } else if (event.action === 'removed') {
            const relatedRoom = this.originalRooms.find(room => room && (room.id === event.roomId));
            const index = ArrayUtil.getIndex(this.originalRooms, 'id', event.roomId);

            const roomAlreadyInList = this.rooms.find(room => room && (room.id === event.roomId));
            if (!roomAlreadyInList && relatedRoom) {
                this.rooms.splice(index, 0, relatedRoom);
            }
        }
    }
    /**
     *  This method is called when the floors are reordered and calls saveFloorOrder method.
     */
    reorderFloors() {
        const deleteSub = new Subject();
        const dialogRef: any = this.dialog.open(SortOrderComponent, {
            width: '400px',
            panelClass: 'sort-order-dialog',
            data: {
                list: this.getFloorsOrder(ObjectUtil.deepClone(this.floors)),
                key: 'dis',
                title: 'Floors',
                subTitle: 'Drag and drop floors to reorder',
                showDelete: true,
                deleteObservable: deleteSub,
                deleteIconKey: 'showDelete'
            }
        });

        deleteSub.subscribe((floor: any) => {
            //delete floor
            if (floor) {
                this.deleteFloor(floor, dialogRef);
            }

        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.saveFloorOrder(result);
            }
            deleteSub.unsubscribe();
        })
    }
    /**
     *  This method deletes the selected floor.
     */
    deleteFloor(floor, sortDialogRef) {
        const self = this;
        const dialogRef = self.dialog.open(ConfirmModalComponent, {
            width: '300px',
        });
        let htmlContent = `Are you sure you want to delete floor : <b>${floor.dis}</b> ?`
        dialogRef.componentInstance.title = 'Confirmation';
        dialogRef.componentInstance.confirmBtnText = 'Delete';
        dialogRef.componentInstance.showConfirmIcon = false;
        dialogRef.componentInstance.showTimer = true;
        dialogRef.componentInstance.timer = 5;
        dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
        dialogRef.afterClosed().subscribe(result => {
            if (result == 'confirm') {
                const floorRef = this.stripHayStackTags(floor.id).split(' ')[0]
                this.siteService.removeEntity(floorRef).toPromise().then(() => {
                    this.alertService.success(`Floor successfully removed!`);
                    this.floors = this.floors.filter(f => f.id != floor.id);
                    sortDialogRef.componentInstance.list = this.getFloorsOrder(ObjectUtil.deepClone(this.floors))
                }).catch((err) => {
                    this.alertService.error(err.error.msg || `Failed to delete floor!`);
                })
            }
        })
    }
    /**
     *  This method returns the floors in sorted order.
     */
    getFloorsOrder(floors) {
        floors = floors.map(floor => {
            floor["floorNum"] = Number(this.helperService.stripHaystackTypeMapping(floor["floorNum"]));
            return floor;
        })

        floors = sortBy(floors, 'floorNum');
        return floors;

    }
    /**
     *  This method saves the floor order and calls the alertService.
     */
    saveFloorOrder(floors) {
        if (floors && floors.length) {
            let length = floors.length;
            for (let i = 0; i < floors.length; i++) {
                let orientationangle = floors[i] ? parseInt(this.stripHayStackTags(floors[i]['orientation'] ? floors[i]['orientation'] : 0)) : 0;
                const payload = this.getFloorEntityPayload(floors[i], orientationangle, "" + i);
                this.siteService.addEntity(payload).toPromise().then((res) => {
                    if (res.rows && res.rows.length) {
                        const index = this.floors.findIndex(floor => floor.id == res.rows[0].id);
                        if (index > -1) {
                            res.rows[0]['showDelete'] = this.floors[index]['showDelete'];
                            this.floors[index] = res.rows[0];
                        }
                    }
                    length--;
                    if (length == 0) {
                        this.alertService.success("Floors reordered successfully");
                    }
                }, err => {
                    length--;
                    if (length == 0) {
                        this.alertService.success("Floors reordered successfully");
                    }
                })
            }
        }
    }

    menuItemClicked(submenu: any) {
        if (submenu.label == "Floor Planner") {
            this.showFloorPlan = true;
            this.isHighlighted = false;
            this.isSiteActive =  true;
        }
    }


    toggleFloorPlan() {
        this.showFloorPlan = false
        this.isHighlighted = true;
        this.isSiteActive = false;
    }


    getPointByQuery() {
        let displayUnitQuery;
        const siteRefPart = `siteRef==@${this.siteRef}`;
        displayUnitQuery = `${siteRefPart} and ((writable and point and displayUnit))`;//use the or to add the point to the query EX: ((writable and point and displayUnit) or (writable and point and user and cooling and min and limit))
        this.siteService.findByQuery(displayUnitQuery).subscribe(res => {
            if (res && res.rows) {
                this.getPointData(res.rows);
            }
        })
    }

    getPointData(pointRows) {
        let resPoints = [];
        pointRows.forEach(pointData => {
            if (pointData.building && pointData.displayUnit && pointData.point && pointData.writable) {
                resPoints.push({
                    name: 'displayUnit',
                    tags: ['building', 'displayUnit', 'writable', 'point'],
                    id: pointData.id
                })
            }
        });
        if (resPoints.length) {
            this.displayControls = true
            this.getDataFromPointIds(resPoints);
        }
        else {
            this.displayControls = false;
        }
    }

    getDataFromPointIds(pointsData) {
        pointsData.forEach(element => {
            this.helperService.assemblePointIdData(this.helperService.stripHaystackTypeMapping(element.id), 'write', element.name, '', 'update');
        });
        this.getUserSettingData();
    }

    getUserSettingData() {
        let pointCurrentData: any = {};
        this.helperService.getPointData().subscribe(res => {
            if (res) {
                this.remoteDataArray = res;
                pointCurrentData.displayUnit = { val: res.displayUnit.val, level: '16' }
                this.remoteControls = pointCurrentData;
            }
        });
    }

    onCloseMethod(select: MatSelect) {
        this.selectedFloor = '';
    }

    
    checkUserCertificationAccess(view) {
        return this.facHelperService.checkCertification(view,this.showRemoteAccess);
    }

}
