import { SharedModule } from "@/shared/shared.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RemoteAccessComponent } from "./component/remote-access-lyt/remote-access.component";

const routes: Routes = [{ path: "", component: RemoteAccessComponent }];

@NgModule({
  declarations: [RemoteAccessComponent],
  imports: [RouterModule.forChild(routes), CommonModule, SharedModule],
  providers: [],
  entryComponents: [],
})
export class RemoteAccessLayoutModule {}
