<div class="floorplan-section p-l-20 p-t-30 p-r-20">
    <div class="row">
        <div class="col-3 settings-nav">
            <ul class="list-none">
                
                <ng-container *ngFor="let submenu of submenus">
                    <li class="m-20-a">
                        <div class="d-flex justify-content-between">
                            <a class="text-uppercase {{submenu.key}}" *ngIf="checkUserCertificationAccess(submenu.key)" [ngClass]="{'margin_left': submenu.key == 'building-options' || submenu.key == 'remote-ccu-access'}" [routerLink]="submenu?.path" [routerLinkActive]="['active']" (click)="menuItemClicked(submenu)" >
                                {{submenu?.label}}
                            </a>
                            <div *ngIf="floors.length && submenu.key == 'floor'" class="reorder-btn" [matTooltipPosition]="'above'" matTooltip="Reorder Floors" (click)="reorderFloors()">
                                <img src="assets/images/reorder.svg" alt=""/>
                            </div>
                        </div>
                        <ng-container *ngIf="submenu.key == 'floor'"  >
                            <div class="fs-settings__content__left__select-floor selectFloorDropDown" *ngIf="isSiteActive" (click)="menuItemClicked(submenu)">
                                    <puc-select-dropdown class="select-dropdown" (onfocusout)="onCloseMethod($event)" [(ngModel)]="selectedFloor" [noEntriesFoundLabel]="loadingVal" [emptyResults]="isEmpty" [placeholderLabel]="'Select Floor'" [data]="floors" [displayKey]="'dis'" [valueKey]="'id'" (valueChanged)="getFloorRooms($event)" [disabled]="isUploadProgress">
                                    </puc-select-dropdown>
                            </div>
                            <div class="fs-settings__content__left__overflow" *ngIf="floorSelected &&isSiteActive">
                                <div class="fs-settings__content__left__overflow__upload-btn">
                                    <label>
                                        <input #fileUploader id="floor" type="file" (change)="onAdd($event)"
                                            [disabled]="isUploadProgress" />
                                    </label>
                                    <p>Upload a .jpg or .png or .gif less than 5MB</p>
                                </div>
                                <div class="fs-settings__content__left__overflow__zones">
                                    <p>Zones
                                        <span>Drag and Drop onto floor plan</span>
                                    </p>
                                    <div id="zone-container" class="d-flex justify-content-between relative" [ngClass]="{'m-h-a':(showTuners)}"
                                        (scroll)="scrollMe($event,'zones')">
                                        <div class="predefined-shapes">
                                          <ng-container *ngFor="let shape of predefinedShapes">
                                            <div [ngClass]="['box', shape, selectedShapeType === shape ? 'is-active': '']" (click)=selectPredefinedShape(shape)>
                                              <div [ngClass]="['box-inner', shape]"></div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <div class="room-names-list">
                                              <ng-container *ngFor="let room of rooms">
                                                  <div class="room-name">
                                                      <div data-id="{{ stripHayStackTags(room?.id).split(' ')[0] }}" id="{{ room?.dis }}"
                                                          draggable="true" (dragstart)="roomDragged($event, room)">
                                                          {{ room?.dis }}
                                                          <div class="drag-handle" cdkDragHandle>
                                                              <i class="fas fa-arrows-alt"></i>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </ng-container>
                                        </div>
                                        <div class="dummy-scroll" *ngIf="!showTuners"></div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                    </li>
                </ng-container>
            </ul>     
       </div>

        <div class="col-9 floor-content" [hidden]="!showFloorPlan ">
            <div class="row" *ngIf="floorSelected">
                <div class="fs-settings__upload-section__floor-wrapper__preview-image col-10" id="floor_plan_ctnr" *ngIf="floorRef">
                    <puc-planner-layout [selectedFloorId]=floorRef [siteRef]=siteRef [url]=url [predefinedShapeType]=selectedShapeType [selectedRoom]=currentBox [selectedFile]=selectedFile
                        (roomMappingChanged)=roomMappingChanged($event) [existingZonesMapped]=droppeditem mode='planner'></puc-planner-layout>
                </div>
                <div class="fs-settings__upload-section__floor-wrapper__orientation col-2 d-flex p-0-20 p-l-10 flex-column"
                    *ngIf="displayOrientation">
                    <div class="orientation__container">
                        <p class="font-weight-bold">Set Orientation</p>
                        <div>
                            <mat-select  class="dropdownselect" (selectionChange)="setOrientation($event)" [(ngModel)]="orientationangle"
                                class="fs-settings__upload-section__floor-wrapper__orientation__angle sel-primary m-b-20">
                                <mat-option *ngFor="let angle of orientationSelectValues" [id]="angle" [value]="angle">{{angle}}&#xb0;
                                </mat-option>
                            </mat-select>
                        </div>

                        <img class="fs-settings__upload-section__floor-wrapper__orientation__compass"
                            [ngStyle]="{'transform': 'rotate(' + orientationangle + 'deg  )'}"
                            src="./assets/images/compass.svg" alt =""/>

                    </div>

                    <div
                        class="fs-settings__upload-section__floor-wrapper__orientation__resetUpload d-flex flex-column align-items-start justify-content-end floor-upload-btns p-l-60">
                        <button id="reset-floor" class="btn btn-outline-primary" (click)="resetFloor()"
                            *ngIf="url || onActiveFloorPlan">RESET</button>
                        <button id="update-floor" class="btn btn-outline-primary m-l-0 m-t-10" *ngIf="isFloorActive"
                            (click)="uploadFloor()" [disabled]="isUploadProgress">UPDATE</button>
                    </div>
                </div>
            </div>
            <div class="fs-settings__upload-section__floor-wrapper col-12 d-flex justify-content-center align-items-center"
                *ngIf="!floorSelected">
                <div class="fs-settings__upload-section__floor-wrapper--no-floor text-center">
                    <div>
                        <img width="100" src="./assets/images/info.svg">
                    </div>
                    <div>Please Select The Floor</div>
                </div>
            </div>
        </div>
    </div>
</div>
