import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteManagerComponent } from './site-manager.component';
import { SiteManagerRoutingModule } from './site-manager-routing.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [SiteManagerComponent],
  imports: [
    CommonModule,
    SiteManagerRoutingModule,
    NgxSkeletonLoaderModule
  ]
})
export class SiteManagerModule { }
