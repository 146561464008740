import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TunersApplyComponent } from './components/tuners-apply/tuners-apply.component';
import { SharedModule } from '@/shared/shared.module';
import { MaterialModule } from '@/shared/material.module';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import {

    OverridePriorityComponent,
    PucComponentsModule,
    TunersModalComponent,

} from '@75f/portal-ui-components';
const routes: Routes = [
    { path: '', component: TunersApplyComponent }
];
@NgModule({
    declarations: [TunersApplyComponent],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        FormsModule,
        RouterModule.forChild(routes),
        PucComponentsModule
    ],
    entryComponents: [
        OverridePriorityComponent,
        TunersModalComponent
    ]
})
export class TunersModule { }
