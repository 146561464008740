import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from './components/dashboard-layout/dashboard-layout.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '@/shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
const routes: Routes = [
    { path: '', component: DashboardLayoutComponent }
];
@NgModule({
    declarations: [DashboardLayoutComponent],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxSkeletonLoaderModule,
        RouterModule.forChild(routes)
    ]
})
export class DashboardLayoutModule { }
