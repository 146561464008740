<div class="fs-wrapper">

    <div class="fs-header">
        <fs-topbar [isDashboardPage]="isDashBoardUrl" [isExpanded]="message.isExpanded"></fs-topbar>
    </div>
    <div class="fs-sidebar" *ngIf="!isDashBoardUrl">
        <div>
            <fs-sidebar [isExpanded]="message.isExpanded"></fs-sidebar>
        </div>
    </div>
    <div class="fs-content">
        <router-outlet></router-outlet>
    </div>
</div>