import { Injectable } from "@angular/core";
import { Building } from "@shared/_models";

// Service created to restrict constant reloading of User Intent slider as input is Continuous
@Injectable({
    providedIn: 'root'
})
export class SelectedBuildingService {
    SELECTED_SITE:string = 'SelectedSite';
    public _selectedbuilding: Building = undefined;
    public _isselectedbuilding: boolean = false;

    // Add latest Selected Building's properties
    public setBuildingData(selectedbuilding: Building) {
        if (selectedbuilding) {
            this._selectedbuilding = selectedbuilding;
            this._isselectedbuilding = true;
        }
    }


    // Fetch data by passing the latest value holder data source
    public getBuildingData(): Building {
        return this._selectedbuilding;
    }


    //To fetch whether the building is selected or not
    public getSelectedBuildingFlagBy(): boolean {
        return this._isselectedbuilding;
    }

    //To set whether the building is selected or not
    public clearSelectedBuilding() {
        this._selectedbuilding = undefined;
        this._isselectedbuilding = false;
    }

    // add setter and getter methods for the selected building
    public setSelectedBuilding(selectedbuilding: Building) {
        localStorage.setItem(this.SELECTED_SITE, JSON.stringify(selectedbuilding));
        this._selectedbuilding = selectedbuilding;
    }

    public getSelectedBuilding(): Building {
        if (this._selectedbuilding) {
            return this._selectedbuilding;
        } else {
            this._selectedbuilding = JSON.parse(localStorage.getItem(this.SELECTED_SITE));
            return this._selectedbuilding;
        }
    }

}

