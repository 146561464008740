import { AfterViewInit, Component } from '@angular/core';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({
  selector: 'fs-named-schedule-layout',
  templateUrl: './named-schedule-layout.component.html'
})
export class NamedScheduleLayoutComponent implements AfterViewInit  { 
  constructor(private analyticsService: AnalyticsService) {
}

  ngAfterViewInit(){
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }
}
