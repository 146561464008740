import { Component, OnInit } from '@angular/core';
import { MessageService, HelperService, ConfigurationService, NotificationsService } from '@75f/portal-ui-components';
declare var firebase;

@Component({
  selector: 'fc-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.scss']
})
export class ProtectedComponent implements OnInit {

  message: any = {
    isExpanded: false
  };
  state: boolean = false;
  subscription: any;
  isDashBoardUrl: boolean = false;
  subscriptions: any = {};

  constructor(
    public messageService: MessageService,
    public notificationService: NotificationsService,
    private helperService: HelperService,
    private configService: ConfigurationService,
  ) { }

  ngOnInit() {

    this.subscriptions['isDashboardUrl'] = this.helperService.isDashboardPage$.subscribe((_res) => {
      this.isDashBoardUrl = _res;
    })

    if (!localStorage.getItem('SelectedSite'))
      this.helperService.isDashboardPage$.next(true);


    this.subscription = this.messageService.getMessage().subscribe(message => this.message = message);
    this.firebaseInit();
  }

  firebaseInit() {
    if(firebase) {
      let firebaseConfig = this.configService.getConfig('firebaseConfig');
      const self = this;
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      if (firebase && firebase.messaging()) {
        // requesting permission to use push notifications
        firebase.messaging().requestPermission().then(() => {
          // getting FCM token
          //navigator.serviceWorker.getRegistrations().then(function(registrations) { for(let registration of registrations) { registration.unregister() } })
          if('serviceWorker' in navigator) {
            navigator.serviceWorker.register('./assets/js/firebase-messaging-sw.js',{scope: "/assets/js/firebase-cloud-messaging-push-scope"})
            .then(function(registration) {
              firebase.messaging().useServiceWorker(registration);
              firebase.messaging().getToken().then((fcmToken) => {
                self.registerPushNotification(fcmToken)
              }).catch((err) => {
                // can't get token
                console.log(err);
              });
            });
          }

        }).catch((err) => {
          console.log(err);
        });

        firebase.messaging().onMessage((payload) => {
          // ...
          navigator.serviceWorker.getRegistration('./assets/js/firebase-cloud-messaging-push-scope').then(registration => {

            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: payload.notification.body,
              icon: "./favicon.ico"
            };
            registration.showNotification(
              notificationTitle,
              notificationOptions
            )
        });
        });
        firebase.messaging().onTokenRefresh((token) => {
          self.registerPushNotification(token);
        });

      } else {
        // no Firebase library imported or Firebase library wasn't correctly initialized
      }
    }
  }


  registerPushNotification(fcmToken) {
    this.notificationService.registerPushNotification(fcmToken).subscribe();
  }

  ngOnDestroy() {
    const self = this;
    self.subscription.unsubscribe();
    Object.keys(self.subscriptions).forEach(e => {
      self.subscriptions[e].unsubscribe();
    });
  }

}
