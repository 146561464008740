export const ProfileUserIntent = {
    hsCpu: [{
        name: 'analogOutRelay1',
        tags: ['analog1', 'cmd', 'enabled', 'output'],
        type: 'write'
    },
    {
        name: 'analogOutRelay2',
        tags: ['analog2', 'cmd', 'enabled', 'output'],
        type: 'write'

    }, {
        name: 'analogOutRelay3',
        tags: ['analog3', 'cmd', 'enabled', 'output'],
        type: 'write'

    }, {
        name: 'relay1',
        tags: ['zone', 'relay1', 'enabled', 'cmd'],
        type: 'write'

    }, {
        name: 'relay2',
        tags: ['zone', 'relay2', 'enabled', 'cmd'],
        type: 'write'

    }, {
        name: 'relay3',
        tags: ['zone', 'relay3', 'enabled', 'cmd'],
        type: 'write'

    }, {
        name: 'relay4',
        tags: ['zone', 'relay4', 'enabled', 'cmd'],
        type: 'write'

    }, {
        name: 'relay5',
        tags: ['zone', 'relay5', 'enabled', 'cmd'],
        type: 'write'

    }, {
        name: 'relay6',
        tags: ['zone', 'relay6', 'enabled', 'cmd'],
        type: 'write'

    }, {
        name: 'analogOut1Association',
        tags: ['enum', 'analog1', 'association', 'output'],
        type: 'write'

    }, {
        name: 'analogOut2Association',
        tags: ['enum', 'analog2', 'association', 'output'],
        type: 'write'

    }, {
        name: 'analogOut3Association',
        tags: ['enum', 'analog3', 'association', 'output'],
        type: 'write'

    }, {
        name: 'relay1Association',
        tags: ['association', 'relay1', 'config', 'dis'],
        type: 'write'

    }, {
        name: 'relay2Association',
        tags: ['association', 'relay2', 'config', 'dis'],
        type: 'write'

    }, {
        name: 'relay3Association',
        tags: ['association', 'relay3', 'config', 'dis'],
        type: 'write'

    }, {
        name: 'relay4Association',
        tags: ['association', 'relay4', 'config', 'dis'],
        type: 'write'

    }, {
        name: 'relay5Association',
        tags: ['association', 'relay5', 'config', 'dis'],
        type: 'write'

    }, {
        name: 'relay6Association',
        tags: ['association', 'relay6', 'config', 'dis'],
        type: 'write'

    }, {
        name: 'relay6Type',
        tags: ['config', 'association', 'relay6', 'hyperstat', 'type'],
        type: 'write'

    }, {
        name: 'heatingstage1',
        tags: ['cmd', 'heating', 'stage1', 'his'],
        type: 'write'
    }, {
        name: 'heatingstage2',
        tags: ['cmd', 'heating', 'stage2', 'his'],
        type: 'write'
    }, {
        name: 'coolingstage1',
        tags: ['cooling', 'stage1', 'his', 'hisInterpolate', 'cmd'],
        type: 'write'
    }, {
        name: 'coolingstage2',
        tags: ['cooling', 'stage2', 'his', 'hisInterpolate', 'cmd'],
        type: 'write'
    }, {
        name: 'airflowtempsensor',
        tags: ['air', 'cur', 'discharge', 'sensor'],
        type: 'write'
    }, {
        name: 'conditioningMode',
        tags: ['mode', 'conditioning', 'zone', 'sp'],
        type: 'write'
    }, {
        name: 'fanMode',
        tags: ['zone', 'fan', 'mode', 'operation', 'sp'],
        type: 'write'
    }, {
        name: 'dischargeAirTemp',
        tags: ['discharge', 'air', 'temp', 'sensor'],
        type: 'read'
    }
    ],

    cpu: [{
        name: 'targetHumidity',
        tags: ['target', 'humidity'],
        type: 'write'
    }, {
        name: 'relay6Type',
        tags: ['config', 'relay6', 'group', 'cpu', 'type'],
        type: 'write'
    }, {
        name: 'heatingstage1',
        tags: ['relay4', 'cpu', 'zone', 'standalone', 'enable'],
        type: 'write'
    }, {
        name: 'heatingstage2',
        tags: ['relay5', 'cpu', 'zone', 'standalone', 'enable'],
        type: 'write'
    }, {
        name: 'coolingstage1',
        tags: ['zone', 'relay1', 'cpu', 'enable', 'standalone'],
        type: 'write'
    }, {
        name: 'coolingstage2',
        tags: ['zone', 'relay2', 'cpu', 'enable', 'standalone'],
        type: 'write'
    }, {
        name: 'airflowtempsensor',
        tags: ['th1', 'cpu', 'zone', 'standalone', 'enable'],
        type: 'write'
    }, {
        name: 'conditioningMode',
        tags: ['zone', 'temp', 'mode', 'conditioning'],
        type: 'write'
    }, {
        name: 'fanMode',
        tags: ['zone', 'fan', 'mode', 'operation'],
        type: 'write'
    }, {
        name: 'dischargeAirTemp',
        tags: ['zone', 'discharge', 'air', 'temp', 'sensor'],
        type: 'read'
    }],

    hyperStatPipe2 : {
        userIntent : [
            {
                name:'equipStatusMessage',
                tags: ['zone', 'message', 'status'],
                type: 'write'
            },
            {
                name:'lastUpdated',
                tags: ['heartbeat'],
                type: 'read'
            },
            {
                name:'conditioningMode',
                tags: ['zone', 'sp', 'mode', 'conditioning'],
                type: 'write'
            },
            {
                name:'fanMode',
                tags: ['zone', 'fan', 'mode', 'operation','sp'],
                type: 'write'
            },
            {
                name:'targetHumidity',
                tags: ['target', 'humidifier'],
                type: 'write'
            },
            {
                name:'targetDehumidity',
                tags: ['target', 'dehumidifier'],
                type: 'write'
            },
            {
                name:'dischargeAirTemp',
                tags: ['discharge', 'air', 'sensor'],
                type: 'read'
            },
            {
                name:'relay1',
                tags: ['zone', 'relay1', 'enabled'],
                type: 'write'
            },
            {
                name:'relay2',
                tags: ['zone', 'relay2', 'enabled'],
                type: 'write'
            },
            {
                name:'relay3',
                tags: ['zone', 'relay3', 'enabled'],
                type: 'write'
            },
            {
                name:'relay4',
                tags: ['zone', 'relay4', 'enabled'],
                type: 'write'
            },
            {
                name:'relay5',
                tags: ['zone', 'relay5', 'enabled'],
                type: 'write'
            },
            {
                name:'relay6',
                tags: ['zone', 'relay6', 'enabled'],
                type: 'write'
            },
            {
                name:'relay1Association',
                tags: ['zone', 'relay1', 'association'],
                type: 'write'
            },
            {
                name:'relay2Association',
                tags: ['zone', 'relay2', 'association'],
                type: 'write'
            },
            {
                name:'relay3Association',
                tags: ['zone', 'relay3', 'association'],
                type: 'write'
            },
            {
                name:'relay4Association',
                tags: ['zone', 'relay4', 'association'],
                type: 'write'
            },
            {
                name:'relay5Association',
                tags: ['zone', 'relay5', 'association'],
                type: 'write'
            },
            {
                name:'relay6Association',
                tags: ['zone', 'relay6', 'association'],
                type: 'write'
            },
            {
                name:'analogOutRelay1',
                tags: ['analog1', 'output', 'enabled'],
                type: 'write'
            },   
            {
                name:'analogOutRelay2',
                tags: ['analog2', 'output', 'enabled'],
                type: 'write'
            },
            {
                name:'analogOutRelay3',
                tags: ['analog3', 'output', 'enabled'],
                type: 'write'
            },
            {
                name:'analogOut1Association',
                tags: ['analog1', 'output', 'association'],
                type: 'write'
            },
            {
                name:'analogOut2Association',
                tags: ['analog2', 'output',  'association'],
                type: 'write'
            },
            {
                name:'analogOut3Association',
                tags: ['analog3', 'output',  'association'],
                type: 'write'
            },
            {
                name : 'scheduleType',
                tags :['scheduleType'],
                type: 'write'
            },
        ],
    },

    hyperStatHpu : {
        userIntent : [
            {
                name:'equipStatusMessage',
                tags: ['zone', 'message', 'status'],
                type: 'write'
            },
            {
                name: 'scheduleType',
                tags: ['zone', 'scheduleType'],
                type: 'write'
            },
            {
                name:'lastUpdated',
                tags: ['heartbeat'],
                type: 'read'
            },
            {
                name:'conditioningMode',
                tags: [ 'zone', 'sp', 'mode', 'conditioning'],
                type: 'write'
            },
            {
                name:'fanMode',
                tags: ['zone','sp','fan','mode','operation'],
                type: 'write'
            },
            {
                name:'targetHumidity',
                tags: ['target', 'humidifier'],
                type: 'write'
            },
            {
                name:'targetDehumidity',
                tags: ['target', 'dehumidifier'],
                type: 'write'
            },
            {
                name:'dischargeAirTemp',
                tags: ['discharge', 'air', 'sensor'],
                type: 'read'
            },
            {
                name:'relay1',
                tags: ['zone', 'relay1', 'enabled'],
                type: 'write'
            },
            {
                name:'relay2',
                tags: ['zone', 'relay2', 'enabled'],
                type: 'write'
            },
            {
                name:'relay3',
                tags: ['zone', 'relay3', 'enabled'],
                type: 'write'
            },
            {
                name:'relay4',
                tags: ['zone', 'relay4', 'enabled'],
                type: 'write'
            },
            {
                name:'relay5',
                tags: ['zone', 'relay5', 'enabled'],
                type: 'write'
            },
            {
                name:'relay6',
                tags: ['zone', 'relay6', 'enabled'],
                type: 'write'
            },
            {
                name:'relay1Association',
                tags: ['zone', 'relay1', 'association'],
                type: 'write'
            },
            {
                name:'relay2Association',
                tags: ['zone', 'relay2', 'association'],
                type: 'write'
            },
            {
                name:'relay3Association',
                tags: ['zone', 'relay3', 'association'],
                type: 'write'
            },
            {
                name:'relay4Association',
                tags: ['zone', 'relay4', 'association'],
                type: 'write'
            },
            {
                name:'relay5Association',
                tags: ['zone', 'relay5', 'association'],
                type: 'write'
            },
            {
                name:'relay6Association',
                tags: ['zone', 'relay6', 'association'],
                type: 'write'
            },
            {
                name:'analogOutRelay1',
                tags: ['analog1', 'output', 'enabled'],
                type: 'write'
            },   
            {
                name:'analogOutRelay2',
                tags: ['analog2', 'output', 'enabled'],
                type: 'write'
            },
            {
                name:'analogOutRelay3',
                tags: ['analog3', 'output', 'enabled'],
                type: 'write'
            },
            {
                name:'analogOut1Association',
                tags: ['analog1', 'output', 'association'],
                type: 'write'
            },
            {
                name:'analogOut2Association',
                tags: ['analog2', 'output',  'association'],
                type: 'write'
            },
            {
                name:'analogOut3Association',
                tags: ['analog3', 'output',  'association'],
                type: 'write'
            }
        ],
    },
}