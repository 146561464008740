import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, state, animate, style } from '@angular/animations';
import { FeatureToggleService, HelperService, AlertService, AuthenticationService } from '@75f/portal-ui-components';
import { Subject, takeUntil } from 'rxjs';
import { CPMService } from '@/shared/_services/cpm-service';

@Component({
  selector: 'fs-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '141px',
        opacity: 1,
      })),
      state('closed', style({
        width: '0px',
        opacity: 1,
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
      transition('* => closed', [
        animate('0s')
      ]),
      transition('* => open', [
        animate('0.3s')
      ]),
      transition('open <=> closed', [
        animate('0.2s')
      ]),
      transition('* => open', [
        animate('0.3s',
          style({ opacity: '*' }),
        ),
      ]),
      transition('* => *', [
        animate('0.3s')
      ]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  @Input() isExpanded?: boolean = false;
  siteRef: any;
  cpm:any = false;
  isVofm = false;
  isSiteHasPublishedPlants: boolean = false;
  private unsubscribe: Subject<void> = new Subject();
  constructor(public router: Router,
    private helperService: HelperService,
    public featureToggleService: FeatureToggleService,
    private cpmService: CPMService,
    private alertService: AlertService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    const selectedSite = JSON.parse(localStorage.getItem('SelectedSite'));
    this.siteRef = this.helperService.stripHaystackTypeMapping(selectedSite.siteId).split(' ')[0];
    this.featureToggleService.flagChange.pipe(takeUntil(this.unsubscribe)).subscribe((flags)=>{
      this.setFlags(flags);
  });
  this.getPublishedPlantsOfOrg();
  this.useFeatureToggles();
  }

  useFeatureToggles() {
    let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags)=>{
        this.setFlags(flags);
        sub.unsubscribe();
    }); 
    this.featureToggleService.getFlags();
}

setFlags(flags) {
  this.cpm = flags.hasOwnProperty('cpm') ? flags['cpm'].value: false;
}
/**
 *  This method sets the  style width
 */
  open(event: any) {
    window.dispatchEvent(new Event('resize'));
    if (event.target.getElementsByTagName('span')[0].style.width == '0px') {
      event.target.getElementsByTagName('span')[0].style.width = '140px'
    }
  }
/**
 *  This method sets the  style width
 */
  close(event: any) {
    window.dispatchEvent(new Event('resize'));
    if (event.target.getElementsByTagName('span')[0].style.width == '140px') {
      event.target.getElementsByTagName('span')[0].style.width = '0px'
    }
  }
/**
 *  This method call the navigate method in router and navigates to '/alerts'
 */
  navigateToAlerts() {
    this.router.navigate(['/alerts'], { queryParams: { allSite: false } });
  }

  get isSettingsAccessable(): boolean {
    return this.authService.isUserViewOnly();

}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getPublishedPlantsOfOrg() {
    if (localStorage.getItem('certificateLevel') == "L3") {
      this.isSiteHasPublishedPlants = true;
    } else {
      const buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
      this.cpmService.getPublishedPlants(buildingDetails.orgName).subscribe({
        next: (_res: any) => {
          if (_res?.length) {
            this.isSiteHasPublishedPlants = _res.find(_item => _item.siteId == this.helperService.stripHaystackTypeMapping(buildingDetails.siteId));
          }
        }, error: (err) => {
          this.alertService.error(err.error || 'Something went wrong, please try again');
        }
      });
    }
  }
}
