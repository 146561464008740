import {
    Component,
    ViewEncapsulation,
    OnInit
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { LoaderService, SiteService, HelperService, AuthenticationService } from '@75f/portal-ui-components';
import { map } from 'rxjs/internal/operators/map';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';


@Component({
    selector: 'fs-alerts-layout',
    templateUrl: './alerts-layout.component.html',
    styleUrls: ['./alerts-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AlertsLayoutComponent implements OnInit {

    displayAllSite: boolean;
    allSitesSelectedForAlerts: boolean
    siteList: any = [];

    siteRefs = [];
    selectedRow;
    siteTimeZones = {};
    isVofm = false;

    constructor(
        public route: ActivatedRoute,
        public siteService: SiteService,
        public helperService: HelperService,
        private loaderService: LoaderService,
        private analyticsService: AnalyticsService,
        private authService: AuthenticationService
    ) {

    }

    ngOnInit() {
        this.isVofm = this.authService.isUserViewOnly();
        this.loaderService.active(true);
        this.route.queryParams.subscribe(params => {
            if (params.allSite) {
                if (params.allSite == "true") {
                    this.displayAllSite = true;
                    this.allSitesSelectedForAlerts = true;
                }
                else {
                    this.displayAllSite = false;
                    this.allSitesSelectedForAlerts = false;
                }

            }
            else {
                let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
                if (buildingDetails) {
                    this.displayAllSite = false;
                    this.allSitesSelectedForAlerts = false;
                    this.siteRefs = [this.helperService.stripHaystackTypeMapping(buildingDetails.siteId).split(' ')[0]];
                }
                else {
                    this.displayAllSite = true;
                    this.allSitesSelectedForAlerts = true;
                }

            }
            if (this.displayAllSite) {
                this.fetchAllSiteData();
                this.selectedRow = -1;
            }
            else {
                this.fetchSiteSpecificData();
            }
        });
    }

    ngAfterViewInit() {
        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }

/**
 * This method assign the value for allSitesSelectedForAlerts, siteRefs and selectedRow.
 */ 
    selecteSite(siteId: string, selectedIndex: number) {
        if(selectedIndex == -1) {
            this.siteRefs = this.siteList.map(s=>s.siteId);
            this.allSitesSelectedForAlerts = true;
        } else {
            this.siteRefs = Array.from([siteId]);
            this.allSitesSelectedForAlerts = false;
        }
        this.selectedRow = selectedIndex;
    }
/**
 * This method assign the value for siteTimeZones
 */ 
    getSiteTimeZones() {
        const authSiteIds = this.siteRefs;
        this.siteService.getReadByIdMany(authSiteIds).pipe(
        map(this.helperService.stripHaystackTypeMapping),
        ).subscribe((res)=>{
            res.rows.map((site)=>{
                this.siteTimeZones[site.id] = this.siteService.getIANATimeZone(site.tz);
            })
           
        },err=>{

        })
      }

/**
 * This method calls getSiteTimeZones() method and siteService.getAuthSites()
 */ 
    fetchAllSiteData() {
        this.siteList = [];
        localStorage.removeItem('SelectedSite');
        this.siteService.getAuthSites().subscribe(data => {
            if (data?.sites?.length > 0) {
                this.isVofm = data.sites.filter(s=>s.isReadOnly).length == data.sites.length;
                this.siteList = data.sites.map(({ siteId, siteName }) => ({ siteId: siteId.split('@')[1], siteName }));;
                this.siteRefs = this.siteList.map(s=>s.siteId);
                if(this.siteList.length < 2) {
                    this.displayAllSite = false;
                    this.allSitesSelectedForAlerts = false;
                    this.selectedRow = 0;
                }
                this.getSiteTimeZones();
            }
        });

    }
/**
 * This method calls getSiteTimeZones() method if buildingDetails else calls fetchAllSiteData() method.
 */
    fetchSiteSpecificData() {
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        if(buildingDetails) {
            this.siteRefs = [this.helperService.stripHaystackTypeMapping(buildingDetails.siteId).split(' ')[0]];
            this.getSiteTimeZones();
        } else {
            this.displayAllSite = true;
            this.allSitesSelectedForAlerts = true;
            this.fetchAllSiteData();
            this.selectedRow = -1;
        }
        
    }

}