import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';

import { RouterModule } from '@angular/router';

import {
    PucPipesModule, PucDirectivesModule, PucComponentsModule, ConfirmModalComponent, VinMentionModule
} from '@75f/portal-ui-components';
@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        PucPipesModule,
        PucComponentsModule,
        PucDirectivesModule,
        VinMentionModule
    ],
    exports: [
        MaterialModule,
        PucPipesModule,
        PucComponentsModule,
        PucDirectivesModule
    ],
    providers: [

    ],
    entryComponents: [
        ConfirmModalComponent
    ]
})
export class SharedModule { }
