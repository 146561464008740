<section class="building-option-sections p-l-20 p-t-30 p-r-20">
    <div class="row">
        <div class="settings-nav col-3">
            <ul class="list-none text-uppercase p-l-15">
                <li *ngFor="let submenu of submenus;let i = index;" class="m-20-a">
                    <a  *ngIf="checkUserCertificationAccess(submenu.key)" [routerLink]="submenu?.path" class="{{submenu.key}}" id="{{'building-option-'+i}}" [ngClass]="{'margin_left': submenu?.key == 'remote-ccu-access'}" [routerLinkActive]="['active']">
                        {{submenu?.label}}
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-9">
            <puc-remote-access-portal [siteId]="siteId"></puc-remote-access-portal> 
        </div>
    </div>
</section>