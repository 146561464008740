import { settingsMenu } from "@/modules/settings/menu-constant";
import { HelperServiceFacilisight } from "@/shared/_services/helper.service";
import { AuthenticationService, FeatureToggleService, HelperService } from "@75f/portal-ui-components";
import { Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "fs-remote-access",
  templateUrl: "./remote-access.component.html",
  styleUrls: ["./remote-access.component.scss"],
})
export class RemoteAccessComponent implements OnInit {
  siteId: string;
  private unsubscribe: Subject<void> = new Subject();
  submenus: any[] = settingsMenu;
  showRemoteAccess: boolean = false;
  constructor(
    public helperService: HelperService,
    public featureToggleService: FeatureToggleService,
    public authService: AuthenticationService,
    public facHelperService: HelperServiceFacilisight
  ) { }

  ngOnInit(): void {
    const selectedSite = JSON.parse(localStorage.getItem("SelectedSite"));

    this.siteId = this.helperService.stripHaystackTypeMapping(
      selectedSite?.siteId
    );
    this.useFeatureToggles();
  }

  checkUserCertificationAccess(view) {
    return this.facHelperService.checkCertification(view,this.showRemoteAccess);
  }

  useFeatureToggles() {
    let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags) => {
      this.setFlags(flags);
      sub.unsubscribe();
    });
    this.featureToggleService.getFlags();
  }

  setFlags(flags) {
    this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value : false;
  }
}
