import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@/shared/shared.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Routes, RouterModule } from '@angular/router';
import { SettingsFloorPlanLayoutComponent } from './components/settings-floor-plan-layout/settings-floor-plan-layout.component';
import { FileService, PucDirectivesModule, SortOrderComponent, RemoteInstallerModule } from '@75f/portal-ui-components';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BuildingOptionsComponent } from '../building-options/components/building-options/building-options.component';



const routes: Routes = [
    { path: '', component: SettingsFloorPlanLayoutComponent },
];

@NgModule({
    declarations: [
        SettingsFloorPlanLayoutComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        NgxSkeletonLoaderModule,
        CommonModule,
        SharedModule,
        RemoteInstallerModule,
        FormsModule,
        PucDirectivesModule,
        TableVirtualScrollModule,
        NgxSliderModule,
    ],
    providers: [
        FileService,
        RemoteInstallerModule,

        NgxSliderModule,

    ],
    entryComponents: [SortOrderComponent,
    ]
})
export class SettingsFloorPlanModule { }
