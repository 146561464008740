<div class="m-t-20">
    <div class="col-xl-10 col-xs-12">
        <mat-accordion hideToggle="true">
            <mat-expansion-panel [expanded]="isFormShown" (closed)="cancelForm()" (opened)="isFormShown=true;init()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Add User <i *ngIf="!isFormShown" class=" m-l-5 fa fa-plus icon" aria-hidden="true"></i>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <form [formGroup]="userForm" class="m-t-10" action="">
                        <div class="row">
                            <div class="col">
                                <div class="flex-grow">
                                    <mat-form-field [floatLabel]="'never'" class="w-100 fontDetails">
                                        <input id="add-user-email" matInput trim type="text" formControlName="email" placeholder="Email ID"
                                            [errorStateMatcher]="errorMatcher" autocomplete="off"
                                            (blur)="checkUserExists()">
                                        <span class="required-field" matPrefix>*</span>
                                        <mat-error
                                            *ngIf="userForm.controls.email.hasError('required') && (userForm.controls.email.dirty || userForm.controls.email.touched)">
                                            Email is required
                                        </mat-error>
                                        <mat-error
                                            *ngIf="userForm.controls.email.hasError('email') && (userForm.controls.email.dirty || userForm.controls.email.touched)">
                                            Not a valid email
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col">
                                <div class="m-l-10 flex-grow fontDetails">
                                    <mat-form-field [floatLabel]="'never'" class="w-100">
                                        <input id="add-user-firstName" matInput type="text" trim="blur" formControlName="firstName"
                                            placeholder="First Name"
                                            autocomplete="off" [readonly]="isInputEditable">
                                        <span class="required-field" matPrefix>*</span>
                                        <mat-error class="err-text">
                                            <ng-container *ngIf="!userForm.controls.firstName.hasError('forbiddenName') && userForm.controls.firstName.invalid && !userForm.controls.firstName.hasError('pattern') && (userForm.controls.firstName.dirty || userForm.controls.firstName.touched)">
                                            First Name is required
                                            </ng-container>
                                            <ng-container *ngIf="!userForm.controls.firstName.hasError('forbiddenName') && userForm.controls.firstName.hasError('pattern')">
                                                <ng-container *ngTemplateOutlet="errorMsgTemplate;   
                                                context: { name:'First Name' }">
                                                </ng-container>
                                            </ng-container>
                                           <ng-container *ngIf="userForm.controls.firstName.hasError('forbiddenName')">
                                            First Name cannot start with special characters.
                                            </ng-container>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col">
                                <div class="m-l-10 flex-grow fontDetails">
                                    <mat-form-field [floatLabel]="'never'" class="w-100">
                                        <input id="add-user-lastName" matInput type="text" trim="blur" formControlName="lastName"
                                            placeholder="Last Name" [errorStateMatcher]="errorMatcher"
                                            autocomplete="off" [readonly]="isInputEditable">
                                        <mat-error class="err-text">
                                            <ng-container *ngIf="!userForm.controls.lastName.hasError('forbiddenName') && userForm.controls.lastName.hasError('pattern')">
                                                <ng-container  *ngTemplateOutlet="errorMsgTemplate;   
                                                context: { name:'Last Name' }">
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="userForm.controls.lastName.hasError('forbiddenName')">
                                                Last Name cannot start with special characters.
                                            </ng-container>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <ng-template #errorMsgTemplate let-name='name'>
                            Special characters are not allowed in {{name}} Allowed characters[hyphen(-), underscore(_), space()].
                        </ng-template>

                        <div class="row">
                            <div class="col">
                                <div class="flex-grow">
                                    <div class="w-100">
                                        <puc-select-dropdown [placeholderLabel]="'Select Role'" [isRequired]="true"
                                            [data]="filteredRoles" [displayKey]="'name'" [search]="false"
                                            formControlName="role" (valueChanged)="roleChanged()">
                                        </puc-select-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col" *ngIf="showZonesDropdown">
                                <div class="tooltip-zones">
                                <span id="user-tooltip-text" *ngIf="selectedZones?.length ? true: false" class="tooltiptext" [innerHTML]="generateTooltipText()"></span>
                                <div class="m-l-10 flex-grow fontDetails">
                                    <puc-multi-select-checkbox #multiSelect [form]="userForm" [formControlName]="'zones'" [placeHolder]="'Select Zones'" (items)="getSelectedZones($event)" [inputPlaceHolder]="'Search for Zones'" 
                                    [groupedItems]="groupedZoneList" [noEntriesFoundLabel]="'No Zones Found.'" [duplicateGroupedItems]="duplicateGroupedZoneList" formControlName="zones"></puc-multi-select-checkbox>
                                </div>
                            </div>
                            </div>
                        </div>

                         <!-- Certification Level Selections -->
                         <div class="row p-b-5" *ngIf="isInstallerSelected">
                            <div class="row">
                                <h3 class="installer-section-title w-100">Certification Level</h3>
                                <h6 class="installer-section-text w-100">Certified users will have access to advanced features mentioned below.
                                    Non-certified user will have access to {{appName}}</h6>
                            </div>
                            <fs-installer-levels class="w-100 row" [control]="userForm.get('certificationLevel')"></fs-installer-levels>
                        </div>

                        <ng-template #accessInfoToolTip>
                            <div class="tooltip">
                                <div class="tooltip-header entity-tooltip entity-tooltip">
                                    <h5 class="tooltip-header">Access to</h5>
                                    <div class="tool-text">
                                        <span class="sub-text" *ngIf="isNonCertifiedUser">View Only Facilisight</span>
                                        <span class="sub-text" *ngIf="!isNonCertifiedUser">Facilisight</span><br>
                                        <span class="feature-options" *ngFor="let item of filteredFeatureItems">
                                            - {{item}} <br>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row">
                            <div class="col">
                                <label>Temperature Unit</label>
                                <div class="m-l-10 flex-grow">
                                    <puc-select-dropdown class="w-100" [data]="unitService.temperatureList"
                                        [valueKey]="'name'" [displayKey]="'name'" [search]="false"
                                        formControlName="temperature"></puc-select-dropdown>
                                </div>
                            </div>
                            <ng-container *ngIf="!showZonesDropdown">
                                <div class="col">
                                    <label>Energy Consumption Unit</label>
                                    <div class="m-l-10 flex-grow">
                                        <puc-select-dropdown class="w-50" [data]="unitService.energyConsumptionList"
                                            [valueKey]="'name'" [displayKey]="'name'" [search]="false"
                                            formControlName="energyConsumption"></puc-select-dropdown>
                                    </div>
                                </div>
                                <div class="col">
                                    <label>Airflow Volume Unit</label>
                                    <div class="m-l-10 flex-grow">
                                        <puc-select-dropdown class="w-100" [data]="unitService.airflowVolumeList"
                                            [valueKey]="'name'" [displayKey]="'name'" [search]="false"
                                            formControlName="airflowVolume"></puc-select-dropdown>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="row" *ngIf="!showZonesDropdown">
                            <div class="col">
                                <label>Air Pressure Unit</label>
                                <div class="m-l-10 flex-grow">
                                    <puc-select-dropdown class="w-100" [data]="unitService.airPressureList"
                                        [valueKey]="'name'" [displayKey]="'name'" [search]="false"
                                        formControlName="airPressure"></puc-select-dropdown>
                                </div>
                            </div>
                            <div class="col">
                                <label>Water Pressure Unit</label>
                                <div class="m-l-10 flex-grow">
                                    <puc-select-dropdown class="w-100" [data]="unitService.waterPressureList"
                                        [valueKey]="'name'" [displayKey]="'name'" [search]="false"
                                        formControlName="waterPressure"></puc-select-dropdown>
                                </div>
                            </div>
                            <div class="col">
                                <label>Water Flow Unit</label>
                                <div class="m-l-10 flex-grow">
                                    <puc-select-dropdown class="w-100" [data]="unitService.waterFlowList"
                                        [valueKey]="'name'" [displayKey]="'name'" [search]="false"
                                        formControlName="waterFlow"></puc-select-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <button id="add-user-cancel" class="btn" mat-button (click)="cancelForm()">Cancel</button>

                            <button id="add-user-confirm" class="btn" mat-button (click)="submitForm()"
                                [disabled]="userForm.invalid || !userForm.dirty">Add
                                <i class=" m-l-5 fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                    </form>
                </ng-template>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
</div>
