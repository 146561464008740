export const UserIntent  = {
    zoneSettings : {
        dischargeAirTemp: undefined,
        coolingSupplyAir: undefined,
        coolingDamper: undefined,
        heatingDamper: undefined,
        heatingSupplyAir: undefined,
        currentTemp: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        coolingDeadband: undefined,
        heatingDeadband: undefined,
        equipScheduleStatus: undefined,
        masterOperationMode: undefined,
        masterControllerMode: undefined,
        scheduleType: undefined,
        equipStatusMessage: undefined,
        damperPos: undefined,
        reheatPos: undefined,
        enteringAirTemp: undefined,
        zonePriority: undefined,
        ConditionMode: undefined,
        fanMode: undefined,
        buildingSchedule: undefined,
        zoneSchedule: undefined,
        zoneVacation: undefined,
        currentRate: undefined,
        energyReading: undefined,
        inputValue: undefined,
        offsetValue: undefined,
        targetValue: undefined
    },
    oaoArcOptions:  {
        size: 200,
        bgColor: 'transparent',
        animate: {
            enabled: true,
            duration: 2000,
            ease: 'bounce'
        },
        trackColor: "rgba(90,91,94, .2)",
        barColor: "var(--primary)",
        prevBarColor: "orange",
        // unit: "%",
        trackWidth: 10,
        barWidth: 10,
        textColor: 'rgba(3, 5, 4, 1)',
        opacity: 0.6,
        fontSize: 'auto',
        subText: {
            enabled: true,
            text: "PPM",
            color: 'var(--primary)',
            font: "auto"
        },
        scale: {
            enabled: true, type: '', color: 'gray', width: 0, quantity: 45, height: 100, spaceWidth: 0
        },
        step: 1,
        displayPrevious: true,
        min: 0,
        max: 2000,
        notifyOnDragEnd: true
    },
    options:  {
        zoneSchedule:
            [{
                type: 'scheduleType',
                name: 'Building',
                value: '0'
            },
            {
                type: 'scheduleType',
                name: 'Zone',
                value: '1'
            },
            {
                type: 'scheduleType',
                name: 'Named ',
                value: '2'
            }
        ],
        zoneScheduleRevamp:
            [{
                type: 'scheduleType',
                name: 'Zone',
                value: '1'
            },
            {
                type: 'scheduleType',
                name: 'Named ',
                value: '2'
            }
        ],
        zonePriority: [{
            type: 'zonePriority',
            name: 'High',
            value: '3'
        },
        {
            type: 'zonePriority',
            name: 'Normal',
            value: '2'
        },
        {
            type: 'zonePriority',
            name: 'Low',
            value: '1'
        },
        {
            type: 'zonePriority',
            name: 'None',
            value: '0'
        }],
    }
}