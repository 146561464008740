import { Injectable } from '@angular/core';
import { ConfigurationService, FeatureToggleService } from '@75f/portal-ui-components';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public pageLoadStart: any;
  public currentPage: any;
  public isAnalyticsEnabled: boolean;

  constructor(private configService: ConfigurationService,
    private featureToggleService: FeatureToggleService) {

      this.featureToggleService.flagChange.subscribe((flags) => {
        this.setFlags(flags);
      })
     }

     setFlags(flags) {
      this.isAnalyticsEnabled = flags.hasOwnProperty('google-analytics') ? flags['google-analytics'].value : false;
  }

  useFeatureToggles() {
    let sub = this.featureToggleService.featureFlagsSubject.subscribe((flags)=>{
      this.setFlags(flags);
      this.attachSrcToHTML();
      sub.unsubscribe();
    });
    this.featureToggleService.getFlags();
  }

  /* Google Analytics Configuration */
  addAnalyticsScript() {
    this.useFeatureToggles();
  }

  attachSrcToHTML() {
    if (this.isAnalyticsEnabled) {
      let analyticsKey = this.configService.getConfig('analyticsConfig').analyticsKey;
      let gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = this.configService.getConfig('analyticsConfig').analyticsUrl + analyticsKey;
      document.head.prepend(gtagScript);
      gtag('config', analyticsKey, { 'site_speed_sample_rate': 100 });
    }
   
  }

  /* Google Analytics log Page View */
  pageView(path) {
    this.currentPage = path;
    if (this.isAnalyticsEnabled) {
      gtag('event', 'page_view', {
        page_path: path,
        // 'metricX': 300
      })
    }
  }

  /* Google Analytics log Event  */
  eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    if (this.isAnalyticsEnabled) {
      gtag('event', eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue
      })
    }
  }

  /* Google Analytics log Page Load Time  */
  pageLoadTime(loadTime) {
    if (this.isAnalyticsEnabled) {
      gtag('event', 'timing_complete', {
        'name': 'Avg. Page load Time',
        'value': loadTime,
        'event_category': this.currentPage
      });
    }
  }

  /* Google Analytics log Exception  */
  logException(error, isFatal) {
    if (this.isAnalyticsEnabled) {
      gtag('event', 'exception', {
        'description': error,
        'fatal': isFatal
      });
    }
  }

  setPageLoadStartTime(time) {
    this.pageLoadStart = time;
  }

  getPageLoadStartTime() {
    return this.pageLoadStart;
  }
}
