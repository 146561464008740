import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { PucComponentsModule, AlertModule, AlertsConfigComponent } from '@75f/portal-ui-components';
import { SharedModule } from '@/shared/shared.module';
import { MaterialModule } from '@/shared/material.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AlertsConfigurationComponent } from './components/alerts-configuration/alerts-configuration.component';
const routes: Routes = [
    { path: '', component: AlertsConfigurationComponent }
];

@NgModule({
    declarations: [
    AlertsConfigurationComponent
  ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        MaterialModule,
        PucComponentsModule,
        AlertModule,
        NgxDaterangepickerMd.forRoot(),
        RouterModule.forChild(routes)
    ],
    entryComponents: [
        AlertsConfigComponent
    ]
})
export class AlertsConfigLayoutModule {
}
