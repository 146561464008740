import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '@75f/portal-ui-components';


@Injectable({
	providedIn: 'root'
})
export class CPMService {
    cpmServiceUrl = this.configService.getConfig('cpmServiceUrl');
    constructor(private httpClient: HttpClient,
        private configService: ConfigurationService) {
	}

    getPublishedPlants(orgName) {
        return this.httpClient.get(`${this.cpmServiceUrl}chillerplant/published/list/${orgName}`).pipe();
    }
}