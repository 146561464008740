<div>
    <div class="text-center p-b-20">
        <img class="about-page-img" src="./assets/images/logo-mark.svg" alt=""/>
    </div>
    <div class="padding-right">
        <b>Contact:</b>
    </div>
    <div class="d-flex align-items-center padding-row" *ngFor="let info of contactInfo">
        <div class="padding-right">
            <i class={{info.icon}} aria-hidden="true"></i>
        </div>
        <div>
            <div>{{info.label}}</div>
            <div class="info-txt"><a href='{{info.target}}' target="'{{info.target}}'">{{info.value}}</a></div>
        </div>
    </div>
    <div class="version-text p-t-20">
        <div id="appVersion">{{versionText}}: <b>{{version}}</b></div>
    </div>
    <div class="copy-right-txt">
        <div class="p-t-2" id="copyRight"><span [innerHTML]="copyright" name="far fa-copyright" aria-hidden="true"></span></div>
    </div>
</div>