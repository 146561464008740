import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm, FormGroupDirective } from '@angular/forms';
import {
    AlertService, ConfigurationService, CountriesService, LoaderService,
    UserManagementService, UnitService, ObjectUtil, SiteService, MultiSelectCheckboxComponent, forbiddenIfSplCharAtStartValidator, AuthenticationService
} from '@75f/portal-ui-components';
import { ErrorStateMatcher } from '@angular/material/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Component({
    selector: 'fs-add-users',
    templateUrl: './add-users.component.html',
    styleUrls: ['./add-users.component.scss'],
})

export class AddUsersComponent implements OnInit {
    @Input() isPrimary: boolean = false;
    @Input() siteId: string = '';
    @Input() orgManager: boolean = false;
    @Input() role: string;
    @Output() userAdded = new EventEmitter();
    @ViewChild('searchSelectInput', { static: false }) searchTextField: ElementRef;
    @ViewChild('multiSelect', { static: false }) multiSelectCheckbox: MultiSelectCheckboxComponent;
    userForm: FormGroup;
    isFormShown: boolean = false;
    searchinput = '';
    namePattern = /^(?:[A-Za-z0-9\s]+)(?:[A-Za-z0-9_-\s]*)$/;
    isInstallerSelected: boolean = false;
    filteredRoles;
    roles = [{
        id: 2,
        name: 'Secondary Manager',
        role: 'facilityManager'
    },
    {
        id: 5,
        name: 'Occupant',
        role: 'occupant'
    },
    {
        id: 6,
        name: 'Secondary Installer',
        role: 'installer'
    },
    {
        id: 3,
        name: 'View Only FM',
        role: 'facilityManager'
    }
    ]

    featureItems = [
        'Audit Trail',
        'Custom Alerts',
        'Custom Analytics',
        'Custom Parameters',
        'Custom Summary Table',
        'Energy Configuration',
        'Notes',
        'Site Manager',
        'Site Sequencer',
        'Remote Access',
        'Building Options'
      ];
    filteredFeatureItems: any;
    isNonCertifiedUser: boolean = false;

    buildingList = [];
    duplicateGroupedZoneList = [];
    groupedZoneList: Array<any> = []
    showBuildingDropdown: boolean;
    showZonesDropdown: boolean;
    errorMatcher = new CustomErrorStateMatcher();
    subscriptions: any = {};
    isInputEditable: boolean = false;
    selectedZones = [];
    appName: any;
    constructor(public unitService: UnitService, public fb: FormBuilder,
        public userMangementService: UserManagementService,
        private loaderService: LoaderService,
        public alertService: AlertService, private configService: ConfigurationService,
        private siteService: SiteService,
        public authService: AuthenticationService
    ) {
        this.appName = this.configService.getConfig('appDisplayName');
        this.createUserForm();
    }

    ngOnInit() {
        this.init();
    }

    init() {
        const self = this;
        self.resetData();

        if(this.isPrimary) {
            if(this.role == 'installer'){
                this.filteredRoles = this.roles.filter(role => role.id == 6)
            } else if(this.role == 'facilityManager'){
                this.filteredRoles = this.roles;
            }
        } else if(!this.isPrimary){
            switch (this.role) {
                case "facilityManager":
                    this.filteredRoles = this.roles.filter(role => role.id != 2);
                    break;
                case "installer":
                    this.filteredRoles = this.roles.filter(role => role.id == 6);
                    break;       
            }
        }

        this.userForm.controls.sites.setValue([this.siteId]);
        if (this.isFormShown) {
            this.formGroupedZonesBySiteId();
        }
    }

    /**
     *  This method resets the form data
     */
    resetData() {
        this.filteredRoles = this.roles;      
        this.resetForm();
        this.addValidations(['sites', 'zones'], null);
        this.showBuildingDropdown = false;
        this.showZonesDropdown = false;
        this.buildingList = [];
        this.isInputEditable = false;
    }
    /**
     *  This method check if the users already exits
     */
    checkUserExists() {
        if (this.userForm.controls.email.valid) {
            this.userMangementService.getUsers({
                searchText: this.userForm.controls.email.value,
                isExact: true

            }).subscribe((res: any) => {

                if (res.success && res.users.length) {
                    if (document.activeElement instanceof HTMLElement)
                        document.activeElement.blur();
                    this.userForm.controls.firstName.setValue(res.users[0].personalInfo.firstName);
                    this.userForm.controls.lastName.setValue(res.users[0].personalInfo.lastName);
                    this.userForm.controls.certificationLevel.setValue(this.updateCertificationLevel(res.users[0].certificateLevel));
                    this.userForm.controls.temperature.setValue(res.users[0].userPreferences.temperatureUnit);
                    this.userForm.controls.energyConsumption.setValue(res.users[0].userPreferences.energyConsumptionUnit);
                    this.userForm.controls.airflowVolume.setValue(res.users[0].userPreferences.airflowVolumeUnit);
                    this.userForm.controls.airPressure.setValue(res.users[0].userPreferences.airPressureUnit);
                    this.userForm.controls.waterPressure.setValue(res.users[0].userPreferences.waterPressureUnit);
                    this.userForm.controls.waterFlow.setValue(res.users[0].userPreferences.waterFlowUnit);
                } else {
                    this.userForm.controls.temperature.setValue(this.unitService.temperatureUnitListArray[1]);
                    this.userForm.controls.energyConsumption.setValue(this.unitService.energyConsumptionUnitListArray[0]);
                    this.userForm.controls.airflowVolume.setValue(this.unitService.airflowVolumeUnitListArray[0]);
                    this.userForm.controls.airPressure.setValue(this.unitService.airPressureUnitListArray[0]);
                    this.userForm.controls.waterPressure.setValue(this.unitService.waterPressureUnitListArray[0]);
                    this.userForm.controls.waterFlow.setValue(this.unitService.waterFlowUnitListArray[0]);
                }
            }, (err) => {

            })
        }
        this.isInputEditable = false;
    }

    updateCertificationLevel(level: string) {
        let certificationLevel;
        switch (level) {
            case 'L0':
                certificationLevel = 1;
                break;
            case 'L1':
                certificationLevel = 2;
                break;
            case 'L2':
                certificationLevel = 3;
                break;
            case 'L3':
                certificationLevel = 4;
                break;
        }
        return certificationLevel;
    }

    /**
     *  This method creates the required form fields with validators
     */
    createUserForm() {
        this.userForm = this.fb.group({
            "firstName": ["", [Validators.required,Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
            "lastName": ["", [Validators.pattern(this.namePattern),forbiddenIfSplCharAtStartValidator]],
            "email": ["", [Validators.required, Validators.email]],
            'role': ["", Validators.required],
            'certificationLevel': [""],
            'sites': ["", Validators.required],
            "zones": [""],
            "temperature": ["", Validators.required],
            "energyConsumption": ["", Validators.required],
            "airflowVolume": ["", Validators.required],
            "airPressure": ["", Validators.required],
            "waterPressure": ["", Validators.required],
            "waterFlow": ["", Validators.required],
        });
        this.userForm.controls.role.valueChanges.subscribe((val) => {
            if (val && val.id == 5) {
                this.showZonesDropdown = true;
                this.addValidations(['zones'], [Validators.required]);
                if(!this.userForm.controls.energyConsumption.value) {
                    this.userForm.controls.energyConsumption.setValue(this.unitService.energyConsumptionUnitListArray[0]);
                } 
                if(!this.userForm.controls.airflowVolume.value) {
                    this.userForm.controls.airflowVolume.setValue(this.unitService.airflowVolumeUnitListArray[0]);
                }
                if(!this.userForm.controls.airPressure.value) {
                    this.userForm.controls.airPressure.setValue(this.unitService.airPressureUnitListArray[0]);
                }
                if(!this.userForm.controls.waterPressure.value) {
                    this.userForm.controls.waterPressure.setValue(this.unitService.waterPressureUnitListArray[0]);
                }
                if(!this.userForm.controls.waterFlow.value){
                    this.userForm.controls.waterFlow.setValue(this.unitService.waterFlowUnitListArray[0]);
                }

            } else {
                this.showZonesDropdown = false;
                this.addValidations(['zones'], null);
            }

        });
    }

    addValidations(controls, value) {
        controls.forEach(control => {
            this.userForm.controls[control].setValidators(value);
            this.userForm.controls[control].updateValueAndValidity();

        });
    }
    /**
     *  This method resets the form
     */
    resetForm() {
        this.userForm.reset();
        this.userForm.markAsUntouched();
        this.userForm.markAsPristine();
    }
    /**
     *  This method resets the form and cancels the form
     */
    cancelForm() {
        this.isFormShown = false;
        this.selectedZones = [];
        this.resetForm();
    }
    /**
     *  This method submits the form
     */
    submitForm() {
        this.createUser();
    }

    /**
     *  This method generates the payload
     */
    generatePayload(formData) {
        const payload = {
            "emailId": formData.email,
            "firstName": formData.firstName,
            "lastName": formData.lastName,
            "role": formData.role.role,
            "sites": [],
            "zones": [],
            userPreferences: {
                temperatureUnit: formData.temperature,
                energyConsumptionUnit: formData.energyConsumption,
                airflowVolumeUnit: formData.airflowVolume,
                airPressureUnit: formData.airPressure,
                waterPressureUnit: formData.waterPressure,
                waterFlowUnit: formData.waterFlow
            }
        }

        if (formData.role.id != 5) {
            payload["sites"] = formData['sites'].filter((_filterItem) => _filterItem)
                .map((_item) => {
                    const obj = {};
                    obj['siteId'] = _item;
                    if (formData.role.id == 3) {
                        obj['isReadOnly'] = true;
                    }
                    return obj;
                });
        } else {
            this.multiSelectCheckbox.selectedItems.forEach(floor => {
                floor.children.forEach(zone => {
                    payload['zones'].push({
                        "siteId": this.siteId,
                        "floorId": `@` + floor.id.toString().split(':')[1],
                        "floorName": floor.name,
                        "zoneId": `@` + zone.id.toString().split(':')[1],
                        "zoneName": zone.name,
                        "op": "add"
                    })
                });
            })
        }
        return payload;
    }

    /**
     *  This method creates a new user if the entered details in the form are valid
     */
    createUser() {
        const self = this;
        if (self.userForm.valid) {
            let formValues = self.userForm.value;
            const body: any = self.generatePayload(formValues);
            self.loaderService.active(true);
            self.subscriptions['createUser'] = self.userMangementService.createUser(body).subscribe((res) => {
                self.isFormShown = false;
                self.loaderService.active(false);
                if (res.sitesNeedConfirmation && res.sitesNeedConfirmation.length > 0) {
                    self.alertService.error(`${formValues.role.name} already exists`);
                    return;
                }
                self.alertService.success('User created successfully!');

                self.resetForm();
                self.userMangementService.userChanged$.next(true);
            }, err => {
                self.loaderService.active(false);
                self.alertService.error(err?.error?.message || 'Something went wrong');

            })

        }

    }

    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }

    getSelectedZones(event) {
        this.selectedZones = event;
    }

    roleChanged(){
        if(this.userForm.controls.role.value?.id === 6){
            this.isInstallerSelected = true;
        } else {
            this.isInstallerSelected = false;
        };

        let installerOptions = this.userForm.controls.certificationLevel.value ?? 1;
        this.userForm.controls.certificationLevel.setValue(installerOptions);     
    }

    formGroupedZonesBySiteId() {
        const query = `ver:"2.0"\nfilter\n"siteRef==${this.siteId} and ((profile and roomRef and not system) or (room or floor))"`
        this.siteService.queryAssist(query, `v2/read`).subscribe(res => {
            let roomIds = [];
            let profilesIds = [];
            const floorsIds = [];
            res.rows.forEach(_item => {
                if (_item.room) {
                    roomIds.push(_item);
                } else if (_item.profile) {
                    profilesIds.push(_item);
                } else if (_item.floor) {
                    floorsIds.push(_item);
                }
            });
            profilesIds = profilesIds.filter(prof => {
                if (prof.profile != "PLC" && prof.profile != 'EMR' && prof.profile.indexOf('SENSE') == -1 && prof.profile.indexOf('TEMP_INFLUENCE') == -1 && prof.profile.indexOf('MONITORING') == -1) {
                    return prof;
                }
            });
            roomIds = roomIds.filter(r => profilesIds.some(item => item.roomRef == r.id));
            this.groupedZoneList = floorsIds.map(r => {
                return {
                    id: r.id, name: r.dis, value: r.id,
                    children: roomIds.map(data => {
                        if (data.floorRef == r.id) {
                            return { id: data.id, name: data.dis, value: data.id };
                        }
                    }).filter(zone => zone)
                };
            });
            this.duplicateGroupedZoneList = ObjectUtil.deepCopy(this.groupedZoneList);
        })
    }

    generateTooltipText() {
        const div = document.createElement('div');
        this.selectedZones.forEach((parent, i) => {
            div.innerHTML += `${parent.name}:`;
            const span = document.createElement('span');
            span.style.position = 'absolute; !important';
            parent.children.forEach((child, j) => {
                const comma = j != parent.children.length - 1 ? ', ' : '';
                span.innerHTML += `<b>${child.name}${comma}</b>`;
            });
            const lineBreak = i != this.selectedZones.length - 1 ? ` <br>  ` : ``;
            div.appendChild(span);
            div.innerHTML += `${lineBreak}`;
        });
        div.style.height = '100%';
        return div.innerHTML;
    }

}

// define custom ErrorStateMatcher
export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl, form: NgForm | FormGroupDirective | null) {
        return control && control.invalid && control.touched;
    }
}

