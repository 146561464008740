import { Injectable } from '@angular/core';
import { ArrayUtil, ObjectUtil } from '@75f/portal-ui-components';

@Injectable({
    providedIn: 'root'
})
export class EnumService {
    enums: any[] = [
        {
            'enumType': 'priorityLevel',
            'enumItems': {
                'system': {
                    level: '14'
                },
                'building': {
                    level: '16'
                },
                'zone': {
                    level: '10'
                },
                'module': {
                    level: '8'
                }
            }
        }
    ];

    constructor() { }
    getEnum(key) {
        const self = this;
        return ObjectUtil.getFromPath(ArrayUtil.findInArray(self.enums, 'enumType', key), ['enumItems']);
    }
}
