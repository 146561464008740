<div class="acc-container"  *ngIf="primarySitesAvailable">
    <div class="username">Buildings</div>
    <!-- <div class="user-role">Primary Facility Manager</div> -->
    <div class="buildings m-t-20 p-0-30">
        <div class="building p-l-10 p-r-20 m-b-10" *ngFor="let site of filteredBuildings">
            <mat-checkbox class="m-r-10" [(ngModel)]="site.isSelected" (change)="toggleSelection(site)"></mat-checkbox>
            <div class="ctnr">
                <div class="name" title="{{site?.siteName}}">{{site?.siteName}}</div>
                <div class="address">
                  {{site?.locationDetails?.geoAddr}}
                  <br />
                  {{site?.locationDetails?.geoCity}}
                  <br />
                  {{site?.locationDetails?.geoState}} {{(site?.locationDetails?.geoCountry)?',':''}} {{site?.locationDetails?.geoCountry}}
                  {{(site?.locationDetails?.geoPostalCode)?',':''}} {{site?.locationDetails?.geoPostalCode}}
                </div>
            </div>
        </div>
    </div>


    <div class="transfer-form">
        <form [formGroup]="transferOwnershipForm" (ngSubmit)="onSubmit()">
            <div class="row">

                <div class="col">
                    <span class="icon-holder">
                    <i class="fas fa-user from-icon"></i>
                    <i class="fas fa-arrow-right from-icon"></i>
                    <i class="fas fa-user color-primary"></i>
                </span>
                    <label>Transfer to :</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown [placeholderLabel]="'Select User'" [isRequired]="true" [data]="usersList" [displayKey]="'emailId'" formControlName="transferUser" [valueKey]="'userId'"  (valueChanged)="userSelectionChange($event)" ></puc-select-dropdown>
                    </div>
                </div>
            </div>
            <div class="btn-ctnr">
                <button mat-stroked-button  (click)="cancelChanges()">Cancel</button>
                <button mat-stroked-button  [disabled]="transferOwnershipForm.invalid || !siteSelectedCount()" type="submit" >Transfer</button>
            </div>
        </form>
    </div>
</div>
<div class="container" *ngIf="!primarySitesAvailable && !loading">
    No primary buildings to transfer
</div>
