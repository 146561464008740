import { AuthenticationService, ConfigurationService } from '@75f/portal-ui-components';
import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        public router: Router,
        public authService: AuthenticationService,
        private configService: ConfigurationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.getUser();
        const array = ["/heatmap", "/scheduling", "/zones", "/systemoperations", "/settings/floorplan", "/settings/tuners", "/settings/buildingOptions"];
        const idx = array.indexOf(state.url);
        const selectedSite = JSON.parse(localStorage.getItem('SelectedSite'));
        if (currentUser) {
            if(idx > -1){
                if(selectedSite != null){
                    return true;
                }
                else {
                    this.router.navigate(['']);
                }
            } 
            return true;
        }
        return false;
    }
}
