<div class="account-layout">
    <div class="layout-header text-uppercase"><i class="fas fa-user-cog m-l-5 m-r-10"></i>Account Management</div>
    <div class="line"></div>
    <div class="container">
        <div class="routes-list">
            <div  [routerLink]="'details'" routerLinkActive="active">Account Details</div>
            <div  [routerLink]="'roles'" routerLinkActive="active">Role Details</div>
            <div  [routerLink]="'notification-mgt'" routerLinkActive="active">Notification Management</div>
            <div *ngIf="showPasswordLink" [routerLink]="'change-password'"  routerLinkActive="active">Change Password</div>
            <div [routerLink]="'transfer-ownership'"  routerLinkActive="active">Transfer Ownership</div>
        </div>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
