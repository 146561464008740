import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from '@/shared/_validators/custom-validators';
import { AlertService, AuthenticationService, LoaderService, UserService } from '@75f/portal-ui-components';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('btnReset', { static: false }) resetFormBtn;

  changePasswordForm: FormGroup;
  constructor(public fb: FormBuilder, public userService: UserService,
    private loaderService: LoaderService,
    public authService: AuthenticationService, public alertService: AlertService,
    private analyticsService: AnalyticsService) {
    this.createChangePasswordForm();

  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }

/**
 * It throws error if the password entered does not meet the criteria i.e the password must be at least 6 characters with at least 1 in Capital Case and at least 1 Special Character.
 */
  getErrorMessage() {
    let control = this.changePasswordForm.controls.password;
    return control.hasError('required') ? 'Password is required' :
      control.hasError('minlength') ? 'Must be at least 6 characters!' :
        control.hasError('hasCapitalCase') ? 'Must contain at least 1 in Capital Case!' :
          control.hasError('hasSpecialCharacters') ? 'Must contain at least 1 Special Character!' :
            '';
  }
/**
 * It generates form with the three fields i.e oldPassword, password and confirmPassword and checks for validation such as the password must be at least 6 characters with at least 1 in Capital Case and at least 1 Special Character .
 */
  createChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      "oldPassword": ["", Validators.required],
      "password": ["", Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        //CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        //CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 5. check whether the entered password has a special character
        CustomValidators.patternValidator(/[^A-Za-z0-9]/, { hasSpecialCharacters: true }),
        // 6. Has a minimum length of 8 characters
        Validators.minLength(6)])],
      "confirmPassword": ["", Validators.required]
    }, {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    });
  }


/**
 * It submits the change password form if all the fields meet the criteria or throws an error.
 */
  onSubmit() {
    if (this.changePasswordForm.valid) {
      this.loaderService.active(true);
      this.userService.changePassword({
        "currPass": this.changePasswordForm.controls.oldPassword.value,
        "newPass": this.changePasswordForm.controls.password.value
      }).subscribe((res) => {
        this.loaderService.active(false);
        this.alertService.success('Password updated successfully');
        this.resetFormBtn._elementRef.nativeElement.click();
        this.logout();
      }, err => {
        this.loaderService.active(false);
        this.alertService.error((err.error ? err.error.msg : 'Something went wrong') || 'Something went wrong');
      })
    }

  }
/**
 * This method logs out the User.
 */
  logout() {
    this.authService.logout();
  }
/**
 * This method resets the change password form7
 */
  cancelChanges() {
    this.changePasswordForm.reset();
    // this.changePasswordForm.updateValueAndValidity();


  }


}
