<div *ngIf="isSiteExists" class="row severity-block">
<div class="col-xl-4 col-md-4 col-sm-8 alertBlock">
    <puc-select-group-view [optionsList]="groupedSite" [key]="'siteName'" [valueKey]="'siteId'" (selectionChangeEvent)="siteSelectionChange($event)" class="select-site-dropdown"></puc-select-group-view>

    <mat-form-field class="search-input" [floatLabel]="'never'">
        <input id="alerts-config-search" autocomplete="off" trim="blur" matInput placeholder="Search For Alerts"
            #searchTextFieldForAlerts (keyup)="search(searchTextFieldForAlerts?.value)" />
        <i class="fas fa-search" matSuffix></i>
    </mat-form-field>
</div>

<div class="col-xl-5 col-md-5 col-sm-12 severity-main">
    <i class="fas fa-exclamation severity severity-severe"></i> <span class='severity-align'>SEVERE</span>
    <i class="fas fa-exclamation severity severity-moderate"></i> <span
        class='severity-align'>MODERATE</span>
    <i class="fas fa-exclamation severity severity-low"></i><span class='severity-align'>LOW</span>
</div>

</div>

<div class="container" *ngIf="!isSiteExists">
    User has no buildings assigned
</div>
<div *ngIf="selectedBuilding">
    <div class="sub-text">Notification that you will receive</div>
</div>

<div class="row p-b-30" *ngIf="selectedBuilding">
    <puc-notification-preference id="primaryNotificationPref" [siteId]="selectedBuilding.siteId" [userId]="userId" [alerts]="primaryAlerts" [canToggleSms]="canToggleSms"></puc-notification-preference>
   
    <div class="p-b-100 p-t-20 w-100" *ngIf="subUsers && subUsers?.length">
        <div class="sub-text m-b-5">Notification that you want 
            <mat-form-field class="sub-user-select siteSelectorFormField" [floatLabel]="'never'">
                <mat-select [placeholder]="'Select User'" [panelClass]="'sub-user-select-panel'" (selectionChange)="changeUserselection($event)" disableOptionCentering>
                
                <mat-option *ngFor="let user of subUsers" [value]="user.userId">
                    {{user.personalInfo.firstName  + (user.personalInfo.lastName?' '+user.personalInfo.lastName:'')}} {{"("+user.emailId+")"}}
                </mat-option>
                </mat-select>
            </mat-form-field> 
          to receive</div>
          <puc-notification-preference id="secondaryNotificationPref" [siteId]="selectedBuilding.siteId" [userId]="selectedSubUser" [alerts]="subUserAlerts" [canToggleSms]="canTogglSmsForSubuser"></puc-notification-preference>
    </div>
   
</div>