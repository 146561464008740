import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ArrayUtil, ConfirmModalComponent } from '@75f/portal-ui-components';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService, AuthenticationService, LoaderService, UserService } from '@75f/portal-ui-components';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({
  selector: 'app-transfer-ownership',
  templateUrl: './transfer-ownership.component.html',
  styleUrls: ['./transfer-ownership.component.scss']
})
export class TransferOwnershipComponent implements OnInit {

  buildings = [];
  filteredBuildings = [];
  transferOwnershipForm: FormGroup;
  usersList = [];
  usersListTemp = [];
  subscriptions = {};
  primarySitesAvailable: boolean = false;
  selectedUser;
  loading: boolean = false

  orgIdsList = [];
  constructor(public fb: FormBuilder,
    public dialog: MatDialog,
    private loaderService: LoaderService,
    public alertService: AlertService,
    public authService: AuthenticationService,
    private dom: DomSanitizer,
    public userService: UserService,
    private analyticsService: AnalyticsService) {
    this.createTransferOwnershipForm();
    this.getUserSites();
    // this.getAllUsers();

  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }

/**
 *'getUserSites()' method will get all the sites which the primary manager has.
 */
  getUserSites() {
    this.loading = true;
    this.loaderService.active(true);

    let userId = this.authService.getUser() ? this.authService.getUser().userId : null;

    this.subscriptions['sites'] = this.userService.getUserSites(userId).subscribe((res: any) => {
      this.loaderService.active(false);
      this.buildings = res.sites || [];

      this.filteredBuildings = this.buildings.filter(building => building.isPrimary);
      // let index = this.buildings.findIndex(site=>site.isPrimary);
      this.primarySitesAvailable = this.filteredBuildings.length > 0;
      this.loading = false;

    }, (err) => {

      this.loading = false;
      this.loaderService.active(false);
      this.alertService.error('Something went wrong, please try again');
    });
  }
/**
 *'getAllUsers()' method will add all the  users to usersList Array
 */
  getAllUsers() {
    let userId = this.authService.getUser() ? this.authService.getUser().userId : null;
    this.subscriptions['users'] = this.userService.getUsersRelatedToSites(
      userId, {
      orgIds: this.orgIdsList.join(",")
    }).subscribe((res: any) => {
      if (res.success)
        this.usersList = res.peers.peers;
      this.usersListTemp = ArrayUtil.clone(this.usersList);
    }, (err) => {
      this.alertService.error('Something went wrong, please try again');
    })
  }
/**
 * 'createTransferOwnershipForm()' method will generate form with transferUser field and validates the field.
 */
  createTransferOwnershipForm() {
    this.transferOwnershipForm = this.fb.group({
      "transferUser": ["", Validators.compose([Validators.required])]
    });
  }


/**
 * 'onSubmit()' method will check if the form is valid and whether any site is selected to transfer.If the condition is true it will call the confirmTransfer() method.
 */
  onSubmit() {
    if (this.transferOwnershipForm.valid && this.siteSelectedCount()) {
      this.confirmTransfer();
    }

  }
/**
* 'transfer()' method will transfer the role of Facility manager for the sites that will be selected to the other user.
* It will consider userId from which the role is being transferred and the values from transferownership form.
*/
  transfer() {
    let siteModifications = [];
    let transferUser: any = this.transferOwnershipForm.controls.transferUser.value;
    let userId = this.authService.getUser() ? this.authService.getUser().userId : null;
    this.buildings.forEach((site) => {
      if (site['isSelected']) {
        siteModifications.push(site.siteId)
      }
    });
    let payload = {
      "from": userId,
      "to": transferUser,
      "siteIds": siteModifications,
      "role": "facilityManager"
    }
    this.loaderService.active(true);
    this.userService.transferOwnership(payload).subscribe((res: any) => {
      this.loaderService.active(false);
      if (res.success) {
        this.alertService.success('Sites transfered successfully');
        this.transferOwnershipForm.reset();
        this.getUserSites();
      } else {
        this.alertService.error(res.msg)
      }

    }, err => {
      this.loaderService.active(false);
      this.alertService.error(err?.msg || 'Something went wrong')
    })
  }
/**
 * 'cancelChanges()' method will reset the transfer ownership form.
 */
  cancelChanges() {
    this.transferOwnershipForm.reset();
    if (this.buildings) {
      this.buildings.forEach((site) => {
        site['isSelected'] = false;
      })
    }
    this.usersList = [];
  }
/**
 * 'userSelectionChange()' method will take the change in user selection and assign it to selectedUser Array
 */ 
  userSelectionChange(event) {
    // console.log(event);
    this.selectedUser = event;
    this.usersList = ArrayUtil.clone(this.usersListTemp);
  }
/**
 * 'toggleSelection()' method will check if a site is selected and will push the the organization Id of that site to the orgIds list array.
 */
  toggleSelection(site) {
    //site['isSelected'] = !site['isSelected'];
    if (site['isSelected']) {
      this.orgIdsList.push(site.orgId);
    } else {
      this.orgIdsList = this.orgIdsList.filter(orgId => orgId != site.orgId);
    }

    if (this.orgIdsList.length > 0) {
      this.getAllUsers();
    } else {
      this.usersList = [];
    }

  }
/**
 * 'siteSelectedCount()' method will return a boolean value depending on whether a site is selected or not.
 */
  siteSelectedCount() {
    if (this.buildings) {
      for (let i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i]['isSelected']) return true;
      }
    }
    return false;
  }
/**
 * 'confirmTransfer()' method will display a dialogbox which asks the user to confirm the transfer of ownership to the selected user.
 * On confirming it will call the transfer() method.
 */
  confirmTransfer() {
    const self = this;
    let buildings = '';
    let userEmail = this.selectedUser[0].emailId;
    this.buildings.forEach((site) => {
      if (site['isSelected']) {
        buildings += buildings.length ? ', ' + site.siteName : site.siteName;
      }
    });
    const dialogRef = self.dialog.open(ConfirmModalComponent, {
      panelClass: 'fs-mat-dialog-container',
      width: '500px'
    });
    let msg = `<div>Please Confirm ownership transfer of <span class="color-primary">${buildings}</span> to <span class="color-primary">${userEmail}</span> </div>`
    const htmlContent = msg;
    dialogRef.componentInstance.title = 'Confirm Transfer';
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.transfer();
      }
    });
  }
  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    })
  }

}
