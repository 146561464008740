import { NgModule } from '@angular/core';
import { AuditTrailModule } from '@75f/portal-ui-components';



@NgModule({
    imports: [
      AuditTrailModule
    ],
   
})
export class AuditTrailFacilisightModule { }
