import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fs-alerts-configuration',
  templateUrl: './alerts-configuration.component.html',
  styleUrls: ['./alerts-configuration.component.scss']
})
export class AlertsConfigurationComponent implements OnInit {
  selectedId;
  ngOnInit(): void {
    const siteData = JSON.parse(localStorage.getItem('SelectedSite'));
    this.selectedId = siteData?.siteId?.replace("r:", "");
  }
}
