<div class="container">
    <div class="user-role">Password must be at least 6 characters long and must contain at least one <b>Uppercase Letter</b> and one <b>Special Character</b></div>

    <div class="account-form">
        <form [formGroup]="changePasswordForm"  (ngSubmit)="onSubmit()">
            <div class="row">
               
                <div class="col">
                    
                    <label>Current Password :</label>
                    <div class="m-l-10 flex-grow">
                        <mat-form-field class="w-100" [floatLabel]="'never'">
                            <input matInput type="password" formControlName="oldPassword" placeholder="" autocomplete="off"/>
                            <span matSuffix> <i class="fas fa-pen edit-icon"></i></span>
                            <mat-error *ngIf="changePasswordForm.controls.oldPassword.invalid && (changePasswordForm.controls.oldPassword.dirty || changePasswordForm.controls.oldPassword.touched)" class="error-msg">Password is required</mat-error>
                        </mat-form-field>
                    </div>
               
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>New Passsword :</label>
                    
                    <div class="m-l-10 flex-grow">
                        <mat-form-field class="w-100" [floatLabel]="'never'">
                            <input matInput type="password" formControlName="password" autocomplete="off"/>
                            <span matSuffix> <i class="fas fa-pen edit-icon"></i></span>
                            <mat-error *ngIf="changePasswordForm.controls.password.invalid && (changePasswordForm.controls.password.dirty || changePasswordForm.controls.password.touched)">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col">
                    <label>Confirm New Passsword :</label>
                    <div class="m-l-10 flex-grow">
                        <mat-form-field class="w-100" [floatLabel]="'never'">
                            <input matInput type="password" formControlName="confirmPassword" autocomplete="off"/>
                            <span matSuffix> <i class="fas fa-pen edit-icon"></i></span>
                            <mat-error *ngIf="changePasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch') && (changePasswordForm.controls.confirmPassword.dirty || changePasswordForm.controls.confirmPassword.touched)" class="error-msg">Password do not match</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="btn-ctnr">
                <button mat-stroked-button type="reset" #btnReset (click)="cancelChanges()">Cancel</button>
                <button mat-stroked-button [disabled]="changePasswordForm.invalid" type="submit" >Update</button>
            </div>
        </form>
    </div>
</div>
