import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

let component: any;
@Component({
    selector: 'fs-site-explorer-layout',
    templateUrl: './site-explorer-layout.component.html',
    styleUrls: ['./site-explorer-layout.component.scss']
})
export class SiteExplorerLayoutComponent implements OnInit {
    constructor(private analyticsService: AnalyticsService) {
        component = this;
    }

    siteId: String;
    siteName: String;

    ngOnInit() {
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));

        this.siteId = buildingDetails.siteId;
        this.siteName = buildingDetails.siteName;
    }

    ngAfterViewInit() {
        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }
}