<section class="tuners-sections p-l-20 p-t-30 p-r-20">
    <div class="row">
        <div class="col-3 settings-nav">
            <ul class="list-none text-uppercase">
                <li *ngFor="let submenu of submenus;let i =index;" class="m-20-a">
                    <a [routerLink]="submenu?.path" class="{{submenu?.key}}" id="{{'tuner-option-'+i}}" *ngIf="checkUserCertificationAccess(submenu.key)" [ngClass]="{'margin_left': submenu.key == 'building-options'|| 'remote-access'}" [routerLinkActive]="['active']">
                        {{submenu?.label}}
                    </a>
                    <ng-container *ngIf="submenu.key == 'tuner'">
                        <ul class="list-none tuner-radio-group">
                            <li class="tuner-radio-button" *ngFor="let type  of types;let i = index;">
                                <label>
                                    <input type="radio" name="type" (ngModelChange)="tunerChange($event)"
                                        [(ngModel)]="tunerLevel" [value]="type?.value" id="{{'radio-'+ i}}" />
                                    <span class="radiobtn"></span>
                                    <span class="text-uppercase">
                                        {{type?.label}}
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </ng-container>
                </li>
            </ul>
        </div>
        <div class="col-9 settings-content">
            <div class="row">
                <div class="col-12">
                    <ng-container *ngIf="checkUserCertificationAccess('tunersOta')">
                        <puc-command [level]="tunerLevel" [data]="sgTable._data" dataType ="tuners"[ccuObjDetails] = "ccuObj"></puc-command>
                    </ng-container>
                    <br>

                    <puc-smart-group-table #sgTable [hasSort]="false" [hasSearch]="false" [hasPagination]="false"
                        [pageType]="'tuners'" [type]="'building'" [data]="data" [columns]="columns"
                        [onTunerLevelChange$]="onTunerLevelChange$" [level]="tunerLevel" [isFac]="true">
                    </puc-smart-group-table>
                </div>

                <div class="col-md-12 col-xs-12 col-sm-12 tuners-label m-20-a p-b-10">
                    <div class="row">
                        <div class="col-md-9 col-xs-12 col-sm-6 p-l-0">
                            <label class="text-uppercase">
                                Tuners
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-12 p-10-0">
                    <puc-tuner-list #tunersList [hasGroup]="true" [level]="tunerLevel"
                        [isTunersUpdated$]="isTunersUpdated$" [notesList] = "filteredNotesList"  (onTunerApply)="onTunerApply($event)"
                        [buildingsData]="payload" (onEditClick)="editTuner($event)" [data]="tuners"
                        [portalType]="'facilisight'">
                    </puc-tuner-list>
                </div>
            </div>

        </div>


    </div>
</section>
<puc-modal
    [ngClass]="{'isConfirmationModalShow':confirmationModal.active,'isConfirmationModalHide':!(confirmationModal.active)}"
    footerActive="0" (closeModal)="closeconfirmationModal()">
    <div class="customModalForConfirmation">
        <div class="confirmation-dialog">
            <div class="confirmation-dialog__message">
                <i class="confirmation-dialog__message__icon fas fa-exclamation-triangle"></i>
                <span class="confirmation-dialog__message__text">Please select atleast one
                    SmartGroup/Building/System/Zone/Module to apply the edited tuner</span>
            </div>
            <div class="confirmation-dialog__buttongroup">

                <button id="confirm-tuner-button" class="confirmation-dialog__buttongroup__ok" (click)="closeconfirmationModal()">OK</button><br>
            </div>
        </div>
    </div>
</puc-modal>