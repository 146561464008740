import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountManagementComponent } from './pages/account-management/account-management.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { TransferOwnershipComponent } from './pages/transfer-ownership/transfer-ownership.component';
import { RoleDetailsComponent } from './pages/role-details/role-details.component';
import { NotificationManagementComponent } from './pages/notification-management/notification-management.component';


const routes: Routes = [
  {
    path:'',component:AccountManagementComponent, children:[{
      path:'details',component:AccountDetailsComponent
    },{
      path:'roles',component:RoleDetailsComponent
    },{
      path:'change-password',component:ChangePasswordComponent
    },{
      path:'transfer-ownership',component:TransferOwnershipComponent
    },{
      path:'notification-mgt',component:NotificationManagementComponent
    },{
      path:'',redirectTo:'details',pathMatch: 'full'
    }]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountManagementRoutingModule { }
