import { Component, OnInit, OnDestroy } from '@angular/core';
import { Building } from '@/shared/_models';

import { map } from 'rxjs/operators';
import {
    PucSliderInputOutputData, SliderUserIntentService, LoaderService,
    SiteService, VacationsService, HelperService, DeviceHelperService,
    PubNubService,UnitService,ConfigurationService, AuthenticationService, ObjectUtil, ArrayUtil
} from '@75f/portal-ui-components';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({
    selector: 'fs-building-schedule',
    templateUrl: './building-schedule.component.html',
    styleUrls: ['./building-schedule.component.scss']
})
export class BuildingScheduleComponent implements OnInit, OnDestroy {  
    skeletonContentLoaded:boolean;
    buildings: any = {
        buildings: {},
        floors: {},
        floorsMaster: [],
        ccus: {},
        floorCcu: {},
        rooms: {},
        equips: {},
        zones: [],
        schedule: [],
    };
    userTempPreference:any;
    scheduleId: any;
    scheduleInfo: any;
    buildingId: any;
    siteRef: any = undefined;
    isScheduleInfo: boolean = false;
    occupancyStatus: any;
    sliderInput: PucSliderInputOutputData = {
        currentTemp: "0",
        desiredTempHeating: "0",
        desiredTempCooling: "0",
        heatingUserLimitMin: "0",
        heatingUserLimitMax: "0",
        coolingUserLimitMin: "0",
        coolingUserLimitMax: "0",
        coolingDeadband: "0",
        heatingDeadband: "0",
    };
    buildingLimit: any = {
        min: 55,
        max: 90
    };

    defaultLimits: any = {
        desiredTempHeating: undefined,
        desiredTempCooling: undefined
    };

    vacationsData: any = {
        buildingVacations: undefined,
        roomRef: undefined,
        siteRef: undefined,
        siteTimeZone: undefined,
        isZoneVac: undefined
    }
    priorityArray: any = [];
    selectedBuilding: Building = { siteId: undefined, ccus: undefined, rooms: undefined, siteName: undefined };
    buildingMaster: any;
    showSlider: Boolean = undefined;
    showScheduler: Boolean = false;
    allZonesData: any = [];
    buildingSettings: any = {
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        buildingLimitMin: undefined,
        buildingLimitMax: undefined,
        ScheduleStatus: undefined,
        buildingSchedule: undefined,
        buildingVacation: undefined
    };
    heatingDeadBandCount: number = undefined;
    coolingDeadBandCount: number = undefined;
    subscriptions: any = {};
    siteTz;
    siteName = '';
    showSchedulerOccupancy: boolean = true;
    buildingHeaderText: any;
    messagingSubscribe;
    isVofm = false;
    query = '(schedulable or scheduleType or vacation or (building and occupancy) or (zone and schedule and days) or (special and schedule) or room or floor or ccu or equip or (building and limit and (min or max))) and siteRef == @'

    constructor(
        public siteService: SiteService,
        public helperService: HelperService,
        public deviceHelper: DeviceHelperService,
        public pubNubService: PubNubService,
        public loaderService: LoaderService,
        public unitService : UnitService,
        public sliderUserIntentService: SliderUserIntentService,
        public vacationsService: VacationsService,
        public configService: ConfigurationService,
        private analyticsService: AnalyticsService,
        private authService: AuthenticationService
    ) { 
    }
    pollingForData() {
        this.messagingSubscribe = setInterval(() => {
            this.helperService.clearWritablePointsUpdatedStatus();
            this.helperService.getPointData().subscribe(res => res);
        }, 60*1000);
    }

    async ngOnInit() {
       
        this.isVofm = this.authService.isUserViewOnly();
        let userPreference = this.authService.getLoggedInUserPreferences();
        this.loaderService.active(true);
        this.userTempPreference = userPreference.temperatureUnit;
        this.skeletonContentLoaded = false
        this.scheduleId = '';
        this.scheduleInfo = [];
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        this.siteName = buildingDetails.siteName;
        this.getBuildingRef(buildingDetails.siteId);
        this.initializeVacationsData();
        this.subscriptions["pageload"] = this.siteService.findByQuery(this.query+this.buildingId).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
        ).subscribe(
            (response: any) => {
                this.buildingMaster = response;
                this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                this.buildings.floors = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
                this.buildings.ccus = this.buildings.floorCcu = this.helperService.getBuildingComponents(this.buildingMaster, 'ccu');
                this.buildings['buildingOccupancy'] = [];
                this.getZonesList();
                this.buildings.equips = this.helperService.getBuildingComponents(this.buildingMaster, 'equip');
                this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule');
                this.buildings.tuners = this.getSiteObj(['tuner', 'equip']);
                this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy');
                this.loaderService.active(false);
                let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
                this.analyticsService.pageLoadTime(loadTime);
                this.setbuildingSettings();
                this.getUserSettingData("firstfetchCall");
                this.pubNubService?.subscribe([this.siteRef]);
                this.subscriptions["pageload"]?.unsubscribe();
                delete this.subscriptions["pageload"];
                this.pollingForData();
                
            }
        );

        this.subscriptions['vacationAddedSubscriptions'] = this.pubNubService.vacationAdded.subscribe({
            next: (addedVacId) => {
                this.UpdateBuildingsDataForVacationEdit(addedVacId);
            }
        });

        this.subscriptions['updateEntitySubscriptions'] = this.pubNubService.updateEntityForNamedSch.pipe(
        ).subscribe({
            next: (ids) => {
                if (this.buildingSettings?.buildingOccupancy?.id == ids.id[0]) {
                    this.UpdateBuildingsOccupancy();
                }
            }
        });

    }
/**
 *This method assigns zones by list.
 */
    getZonesList() {
        this.buildings.zones = [];
        this.buildings.floors.map((floor) => {
            floor.entities.map((ent) => {
                if (ent.type == "room") {
                    const profile = this.deviceHelper.getZoneProfile([ent]);
                    { this.buildings.zones.push({ 'id': ent._id, 'ccuRef': ent.referenceIDs['ccu'], 'name': ent.name, floorName: floor.name ,'profileType':profile[0]?.profileType}); }


                }
            });

        });
    }
/**
 *This method feteches all the building settings for the selected id.
 */
    getbuildingSettings(settingType: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
        if (pointID) {
            pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, settingType, profileEquipName, 'update'));
            Object.assign(this.priorityArray, { [settingType]: {} });
            this.priorityArray[settingType]['id'] = pointID;
        }
    }
/**
 *This method returns the site object.
 */

    getSiteObj(equipTags: any) {
        return this.buildings.equips
            .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1))[0];
    }
/**
 *This method calls getDeadBands by zones.
 */

    setbuildingSettings() {
        this.getbuildingSettings('buildingSchedule', this.getPointIdbyTags(this.buildings.schedule, ['building', 'days', 'schedule'], 'schedule'), 'schedule');
        this.getbuildingSettings('buildingOccupancy', this.getPointIdbyTags(this.buildings.buildingOccupancy, ['building', 'days', 'occupancy'], 'schedule'), 'schedule');
        this.getbuildingSettings('buildingSpecialSchedule', this.getPointIdbyTags(this.buildings.schedule, ['building', 'special', 'schedule', 'range'], 'schedule'), 'vacation');
        this.getbuildingSettings('buildingVacation', this.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'range', 'vacation'], 'schedule'), 'vacation');

        const siteObj = this.getSiteObj(['equip','tuner']);
        this.getbuildingSettings('buildingLimitMin', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'min', 'kind'],null,['tuner']), 'write');
        this.getbuildingSettings('buildingLimitMax', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'max', 'kind'],null,['tuner']), 'write');
        this.getbuildingSettings('buildingToZoneDifferential', this.helperService.getPointIdbyTags(siteObj, ['building', 'differential', 'zone', 'kind'],null,['tuner']), 'write');
        this.getbuildingSettings('heatingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'schedulable','default', 'limit', 'min']), 'write');
        this.getbuildingSettings('coolingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'schedulable' ,'default', 'limit', 'max']), 'write');
        this.getbuildingSettings('heatingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating',  'schedulable','default', 'limit', 'max']), 'write');
        this.getbuildingSettings('coolingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling',  'schedulable','default', 'limit', 'min']), 'write');
        this.getbuildingSettings('buildingLevelunoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['default', 'unoccupied', 'setback', 'schedulable']), 'write');
    
        this.getDeadBands();
        this.buildings.zones.map((zone) => {
            const schId = this.getSchIdRefence(zone.name);
            this.getbuildingSettings('zoneSchedule', [schId], 'schedule', zone.name);
            this.getbuildingSettings('scheduleType', this.helperService.getPointIdbyTags(this.buildings, ['zone', 'scheduleType'], zone.id), 'write', zone.name);
        });


    }

/**
 *This method sets the deadband value for the selected buildingSettings.
 */
    getDeadBands() {
        let heatingDeadBandPointIdList: any;
        let coolingDeadBandPointIdList: any;
        heatingDeadBandPointIdList = this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'schedulable']);
        coolingDeadBandPointIdList = this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'schedulable']);
        this.heatingDeadBandCount = heatingDeadBandPointIdList.length;
        heatingDeadBandPointIdList.map((pointId) => {
            let point = this.buildings.schedulable.filter((ent) => ent._id == pointId)[0];
            this.getbuildingSettings(point.name, [pointId], 'write', 'HeatingDeadbands');
        });

        this.coolingDeadBandCount = coolingDeadBandPointIdList.length;
        coolingDeadBandPointIdList.map((pointId) => {
            let point = this.buildings.schedulable.filter((ent) => ent._id == pointId)[0];
            this.getbuildingSettings(point.name, [pointId], 'write', 'CoolingDeadbands');
        });

    }
/**
 *This method returns an entity._id when the zone is in occupancy.
 */
    getPointIdbyTags(buildingObj: any, zoneTags: any, type: string) {
        let pt = [];
        if (type == 'occupancy') {
            buildingObj.map((x) => {
                x.entities.filter(entity => {
                    let matchEntity = zoneTags.every(elem => entity.markers.indexOf(elem) > -1);

                    if (matchEntity) {
                        pt.push(entity._id);
                    }
                });
            });
        }

        if (type == 'schedule') {
            buildingObj.filter(entity => {
                let matchEntity = zoneTags.every(elem => entity.markers.indexOf(elem) > -1);

                if (matchEntity) {
                    pt.push(entity._id);
                }
            });
        }

        if (pt) {
            return pt;
        }
    }
/**
 *This method updates building data when vacation mode is on.
 */
    UpdateBuildingsDataForVacationEdit(entityid: any) {
        let addedid = entityid;
        this.siteService.findByQuery(this.query+this.siteRef).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
        ).subscribe(
            (response: any) => {
                this.buildingMaster = response;
                if (response) this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule') || []; 
                if (this.buildings?.['entites']) {
                    this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                }
                this.skeletonContentLoaded = true;
                this.updateBuidingEntitesOnMessage(entityid);

            },
        );
    }

    updateBuidingEntitesOnMessage(entityid) {
        let specialscheduleIds = this.helperService.getPointIdbyTags(this.buildings.schedule, ['building','schedule', 'range', 'special']);
        let vacationids = this.helperService.getPointIdbyTags(this.buildings.schedule, ['building','schedule', 'range', 'vacation']);
        if(specialscheduleIds.includes(entityid))
            this.helperService.updatePointIdData(entityid, 'vacation', 'buildingSpecialSchedule', undefined, 'update');
        if(vacationids.includes(entityid)) 
            this.helperService.updatePointIdData(entityid, 'vacation', 'buildingVacation', undefined, 'update');
        
        this.getUserSettingData("vacationAdded");
    }
/**
 *This method displays all the settings for a user.
 */
    getUserSettingData(key?: string) {

        this.subscriptions[key] = this.helperService.getPointData()
            .subscribe(res => {
                if (res && (!ObjectUtil.isEqual(res, this.buildingSettings))) {
                    try {
                        if (key == "vacationAdded") {
                            if(res) this.buildingSettings = ObjectUtil.deepClone(res);
                            if (this.vacationsService.updateVacationList)
                                this.loadVacations();
                            this.subscriptions[key].unsubscribe();
                            delete this.subscriptions[key];
                        }
                        else {
                            if(res) this.buildingSettings = ObjectUtil.deepClone(res);
                            this.loadSchedulerData();
                            this.setSpecialSchedule();
                            this.loadVacations();
                            this.skeletonContentLoaded = true;
                        }
                    } catch (e) {
                        this.skeletonContentLoaded = true;
                     }
                }


            },err=>{
                this.skeletonContentLoaded = true;
            });
    }
/**
 *This method stoes building id for the selected building .
 */
    getBuildingRef(buildingRef) {
        this.buildingId = this.helperService.stripHaystackTypeMapping(buildingRef).split(' ')[0];
        this.siteRef = this.buildingId;
    }
/**
 *This method initializes and stores vacation data.
 */

    initializeVacationsData() {
        if (this.buildingId == "") {
            //Building cannot be null
            throw new Error("Cannot Identify Building Id.");
        }
        else {
            //Get vacations details

            //Set site ref
            this.vacationsData.siteRef = this.buildingId;

            //Not a zone 
            this.vacationsData.isZoneVac = false;
            this.vacationsData.roomRef = "";

            //Set time zone
            this.vacationsService.getSIteTz(this.buildingId);
            this.vacationsService.siteTimezone.subscribe(tz => {
                this.vacationsData.siteTimeZone = tz;
                this.siteTz = tz;
            });

        }
    }

/**
 *This method stores scheduler data by zones.
 */

    loadSchedulerData() {
        this.allZonesData = [];
       if (this.showSchedulerOccupancy) {
            if (this.buildingSettings && this.buildingSettings.buildingOccupancy && this.buildingSettings.buildingOccupancy.val) {
                this.buildings.zones.map((zone) => {
                    if(!['emr','sense','pid', 'monitoring','modbus'].includes(zone.profileType)){
                        if (this.buildingSettings[zone.name] && this.buildingSettings[zone.name]['scheduleType'] && this.buildingSettings[zone.name]['zoneSchedule'] && !(this.buildingSettings[zone.name]['zoneSchedule'].error) && (this.buildingSettings[zone.name]['scheduleType'].val > -1)
                        ) {
                            if (this.buildingSettings[zone.name]['scheduleType'].val != 0) {
                                this.buildingSettings[zone.name]['zoneSchedule']['zoneName'] = zone.name;
                                this.buildingSettings[zone.name]['zoneSchedule']['floorName'] = zone.floorName;
                                this.buildingSettings[zone.name]['zoneSchedule']['ccuRef'] = zone.ccuRef;
                                this.buildingSettings[zone.name]['zoneSchedule']['siteRef'] = this.siteRef ;
                                this.buildingSettings[zone.name]['zoneSchedule']['roomRef'] = zone.id;
                                this.buildingSettings[zone.name]['zoneSchedule']['scheduleType'] = this.buildingSettings[zone.name]['scheduleType'].val;
                                this.buildingSettings[zone.name]['zoneSchedule']['followBuilding'] = this.buildingSettings[zone.name]['zoneSchedule']?.followBuilding;
                                this.buildingSettings[zone.name]['zoneSchedule']['unoccupiedZoneSetback'] = this.buildingSettings[zone.name]['zoneSchedule']?.unoccupiedZoneSetback;
                                this.allZonesData.push(this.buildingSettings[zone.name]['zoneSchedule']);
                            }
                        }
                    }
                });
            }
            this.setSliderData();
        }



    }

    setSpecialSchedule() {
        if(this.buildingSettings && this.buildingSettings.buildingSpecialSchedule) {
            this.buildingSettings.buildingSpecialSchedule = Array.from(this.buildingSettings.buildingSpecialSchedule);
        } else {
             this.buildingSettings.buildingSpecialSchedule = [];
        }
    }
/**
 *This method is used for setting slider data.
 */

    setSliderData() {
        if (this.buildingSettings
            && this.buildingSettings.buildingLimitMin && this.buildingSettings.buildingLimitMin.val
            && this.buildingSettings.buildingLimitMax && this.buildingSettings.buildingLimitMax.val
            && this.buildingSettings.heatingUserLimitMin && this.buildingSettings.heatingUserLimitMin.val
            && this.buildingSettings.heatingUserLimitMax && this.buildingSettings.heatingUserLimitMax.val
            && this.buildingSettings.coolingUserLimitMin && this.buildingSettings.coolingUserLimitMin.val
            && this.buildingSettings.coolingUserLimitMax && this.buildingSettings.coolingUserLimitMax.val
        ) {
            this.unitPreferenceConversion();
        }
        else {
            if (
                (
                    this.buildingSettings
                    && this.buildingSettings.buildingLimitMin
                    && this.buildingSettings.buildingLimitMax
                    && this.buildingSettings.heatingUserLimitMin
                    && this.buildingSettings.heatingUserLimitMax
                    && this.buildingSettings.coolingUserLimitMin
                    && this.buildingSettings.coolingUserLimitMax
                )
                &&
                (
                    this.buildingSettings.buildingLimitMin.error
                    || this.buildingSettings.buildingLimitMax.error
                    || this.buildingSettings.heatingUserLimitMin.error
                    || this.buildingSettings.heatingUserLimitMax.error
                    || this.buildingSettings.coolingUserLimitMin.error
                    || this.buildingSettings.coolingUserLimitMax.error
                )
            ) {
                this.showSlider = false;
            }
        }

    }

    unitPreferenceConversion() {
        let userPreference = this.authService.getLoggedInUserPreferences();
        this.userTempPreference = userPreference.temperatureUnit;
        let showSlider = true;
        /**Temperature Unit prefernce conversions**/
        this.temperatureConversions()


        let heatingdeadbandcommaseparated = '';
        let coolingdeadbandcommaseparated = '';

        Object.entries(this.buildingSettings.HeatingDeadbands).forEach(([key, value]) => {
            if (value.hasOwnProperty('val')) {
                heatingdeadbandcommaseparated = heatingdeadbandcommaseparated + value['val'] + ',';
            }
            else if (value['error']) {
                showSlider = false;
            }
        });

        Object.entries(this.buildingSettings.CoolingDeadbands).forEach(([key, value]) => {
            if (value.hasOwnProperty('val')) {
                coolingdeadbandcommaseparated = coolingdeadbandcommaseparated + value['val'] + ',';
            }
            else if (value['error']) {
                showSlider = false;
            }
        });

        let zonesheatindeadbandrecieved = (heatingdeadbandcommaseparated.split(',').length - 1) == this.heatingDeadBandCount ? true : false;
        let zonescoolindeadbandrecieved = (coolingdeadbandcommaseparated.split(',').length - 1) == this.coolingDeadBandCount ? true : false;

        if (zonesheatindeadbandrecieved && zonescoolindeadbandrecieved) {
            this.showSlider = showSlider;
        }
        if (this.showSlider) {
            /**Temperature Unit prefernce conversions**/
            if (this.showSlider && this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
                this.sliderInput.heatingDeadband = this.unitService.convertingDeadBandValue(this.findMaxValuefromCommaSeparated(heatingdeadbandcommaseparated)).toString()
                this.sliderInput.coolingDeadband = this.unitService.convertingDeadBandValue(this.findMaxValuefromCommaSeparated(coolingdeadbandcommaseparated)).toString()
            } else {
                this.sliderInput.heatingDeadband = this.findMaxValuefromCommaSeparated(heatingdeadbandcommaseparated);
                this.sliderInput.coolingDeadband = this.findMaxValuefromCommaSeparated(coolingdeadbandcommaseparated);
            }
        }
    }

    temperatureConversions() {
        let userPreference = this.authService.getLoggedInUserPreferences();
        this.userTempPreference = userPreference.temperatureUnit;
      if (this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            this.buildingLimit.min = this.unitService.fahrenheitToCelecius(this.buildingSettings.buildingLimitMin.val,'buildingLimitMin')
            this.buildingLimit.max = this.unitService.fahrenheitToCelecius(this.buildingSettings.buildingLimitMax.val,'buildingLimitMax')
            this.sliderInput.heatingUserLimitMin = (this.unitService.fahrenheitToCelecius(this.buildingSettings.heatingUserLimitMin.val,'heatingUserLimitMin')).toString()
            this.sliderInput.heatingUserLimitMax = (this.unitService.fahrenheitToCelecius(this.buildingSettings.heatingUserLimitMax.val,'heatingUserLimitMax')).toString()
            this.sliderInput.coolingUserLimitMin = (this.unitService.fahrenheitToCelecius(this.buildingSettings.coolingUserLimitMin.val,'coolingUserLimitMin')).toString()
            this.sliderInput.coolingUserLimitMax = (this.unitService.fahrenheitToCelecius(this.buildingSettings.coolingUserLimitMax.val,'coolingUserLimitMax')).toString()
            this.defaultLimits.desiredTempHeating = (this.unitService.fahrenheitToCelecius(this.buildingSettings.coolingUserLimitMin.val,'coolingUserLimitMin')).toString()
            this.defaultLimits.desiredTempCooling = (this.unitService.fahrenheitToCelecius(this.buildingSettings.heatingUserLimitMax.val,'heatingUserLimitMax')).toString()
        } else {
            this.buildingLimit.min = this.buildingSettings.buildingLimitMin.val;
            this.buildingLimit.max = this.buildingSettings.buildingLimitMax.val;
            this.sliderInput.heatingUserLimitMin = this.buildingSettings.heatingUserLimitMin.val;
            this.sliderInput.heatingUserLimitMax = this.buildingSettings.heatingUserLimitMax.val;
            this.sliderInput.coolingUserLimitMin = this.buildingSettings.coolingUserLimitMin.val;
            this.sliderInput.coolingUserLimitMax = this.buildingSettings.coolingUserLimitMax.val;
            this.defaultLimits.desiredTempHeating = this.buildingSettings.coolingUserLimitMin.val
            this.defaultLimits.desiredTempCooling = this.buildingSettings.heatingUserLimitMax.val
        }
        this.defaultLimits.desiredTempCooling = this.sliderInput.coolingUserLimitMin;
        this.sliderInput.desiredTempCooling = this.sliderInput.coolingUserLimitMin;
        if (this.sliderInput.heatingUserLimitMax > this.sliderInput.heatingUserLimitMin) {
            this.sliderInput.desiredTempHeating = this.sliderInput.heatingUserLimitMax;
            this.defaultLimits.desiredTempHeating = this.sliderInput.heatingUserLimitMax;
        } else {
            this.sliderInput.desiredTempHeating = this.sliderInput.heatingUserLimitMin;
            this.defaultLimits.desiredTempHeating = this.sliderInput.heatingUserLimitMin;
        }
    }
/**
 *This method assigns the value for vacationsData in buildingVacations.
 */
    loadVacations() {
        if (this.buildingSettings && this.buildingSettings.buildingVacation) {
            this.vacationsData.buildingVacations = Array.from(this.buildingSettings.buildingVacation);
        }
    }

/**
 *This method returns the maximum value seperated by comma.
 */
    findMaxValuefromCommaSeparated = (input) => {
        let output = input.split(',');
        output.pop();
        output = output.reduce((prev, curr) => {
            return Math.max(Number(prev), Number(curr))
        });
        return output;
    };
/**
 *This method fetches the ccu information for the selected building.
 */

    getCCU(priorityArray) {
        if(priorityArray && priorityArray['data']) {
            return this.buildings.ccus.find(ccu =>{
                return priorityArray['data'][0].who.includes(ccu._id)
            })
        }
    }

/**
 *This method performs custom clean-up.
 */

    ngOnDestroy() {
        sessionStorage.clear();
        this.vacationsService.updateVacationList = true;
        Object.keys(this.subscriptions).forEach((e) => {
            this.subscriptions[e].unsubscribe();
            delete this.subscriptions[e];
        });
        this.helperService.clearData();
        clearInterval(this.messagingSubscribe);

    }

    getSchIdRefence(zoneName) {
        let schId = null;
        this.buildings.floors.forEach(element => {
            const found = element.entities.find(r => r.name == zoneName);
            if (found && Object.keys(found) && Object.keys(found)?.length) {
                schId = found['referenceIDs']['schedule'];
            }	
        });
        return schId;
    }

    isDataLoaded(data: any, equipStatus: boolean = false) {
        return data ? this.helperService.isDataLoaded(data, equipStatus) : '';
    }

    UpdateBuildingsOccupancy() {
        this.siteService.findByQuery(this.query+this.siteRef).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
        ).subscribe((response: any) => {
            this.buildingMaster = response;
            this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy');
            this.getbuildingSettings('buildingOccupancy', (this.buildings?.buildingOccupancy?.length) ? [].concat(this.buildings?.buildingOccupancy[0]._id) : [], 'schedule');
            this.getUserSettingData();
        });
    }

}


