import { ConfigurationService } from '@75f/portal-ui-components';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({
  selector: 'fs-site-manager',
  templateUrl: './site-manager.component.html',
  styleUrls: ['./site-manager.component.scss']
})
export class SiteManagerComponent implements OnInit {

  urlSafe: SafeResourceUrl;
  isLoading: boolean = true;

  constructor(private configService: ConfigurationService,
              private sanitizer: DomSanitizer,
              private analyticsService: AnalyticsService,
              @Inject('LOCALSTORAGE') private localStorage: any) { }

  ngOnInit(): void {
    const appRoute = 'FACILISIGHT';
    const siteData = JSON.parse(localStorage.getItem('SelectedSite'));
    const selectedSiteId = siteData?.siteId?.replace("r:", "");
    const token = this.localStorage.getItem('bearer_token');
    let siteManagerUrl = this.configService.getConfig('siteManagerUrl');
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`${siteManagerUrl}?token=${token}&route=${appRoute}&selectedSite=${selectedSiteId}`);
  }

  ngAfterViewInit() {
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }
}
