import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, UserService, UserManagementService, 
  AlertService, NotificationsService, ObjectUtil, MessageService } from '@75f/portal-ui-components';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'fs-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.scss']
})
export class NotificationManagementComponent implements OnInit {
  getUserNotificationPrefrenece: any;
  siteId: any;
  users: any;
  constructor(private authService: AuthenticationService,
    private userService: UserService,
    private userMangementService: UserManagementService,
    private notificationService: NotificationsService,
    public alertService: AlertService,
    public messageService: MessageService, 
    private analyticsService: AnalyticsService) { 
      
    }
  subUserAlerts = [];
  subscriptions: any = {};
  userId;
  buildings = [];
  primaryAlerts;
  selectedBuilding;
  selectedSubUser;
  subUsers;
  groupedSite;
  isSiteExists: boolean;
  canToggleSms: boolean;
  tempPrimaryAlerts = [];
  tempSubUserAlerts = [];
  searchTextChanged = new Subject();
  searchText: any;

  @ViewChild('searchTextFieldForAlerts') searchTextFieldForAlerts: ElementRef;
  canTogglSmsForSubuser: boolean;

  ngOnInit(): void {
    this.canToggleSms = this.authService.getLoggedInUserDetails()?.phoneNum?.length ? true : false;
    this.isSiteExists = this.messageService.getSitesExist(); 
    this.userId = this.authService.getUser() ? this.authService.getUser().userId : null;
    this.getUserSites();

    this.subscriptions['searchText'] = this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((search) => {
        this.searchText = search;
        this.filterUsers();
      });
  }

  ngAfterViewInit(){
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }
  
  search(searchText) {
    this.searchTextChanged.next(searchText.trim());
  }

  filterUsers() {
    let searchValue = this.searchText || '';
    searchValue = searchValue.toLowerCase();
    if (searchValue.length) {
      this.primaryAlerts = this.tempPrimaryAlerts.filter(a => a.alert.mTitle.toLowerCase().includes(searchValue));
      this.subUserAlerts = this.tempSubUserAlerts.filter(a => a.alert.mTitle.toLowerCase().includes(searchValue));
    } else {
      this.primaryAlerts = this.tempPrimaryAlerts;
      this.subUserAlerts = this.tempSubUserAlerts;
    }
  }
/**
 * This method returns user sites grouped based on organization name
 */
  getUserSites() {
    this.subscriptions['sites'] = this.userService.getUserSitesOfFacilisight(this.userId).subscribe((res: any) => {
      
      this.buildings = res.sites || [];
      this.groupedSite = this.groupByOrg(res.sites,'orgName');
    }, (err) => {
      this.alertService.error('Something went wrong, please try again');
    });
  }
/**
 * This method returns user for the selected building
 */
  siteSelectionChange(event) {
    if(event) {
      if(!this.selectedBuilding || event != this.selectedBuilding.siteId) {
        event  = this.buildings.find(s=>s.siteId == event)
        this.selectedBuilding = ObjectUtil.deepClone(event);
        this.selectedBuilding.siteId = this.selectedBuilding.siteId.replace('@','');
        this.subUsers = [];
        this.selectedSubUser = '';
        this.subUserAlerts = [];
        this.primaryAlerts = null;
        this.tempPrimaryAlerts = [];
        this.tempSubUserAlerts = [];
        this.getNotificationPref(this.selectedBuilding);
        if(this.selectedBuilding.isPrimary)
          this.getUserBySite(this.selectedBuilding);
      }
    }
  }
/**
 * This method selects for the user for the specified site
 */
  changeUserselection(event) {
    this.selectedSubUser = event?.value;
    const selectedUser = this.subUsers?.find(user => user?.userId === this.selectedSubUser);
    this.canTogglSmsForSubuser = selectedUser?.personalInfo?.phoneNum ? true : false;
    this.getNotificationPref(this.selectedBuilding, this.selectedSubUser);
  }
/**
 * This method lets user set notification preferences
 */
  getNotificationPref(site,subUser?) {
    let userId = subUser?subUser:this.userId;
    this.notificationService.getUserNotificationPrefrenece(site.siteId,userId).subscribe(res=>{
      res.data = res.data.filter(a=>a.alert.mEnabled && a.alert.mAlertType == "CUSTOMER VISIBLE");
      if(subUser) {
        this.subUserAlerts = res.data;
        this.tempSubUserAlerts = JSON.parse(JSON.stringify(this.subUserAlerts));
      } else {
        this.primaryAlerts = res.data;
        this.tempPrimaryAlerts = JSON.parse(JSON.stringify(this.primaryAlerts));
      }
    },err =>{
      if(subUser) {
        this.subUserAlerts = [];
        this.tempSubUserAlerts = [];
      } else {
        this.primaryAlerts = [];
        this.tempPrimaryAlerts = [];
      }
    })
  }
/**
 * This method returns user for a particular site
 */
  getUserBySite(site) {
    this.userMangementService.getUserBySite('@'+site.siteId).subscribe((res) => {
      let users = [];
      if (res.users) {
          if (res.users['facilityManager']) {
              users = users.concat(res.users['facilityManager']);
          }
      }
      users = users.filter(u=>!u.isPrimary);
      this.subUsers = users;
      },err=>{
        this.subUsers = [];
      })
  }
/**
 * This method group sites based on organization name
 */
  groupByOrg(xs, key) {
    if(!Array.isArray(xs) || !key) return {};
    return xs.reduce((rv, x)=> {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    })
  }
}
