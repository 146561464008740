import { AuthenticationService } from '@75f/portal-ui-components';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RolesGuard implements CanActivate {
    constructor(
        public router: Router,
        public authService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const certificationLevel = localStorage.getItem('certificateLevel');
        const roles = route.data.roles as Array<string>;
        if (certificationLevel) {
            if (roles?.length) {
                return this.checkRolePermission(roles, certificationLevel);
            }
            return true;
        }
        return true;
    }

    checkRolePermission(roles, certificationLevel) {
        const accessPermission = roles.includes(certificationLevel);
        if (!accessPermission) {
            this.router.navigate(['/heatmap']);
        }
        return accessPermission ? true: false;
    }
}