import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserManagementService, MessageService, UserService, SiteService, HelperService } from '@75f/portal-ui-components';
import { catchError } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({
  selector: 'fs-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent implements OnInit {

  orgs = [];
  orgSites = {};
  primarySites = [];
  secondarySites = [];
  primaryInstaller = [];
  secondaryInstaller = [];
  vofmSites = [];
  isSiteExists: boolean;
  data = [];
  occupantList= [];

  constructor(public authService: AuthenticationService, public userMgtService: UserManagementService, public messageService: MessageService, private analyticsService: AnalyticsService, private userService: UserService, private siteService:SiteService, private helperService:HelperService) {
   
    this.userMgtService.mockUserChange$.subscribe((user) => {
      if (user) {
        let index = this.data.findIndex(u => u.emailId == user.emailId)
        if (index > -1) {
            this.data.splice(index, 1);
        }
        this.data.push(user);
    }
  })
  }

  ngOnInit() {
  this.isSiteExists = this.messageService.getSitesExist();  
  this.getOccupantUserZones()  
  this.getUserRoles();
  }

  ngAfterViewInit(){
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }

/**
 *  Here if the site role is facilityManager and if isPrimary == 1 then that site is pushed to primarySites Array else the site is added to secondarySites Array. If there is any error then it gets logged on the console.
 */
  getUserRoles() {
    let user = this.authService.getUser();

    let hasAccessToOrg = [];

    let forkJoinRq = [] ;
    forkJoinRq.push(this.userMgtService.getUserOrganizations(user.userId, {
      getSites: 'false'
    }).pipe(
      catchError(err => of({}))
    ))

    forkJoinRq.push(this.userMgtService.getBuildingsByUser(user.userId, {
      portal: 'facilisight'
    }).pipe(
      catchError(err => of({}))
    ))

    forkJoin(forkJoinRq).subscribe((res:any)=>{
      let orgs = res[0];
      let userSites = res[1]
      hasAccessToOrg = orgs.organizations.map(organization => organization.name);
      if (userSites?.sites?.length) {
        userSites.sites.forEach(site => {
          if (site.role === 'facilityManager') {
            if (site.isPrimary) {
              this.primarySites.push(site);
            }else if(site.isReadOnly){
              this.vofmSites.push(site)
            } else {
              this.secondarySites.push(site);
            }
          } else if(site.role === 'installer'){
            if (site.isPrimary) {
              this.primaryInstaller.push(site);
            } else {
              this.secondaryInstaller.push(site);
            }
          }

          if (site.orgId) {

            if (!this.orgSites[site.orgName]) this.orgSites[site.orgName] = {
              sites: []
            };

            this.orgSites[site.orgName].sites.push(site);
          }
        });
        for (let orgName of Object.keys(this.orgSites)) {

          if (hasAccessToOrg.indexOf(orgName) == -1) {
            delete this.orgSites[orgName];
          }
        }

        this.orgs = Object.keys(this.orgSites);
      } 
    })

  }
/**
 * This method sets closed property on mat-expansion-panel in the template as false.
 */
  collapseOrg(orgName) {
    this.orgSites[orgName]['open'] = false;
  }
/**
 * This method sets opened property on mat-expansion-panel in the template as true.
 */
  expandOrg(orgName) {
    this.orgSites[orgName]['open'] = true;
  }

  getOccupantUserZones() {
    const item =  this.authService.getUser();
    this.userService.getOccupantUserZones(item.userId).subscribe((res) => {
      if(res['zones'].length){
			const sites = [];
			const zoneIds = [];
			res['zones'].forEach(element => {
				sites.push(element.site_id.toString().split('@')[1]);
				zoneIds.push(element.zone_id.toString().split('@')[1]);
			});
			this.getUniquePointIds(zoneIds,	res['zones'])
    }
		});
	}


  getUniquePointIds(zoneIds, zonesList) {
    this.siteService.getReadByIdMany(zoneIds).subscribe(res => {
      let rooms = [];
      res.rows.forEach(r => {
        if (r.room) {
          rooms.push(r);
        } 
      });
      let obj = {};
      zonesList = zonesList.map(zone => {
        const room = rooms.find(room => (room.id.toString().split(":")[1] == zone.zone_id.toString().split("@")[1])); 
          zone['zoneName'] = room.dis;
          return zone;
      })

      let sites =this.groupBy(zonesList, (c) => c.site_name);
      Object.keys(sites).forEach(z => {
        this.occupantList.push({siteName:z, zones: sites[z]} )
      })
    })
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }



}
