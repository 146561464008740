import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@/shared/shared.module'
import { Routes, RouterModule } from '@angular/router';
import { SystemOperationsLayoutComponent } from './components/system-operations-layout/system-operations-layout.component';
import { PucDirectivesModule } from '@75f/portal-ui-components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
const routes: Routes = [
    { path: '', component: SystemOperationsLayoutComponent }
];

@NgModule({
    declarations: [
        SystemOperationsLayoutComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        NgxSkeletonLoaderModule,
        SharedModule,
        PucDirectivesModule

    ],
    providers: [

    ]
})
export class SystemOperationsLayoutModule { }
