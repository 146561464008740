import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NamedScheduleLayoutComponent } from './components/named-schedule-layout/named-schedule-layout.component';
import { SharedModule } from '@/shared/shared.module';
import { PucPipesModule } from '@75f/portal-ui-components';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

const routes: Routes = [
  {
      path: '', component: NamedScheduleLayoutComponent
  }
];

@NgModule({
  declarations: [NamedScheduleLayoutComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    PucPipesModule,
    RouterModule.forChild(routes)
  ]
})
export class NamedScheduleLayoutModule { }
